// **************************************************************************
// **************************************************************************
// **************************************************************************
// **************************************************************************
// <<<<<<<<<<<<<<<<<<<<<<<<<<     Staging        >>>>>>>>>>>>>>>>>>>>>>>>>>>>
// **************************************************************************
// **************************************************************************
// **************************************************************************
// **************************************************************************

// export const Chart_Base_Url =
//   "https://charts.mongodb.com/charts-project-0-kjtvb";
// export const chart_Data_Urls = {
//   Revenue_By_Locations: "625134b0-5d61-46db-86c0-48eef0760b6e",
//   Bookings_By_Brands: "625139bf-45ca-4d14-85ba-9a0e96d9ab76",
//   Revenue_by_Brands: "6256af9a-04a5-4f01-8929-ba406cf900cb",
//   Revenue_By_Booking_Type: "62514999-70f4-431b-8d96-68f6a4c6a942",
//   Bookings_By_Resource_Type: "625154e9-ed04-4f19-8b12-9548acd2508c",
//   Revenue_By_Resource_Type: "62514d58-905f-40e2-83f4-4c6b1b2877eb",
//   Bookings_By_Locations: "62515345-2438-4c2b-8a7d-0245e2511098",
//   Locations_With_Booking_Types: "6257b292-342b-4a2c-83f5-dfcb02f4bae1",
//   Users_By_Industry: "6256aed7-adc9-4235-8e2e-c4d5e33c4cd1",
//   Users_By_Position: "6256af21-ad42-491c-886b-c379e048fc5a",
//   Package_Id: "63b2cf71-b99d-402d-8a1f-82b42ce5b987",
//   Booking_By_ResourceType_team: "64bf8103-d49a-4a8e-8975-2a63d4e6c0ef",
//   Revenue_team: "64bf8278-f965-4ca3-897a-09b494e0fb7c",
//   Top_Five_Teams: "64bf84c9-f965-4ac4-8675-09b494e26967",
//   Revenue_By_Location_Teams: "64bf8987-8268-438c-8a39-46b532540867",
// };

// **************************************************************************
// **************************************************************************
// **************************************************************************
// **************************************************************************
// <<<<<<<<<<<<<<<<<<<<<<<     Production        >>>>>>>>>>>>>>>>>>>>>>>>>>>>
// **************************************************************************
// **************************************************************************
// **************************************************************************
// **************************************************************************

export const Chart_Base_Url =
  "https://charts.mongodb.com/charts-diom-prod-fzsyb";
export const chart_Data_Urls = {
  Revenue_By_Locations: "62a0878b-cc45-436c-8d66-83d52ac06b35",
  Bookings_By_Brands: "62a088e6-40c4-4f4e-842b-f254a09779d5",
  Revenue_by_Brands: "62a2f098-cc45-438d-8a0d-83d52a75f4cc",
  Revenue_By_Booking_Type: "62a08c70-40c4-4b39-8224-f254a0993687",
  Bookings_By_Resource_Type: "64bf78ba-250e-467f-8d5d-0ebe363cfd57",
  Revenue_By_Resource_Type: "62a08f03-16bf-45dd-8f72-231614c22055",
  Bookings_By_Locations: "62a09237-cc45-4dab-816f-83d52ac7c6e1",
  Locations_With_Booking_Types: "62a2f16f-8f99-4f92-8792-195c35bb6d2e",
  Users_By_Industry: "62a2e868-1170-4574-8045-351b43bb1e6a",
  Users_By_Position: "62a2ead6-8f29-47e8-8cfd-d46f9b05dead",
  Package_Id: "6465ca07-a657-4a3f-8535-58b17160f922",
};
