import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { getDiscountAmounts } from "../../../APIS/discountAmount";

const UseDiscount = () => {
  let history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams();
  const token = localStorage.getItem("Token");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [activeTabState, setActiveTabState] = useState(true);
  const [activeTabJustify, setActiveTabJustify] = useState("1");
  const changeCurrentPage = (pageNum) => setCurrentPage(pageNum);
  const QueryClient = useQueryClient();

  // const total=50;

  // const DiscountAmounts

  const {
    data: discountAmounts,
    hasNextPage,
    hasPreviousPage,
    total,
    isLoading,
  } = useQuery(["DiscountAmounts", activeTabState], () =>
    getDiscountAmounts(pageSize, currentPage, token, history, activeTabState)
  );
  const discountAmountData = discountAmounts?.data;

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const pagelengthnum = (e) => {
    if (e.target.value === "1") {
      setPageSize(10);
    } else if (e.target.value === "2") {
      setPageSize(30);
    } else if (e.target.value === "3") {
      setPageSize(50);
    }
  };
  const pageOptions = {
    sizePerPage: pageSize,
    totalSize: total ?? 0,
    custom: true,
  };

  const toggleCustomJustified = (tab) => {
    if (activeTabJustify !== tab) {
      setActiveTabJustify(tab);
    }
  };

  useEffect(() => {
    QueryClient.invalidateQueries("DiscountAmounts");
  }, [activeTabState]);

  const defaultSorted = [
    {
      dataField: "code",
      order: "asc",
    },
  ];

  const data = [
    {
      code: "No Data",
      type: "",
      amount: "",
      timesUsed: "No Data",
      lastUsed: "",
      nodata: true,
    },
  ];

  return {
    defaultSorted,
    data,
    currentPage,
    pageOptions,
    toggleCustomJustified,
    // userdetailsData,

    activeTabJustify,
    setActiveTabJustify,
    // userActivebookingsData,
    // userPastBookingsData,
    // userScheduledBookingsData,
    hasNextPage,
    hasPreviousPage,
    total,
    // pastHasNextPage,scheduledHasNextPage,
    isLoading,
    pagelengthnum,
    pageSize,
    toggle,
    isOpen,
    discountAmountData,
    changeCurrentPage,
    setActiveTabState,
  };
};

export default UseDiscount;
