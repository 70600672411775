// *********** Get All PAckage Category*************

import { DIOM_BASED_URLS } from "../config/url";

export const getPackagesCategories = async (token = "", history) => {
  const res = await fetch(`${DIOM_BASED_URLS}/package-categories`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  // ).json();
  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resJson = await res?.json();
  return {
    data: resJson,
  };
};

// *********** Get One PAckage Category*************

export const getPackageCategory = async (token = "", history, id = "") => {
  if (id !== "") {
    const res = await fetch(`${DIOM_BASED_URLS}/package-categories/${id}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    // ).json();
    if (!res.ok) {
      if (res.status === 401) {
        history.push("/login");
        throw new Error(resJson.error.message);
      }
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const resJson = await res?.json();
    return {
      data: resJson,
    };
  }
};

// **********resourceTypes of Packages*********

export const getResourcetypePackages = async (token = "") => {
  const res = await fetch(
    `${DIOM_BASED_URLS}/admin-resource-types-inventories?filter={"where":{"visibility":true ,"packageCategoryId":null  }}`,

    // `${DIOM_BASED_URLS}/admin-resource-types-inventories?filter={"where":{"visibility":true }}`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  // ).json();
  if (!res.ok) {
    if (res.status === 401) {
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson);
  }

  const resJson = await res.json();

  return {
    data: resJson?.data,
  };
};
