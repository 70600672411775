import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import Switch from "react-switch";
import UseEditDiscount from "./useEditDiscount";
import "../css/editDiscount.css";
import UserPromissionFetcher from "../../../../helpers/getUserPerimissions";
import Spinner from "../../../../components/Common/Loader";
import NoAccess from "../../../../components/Common/NoAccess";

const EditDiscount = () => {
  const userPermissions = UserPromissionFetcher("discountCodes");

  const {
    isLoading,
    setModal_static3,
    modal_static3,
    tog_static3,
    setModal_static4,
    modal_static4,
    tog_static4,
    discountAmountData,
    restrictions,
    cancelBtnState,
    cancelBtnHandler,
    useCancelBtnState,
    changeCurrentPage,
    minimumLimitationsMinutsFunc,
    minimumLimitationsHoursFunc,
    maximumLimitationsMinutsFunc,
    maximumLimitationsHoursFunc,

    discoutUpdateFunc,
    setRestrictions,
  } = UseEditDiscount();
  function Offsymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }

  function OnSymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }

  return (
    <>
      {userPermissions === null ? (
        <Spinner />
      ) : userPermissions.update ? (
        <div className="page-content">
          <Row className="mb-4">
            <Col lg={4} md={4} sm={8} xs={8}>
              <Link to="/discount" style={{ color: "black" }}>
                {" "}
                <span className="fas fa-angle-left arrowheightwidth"></span>
              </Link>
              <span className="bookingtitle ml-4">Edit a Code</span>
            </Col>
            <Col lg={4} md={4} sm={8} xs={8}></Col>
            <Col lg={2} md={2} sm={4} xs={4}>
              {cancelBtnState ? (
                <Button
                  onClick={tog_static3}
                  // type="reset"
                  outline
                  color="success"
                  className=" w-100 "
                  block
                >
                  <span className="e_a_btns ">Cancel</span>
                </Button>
              ) : (
                ""
              )}
              {/* </Link> */}
            </Col>
            <Col lg={2} md={2} sm={4} xs={4}>
              <Button
                // onClick={handlePrint}
                color="success"
                className=" mr-1 w-100 "
                block
                onClick={tog_static4}
              >
                <span className="e_a_btns ">Update</span>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Card className="p-4">
                <Row className="mt-3">
                  <Col md={12}>
                    <Label className="ed_label1 ">
                      Enter details for the new Discount Code
                    </Label>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <Label className="ed_label2">Discount code</Label>
                    <Input disabled={true} value={discountAmountData?.Code} />
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <Label Label className="ed_label2">
                      Status
                    </Label>
                    <Row>
                      <Col lg={2} md={2} sm={4} xs={4}>
                        <Switch
                          disabled={true}
                          name="always"
                          onHandleColor="#16b185"
                          width={70}
                          uncheckedIcon={Offsymbol(<small>NO</small>)}
                          checkedIcon={OnSymbol(<small>YES</small>)}
                          onColor="#a2a2a2"
                          checked={discountAmountData?.Active}
                          className="mr-1 mt-1  "
                        />
                      </Col>
                      <Col lg={4} md={4} sm={8} xs={8}>
                        <Label className="ed_label3 mt-2">
                          Active discount
                        </Label>
                      </Col>
                      <Col lg={2} md={2} sm={4} xs={4}>
                        <Switch
                          disabled={true}
                          name="always"
                          onHandleColor="#16b185"
                          width={70}
                          uncheckedIcon={Offsymbol(<small>NO</small>)}
                          checkedIcon={OnSymbol(<small>YES</small>)}
                          onColor="#a2a2a2"
                          checked={discountAmountData?.ReferralDiscount}
                          className="mr-1 mt-1  "
                        />
                      </Col>
                      <Col lg={4} md={4} sm={6} xs={6}>
                        <Label className="ed_label3 mt-2">
                          Referral discount
                        </Label>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Label className="ed_label2 mt-3">Description</Label>
                    <Input
                      type="textarea"
                      disabled={true}
                      value={discountAmountData?.Description}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Label className="ed_label2 mt-3">Discount amount</Label>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} md={1} sm={3} xs={3}>
                    <Label className="ed_label3 mt-3">Percentage</Label>
                  </Col>
                  <Col lg={1} md={1} sm={3} xs={3}>
                    <Switch
                      disabled={true}
                      name="always"
                      onHandleColor="#16b185"
                      width={70}
                      uncheckedIcon={Offsymbol(<small>NO</small>)}
                      checkedIcon={OnSymbol(<small>YES</small>)}
                      onColor="#a2a2a2"
                      checked={
                        discountAmountData?.DiscountAmount === null
                          ? false
                          : true
                      }
                      // onChange={(e) => alwaysFun(e)} // where this fn
                      className=" mt-2  ed_pecentage_toggle"
                    />
                  </Col>
                  <Col lg={2} md={2} sm={6} xs={6}>
                    <Label className="ed_label3 mt-3">Amount</Label>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <Input
                      className="mt-3"
                      disabled={true}
                      value={
                        discountAmountData?.DiscountAmount === null
                          ? discountAmountData?.DiscountPercentage
                          : discountAmountData?.DiscountPercentage === null
                          ? discountAmountData?.DiscountAmount
                          : ""
                      }
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}></Col>
                </Row>

                <Row>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <Label className="ed_label1 mt-3">Restrictions</Label>
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}></Col>
                </Row>

                <Row>
                  <Col lg={5} md={5} sm={12} xs={12}>
                    <Label className="ed_label3 mt-3">
                      Does this discount code has any minimum hourly limit?
                    </Label>
                  </Col>
                  <Col lg={1} md={1} sm={3} xs={3}>
                    <Label className="ed_label3 ed_right_label mt-3"> No</Label>
                  </Col>
                  <Col lg={1} md={1} sm={3} xs={3}>
                    <Switch
                      name="always"
                      onHandleColor="#16b185"
                      width={70}
                      uncheckedIcon={Offsymbol(<small>NO</small>)}
                      checkedIcon={OnSymbol(<small>YES</small>)}
                      onColor="#a2a2a2"
                      checked={restrictions?.hasMininumHourlyLimit}
                      onChange={(e) => {
                        setRestrictions({
                          ...restrictions,
                          hasMininumHourlyLimit: e,
                        });
                        // useCancelBtnState(true);  getting error dont call hook in callback...
                      }}
                      className="mr-1 mt-2  "
                    />
                  </Col>
                  <Col lg={1} md={1} sm={3} xs={3}>
                    <Label className="ed_label3 mt-3">Yes</Label>
                  </Col>
                  <Col lg={3} md={3} sm={6} xs={6}></Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Label className="ed_label4">
                      This limit checks whether a booking is above the mentioned
                      number of hours or not.
                    </Label>
                    <Label className="ed_label4">
                      If a booking exceeds the defined hours then an error shall
                      be shown to that user. Other wise, discount should be
                      applied.
                    </Label>
                  </Col>
                </Row>
                {restrictions?.hasMininumHourlyLimit &&
                restrictions?.hasMininumHourlyLimit === true ? (
                  <Row>
                    <Col lg={3} md={3} sm={6} xs={6}>
                      <p className="ed_label3 mt-2">Minimum Hours</p>
                      <Input
                        type="number"
                        disabled={!restrictions?.hasMininumHourlyLimit}
                        value={restrictions?.minimumHours}
                        onChange={(e) => {
                          minimumLimitationsHoursFunc(e);
                        }}
                      />
                    </Col>
                    {restrictions?.minimumHours ? (
                      <Col lg={3} md={3} sm={6} xs={6}>
                        <p className="ed_label3 mt-2">Minimum Minutes</p>
                        <Input
                          type="number"
                          disabled={!restrictions?.hasMininumHourlyLimit}
                          value={restrictions?.minimumMinutes}
                          onChange={(e) => {
                            minimumLimitationsMinutsFunc(e);
                          }}
                        />
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                ) : (
                  ""
                )}

                <Row className="mt-3">
                  <Col lg={5} md={5} sm={12} xs={12}>
                    <Label className="ed_label3 mt-3">
                      Does this discount code has any maximum hourly limit?
                    </Label>
                  </Col>
                  <Col lg={1} md={1} sm={3} xs={3}>
                    <Label className="ed_label3 ed_right_label mt-3"> No</Label>
                  </Col>
                  <Col lg={1} md={1} sm={3} xs={3}>
                    <Switch
                      name="always"
                      onHandleColor="#16b185"
                      width={70}
                      uncheckedIcon={Offsymbol(<small>NO</small>)}
                      checkedIcon={OnSymbol(<small>YES</small>)}
                      onColor="#a2a2a2"
                      checked={restrictions?.hasMaximumHourlyLimit}
                      onChange={(e) => {
                        setRestrictions({
                          ...restrictions,
                          hasMaximumHourlyLimit: e,
                        });
                        // useCancelBtnState(true);
                      }}
                      className="mr-1 mt-2  "
                    />
                  </Col>
                  <Col lg={1} md={1} sm={3} xs={3}>
                    <Label className="ed_label3 mt-3">Yes</Label>
                  </Col>
                  <Col lg={3} md={3} sm={6} xs={6}></Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Label className="ed_label4">
                      This limit checks whether a booking is below the mentioned
                      number of hours or not.
                    </Label>
                    <Label className="ed_label4">
                      If a booking is less than the defined hours then an error
                      shall be shown to that user. Other wise, discount should
                      be applied.
                    </Label>
                  </Col>
                </Row>
                {restrictions?.hasMaximumHourlyLimit &&
                restrictions?.hasMaximumHourlyLimit === true ? (
                  <Row>
                    <Col lg={3} md={3} sm={6} xs={6}>
                      <p className="ed_label3 mt-2">Maximum Hours</p>
                      <Input
                        type="number"
                        disabled={!restrictions?.hasMaximumHourlyLimit}
                        value={restrictions?.maximumHours}
                        onChange={(e) => {
                          maximumLimitationsHoursFunc(e);
                        }}
                      />
                    </Col>
                    {restrictions?.maximumHours ? (
                      <Col lg={3} md={3} sm={6} xs={6}>
                        <p className="ed_label3 mt-2">Maximum minutes</p>
                        <Input
                          type="number"
                          disabled={!restrictions?.hasMaximumHourlyLimit}
                          value={restrictions?.maximumMinutes}
                          onChange={(e) => {
                            maximumLimitationsMinutsFunc(e);
                          }}
                        />
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                ) : (
                  ""
                )}
              </Card>
            </Col>
          </Row>

          <div>
            <Modal isOpen={modal_static3} toggle={tog_static3} centered={true}>
              <ModalBody>
                <Row style={{ textAlign: "center" }}>
                  <p className="modeltitlecss mt-2 mb-2">Cancel Changes</p>
                </Row>

                <hr />
                <Row style={{ textAlign: "center" }}>
                  <p className="mt-3">
                    Are you sure you want to cancel changes?
                  </p>
                </Row>

                <hr />
                <Row>
                  <Col md={2}></Col>

                  <Col md={4}>
                    <Button
                      color="success"
                      outline
                      className="waves-effect waves-light w-100"
                      onClick={() => setModal_static3(false)}
                    >
                      No
                    </Button>
                  </Col>

                  <Col md={4}>
                    <Button
                      color="success"
                      className="waves-effect waves-light w-100"
                      onClick={cancelBtnHandler}
                    >
                      Yes
                    </Button>
                  </Col>
                  <Col md={2}></Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
          <div>
            <Modal
              isOpen={modal_static4}
              size="md"
              toggle={tog_static4}
              centered={true}
            >
              <ModalBody>
                <Row style={{ textAlign: "center" }}>
                  <p className="modeltitlecss mt-2 mb-2">Update Changes</p>
                </Row>

                <hr />
                <Row style={{ textAlign: "center" }}>
                  <p className="mt-3">
                    Are you sure you want to update changes?
                  </p>
                </Row>

                <hr />
                <Row>
                  <Col md={2}></Col>

                  <Col md={4}>
                    <Button
                      color="success"
                      outline
                      className="waves-effect waves-light w-100"
                      onClick={() => setModal_static4(false)}
                    >
                      No
                    </Button>
                  </Col>

                  <Col md={4}>
                    <Button
                      color="success"
                      className="waves-effect waves-light w-100"
                      onClick={discoutUpdateFunc}
                    >
                      Yes
                    </Button>
                  </Col>
                  <Col md={2}></Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default EditDiscount;
