import React, { useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  getcustomersPackages,
  getcustomersSearchPackages,
  getLocationPackges,
  getPackage,
} from "../../../../APIS/packages";
import { DIOM_BASED_URLS } from "../../../../config/url";
import { usePaginatedQuery } from "../../../../hooks/query";
import { useHistory } from "react-router-dom";

const UseEditPackage = () => {
  const history = useHistory();
  const [imageRefCover, setImageRefCover] = useState();
  const { id } = useParams();
  const token = localStorage.getItem("Token");
  const [secondRowData, setSecondRowData] = useState(true);
  const [secondState, setSecondState] = useState(false);
  const [cardViewLoading, setCardViewLoading] = useState(false);
  const [thirdRowData, setThirdRowData] = useState(true);
  const [thirdState, setThirdState] = useState(true);
  const [fourthRowData, setFourthRowData] = useState(true);
  const [detailedViewLoading, setDetailedViewLoading] = useState(false);
  const [fourthState, setFourthState] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState("");
  const [croppedImage, setCroppedImage] = useState(null);
  const [createPackageObj, setCreatePackageObj] = useState({});
  const [categoryTimeCredit, setCategoryTimeCredit] = useState({});
  const [categoryTimeCreditArray, setCategoryTimeCreditArray] = useState([]);
  const [detailImageButton, setDetailImageButton] = useState(false);
  const [modal_static3, setModal_static3] = useState(false);
  const [imgCC, setImgCC] = useState();
  const [lengthCategory, setLengthCategory] = useState();
  const [zoom1, setZoom1] = useState(1);
  const [zoom2, setZoom2] = useState(1);
  const [count, setCount] = useState(1);
  const [testImg, setTestImg] = useState();
  const [cate1, setCat1] = useState({});
  const [cate2, setCat2] = useState({});
  const [cate3, setCat3] = useState({});
  const [imageOneArea, setImageOneArea] = useState("");
  const [imageTwoArea, setImageTwoArea] = useState("");
  const [cardImageButton, setCardImageButton] = useState(false);
  const QueryClient = useQueryClient();
  const [crop, setCrop] = useState({ x: 0, y: 0, width: 173, height: 173 });
  const [zoom, setZoom] = useState(1);
  const [profileMedia, setProfileMedia] = useState();
  const [customerfinalOptions, setCustomerfinalOptions] = useState([]);
  const [customerSearchVer, setCustomerSearchVer] = useState();
  const [cropCover, setCropCover] = useState({
    x: 0,
    y: 0,
    width: 173,
    height: 173,
  });
  const [zoomCover, setZoomCover] = useState(1);
  const [coverMedia, setCoverMedia] = useState();
  const [crop11, setCrop11] = useState({
    unit: "px", // Can be 'px' or '%'
    x: 0,
    y: 0,
    width: 50,
    height: 50,
  });
  const [crop22, setCrop22] = useState({
    unit: "px", // Can be 'px' or '%'
    x: 0,
    y: 0,
    width: 50,
    height: 50,
  });
  const PackageValidityOption = [
    {
      label: "One Day",
      value: "OneDay",
    },
    {
      label: "One Week",
      value: "OneWeek",
    },
    {
      label: "Two Weeks",
      value: "TwoWeeks",
    },
    {
      label: "One Month",
      value: "OneMonth",
    },
    {
      label: "Three Months",
      value: "ThreeMonths",
    },
    {
      label: "One Year",
      value: "OneYear",
    },
  ];

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const tog_static3 = () => {
    setModal_static3(!modal_static3);
    removeBodyCss();
  };

  const pkgValidityFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, Validity: e?.value });
  };
  const pkgLocationFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, Location: e });
  };
  const pkgInventoryFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, Inventory: e?.target?.value });
  };
  const pkgPriorityFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, Priority: e?.target?.value });
  };
  const pkgCustomersFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, CustomersData: e });
  };
  const pkgConditionsFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, Conditions: e?.target?.value });
  };
  const pkgCategory3Func = (e) => {
    setCategoryTimeCredit({
      ...categoryTimeCredit,
      id: e?.value,
      name: e?.label,
    });
  };
  const pkgDisplayHours3Func = (e) => {
    setCategoryTimeCredit({
      ...categoryTimeCredit,
      DisplayHours: e?.target?.value,
    });
  };
  const pkgDisplayMinutes3Func = (e) => {
    setCategoryTimeCredit({ ...categoryTimeCredit, DisplayMinutes: e?.value });
  };
  const pkgActualHours3Func = (e) => {
    setCategoryTimeCredit({
      ...categoryTimeCredit,
      ActualHours: e?.target?.value,
    });
  };
  const pkgActualMinutes3Func = (e) => {
    setCategoryTimeCredit({ ...categoryTimeCredit, ActualMinutes: e?.value });
  };
  const pkgCategory2Func = (e) => {
    setCategoryTimeCredit({
      ...categoryTimeCredit,
      id: e?.value,
      name: e?.label,
    });
  };
  const pkgDisplayHours2Func = (e) => {
    setCategoryTimeCredit({
      ...categoryTimeCredit,
      DisplayHours: e?.target?.value,
    });
  };

  const pkgDisplayMinutes2Func = (e) => {
    setCategoryTimeCredit({ ...categoryTimeCredit, DisplayMinutes: e?.value });
  };

  const pkgActualHours2Func = (e) => {
    setCategoryTimeCredit({
      ...categoryTimeCredit,
      ActualHours: e?.target?.value,
    });
  };

  const pkgActualMinutes2Func = (e) => {
    setCategoryTimeCredit({ ...categoryTimeCredit, ActualMinutes: e?.value });
  };

  const pkgCategory1Func = (e, uid) => {
    const category = categoryTimeCreditArray.find(
      (category) => category.uid === uid
    );
    const newObject = { ...category, name: e.label, id: e.value };
    setCategoryTimeCreditArray((categoryTimeCreditArray) =>
      categoryTimeCreditArray.map((item) =>
        item.uid === uid ? newObject : item
      )
    );
  };

  const pkgDisplayHours1Func = (e, uid) => {
    // DisplayHours
    // setCategoryTimeCredit({
    //   ...categoryTimeCredit,
    //   DisplayHours: Number(e?.target?.value),
    // });

    // setCategoryTimeCreditArray([...categoryTimeCreditArray,])

    const category = categoryTimeCreditArray.find(
      (category) => category.uid === uid
    );
    const newObject = { ...category, DisplayHours: Number(e.target.value) };
    setCategoryTimeCreditArray((categoryTimeCreditArray) =>
      categoryTimeCreditArray.map((item) =>
        item.uid === uid ? newObject : item
      )
    );
  };
  const pkgDisplayMinutes1Func = (e, uid) => {
    // setCategoryTimeCredit({ ...categoryTimeCredit, DisplayMinutes: e?.value });

    const category = categoryTimeCreditArray.find(
      (category) => category.uid === uid
    );
    const newObject = { ...category, DisplayMinutes: Number(e?.value) };
    setCategoryTimeCreditArray((categoryTimeCreditArray) =>
      categoryTimeCreditArray.map((item) =>
        item.uid === uid ? newObject : item
      )
    );
  };
  const pkgActualHours1Func = (e, uid) => {
    // setCategoryTimeCredit({
    //   ...categoryTimeCredit,
    //   ActualHours: Number(e?.target?.value),
    // });

    const category = categoryTimeCreditArray.find(
      (category) => category.uid === uid
    );
    const newObject = { ...category, ActualHours: Number(e?.target?.value) };
    setCategoryTimeCreditArray((categoryTimeCreditArray) =>
      categoryTimeCreditArray.map((item) =>
        item.uid === uid ? newObject : item
      )
    );
  };
  const pkgActualMinutes1Func = (e, uid) => {
    // setCategoryTimeCredit({ ...categoryTimeCredit, ActualMinutes: e?.value });
    const category = categoryTimeCreditArray.find(
      (category) => category.uid === uid
    );
    const newObject = { ...category, ActualMinutes: Number(e?.value) };
    setCategoryTimeCreditArray((categoryTimeCreditArray) =>
      categoryTimeCreditArray.map((item) =>
        item.uid === uid ? newObject : item
      )
    );
  };
  const pkgNameFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, Name: e?.target?.value });
  };
  const pkgPriceFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, Price: e?.target?.value });
  };
  const pkgDescriptionFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, Description: e?.target?.value });
  };

  const pkgActiveateToggleFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, ActivateToggle: e });
  };
  const pkgTagInputFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, TagInput: e?.target?.value });
  };
  const pkgTagToggleFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, TagToggle: e });
  };

  const refundableMinutes = (e) => {
    setCreatePackageObj({
      ...createPackageObj,
      refundableMinutes: e?.target?.value,
    });
  };
  const refundableHours = (e) => {
    setCreatePackageObj({
      ...createPackageObj,
      refundableHours: e?.target?.value,
    });
  };
  const handleCustomerInputChange = (e) => {
    setCustomerSearchVer(e);
  };

  // *************
  const { data: locationsData, isLoading: loadingLocations } = useQuery(
    ["locationsData"],
    () => getLocationPackges(token)
  );
  // *******************

  const { data: packagesCustomerData, isLoading: CustomerLoading } =
    usePaginatedQuery(["packagesCustomerData"], () =>
      getcustomersPackages(token)
    );

  //   ***********************
  // *******************packagesCustomerSearchData********
  const { data: packagesCustomerSearchData, isLoading: CustomerSearchLoading } =
    usePaginatedQuery(
      ["packagesCustomerSearchData", customerSearchVer],
      () => getcustomersSearchPackages(token, customerSearchVer),
      {
        retry: 0,
      }
    );

  //   ***********************

  const {
    data: PackageData,
    hasNextPage,
    hasPreviousPage,
    total,
    isLoading,
  } = useQuery(["Package", id], () => getPackage(token, history, id));
  const packageDataa = PackageData?.data?.data;

  useMemo(() => {
    setCreatePackageObj({
      type: packageDataa?.type ? packageDataa?.type : "",
      Name: packageDataa?.name,
      Price: packageDataa?.price,
      TagToggle: packageDataa?.showTag,
      TagInput: packageDataa?.tags,
      ActivateToggle: packageDataa?.visibility,
      Description: packageDataa?.description,
      Conditions: packageDataa?.termsAndCondidtions,
      refundAbleMinutes: packageDataa?.refundAbleMinutes,

      refundableMinutes: Number(
        Number(packageDataa?.refundAbleMinutes) -
          Number(
            String(packageDataa?.refundAbleMinutes / 60).split(".")[0] * 60
          )
      ),

      refundableHours: Number(
        String(packageDataa?.refundAbleMinutes / 60).split(".")[0]
      ),

      CustomersData: packageDataa?.customers[0]
        ? packageDataa?.customers?.map((e) => ({
            label: e?.name,
            value: e?.id,
          }))
        : [],
      Priority: packageDataa?.priority,
      Inventory: packageDataa?.inventory,
      Validity: packageDataa?.validity,
      Location: packageDataa?.businesses
        ? packageDataa?.businesses?.map((e) => ({
            label: e?.Name,
            value: e?.id,
          }))
        : "",
    });
    setCat1(packageDataa?.categories[0]);
    setCat2(packageDataa?.categories[1]);
    setCat3(packageDataa?.categories[2]);
    setCategoryTimeCreditArray(
      packageDataa?.categories.map((item) => {
        return {
          ...item,
          uid: uuidv4(),
          DisplayHours: Number(String(item?.displayTime / 60).split(".")[0]),
          DisplayMinutes: Number(
            item?.displayTime -
              String(item?.displayTime / 60).split(".")[0] * 60
          ),
          ActualHours: Number(String(item?.actualTime / 60).split(".")[0]),
          ActualMinutes: Number(
            item?.actualTime - String(item?.actualTime / 60).split(".")[0] * 60
          ),
        };
      })
    );
    setSelectedFiles(packageDataa?.actualImage);
    setImageOneArea(packageDataa?.profileImage);
    setImageTwoArea(packageDataa?.coverImage);
    setLengthCategory(packageDataa?.categories?.length);
    if (packageDataa?.categories?.length > 1) {
      setSecondRowData(false);
      setThirdRowData(false);
      setThirdRowData(false);

      setFourthState(true);
      setThirdState(true);
      setSecondState(true);
    }
  }, [packageDataa]);
  // ********************

  const onCropEnd = (e) => {};

  const cropedImg1 = (e) => {};

  function Offsymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }

  function OnSymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }

  const timeOptions = [
    { label: 0, value: 0 },
    { label: 30, value: 30 },
  ];

  const secondRowDataFunc = () => {
    categoryTimeCreditArray.push({ uid: uuidv4() });
    setSecondRowData(false);
    setSecondState(true);
    setThirdState(false);
  };

  const rowSecondDellFunc = (uid) => {
    setSecondRowData(true);
    setSecondState(false);
    setThirdState(true);

    const newData = categoryTimeCreditArray.filter(
      (category) => category.uid !== uid
    );
    setCategoryTimeCreditArray(newData);
  };
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const handleAcceptedFiles = async (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        htmlFormattedSize: formatBytes(file.size),
      })
    );
    setTestImg(files);
    setImgCC(files);
    // setSelectedFiles(files);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("Token")}`
    );

    var formdata = new FormData();
    formdata.append("file", files[0], files[0].preview);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    await fetch(`${DIOM_BASED_URLS}/file-uploads`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setSelectedFiles(result[0]?.Location);
      })
      .catch((error) => toast.error(" Something went wrong"));
  };

  // *************POST Packages**********

  const updatePackagesFunc = () => {
    // categoryTimeCreditArray.push(categoryTimeCredit)
    const apiObjectPakcage = {
      visibility: createPackageObj?.ActivateToggle
        ? createPackageObj?.ActivateToggle
        : false,
      name: `${createPackageObj?.Name}`,
      price: Number(createPackageObj?.Price),
      description: `${createPackageObj?.Description}`,
      categories: categoryTimeCreditArray?.map((e) => {
        return {
          id: e?.id,
          name: e?.name,
          displayTime: Number(e?.DisplayHours * 60 + e?.DisplayMinutes),
          actualTime: Number(e?.ActualHours * 60 + e?.ActualMinutes),
        };
      }),
      validity: `${createPackageObj?.Validity}`,
      type: `${createPackageObj?.type ? createPackageObj?.type : ""}`,
      businesses: createPackageObj?.Location?.map((e) => ({
        id: Number(e?.value),
        name: `${e?.label}`,
      })),
      inventory: Number(createPackageObj?.Inventory),
      priority: Number(createPackageObj?.Priority),
      showTag: createPackageObj?.TagToggle
        ? createPackageObj?.TagToggle
        : false,
      tags: `${createPackageObj?.TagInput}`,
      customers: createPackageObj?.CustomersData[0]
        ? createPackageObj?.CustomersData?.map((e) => ({
            id: e?.value,
            name: e?.label,
          }))
        : [],
      refundAbleMinutes: Number(
        Number(createPackageObj?.refundableHours * 60) +
          Number(createPackageObj?.refundableMinutes)
      ),
      profileImage: `${imageOneArea}`,
      coverImage: `${imageTwoArea}`,
      actualImage: `${selectedFiles}`,
      termsAndCondidtions: `${createPackageObj?.Conditions}`,
    };

    if (createPackageObj?.Name === undefined) {
      toast.error("Name is required");
    } else if (createPackageObj?.Price === undefined) {
      toast.error("Price is required");
    } else if (createPackageObj?.Description === undefined) {
      toast.error("Description is required");
    } else if (createPackageObj?.Validity === undefined) {
      toast.error("Validity is required");
    } else if (createPackageObj?.Location[0]?.value === undefined) {
      toast.error("Location is required");
    } else if (createPackageObj?.Inventory === undefined) {
      toast.error("Inventory is required");
    } else if (createPackageObj?.Priority === undefined) {
      toast.error("Priority is required");
    } else if (selectedFiles === "") {
      toast.error("Actual image is required");
    } else if (imageTwoArea === "") {
      toast.error("Cover Image is required");
    } else if (imageOneArea === "") {
      toast.error("Profile Image is required");
    } else if (createPackageObj?.Conditions === undefined) {
      toast.error("Conditions  are required");
    } else {
      fetch(`${DIOM_BASED_URLS}/packages/${id}`, {
        method: "PATCH",
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + token,
        },

        body: JSON.stringify(apiObjectPakcage),
      })
        .then((response) => {
          if (response.ok) {
            toast.success("Package Updated Successfully");
            QueryClient.invalidateQueries("Packages");
          } else {
            return response.json();
          }
        })
        .then((result3) => {
          if (result3?.error) {
            toast.error(result3?.error?.message);
          }
        })

        .catch((error) => {
          toast.error("Error: ", " something went wrong");
          console.log(error);
        });
    }
  };

  const loadedImgCover = (img) => {
    setImageRefCover(img?.currentTarget);
  };

  // ****************profile img data*******
  const onCropCompleteProfile = (croppedArea, croppedAreaPixels) => {
    setCardImageButton(false);
    drawImage(croppedArea, croppedAreaPixels);
  };
  const drawImage = (croppedArea, croppedAreaPixels) => {
    const canvas = document.createElement("canvas");
    if (imageRefCover) {
      const ctx = canvas.getContext("2d");
      canvas.setAttribute("width", croppedAreaPixels.width);
      canvas.setAttribute("height", croppedAreaPixels.height);
      ctx.drawImage(
        imageRefCover,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );
      var image = canvas.toDataURL("image/jpeg", 1.0);

      setImageOneArea(image);
    }
  };
  // ****************************Cover img data**********
  const onCropCompleteCover = (croppedArea, croppedAreaPixels) => {
    setDetailImageButton(false);
    drawImageCover(croppedArea, croppedAreaPixels);
  };
  const drawImageCover = (croppedArea, croppedAreaPixels) => {
    const canvas = document.createElement("canvas");
    if (imageRefCover) {
      const ctx = canvas.getContext("2d");
      canvas.setAttribute("width", croppedAreaPixels.width);
      canvas.setAttribute("height", croppedAreaPixels.height);
      ctx.drawImage(
        imageRefCover,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );
      var image = canvas.toDataURL("image/jpeg", 1.0);

      setImageTwoArea(image);
    }
  };
  // ********************** Card View Base64*********************

  const cardViewBase64Func = () => {
    setCardViewLoading(true);
    const apiObjectPakcage = {
      file: `${imageOneArea}`,
    };
    fetch(`${DIOM_BASED_URLS}/base64-upload`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(apiObjectPakcage),
    })
      .then((response) => {
        return response.json();
      })
      .then((result3) => {
        setCardViewLoading(false);
        if (result3?.data?.Location) {
          toast.success("Image saved Successfully");
          setCardImageButton(true);
        }
        setImageOneArea(result3?.data?.Location);

        if (result3?.error) {
          setCardViewLoading(false);
          toast.error(result3?.error?.message);
        }
      })
      .catch((error) => {
        setCardViewLoading(false);
        toast.error("Error: ", error);
        console.log(error);
      });
  };

  // ********************** Detailed View Based64*************
  const detailedViewBase64Func = () => {
    setDetailedViewLoading(true);
    const apiObjectPakcage = {
      file: `${imageTwoArea}`,
    };
    fetch(`${DIOM_BASED_URLS}/base64-upload`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(apiObjectPakcage),
    })
      .then((response) => {
        return response.json();
      })
      .then((result3) => {
        setDetailedViewLoading(false);
        if (result3?.data?.Location) {
          toast.success("Image saved Successfully");
          setDetailImageButton(true);
        }
        setImageTwoArea(result3?.data?.Location);

        if (result3?.error) {
          setDetailedViewLoading(false);
          toast.error(result3?.error?.message);
        }
      })
      .catch((error) => {
        setDetailedViewLoading(false);
        toast.error("Error: ", error);
        console.log(error);
      });
  };

  return {
    setCreatePackageObj,
    CustomerSearchLoading,
    packagesCustomerSearchData,
    handleCustomerInputChange,
    detailImageButton,
    cardViewBase64Func,
    cardImageButton,
    imageTwoArea,
    imageOneArea,
    refundableMinutes,
    refundableHours,
    crop11,
    crop22,
    imgCC,
    setModal_static3,
    modal_static3,
    cardViewLoading,
    tog_static3,
    loadedImgCover,
    categoryTimeCreditArray,
    updatePackagesFunc,
    pkgActiveateToggleFunc,
    pkgTagInputFunc,
    pkgTagToggleFunc,
    pkgValidityFunc,
    pkgLocationFunc,
    pkgInventoryFunc,
    pkgPriorityFunc,
    pkgCustomersFunc,
    pkgConditionsFunc,
    pkgCategory3Func,
    pkgDisplayHours3Func,
    pkgDisplayMinutes3Func,
    pkgActualHours3Func,
    pkgActualMinutes3Func,
    pkgCategory2Func,
    pkgDisplayHours2Func,
    pkgDisplayMinutes2Func,
    pkgActualHours2Func,
    pkgActualMinutes2Func,
    pkgNameFunc,
    pkgPriceFunc,
    pkgDescriptionFunc,
    pkgCategory1Func,
    pkgDisplayHours1Func,
    pkgDisplayMinutes1Func,
    pkgActualHours1Func,
    pkgActualMinutes1Func,
    createPackageObj,
    loadingLocations,
    CustomerLoading,
    PackageValidityOption,
    packagesCustomerData,
    locationsData,
    secondRowData,
    setSecondRowData,
    secondState,
    setSecondState,
    thirdRowData,
    setThirdRowData,
    thirdState,
    setThirdState,
    fourthRowData,
    setFourthRowData,
    fourthState,
    setFourthState,
    selectedFiles,
    setSelectedFiles,
    crop,
    setCrop,
    zoom1,
    setZoom1,
    zoom2,
    setZoom2,
    count,
    setCount,
    croppedImage,
    setCroppedImage,
    onCropEnd,
    cropedImg1,
    Offsymbol,
    OnSymbol,
    timeOptions,
    secondRowDataFunc,
    rowSecondDellFunc,
    formatBytes,
    handleAcceptedFiles,
    packageDataa,
    categoryTimeCreditArray,
    categoryTimeCredit,
    setImageOneArea,
    setImageTwoArea,
    isLoading,
    onCropCompleteProfile,
    detailedViewLoading,
    setCrop,
    setZoom,
    zoom,
    crop,
    setProfileMedia,
    cropCover,
    zoomCover,
    setCropCover,
    onCropCompleteCover,
    setZoomCover,
    setCoverMedia,
    testImg,
    setTestImg,
    detailedViewBase64Func,
  };
};

export default UseEditPackage;
