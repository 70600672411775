import React from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  ModalBody,
  Modal,
  ModalHeader,
  Button,
  FormGroup,
  Label,
  Input,
  Form,
} from "reactstrap";
import "./css/faqs.css";
import UseFaqs from "./useFaqs";
import UserPromissionFetcher from "../../../../helpers/getUserPerimissions";
import Spinner from "../../../../components/Common/Loader";
import NoAccess from "../../../../components/Common/NoAccess";

const Faqs = () => {
  const userPermissions = UserPromissionFetcher("packages");

  const {
    isLoading,
    packageFaqDataOne,
    setModal_static1,
    modal_static1,
    tog_static1,
    setModal_static2,
    modal_static2,
    tog_static2,
    setModal_static3,
    modal_static3,
    tog_static3,
    packageFaqData,
    saveFAQData,
    faqsTitleChange,
    faqsBodyChange,
    deleteFaq,
    oneFaqData,
    faqEditBody,
    faqEditTitle,
    updateFaqFunc,
  } = UseFaqs();

  return (
    <div className="page-content">
      {isLoading ? (
        <Spinner />
      ) : userPermissions === null ? (
        <Spinner />
      ) : userPermissions.read ? (
        <>
          <Row className="mb-4">
            <Col md={4}>
              <Link to="/packages" className="link">
                <span className="fas fa-angle-left arrowheightwidth"></span>
              </Link>
              <span className="bookingtitle ">FAQs</span>
            </Col>
            <Col md={6}></Col>
            <Col md={2}>
              {userPermissions.create && (
                <Button
                  color="success"
                  className="waves-effect waves-light mr-1 w-100 "
                  block
                  onClick={tog_static1}
                >
                  <span className=" printbutton ">Create FAQ</span>
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            {packageFaqData?.map((e) => (
              <Col md={6}>
                <Card className="p-3">
                  <p className="f_title">Question : {e?.title}?</p>
                  <p className="f_title2">{e?.body}</p>
                  <span>
                    {userPermissions.delete && (
                      <i
                        className="delIcon mdi mdi-delete  deliconsize ml-5 f_del_icon"
                        onClick={() => tog_static3(e?.id)}
                      ></i>
                    )}
                    {userPermissions.update && (
                      <i
                        className="editIcon mdi mdi-lead-pencil  deliconsize f_edit_icon"
                        onClick={() => tog_static2(e?.id)}
                      ></i>
                    )}
                  </span>
                </Card>
              </Col>
            ))}
          </Row>

          <div>
            <Modal
              size="lg"
              isOpen={modal_static1}
              toggle={tog_static1}
              centered={true}
            >
              <ModalBody>
                <Row className="">
                  <Col md={8}>
                    {" "}
                    <p className="f_faq_model  mt-3">Create FAQ</p>
                  </Col>
                  <Col md={2}></Col>
                  <Col md={1}>
                    <i className=""></i>
                  </Col>
                </Row>

                <hr />
                <Row>
                  <Col md={12}>
                    <p className="f_faq_model_title1 mt-2">FAQ’s title</p>
                    <Input
                      type="text"
                      placeholder="Add your title for the FAQ here"
                      onChange={(e) => faqsTitleChange(e)}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <p className="f_faq_model_title1 mt-4">FAQ’s answer</p>
                    <Input
                      type="textarea"
                      placeholder="Add Description Here"
                      onChange={(e) => faqsBodyChange(e)}
                    />
                  </Col>
                </Row>

                <hr />
                <Row>
                  <Col md={3}></Col>
                  <Col md={3}></Col>

                  <Col md={3}>
                    <Button
                      color="success"
                      outline
                      className="waves-effect waves-light w-100"
                      onClick={() => setModal_static1(false)}
                    >
                      No
                    </Button>
                  </Col>

                  <Col md={3}>
                    <Button
                      color="success"
                      className="waves-effect waves-light w-100"
                      onClick={saveFAQData}
                    >
                      Create FAQS
                    </Button>
                  </Col>
                  {/* <Col md={3}></Col> */}
                </Row>
              </ModalBody>
            </Modal>
          </div>

          <div>
            <Modal
              size="lg"
              isOpen={modal_static2}
              toggle={tog_static2}
              centered={true}
            >
              <ModalBody>
                <Row className="">
                  <Col md={8}>
                    {" "}
                    <p className="f_faq_model  mt-3">Update FAQ</p>
                  </Col>
                  <Col md={2}></Col>
                  <Col md={1}>
                    <i className=""></i>
                  </Col>
                </Row>

                <hr />
                <Row>
                  <Col md={12}>
                    <p className="f_faq_model_title1 mt-2">FAQ’s title</p>
                    <Input
                      defaultValue={packageFaqDataOne?.title}
                      type="text"
                      placeholder="Add your title for the FAQ here"
                      onChange={(e) => {
                        faqEditTitle(e);
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <p className="f_faq_model_title1 mt-4">FAQ’s answer</p>
                    <Input
                      defaultValue={packageFaqDataOne?.body}
                      type="textarea"
                      placeholder="Add Description Here"
                      onChange={(e) => {
                        faqEditBody(e);
                      }}
                    />
                  </Col>
                </Row>

                <hr />
                <Row>
                  <Col md={3}></Col>
                  <Col md={3}></Col>

                  <Col md={3} sm={3}>
                    <Button
                      color="success"
                      outline
                      className="waves-effect waves-light w-100"
                      onClick={() => setModal_static2(false)}
                    >
                      Cancel
                    </Button>
                  </Col>

                  <Col md={3}>
                    <Button
                      color="success"
                      className="waves-effect waves-light w-100"
                      onClick={updateFaqFunc}
                    >
                      Update FAQS
                    </Button>
                  </Col>
                  {/* <Col md={3}></Col> */}
                </Row>
              </ModalBody>
            </Modal>
          </div>

          <div>
            <Modal isOpen={modal_static3} toggle={tog_static3} centered={true}>
              <ModalBody>
                <Row>
                  <Col md={4}></Col>
                  <Col md={8}>
                    {" "}
                    <p className="modeltitlecss mt-3">Delete FAQ</p>
                  </Col>
                </Row>

                <hr />
                <Row>
                  <Col md={2}></Col>
                  <Col md={9}>
                    {" "}
                    <p className="mt-3">
                      Are you sure you want to delete this FAQ?
                    </p>
                  </Col>
                  <Col md={1}></Col>
                </Row>

                <hr />
                <Row>
                  <Col md={2}></Col>

                  <Col md={4}>
                    <Button
                      color="success"
                      outline
                      className="waves-effect waves-light w-100"
                      onClick={() => setModal_static3(false)}
                    >
                      No
                    </Button>
                  </Col>

                  <Col md={4}>
                    <Button
                      color="success"
                      className="waves-effect waves-light w-100"
                      onClick={deleteFaq}
                    >
                      Delete
                    </Button>
                  </Col>
                  <Col md={2}></Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
        </>
      ) : (
        <NoAccess />
      )}
    </div>
  );
};

export default Faqs;
