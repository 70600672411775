import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getPackages } from "../../../APIS/packages";
import { DIOM_BASED_URLS } from "../../../config/url";

const UsePackages = () => {
  let history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [tabsNum, setTabsNum] = useState(1);
  // const { id } = useParams();
  const token = localStorage.getItem("Token");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [activeTabState, setActiveTabState] = useState(true);
  const [activeTabJustify, setActiveTabJustify] = useState("1");
  const [modal_static3, setModal_static3] = useState(false);
  const [modal_static4, setModal_static4] = useState(false);
  const [modal_static5, setModal_static5] = useState(false);
  const [activeInactiveState, setActiveInactiveState] = useState(true);
  const [delId, setDelId] = useState("");
  const [packageActiveInactiveId, setPackageActiveInactiveId] = useState("");
  const changeCurrentPage = (pageNum) => setCurrentPage(pageNum);
  const QueryClient = useQueryClient();

  const deletePackageFunc = async () => {
    const apiObjectPakcage = {
      command: "ARCHIVE_PACKAGE",
      value: `${delId}`,
      key: `${delId}`,
    };
    const res = await fetch(`${DIOM_BASED_URLS}/packages/run-commands`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(apiObjectPakcage),
    });

    if (!res.ok) {
      if (res.status === 401) {
        toast.error(resJson?.error?.message);
        setModal_static5(false);
      }
      const resJson = await res.json();
      toast.error(resJson?.error?.message);
      setModal_static5(false);
    } else {
      QueryClient.invalidateQueries("Packages");
      toast.success(" Successfully Deleted");
      QueryClient.invalidateQueries("Packages");
      setModal_static5(false);
    }
  };

  const packageActiveInactiveFunc = (e) => {
    fetch(`${DIOM_BASED_URLS}/packages/${packageActiveInactiveId}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        visibility: e,
      }),
    })
      .then((result3) => {
        QueryClient.invalidateQueries("Packages");
        if (result3.status === 200) {
          if (e === false) {
            toast.success("Package Deactivated");
          } else {
            toast.success("Package Activated");
          }

          setModal_static3(false);
          setModal_static4(false);
        } else if (result3.status === 204) {
          setModal_static3(false);
          setModal_static4(false);

          if (e === false) {
            toast.success("Package Deactivated");
          } else {
            toast.success("Package Activated");
          }
        } else {
          QueryClient.invalidateQueries("Packages");
          toast.error(" Something went wrong");
          setModal_static3(false);
          setModal_static4(false);
        }
      })
      .catch((error) => {
        QueryClient.invalidateQueries("Packages");
        toast.error(" Something went wrong");
      });
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const tog_static3 = (id) => {
    setPackageActiveInactiveId(id);
    setModal_static3(!modal_static3);
    removeBodyCss();
  };
  const tog_static4 = (id) => {
    setPackageActiveInactiveId(id);
    setModal_static4(!modal_static4);
    removeBodyCss();
  };
  const tog_static5 = (id) => {
    setDelId(id);
    setModal_static5(!modal_static5);
    removeBodyCss();
  };

  const {
    data: PackagesData,
    hasNextPage,
    hasPreviousPage,
    total,
    isLoading,
  } = useQuery(["Packages", activeInactiveState, tabsNum], () =>
    getPackages(
      pageSize,
      currentPage,
      token,
      history,
      activeInactiveState,
      tabsNum
    )
  );
  const packagesDataa = PackagesData?.data;

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const pagelengthnum = (e) => {
    if (e.target.value === "1") {
      setPageSize(10);
    } else if (e.target.value === "2") {
      setPageSize(30);
    } else if (e.target.value === "3") {
      setPageSize(50);
    }
  };
  const pageOptions = {
    sizePerPage: pageSize,
    totalSize: total ?? 0,
    custom: true,
  };

  const toggleCustomJustified = (tab) => {
    if (activeTabJustify !== tab) {
      setActiveTabJustify(tab);
    }
  };

  useEffect(() => {
    QueryClient.invalidateQueries("Packages");
  }, [activeTabState]);

  const defaultSorted = [
    {
      dataField: "code",
      order: "asc",
    },
  ];

  const data = [
    {
      code: "No Data",
      type: "",
      amount: "",
      timesUsed: "No Data",
      lastUsed: "",
      nodata: true,
    },
  ];

  return {
    setModal_static5,
    modal_static5,
    tog_static5,
    setModal_static4,
    modal_static4,
    tog_static4,
    setModal_static3,
    modal_static3,
    tog_static3,
    defaultSorted,
    data,
    currentPage,
    pageOptions,
    toggleCustomJustified,
    activeTabJustify,
    setActiveTabJustify,
    hasNextPage,
    hasPreviousPage,
    total,
    isLoading,
    pagelengthnum,
    pageSize,
    toggle,
    isOpen,
    packagesDataa,
    changeCurrentPage,
    setActiveTabState,
    deletePackageFunc,
    packageActiveInactiveFunc,
    setActiveInactiveState,
    tabsNum,
    setTabsNum,
  };
};

export default UsePackages;
