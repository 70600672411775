import { toast } from "react-toastify";
import { DIOM_BASED_URLS } from "../config/url";
import moment from "moment";

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns a list of all userProfile in DIOM
 */

//  Get all user By search
export const getsearcuserprofile = async (
  token,
  searcName,
  
) => {
  const url = searcName? `${DIOM_BASED_URLS}/users-search/${searcName}`:`${DIOM_BASED_URLS}/users-search/""`
  
  const res = await fetch(
   url,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    
   return toast.error("Something Went wrong");
  }
  const data = await res.json();

  const userData = data?.data?.map((user, index) => {
    return {
      id: index + 1,
      _id: user.id,
      userName: user.fullName,
      email: user.email,
      MobilePhone: user.phoneNumber,
      checkInTime: user.checkInTime
        ? moment(user.checkInTime).format("YYYY-MM-DD")
        : "",
      isSelected: false,
      role:user.role,
      phoneNumber:user.phoneNumber
    };
    // Todo Wheck check added in back end remove this filter
  }).filter((user)=>user.role==="User")
  const userDataApi = userData.map((user) => {
    return { value: user._id, label: `${user.email} (${user.phoneNumber})`};
  });

  return { dropDownData: userDataApi, aPIData: userData };
};

// Get mobile users for admin booking
export const getMobileUsers = async (
  token,
  searcName,
  
) => {
  const url =  `${DIOM_BASED_URLS}/mobile-users-search/${searcName}`
  
  const res = await fetch(
   url,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    
   return toast.error("Something Went wrong");
  }
  const data = await res.json();

  const userData = data?.data?.map((user, index) => {
    return {
      id: index + 1,
      _id: user.id,
      userName: user.fullName,
      email: user.email,
      MobilePhone: user.phoneNumber,
      checkInTime: user.checkInTime
        ? moment(user.checkInTime).format("YYYY-MM-DD")
        : "",
      isSelected: false,
      role:user.role,
      phoneNumber:user.phoneNumber
    };
    
  })
  const userDataApi = userData.map((user) => {
    return { value: user._id, label: `${user.email} (${user.phoneNumber})`};
  });

  return { dropDownData: userDataApi, aPIData: userData };
};


// Get All User Profile for listing Page
export const getuserprofile = async (
  size = 30,
  page = 1,
  token = "",
  history
) => {
  const res = await fetch(
    `${DIOM_BASED_URLS}/users?size=${size}&page=${page}&filter={ "where":{"role": "User"}}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }

  const resJson = await res.json();
  const userProfileData = resJson.users.map((element, index) => ({
    ...element,
    id: index + 1,
    _id: element.id,
    userName: element.fullName,
    email: element.email,
    MobilePhone: element.MobilePhone,
    checkInTime: element.checkInTime
      ? moment(element.checkInTime).format("YYYY-MM-DD")
      : "",
  }));
  return {
    data: userProfileData,
    total: resJson.total,
    hasNextPage: resJson.hasNextPage,
    hasPreviousPage: resJson.hasPreviousPage,
  };
};
export const getAdminUsersprofile = async (
  
  token = ""
  
) => {
  const res = await fetch(
    `${DIOM_BASED_URLS}/users?filter={ "where":{"role": "Admin"}}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  if (!res.ok) {
    if (res.status === 401) {
      // history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }

  const resJson = await res.json();
  
  return {
    data: resJson.data,
    total: resJson.total,
    hasNextPage: resJson.hasNextPage,
    hasPreviousPage: resJson.hasPreviousPage,
  };
};
// Get all users By specific Role
export const getroleusers = async (token, role) => {
  const res = await fetch(
    `${DIOM_BASED_URLS}/roles/users?assignedRole=${role}`,

    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  const data = await res.json();

  const roleUsers = data?.data?.map((user, index) => {
    return {
      id: index + 1,

      _id: user.id,
      name: user.FullName,
      email: user.email,
      mobileNumber: user.phoneNumber,
      assignedRole: user.assignedRole,
    };
  });

  return { data: roleUsers };
};

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns a list of user roles in DIOM
 */

export const getuserroles = async (
  size = 30,
  page = 1,
  token = "",
  history
) => {
  const res = await fetch(
    `${DIOM_BASED_URLS}/roles`,

    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  if (!res.ok) {
    const resJson = await res.json();

    if (res.status === 401) {
      history?.push("/login");
      throw new Error(resJson.error.message);
    }
    throw new Error(resJson.error.message);
  }
  const resJson = await res.json();
  const userRoleData = resJson.map((element, index) => ({
    id: index + 1,
    _id: element.id,
    name: element.name,
    permissions: element.permissions,
    canBeDeleted: element.canBeDeleted,
  }));

  return {
    data: userRoleData,
    // total: resJson.total,
    // hasNextPage: resJson.hasNextPage,
    // hasPreviousPage: resJson.hasPreviousPage,
  };
};

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns a list of one user in DIOM
 */

export const getuserdetails = async (
  size = 30,
  page = 1,
  token = "",
  id,
  history
) => {
  const res = await fetch(
    `${DIOM_BASED_URLS}/users/${id}?size=${size}&page=${page}`,

    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resJson = await res.json();
  const userdetailsData = resJson;

  return {
    data: userdetailsData,
    total: resJson.total,
    hasNextPage: resJson.hasNextPage,
    hasPreviousPage: resJson.hasPreviousPage,
  };
};
/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns a user Role Detail in DIOM
 */
export const getuserroledetails = async (token = "", id, history) => {
  const res = await fetch(
    `${DIOM_BASED_URLS}/roles/${id}`,

    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    const resJson = await res.json();

    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    throw new Error(resJson.error.message);
  }
  const userdetailsData = await res.json();
 
    
     

  

  return {
    data: userdetailsData
  };
};

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns a list of user active bookings in DIOM
 */

export const getUseractivebookings = async (
  size = 30,
  page = 1,
  token = "",
  id,
  history
) => {
  const res = await fetch(
    `${DIOM_BASED_URLS}/admin-diom-bookings/${id}/active?size=${size}&page=${page}`,

    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resJson = await res.json();
  // const userActivebookingsData = resJson.bookings.map((booking, index) => ({
  const userActivebookingsData = resJson.data.map((booking, index) => ({
    ...booking,
    id: index + 1,
    createdAt: moment(booking.createdAt).format("YYYY-MM-DD HH:mm"),
    _id: booking.id,
  }));

  return {
    data: userActivebookingsData,
    total: resJson.total,
    hasNextPage: resJson.hasNextPage,
    hasPreviousPage: resJson.hasPreviousPage,
  };
};

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns a list of user past bookings in DIOM
 */
export const getUserPastBookings = async (
  size = 30,
  page = 1,
  token = "",
  id,
  history
) => {
  const res = await fetch(
    `${DIOM_BASED_URLS}/bookings/users/${id}/history?size=${size}&page=${page}`,

    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }

  const resJson = await res.json();

  // const userPastBookingsData = resJson.bookings.map((booking, index) => ({
  const userPastBookingsData = resJson.data.map((booking, index) => ({
    ...booking,
    id: index + 1,
    createdAt: moment(booking.createdAt).format("YYYY-MM-DD HH:mm"),
    _id: booking.id,
  }));

  return {
    data: userPastBookingsData,
    total: resJson.total,
    hasNextPage: resJson.hasNextPage,
    hasPreviousPage: resJson.hasPreviousPage,
  };
};

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns a list of one user in DIOM
 */

export const getUserScheduledBookings = async (
  size = 30,
  page = 1,
  token = "",
  id,
  history
) => {
  const res = await fetch(
    `${DIOM_BASED_URLS}/bookings/users/${id}/scheduled?size=${size}&page=${page}`,

    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resJson = await res.json();

  const userScheduledBookingsData = resJson.bookings.map((booking, index) => ({
    ...booking,
    id: index + 1,
    createdAt: moment(booking.createdAt).format("YYYY-MM-DD HH:mm"),
    _id: booking.id,
  }));

  return {
    data: userScheduledBookingsData,
    total: resJson.total,
    hasNextPage: resJson.hasNextPage,
    hasPreviousPage: resJson.hasPreviousPage,
  };
};

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns a list of user  by profession in DIOM
 */

export const getUserByProfession = async (token = "", history) => {
  // return await (
  const res = await fetch(`${DIOM_BASED_URLS}/general/users/professions`, {
    method: "GET",
    redirect: "follow",
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const data = await res.json();

  return {
    data,
  };
  // ).json();
};
