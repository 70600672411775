import React from "react";

import "../css/InvoicesMainPage.css";
import {
  Row,
  Col,
  CardBody,
  Card,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  TabContent,
  NavLink,
  NavItem,
  Nav,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import "react-toastify/dist/ReactToastify.css";
import LoaderHook from "../../../hooks/loaderHook";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Link, useLocation } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../Tables/datatables.scss";
import { useInvoices } from "./useInvoices";
import Spinner from "../../../components/Common/Loader";
import UserPromissionFetcher from "../../../helpers/getUserPerimissions";
import NoAccess from "../../../components/Common/NoAccess";
const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: "id",
    order: "asc",
  },
];

function InvoicesMainPage() {
  const userPermissions = UserPromissionFetcher("invoices");
  const location = useLocation();

  const {
    membershipListingData,
    Teamlistingdata,
    packageHasNextPage,
    packageHasPreviousPage,
    packageTotal,
    packageIsLoading,
    packageListingData,
    InvoicesPackagedata,
    currentPage,
    pageOptions,
    Invoiceslistingdata,
    hasNextPage,
    isLoading,
    hasPreviousPage,
    total,
    toggleCustomJustified,
    pagelengthnum,
    pageSize,
    toggle,
    isOpen,
    activeTabJustify,
    setActiveTabJustify,
    getLocationdata,
    changeCurrentPage,
  } = useInvoices();

  const packagesColumns = [
    {
      dataField: "id",
      text: "No.",
      sort: true,
    },
    {
      dataField: "invoiceId",
      text: "Invoice ID",
      sort: true,
      formatter: (cell, row) => (
        <Link
          to={{ pathname: `/package_invoice_detail/${row.invoiceId}` }}
          className="link"
        >
          {" "}
          {cell}{" "}
        </Link>
      ),
    },

    {
      dataField: "name",
      text: "Name",
      sort: true,
    },

    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
    },
    {
      dataField: "totalPaid",
      text: "Total Paid",
      sort: true,
    },
    {
      dataField: "paymentMethod",
      text: "Payment Method",
      sort: true,
    },
  ];

  const columns = [
    {
      dataField: "id",
      text: "No.",
      sort: true,
    },
    {
      dataField: "invoiceId",
      text: "Invoice ID",
      sort: true,
      formatter: (cell, row) => (
        <Link
          to={{
            pathname: `/invoice/${row.invoiceId}`,
            state: { prevPath: location.pathname },
          }}
          className="link"
        >
          {" "}
          {cell}{" "}
        </Link>
      ),
    },

    {
      dataField: "customerData",
      text: "Name",
      sort: true,
    },

    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
    },
    {
      dataField: "bookingId",
      text: "Booking ID",
      sort: true,
      formatter: (cell, row) => (
        <Link
          to={{
            pathname: `/booking_detail/${row.bookingId}/1`,
            state: { prevPath: location.pathname },
          }}
          className="link"
        >
          {cell}
        </Link>
      ),
    },
    {
      dataField: "totalPaid",
      text: "Total Paid",
      sort: true,
    },
    {
      dataField: "paymentMethod",
      text: "Payment Method",
      sort: true,
    },
  ];

  const teamInv = [
    {
      dataField: "id",
      text: "No.",
      sort: true,
    },
    {
      dataField: "invoiceId",
      text: "Invoice ID",
      sort: true,
      formatter: (cell, row) => (
        <Link
          to={{ pathname: `/team_invoice_detail_page/${row.invoiceId}` }}
          className="link"
        >
          {" "}
          {cell}{" "}
        </Link>
      ),
    },
    {
      dataField: "userName",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "CreatedAt",
      sort: true,
    },
    {
      dataField: "subtotal",
      text: "Sub Total",
      sort: true,
    },
    {
      dataField: "totalPaid",
      text: "Total",
      sort: true,
    },
  ];
  return (
    <>
      {/* { activeTabJustify === "1" ? isLoading ==="true" : packageIsLoading ==="true" ? ( */}
      {isLoading ? (
        <Spinner />
      ) : userPermissions === null ? (
        <Spinner />
      ) : userPermissions.read ? (
        <div className="page-content">
          <div>
            <Row>
              <Col md={2}>
                <h5 className="loctiontitle">Invoices</h5>
              </Col>
              <Col md={2}></Col>
            </Row>
          </div>

          <div className="tablebgcolor">
            <div>
              <Row className="mt-2  ">
                <Col md={8}>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabJustify === "1",
                        })}
                        onClick={() => {
                          toggleCustomJustified("1");
                          // setActiveTabState("true");
                          // setTabsNum(1);
                          // setActiveInactiveState("true")
                          // getUseractivebookings();
                        }}
                      >
                        <span className="d-none d-sm-block">
                          Booking Invoices
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabJustify === "2",
                        })}
                        onClick={() => {
                          toggleCustomJustified("2");
                          // setActiveTabState("false");
                          // setTabsNum(2);
                          // setActiveInactiveState("false")
                          // fetchScheduledBookings();
                        }}
                      >
                        <span className="d-none d-sm-block">
                          Package Invoices
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabJustify === "3",
                        })}
                        onClick={() => {
                          toggleCustomJustified("3");
                        }}
                      >
                        <span className="d-none d-sm-block">Team Invoices</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabJustify === "4",
                        })}
                        onClick={() => {
                          toggleCustomJustified("4");
                        }}
                      >
                        <span className="d-none d-sm-block">
                          Membership Invoices
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTabJustify}>
                    <TabPane tabId="1" className="p-1"></TabPane>
                    <TabPane tabId="2" className="p-1"></TabPane>
                    <TabPane tabId="3" className="p-1"></TabPane>
                    <TabPane tabId="4" className="p-1"></TabPane>
                  </TabContent>
                </Col>

                <Col md={4}></Col>
              </Row>
            </div>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={
                        activeTabJustify === "1"
                          ? columns
                          : activeTabJustify === "2"
                          ? packagesColumns
                          : activeTabJustify === "3"
                          ? teamInv
                          : activeTabJustify === "4"
                          ? teamInv
                          : columns
                      }
                      // InvoicesPackagedata

                      data={
                        // activeTabJustify === "1"
                        //   ? Invoiceslistingdata
                        //   : packageListingData
                        activeTabJustify === "1"
                          ? Invoiceslistingdata
                          : activeTabJustify === "2"
                          ? packageListingData
                          : activeTabJustify === "3"
                          ? Teamlistingdata
                          : Invoiceslistingdata
                      }
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={
                            // activeTabJustify === "1" ? columns : packagesColumns
                            activeTabJustify === "1"
                              ? columns
                              : activeTabJustify === "2"
                              ? packagesColumns
                              : activeTabJustify === "3"
                              ? teamInv
                              : activeTabJustify === "4"
                              ? teamInv
                              : columns
                          }
                          // InvoicesPackagedata
                          data={
                            // activeTabJustify === "1"
                            //   ? Invoiceslistingdata
                            //   : packageListingData
                            activeTabJustify === "1"
                              ? Invoiceslistingdata
                              : activeTabJustify === "2"
                              ? packageListingData
                              : activeTabJustify === "3"
                              ? Teamlistingdata
                              : activeTabJustify === "4"
                              ? membershipListingData
                              : Invoiceslistingdata
                          }
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="search-box chat-search-box" />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="4"></Col>
                                <Col md="4"></Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      hover
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      // selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <span className="paginationitmes ">
                                    Items
                                  </span>
                                  <div className="d-inline">
                                    {/* <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    /> */}
                                    <ButtonDropdown
                                      isOpen={isOpen}
                                      toggle={toggle}
                                      onClick={(e) => pagelengthnum(e)}
                                    >
                                      <DropdownToggle caret color="secondary">
                                        {pageSize}
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem value="1">
                                          10
                                        </DropdownItem>
                                        <DropdownItem value="2">
                                          30
                                        </DropdownItem>
                                        <DropdownItem />
                                        <DropdownItem value="3">
                                          50
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </ButtonDropdown>
                                  </div>
                                  <span className="paginationitmes1 ">
                                    show
                                  </span>
                                  <div className="text-md-right ms-auto">
                                    {/* <PaginationListStandalone
                                      {...paginationProps}
                                    /> */}

                                    <Button
                                      color="secondary"
                                      className="waves-effect "
                                      style={{
                                        marginLeft: 7,
                                        marginRight: 7,
                                      }}
                                      disabled={currentPage <= 1}
                                      onClick={() =>
                                        currentPage === 1
                                          ? null
                                          : changeCurrentPage(
                                              (prev) => prev - 1
                                            )
                                      }
                                    >
                                      <i className="dripicons-chevron-left"></i>
                                    </Button>

                                    <Button
                                      style={{
                                        marginLeft: 7,
                                        marginRight: 7,
                                      }}
                                      color="success"
                                      className="btn-rounded waves-effect waves-light me-1 mr-2 ml-2"
                                    >
                                      {currentPage}
                                    </Button>
                                    <Button
                                      style={{
                                        marginLeft: 7,
                                        marginRight: 7,
                                      }}
                                      color="secondary"
                                      className="waves-effect"
                                      disabled={
                                        activeTabJustify === "1"
                                          ? !hasNextPage
                                          : !packageHasNextPage
                                      }
                                      onClick={() =>
                                        changeCurrentPage((prev) => prev + 1)
                                      }
                                    >
                                      <i className="dripicons-chevron-right"></i>
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
}
// }

export default InvoicesMainPage;
