import { Button, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import "./Forbidden.scss";
function NoAccess() {
  return (
    <>
      <div className="page-content">
        <div className="lock"></div>
        <div className="message">
          <h1>Access to this page is denied</h1>
          <p>
            Please check with the site admin if you believe this is a mistake.
          </p>
          <Link to={"/dashboard"}>
            <Button style={{ marginLeft: "150px" }} color="success">
              {" "}
              Back to Dashboard
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default NoAccess;
