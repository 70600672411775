import React from "react";
import {
  Row,
  Col,
  Button,
  CardBody,
  Form,
  Card,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Dropzone from "react-dropzone";
import Switch from "react-switch";
import Select from "react-select";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import UseCategoryDetail from "./useCategoryDetail";
import "../css/MyCategoriesDetailed.css";
import "react-toastify/dist/ReactToastify.css";
import UserPermissionFetcher from "../../../../helpers/getUserPerimissions";
import NoAccess from "../../../../components/Common/NoAccess";
import Spinner from "../../../../components/Common/Loader";
const Loader = require("react-loader");

const CategoriesDetail = () => {
  const userPromissions = UserPermissionFetcher("resourceTypeCategories");

  const {
    membershipCheck,

    passCheck,
    setPassCheck,
    setMembershipCheck,
    setCateLoad,
    cateLoad,
    isLoading,
    selectedFilesUrl,
    selectedFilesLoading,
    error,
    selectedMulti,
    modal_static,
    modal_static1,
    categoryDetail,
    categoryDailedData,
    categoryDropDown,
    categoryDetailname,
    setModal_static,
    setModal_static1,
    getresourceStatusFunc,
    handleAcceptedFiles,
    setSelectedFilesUrl,
    OnSymbol,
    Offsymbol,
    updateNameAndDescription,
    handleMulti,
    tog_static1,
    tog_static,
    FetchbrandLocations,
    OnChangeCategory,
  } = UseCategoryDetail();
  console.log(
    categoryDropDown,
    "categoryDropDown",
    selectedMulti,
    "selectedMulti",
    cateLoad,
    "cateLoad"
  );
  const Loader = require("react-loader");

  if (error) {
    return (
      <>
        {toast.error(error)}
        <ToastContainer autoClose={8000} />
      </>
    );
  } else {
    return (
      <>
        {isLoading ? (
          <div className="page-content">
            <Spinner loaded={false} className="spinner" />
          </div>
        ) : userPromissions ? (
          <div className="page-content">
            <Row className="mb-3">
              <Col md={4}>
                <Link to="/categories" className="link">
                  <span className="fas fa-angle-left arrowheightwidth"></span>
                </Link>
                <span className="locationbrandtitle  ">
                  {categoryDetailname ? categoryDetailname : "Add new Category"}
                </span>
              </Col>
              <Col md={5}></Col>
              <Col md={3}>
                {userPromissions.update && (
                  <Button
                    color="success"
                    className="waves-effect waves-light mr-1  w-100"
                    block
                    onClick={tog_static}
                  >
                    <span className="printbutton">Save</span>
                  </Button>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <CardBody style={{ background: "white", height: 370 }}>
                  <Row>
                    <Col md={12}>
                      <p className="LBDheadings">Enter category details</p>
                    </Col>
                  </Row>
                  <hr />
                  {/* start */}
                  <Row className="mt-4">
                    <Col md={3}>Category (Status)</Col>
                    <Col md={3}></Col>
                    <Col md={3}></Col>
                    <Col md={3}></Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md={3}>
                      <Switch
                        disabled={!userPromissions.update}
                        onHandleColor="#16b185"
                        width={70}
                        uncheckedIcon={Offsymbol(<small>Inactive</small>)}
                        checkedIcon={OnSymbol(<small>Active</small>)}
                        onColor="#a2a2a2"
                        onChange={(e) => getresourceStatusFunc(e)}
                        checked={categoryDailedData?.visibility}
                        className="mr-1 mt-1  "
                      />
                    </Col>
                    <Col md={3}></Col>
                    <Col md={3}></Col>
                    <Col md={3}></Col>
                  </Row>
                  {/* end  */}
                  {console.log("categoryDailedData", categoryDailedData)}
                  <Row>
                    <Col md={5}>
                      <p className="LBDLabels mt-4">Category Name</p>
                      <Input
                        //  className="detailsinput"
                        disabled={!userPromissions.update}
                        placeholder="Enter Name"
                        type="text"
                        name="defaultconfig"
                        onChange={OnChangeCategory}
                        id="defaultconfig"
                        value={categoryDetail ? categoryDetail : ""}
                      />

                      <Row className="mt-4">
                        <Col md={6}>
                          <span className="d-flex">
                            <Input
                              disabled={!userPromissions.update}
                              className=""
                              type="checkbox"
                              checked={membershipCheck}
                              onChange={(e) => {
                                setMembershipCheck(!membershipCheck);
                              }}
                            />
                            <p className="LBDLabels mx-2">Membership</p>
                          </span>
                        </Col>
                        <Col md={6}>
                          <span className="d-flex">
                            <Input
                              disabled={!userPromissions.update}
                              className=""
                              type="checkbox"
                              checked={passCheck}
                              onChange={(e) => {
                                setPassCheck(!passCheck);
                              }}
                            />
                            <p className="LBDLabels mx-2">Pass</p>
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={1}></Col>
                    <Col md={6}>
                      <p className="LBDLabels mt-4">
                        Resource Type for this Category
                        {cateLoad ? <Spinner /> : ""}
                      </p>
                      <Select
                        disabled={!userPromissions.update}
                        value={selectedMulti}
                        isLoading={cateLoad}
                        isMulti={true}
                        onChange={handleMulti}
                        options={categoryDropDown}
                        classNamePrefix="select2-selection"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {selectedFilesLoading ? (
                  <Loader />
                ) : (
                  <span>
                    <Row>
                      {userPromissions.update && (
                        <Col md={6}>
                          <>
                            {selectedFilesUrl === "" ? (
                              <Form className="dropzone mt-3">
                                <Dropzone
                                  accept={".jpg,.png,.jpeg"}
                                  onDrop={(acceptedFiles) => {
                                    handleAcceptedFiles(acceptedFiles);
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div>
                                      <div
                                        className="dz-message needsclick"
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />

                                        <div className="mb-3">
                                          <i className="display-4 text-muted ri-upload-cloud-2-line" />
                                        </div>
                                        <h5>
                                          Drop files here or click to upload.
                                        </h5>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                              </Form>
                            ) : (
                              <div className="mt-3">
                                <Card>
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col md={10}>
                                        <img
                                          height="80"
                                          className=" rounded bg-light"
                                          alt="img"
                                          src={selectedFilesUrl}
                                        />
                                      </Col>
                                      <Col md={2}>
                                        <span className="">
                                          <Button
                                            style={{
                                              background: "transparent",
                                            }}
                                            onClick={() => {
                                              setSelectedFilesUrl("");
                                            }}
                                          >
                                            <span style={{ color: "black" }}>
                                              <i className="dripicons-cross"></i>
                                            </span>
                                          </Button>
                                        </span>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              </div>
                            )}
                          </>
                        </Col>
                      )}
                    </Row>
                  </span>
                )}
              </Col>
            </Row>

            <Modal isOpen={modal_static} toggle={tog_static} centered={true}>
              <Row>
                <Col md={4}></Col>
                <Col md={7}>
                  <ModalHeader toggle={() => setModal_static(false)}>
                    Resource Addition
                  </ModalHeader>
                </Col>
                <Col md={1}></Col>
              </Row>

              <ModalBody>
                <Row>
                  <Col md={2}></Col>
                  <Col md={8}>
                    <p>Are you sure you want to save your changes?</p>
                  </Col>
                  <Col md={2}></Col>
                </Row>
                <hr />
                <Row>
                  <Col md={2}></Col>

                  <Col md={4}>
                    <Button
                      color="success"
                      outline
                      className="waves-effect waves-light w-100"
                      onClick={() => setModal_static(false)}
                    >
                      No
                    </Button>
                  </Col>
                  <Col md={4}>
                    <Button
                      color="success"
                      className="waves-effect waves-light  w-100"
                      onClick={updateNameAndDescription}
                    >
                      Yes
                    </Button>
                  </Col>

                  <Col md={2}></Col>
                </Row>
              </ModalBody>
            </Modal>
            <ToastContainer autoClose={8000} />

            <Modal isOpen={modal_static1} toggle={tog_static1} centered={true}>
              <Row>
                <Col md={4}></Col>
                <Col md={7}>
                  <ModalHeader toggle={() => setModal_static1(false)}>
                    Category
                  </ModalHeader>
                </Col>
                <Col md={1}></Col>
              </Row>

              <ModalBody>
                <Row>
                  <Col md={2}></Col>
                  <Col md={8}>
                    <p>Are you sure you want to Move Category?</p>
                  </Col>
                  <Col md={2}></Col>
                </Row>
                <hr />
                <Row>
                  <Col md={2}></Col>

                  <Col md={4}>
                    <Button
                      color="success"
                      outline
                      className="waves-effect waves-light w-100"
                      onClick={() => setModal_static1(false)}
                    >
                      No
                    </Button>
                  </Col>
                  <Col md={4}>
                    <Button
                      color="success"
                      className="waves-effect waves-light  w-100"
                      onClick={FetchbrandLocations}
                    >
                      Yes
                    </Button>
                  </Col>

                  <Col md={2}></Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
        ) : (
          <div className="page-content">
            <Loader loaded={false} className="spinner" />
          </div>
        )}
      </>
    );
  }
};

export default CategoriesDetail;
