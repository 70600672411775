import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { getInstantCheckin } from "../../../APIS/instantCheckin";

const UseInstantCheckIn = () => {
  const token = localStorage.getItem("Token");

  // *************instant checkin**********
  const { data: instantCheckInDataa } = useQuery(["instantCheckInData"], () =>
    getInstantCheckin(token)
  );
  const instantCheckInData = instantCheckInDataa?.data;

  // const getTimeFromMins = (instantCheckInData) => {
  //   // do not include the first validation check if you want, for example,
  //   // getTimeFromMins(1530) to equal getTimeFromMins(90) (i.e. mins rollover)
  //   // if (mins >= 24 * 60 || mins < 0) {
  //   //   throw new RangeError(
  //   //     "Valid input should be greater than or equal to 0 and less than 1440."
  //   //   );
  //   // }
  //   // var h = (instantCheckInData?.bookingDuration / 60) | 0,
  //   //   m = instantCheckInData?.bookingDuration % 60 | 0;

  // };
  // getTimeFromMins();

  return { instantCheckInData };
};

export default UseInstantCheckIn;
