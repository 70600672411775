import React from "react";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  Button,
  // ButtonDropdown,
  Card,
  CardBody,
  Col,
  // DropdownItem,
  // DropdownMenu,
  // DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { usePlanDiscounts } from "./usePlanDiscounts";
import BootstrapTable from "react-bootstrap-table-next";
import Spinner from "../../../components/Common/Loader";
import UserPromissionFetcher from "../../../helpers/getUserPerimissions";
import NoAccess from "../../../components/Common/NoAccess";
const { SearchBar } = Search;
function PlanDiscounts() {
  // TODO This page should be auterized by planDiscount resourceName for time being im using discountCodes ResourceName
  const userPermissions = UserPromissionFetcher("discountCodes");

  const {
    planDiscounts,
    isLoading,
    pageOptions,
    activeTabJustify,
    toggleCustomJustified,
    setActiveTabState,
  } = usePlanDiscounts();
  // Columns
  const columns = [
    {
      dataField: "no",
      text: "No.",
      sort: true,
      style: { width: "7%" },
    },
    {
      dataField: "code",
      text: "Code",
      sort: true,
      style: { width: "10%" },
      // formatter: (cell, row) => (
      //   <Link to={`/plan_discounts/${row._id}?edit=${false}`} className="link">
      //     {cell}
      //   </Link>
      // ),
    },
    {
      dataField: "discountPercentage",
      text: "Discount Percentage",
      sort: true,
      style: { width: "15%" },
    },
    {
      dataField: "startDate",
      text: "Start Date ",
      sort: true,
      style: { width: "15%" },
    },
    {
      dataField: "lastTimeUsed",
      text: "Last Time Used",
      sort: true,

      style: { width: "15%" },
    },
    {
      dataField: "timesUsed",
      text: "Times Used",
      sort: true,

      style: { width: "12%", marginRight: "5px" },
    },
    {
      dataField: "active",
      text: "Status",
      sort: true,
      style: { width: "10%" },
      formatter: (cell, row) =>
        row.active ? (
          <span style={{ color: "#32CD32" }}>Active</span>
        ) : (
          <span>Inactive</span>
        ),
    },
    {
      dataField: "Edit",
      text: "Edit",
      sort: true,
      style: { width: "7%", marginRight: "5px" },
      formatter: (cell, row) => (
        // /edit_plan_discount/:id/edit=true
        <Link to={`/plan_discounts/${row._id}?edit=${true}`} className="link">
          <span
            className="mdi mdi-pencil font-weight-bold"
            style={{ fontSize: "15px" }}
          ></span>
        </Link>
      ),
    },
  ];

  // const defaultSorted = [
  //   {
  //     dataField: "id",
  //     order: "desc", // Set the order to "desc" for descending order
  //   },
  // ];
  return (
    <>
      {userPermissions === null ? (
        <Spinner />
      ) : userPermissions?.read ? (
        <div className="page-content">
          {/* Heading Row */}

          <div>
            <Row className="mb-4">
              <Col md={4}>
                <span className="locationbrandtitle ml-4 ">Plan Discounts</span>
              </Col>
              <Col md={6}></Col>
              <Col md={2}>
                {userPermissions?.create ? (
                  <Link to="/plan_discount">
                    <Button color="success" className="w-100">
                      <strong> +</strong> {'  '}Create Plan Discount
                    </Button>
                  </Link>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </div>
          {/* Table Row */}

          <div className="tablebgcolor">
            <Row>
              <Col className="col-12">
                <Card>
                  {/* Tab Row */}
                  <div>
                    <Row className="mt-2  ">
                      <Col md={5}>
                        <Nav tabs className="nav-tabs-custom nav-justified">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: activeTabJustify === "1",
                              })}
                              onClick={() => {
                                toggleCustomJustified("1");
                                setActiveTabState("true");
                                // getUseractivebookings();
                              }}
                            >
                              <span className="d-none d-sm-block">Active</span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: activeTabJustify === "2",
                              })}
                              onClick={() => {
                                toggleCustomJustified("2");
                                setActiveTabState("false");
                                // fetchScheduledBookings();
                              }}
                            >
                              <span className="d-none d-sm-block">
                                Inactive
                              </span>
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent>
                          {/* activeTab={activeTabJustify} */}
                          <TabPane tabId="1" className="p-1"></TabPane>
                          <TabPane tabId="2" className="p-1"></TabPane>
                        </TabContent>
                      </Col>
                      <Col md={3}></Col>
                      <Col md={4}></Col>
                    </Row>
                  </div>
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={columns}
                        data={planDiscounts.data}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={planDiscounts.data}
                            search
                          >
                            {(toolkitProps) => (
                              <React.Fragment>
                                {/* Search Bar Row */}
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="search-box chat-search-box" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="4"></Col>
                                  <Col md="4"></Col>
                                </Row>
                                {/* Table Row */}
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        hover
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        // defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                {/* Pagination btn row  */}
                                {/* <Row className="align-items-md-center mt-3">
                              <Col className="inner-custom-pagination d-flex">
                                <span className="paginationitmes ">Items</span>
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  <ButtonDropdown
                                  isOpen={isOpen}
                                  toggle={toggle}
                                  onClick={(e) => pagelengthnum(e)}
                                  >
                                    <DropdownToggle caret color="secondary">
                                      {pageSize}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem value="1">10</DropdownItem>
                                      <DropdownItem value="2">30</DropdownItem>
                                      <DropdownItem />
                                      <DropdownItem value="3">50</DropdownItem>
                                    </DropdownMenu>
                                  </ButtonDropdown>
                                </div>
                                <span className="paginationitmes1 ">show</span>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                      {...paginationProps}
                                    />

                                  Right Side Btn
                                  <Button
                                    color="secondary"
                                    className="waves-effect "
                                    style={{
                                      marginLeft: 7,
                                      marginRight: 7,
                                    }}
                                    disabled={currentPage <= 1}
                                    onClick={() =>
                                      currentPage === 1
                                        ? null
                                        : changeCurrentPage((prev) => prev - 1)
                                    }
                                  >
                                    <i className="dripicons-chevron-left"></i>
                                  </Button>

                                  <Button
                                    style={{
                                      marginLeft: 7,
                                      marginRight: 7,
                                    }}
                                    color="success"
                                    className="btn-rounded waves-effect waves-light me-1 mr-2 ml-2"
                                  >
                                    {currentPage}
                                  </Button>
                                  <Button
                                    style={{
                                      marginLeft: 7,
                                      marginRight: 7,
                                    }}
                                    color="secondary"
                                    className="waves-effect"
                                    disabled={!hasNextPage}
                                    onClick={() =>
                                      changeCurrentPage((prev) => prev + 1)
                                    }
                                  >
                                    <i className="dripicons-chevron-right"></i>
                                  </Button>
                                </div>
                              </Col>
                            </Row> */}
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
}

export default PlanDiscounts;
