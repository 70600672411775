
import { Button, Card, CardBody, CardTitle, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner as Loader, DropdownToggle, DropdownMenu, DropdownItem, Dropdown, Input, Label } from "reactstrap"
import useGuestBookingDetail from "../hook/useGuestBookingDetail"
import '../css/styles.css';
import { Link } from "react-router-dom";
import Spinner   from "../../../../../components/Common/Loader";
import Select from 'react-select';
import CopyLink from "./CopyLink";
import { DIOM_BASED_URLS_FRONT_END } from "../../../../../config/url";
const AdminGuestBookingDetails = ( { direction, ...args }) => {

  const {id, guestBookingData,
    isGuestBookingDataLoading ,
    formatDateTime ,
    calculateTimeDifference ,
    calculateTotalWithTax,
    isOpen ,
     setIsOpen,
     cancelHandler,
     isAdminGuestBookingCancelLoading,
     dropdownOpen,
     toggle,
     handlePayment,
       handleAddPromoCode,
       isConfirmBookingModalOpen,
        setIsConfirmBookingModalOpen,
    isAdminGuestBookingConfirmedLoading,
    setPymentMethod,
     setReferenceId,
     pymentMethod,
     referenceId
  }= useGuestBookingDetail()
 
 
  return (  
   <div className="page-content">
     { isGuestBookingDataLoading ?  <Spinner />:  
     <>
     <Row>
      <Col md={3}>
      <Link to="/request_bookings/1" className="link">
                  <span className="fas fa-angle-left arrowheightwidth"></span>
                
                <span className="locationbrandtitle ml-4 ">
                  Booking Details
                </span>
                </Link>
              </Col>

              <Col md={5}> <CopyLink baseUrl={DIOM_BASED_URLS_FRONT_END} bookingId={id}/></Col>
              <Col md={2}></Col>
              <Col md={2} >
              <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={direction}  >
        <DropdownToggle caret style={{width:"100%" }} color="success">Actions</DropdownToggle>
        <DropdownMenu {...args}>
         
        <DropdownItem onClick={()=>setIsOpen(true)} 
         disabled={
          guestBookingData.data.data.actionTaken==="DELETE_BOOKING" ||
           guestBookingData.data.data.actionTaken==="DELETED_BY_SYSTEM" || 
           guestBookingData.data.data.actionTaken==="CONFIRM_BOOKING" 
           } >Cancel Booking</DropdownItem>
        <DropdownItem onClick={handleAddPromoCode} disabled>Add Promo Code</DropdownItem>
        {/* <DropdownItem onClick={handlePayment}>Payment</DropdownItem> */}
        </DropdownMenu>
      </Dropdown>


                {/* <Button 
                color="danger"
               style={{width:"100%"}} 
               disabled={guestBookingData.data.data.actionTaken==="DELETE_BOOKING"}
               
               onClick={()=>setIsOpen(true)}>
                Cancel Booking 
                </Button> */}
                </Col>
              </Row>
<Row> 

  <Col md={8}>
  <Card className="mt-5" style={{padding:"15px"}}>
  <CardBody>
    <CardTitle tag="h1">
      Booking Details:
    </CardTitle>
   

 <Row className="mt-3">
  <Col md={4}>
   <p className="title"> Location: </p>
  </Col>
  <Col md={4}>
   <p className="title"> Resource Name: </p>
  </Col>
  
  <Col md={4}>
  <p className="title">  Booking Type: </p>
  </Col>
</Row>
 <Row>
<Col md={4}>
   <p > {guestBookingData?.data.data.businessName}
</p>
  </Col>
  <Col md={4}>
   {  guestBookingData?.data.data.resourceName
}
  </Col>
 
  <Col md={4}>
  { guestBookingData?.data.data.bookingType
}
  </Col>
</Row>


 <Row >
  <Col md={4}>
   <p className="title"> Booking Start </p>
  </Col>
  <Col md={4}>
   <p className="title"> Booking End </p>
  </Col>
  
  <Col md={4}>
  <p className="title">  Booking Id </p>
  </Col>
</Row>

<Row>
<Col md={4}>
   <p > { formatDateTime( guestBookingData.data.data.fromTime)}
     </p>
  </Col>
  <Col md={4}>
   { formatDateTime( guestBookingData.data.data.toTime) }
  </Col>
 
  <Col md={4}>
  { guestBookingData.data.data.bookingId
}
  </Col>
</Row>
<Row >
<Col md={4}>
  <p className="title">  Billable Time: </p>
  </Col>
  <Col md={4}>
   <p className="title"> Booking Status </p>
  </Col>
  <Col md={4}>
   <p className="title"> Payment Status </p>
  </Col>
  
</Row>
<Row  >
<Col md={4}>
  { calculateTimeDifference(guestBookingData.data.data.fromTime , guestBookingData.data.data.toTime)
}
  </Col>
  <Col md={4}>
   <p > {guestBookingData.data.data.actionTaken==="DELETE_BOOKING"?<span style={{color:"red"}}>DELETE_BOOKING</span>: guestBookingData.data.data.actionTaken==="DELETED_BY_SYSTEM"? <span style={{color:"red"}}>DELETED_BY_SYSTEM</span>:guestBookingData.data.data.actionTaken} </p>
  </Col>
  <Col md={4}>
   <p > {guestBookingData.data.data.bookingPaymentStatus} </p>
  </Col>
  
</Row>

</CardBody>

</Card>
  </Col>

  <Col md={4}>

    <Card className="mt-5" style={{padding:"15px"}}>
    <CardBody>
    <CardTitle tag="h1">
      Payment Info
    </CardTitle>
      <Row  className="mt-3">
  <Col md={6}>
   <p className="title"> Sub Total </p>
  </Col>
  <Col md={6}>
   <p className="title">{`SAR ${guestBookingData.data.data.bookingSubTotal}/-`} </p>
  </Col>
  
  
</Row>
<Row  >
  <Col md={6}>
   <p className="title"> Discount </p>
  </Col>
  
  <Col md={6}>
   <p >{`SAR ${guestBookingData.data.data.discountedAmount}/-`} </p>
  </Col>
  
  
  
  
</Row>
<Row >
<Col md={6}>
   <p className="title"> VAT(15%) </p>
  </Col>
  <Col md={6}>
   <p > { `SAR ${calculateTotalWithTax(guestBookingData.data.data.bookingSubTotal)}/-`  } </p>
  </Col>
  
  
 
  
  
</Row>
<hr></hr>
<Row >
<Col md={6}>
   <p className="title"> Total Payable </p>
  </Col>
  
  <Col md={6}>
   <p >{`SAR ${guestBookingData.data.data.totalPayable}/-`} </p>
  </Col>
  
 
  
  
</Row>
<Row>
<Col md={12}> 
<Button   color="success" disabled={guestBookingData.data.data.actionTaken==="DELETE_BOOKING" || guestBookingData.data.data.actionTaken==="DELETED_BY_SYSTEM" || guestBookingData.data.data.actionTaken==="CONFIRM_BOOKING" } 
style={{width:"100%"}}
onClick={()=>setIsConfirmBookingModalOpen(true)}
>
  Pay & Confirm
  </Button>
  </Col>
</Row>
</CardBody>
    </Card>
  </Col>
</Row>
{/* // Cancel Modal */}

<Modal isOpen={isOpen} >
<ModalHeader >Confirmation</ModalHeader>
<ModalBody>
  Are you sure want to cancel this Booking
</ModalBody>
<ModalFooter>
  {' '}
  <Button color="success" onClick={()=>setIsOpen(false)}>
    No
  </Button>
  <Button color="success" disabled={isAdminGuestBookingCancelLoading} onClick={()=> cancelHandler()}>
  {isAdminGuestBookingCancelLoading ? (
        <Loader size="sm" color="light" />
      ) : (
        "Yes"
      )} 
  </Button>
</ModalFooter>
</Modal>
{/* // Cofirm and PayModal */}

        
<Modal isOpen={isConfirmBookingModalOpen} >
<ModalHeader >Confirmation</ModalHeader>
<ModalBody>
<Label
                        htmlFor="user"
                        className="logininboxpanels"
                        style={{ pointerEvents: "none" }}
                      >
                        Payment Method <span className="cp_starik">*</span>
                      </Label>
  <Select  placeholder=" Select Payment Method" options={[{label:"VISA" , value:"VISA"},
    {label:"Mastercard" , value:"Mastercard"},
    {label:"American Express" , value:"American Express"},
    {label:"Mada" , value:"Mada"},
    {label:"Wire Transfer" , value:"Wire Transfer"},
    {label:"Cash" , value:"Cash"}
  ]}
  onChange={(opt)=>setPymentMethod(opt.value)}
  ></Select>
<Label
  htmlFor="user"
  className="logininboxpanels mt-1"
  style={{ pointerEvents: "none" }}
                      >
                        Reference Id <span className="cp_starik">*</span>
                      </Label>
<Input placeholder="Enter Reference Id" onChange={(e)=>setReferenceId(e.target.value)} />
</ModalBody>
<ModalFooter>
  {' '}
  <Button color="success" onClick={()=>setIsConfirmBookingModalOpen(false)}>
    Cancel
  </Button>

  <Button color="success" disabled={ !pymentMethod ||
     !referenceId ||isAdminGuestBookingConfirmedLoading} onClick={()=> handlePayment()}  >
  {isAdminGuestBookingConfirmedLoading ? (
        <Loader size="sm" color="light" />
      ) : (
        "Submit"
      )} 
  </Button>
</ModalFooter>
</Modal>
  
            </>  }
    </div>



  )
}

export default AdminGuestBookingDetails
