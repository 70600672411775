import { useEffect, useState } from "react";
import { getAuditTrailsLog, getAuditTrailsLogs  } from "../../APIS/dataLogs";
import { getAdminUsersprofile  } from "../../APIS/userProfle";
import { useAllDataQuery, useAuditLogsDataQuery, useAuditLogsQuery, usePaginatedQuery } from "../../hooks/query";
import { DIOM_BASED_URLS } from "../../config/url";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import capitalizeWords from "../Utility/WordsCapitalize"
import { toast } from "react-toastify";
import moment from "moment";
import { convertMinutesToTime } from "../Utility/convertMinutesToTime";
const useAuditTrailLogs = () => {

   const token = localStorage.getItem("Token");
   const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [modal_static, setModal_static] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [userLogsLoading, setUserLogsLoading] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedSort, setSelectedSort] = useState('');
  const [filter, setFilter] = useState(null);
  const [logId, setLogId] = useState(null);
  const [logsDataDetail, setLogsDataDetail] = useState();
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const [dateRange, setDateRange] = useState([]);
  const [displayDateRange, setDisplayDateRange] = useState('');
  const [jsonObject, setJsonObject] = useState([]);
  const [beforeOperationsHourValues, setBeforeOperationsHourValues] = useState([]);
  const [beforeResourceValues, setBeforeResourceValues] = useState([]);
  const changeCurrentPage = (pageNum) => setCurrentPage(pageNum);

  // Assuming "/audit-trail-logs/inventory"
  const logType = capitalizeWords(pathParts[pathParts.length - 1]) ; // Get the last part of the URL

//1) Get Audits Logs
   const { data: auditTrailLogsData ,isLoading:auditTrailLogsLoading } = useAuditLogsQuery(
      ["AuditTrailsLogs", currentPage, pageSize ,filter ],
      () => getAuditTrailsLogs(token, currentPage, pageSize , selectedEntity , selectedUser ,startTime , endTime ,selectedSort?.value)
    );


    // 2)Get Audit log
   const { data: auditTrailLogData ,isLoading:auditTrailLogLoading } = useAuditLogsDataQuery(
      ["AuditTrailsLog",logId],
      () => getAuditTrailsLog(token,logId),
      logId

    );

   
    useEffect(() => {


       if (auditTrailLogData?.data) {
     
       
        if(auditTrailLogData.data.logType==='ResourceTypes'){
          setBeforeOperationsHourValues([])
          setBeforeResourceValues([])
setJsonObject([])
         return setJsonObject(auditTrailLogData.data)
        } else if(auditTrailLogData.data.logType==='Locations'){
          setBeforeOperationsHourValues([])
          setBeforeResourceValues([])
setJsonObject([])
        return  setJsonObject(auditTrailLogData.data)


        }
        
        else{


          // 1)Get operationsHorsValues only
          const operationsHours = auditTrailLogData.data.requestBody.operationsHours;
// 2) Get before ResourceType Data
          const beforeValuesJsonObject= JSON.parse(auditTrailLogData?.data?.valueBeforeChange)
          setBeforeResourceValues(beforeValuesJsonObject)

          const keysToExtract = [
            'Name',
            'mondayOpenTime',
            'mondayCloseTime', 
            'tuesdayOpenTime',
            'tuesdayCloseTime', 
            'wednesdayOpenTime',
            'wednesdayCloseTime', 
            'thursdayOpenTime',
            'thursdayCloseTime', 
            'fridayOpenTime',
            'fridayCloseTime', 
            'saturdayOpenTime',
            'saturdayCloseTime',
            'sundayOpenTime',
            'sundayCloseTime', 
            "mondayClosed",
          "tuesdayClosed",
          "wednesdayClosed",
          "thursdayClosed",
          "fridayClosed",
          "saturdayClosed",
          "sundayClosed"
          ];
          // 3) Filter the BeforeValue array of object get only operationsHors related keys
          const extractedValues = beforeValuesJsonObject.map(obj => {
            const newObj = {};
            keysToExtract.forEach(key => {
              if (key in obj) {
                if (key.endsWith('OpenTime') || key.endsWith('CloseTime')) {
                  newObj[key] = convertMinutesToTime(obj[key]);
                } else {
                  newObj[key] = obj[key];
                }}
            });
            return newObj;
          });
          
          

          //4) Update the operationsHours object with formatted times
          const updatedOperationsHours = {
            ...operationsHours,
            fridayOpenTime: convertMinutesToTime(operationsHours.fridayOpenTime),
            fridayCloseTime: convertMinutesToTime(operationsHours.fridayCloseTime), 
            mondayOpenTime: convertMinutesToTime(operationsHours.mondayOpenTime),
            mondayCloseTime: convertMinutesToTime(operationsHours.mondayCloseTime),
            saturdayOpenTime: convertMinutesToTime(operationsHours.saturdayOpenTime),
            saturdayCloseTime: convertMinutesToTime(operationsHours.saturdayCloseTime),
            sundayOpenTime: convertMinutesToTime(operationsHours.sundayOpenTime),
            sundayCloseTime: convertMinutesToTime(operationsHours.sundayCloseTime),
            thursdayOpenTime: convertMinutesToTime(operationsHours.thursdayOpenTime),
            thursdayCloseTime: convertMinutesToTime(operationsHours.thursdayCloseTime),
            tuesdayOpenTime: convertMinutesToTime(operationsHours.tuesdayOpenTime),
            tuesdayCloseTime: convertMinutesToTime(operationsHours.tuesdayCloseTime),
            wednesdayOpenTime: convertMinutesToTime(operationsHours.wednesdayOpenTime),
            wednesdayCloseTime: convertMinutesToTime(operationsHours.wednesdayCloseTime)
          };


    // 5)Filter the extractedValues to return only those keys whose values do not match the updatedOperationsHours object and add name key
    const filteredExtractedValues = extractedValues.map(obj => {
      const filteredObj = {};
      Object.keys(obj).forEach(key => {
        if (obj[key] !== updatedOperationsHours[key]) {
          filteredObj[key] = obj[key];
        }
      });
      // Add the Name key to the filteredObj
      if ('Name' in obj) {
        filteredObj['Name'] = obj['Name'];
      }
      return filteredObj;
    });
    setBeforeOperationsHourValues(filteredExtractedValues)

    // 6)Filter the updatedOperationsHours object to return only those keys whose values do not match the extractedValues array
    const filteredUpdatedOperationsHoursArray = extractedValues.map(extractedObj => {
      const filteredObj = {  };
      Object.keys(updatedOperationsHours).forEach(key => {
        if (extractedObj[key] !== updatedOperationsHours[key]) {
          filteredObj[key] = updatedOperationsHours[key];
        }
      });
      return filteredObj;
    });
    
    setJsonObject(filteredUpdatedOperationsHoursArray);



         
        }
       
      }
    }, [auditTrailLogData ,setJsonObject]);


// 3 Get Admin users 
const {
  data: { data: adminUsersProfileData, },
  isLoading,
} = useAuditLogsDataQuery(["adminUsersProfile",], () =>
  getAdminUsersprofile(token) , true
);

const userDropDownData = adminUsersProfileData?.map((user)=>({label:`${user.FullName}  (${user.email})` , value:user.email}))

   
    const hasNextPage = auditTrailLogsData?.data?.hasNextPage;
    const total = auditTrailLogsData?.data?.total;
   
    const pageOptions = {
      sizePerPage: pageSize,
      totalSize: auditTrailLogsData?.data?.total ?? 0,
      custom: true,
    };


    const logdetialsfunc = async (logId) => {
      setLogId(logId)
      
      setModal_static(true)
    };


    const pagelengthnum = (e) => {
      if (e.target.value === "1") {
        setPageSize(10);
      } else if (e.target.value === "2") {
        setPageSize(30);
      } else if (e.target.value === "3") {
        setPageSize(50);
      }
    };
    const toggle = () => {
      setIsOpen(!isOpen);
    };


    function DateTimeDurationHandler(date){
      const [startDateTime, endDateTime] = date

      if (date.length === 2) {
        const startDateTimeGMT = moment(date[0]).format('YYYY-MM-DD HH:mm [GMT]Z');
        const endDateTimeGMT = moment(date[1]).format('YYYY-MM-DD HH:mm [GMT]Z');
        setDisplayDateRange([startDateTimeGMT , endDateTimeGMT]);
      }else {
        setDisplayDateRange('');
      }
      setDateRange(date);
    
      const startDateTimeISO = moment(startDateTime).toISOString();
      const endDateTimeISO = moment(endDateTime).toISOString();
    
      setStartTime(startDateTimeISO)
      setEndTime(endDateTimeISO)
      
    
    
    }
function entityHandler(entityType){
  setSelectedEntity(entityType)
}

function createdByHandler(email){
  setSelectedUser(email)

}
function sortHandler(sortValue){
  setSelectedSort(sortValue)

}

function filterHandler (){
  setFilter({startTime:startTime , 
              endTime:endTime ,
             selectedEntity:selectedEntity , 
     selectedUser:selectedUser ,
    sort:selectedSort})

}


    return{
      auditTrailLogsData,
      auditTrailLogData,
      currentPage, 
      setCurrentPage,
      pageSize,
       setPageSize,
       pageOptions,
       auditTrailLogsLoading,
       auditTrailLogLoading,
       logdetialsfunc,
       modal_static,
        setModal_static,
        pagelengthnum,
        changeCurrentPage,
        toggle,
        isOpen, 
        setIsOpen,
        hasNextPage,
        total,
        userDropDownData,
        isLoading,
        setSelectedUser,
        userLogsLoading,
        setUserLogsLoading,
        DateTimeDurationHandler,
        entityHandler,
        createdByHandler,
        filterHandler,
        selectedEntity , 
        selectedUser ,
        displayDateRange,
        dateRange,
         endTime,
         sortHandler,
     selectedSort,
     jsonObject,
     beforeOperationsHourValues,
     beforeResourceValues

    }
}
export default useAuditTrailLogs;