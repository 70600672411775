import { useState } from "react"
import {  useCustomQuery } from "../../../../../hooks/query";
import { getMobileUsers, getsearcuserprofile } from "../../../../../APIS/userProfle";
import { getAllLocations } from "../../../../../APIS";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useCheckAvailableResourceMutation, useCreateAdminGuestBookingMutation } from "./useAvailableResourceMutation";
import { toast } from "react-toastify";
import { getResourceTypeByCategory } from "../../../../../APIS/resourceType";
import { convertMinutesToTime } from "../../../../Utility/convertMinutesToTime";
import { addTimeToISODate } from "../../../../Utility/addTimeToISOString";
import { DIOM_BASED_URLS } from "../../../../../config/url";

export default ()=>{
   const [selectedUser , setSelectedUser]= useState(null)
   const [selectedLocation , setSelectedLocation]= useState(null)
   const [selectedAcessType , setAccessType]= useState(null)
   const [selectedRTC , setRTC]= useState(null)
   const [selectedPassType , setSelectedPassType]= useState(null)
   const [userQuery , setUserQuery]= useState(null)
const [bookingDate, setBookingDate] = useState(null);
const [bookingDayRangeDate, setBookingDayRangeDate] = useState([]);
const [bookingStartTime, setBookingStartTime] = useState(null);
const [bookingEndTime, setBookingEndTime] = useState(null);
const [availableResourceType, setAvailableResourceType] = useState(null);
   const [isLoadingSearchUsers , setIsLoadingSearchUsers]= useState('')
   const [selectedResourceType , setSelectedResourceType]= useState('')
   const [isCreateUserModalOpen , setIsCreateUserModatOpen]= useState(false)
   const [isUserCreatedLoading , setIsUserCreatedLoading]= useState(false)
   const token = localStorage.getItem("Token");
   let history = useHistory();

const EnableBtn= !selectedLocation|| !selectedRTC ||!selectedResourceType
const locationIds={
  rubeen:1414902940,
  narjis: 1414918093
}

// 1) Search and Get User
   const { data: userData , isLoading:searchUserDataLoading } = useCustomQuery(["SearchUser" ,userQuery], () =>
    getMobileUsers(token,userQuery ),
   userQuery?true:false
    );

// 2) Get Locations 
const { data: LocationData , isLoading:isLocationDataLoading } = useCustomQuery(["Locations" ,userQuery], () =>
   getAllLocations(token ),
true
 );

//  3)Selected Location ResourceType Category
const RTCOptions = LocationData?.filter(
  (location) => location.id === selectedLocation?.value
)
  .map((location) => location.resourceTypeCategories)
  .flat()
  .map((RTC) => ({
    label: RTC.name,
    value: RTC,
  }))
  .filter(
    (RTC) =>
      !(
        (selectedLocation?.value === locationIds.rubeen ||
          selectedLocation?.value === locationIds.narjis) &&
        (RTC.label === 'Board Room' || RTC.label === 'Inspiration Room')
      )
  );


//  4) Get Available ResourceTypes
const {checkAvailableResourceTypeMutateAsync,
   isCheckAvailableResourceType,}=useCheckAvailableResourceMutation(selectedRTC?.id ,  selectedLocation?.value, history ,setAvailableResourceType)

// 5) Create Guest Booking
const { createAdminGuestBookingMutateAsync,
  isAdminGuestBookingTypeLoading}=useCreateAdminGuestBookingMutation()

   // 6) Get Category ResourceTypes for Set Start time in case shared Spaces signle booking
  const { data: CategoryResourceTypeData } = useCustomQuery(
    ["CategoryResourceTypeData", selectedRTC],
    () => getResourceTypeByCategory(token, selectedRTC?.id),
    selectedRTC ? true : false
  );

   // Get time zone from the browser
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
// -------------------Handlers-----------------------------------------
function locationHandler (locationId){
  setRTC(null)
  setBookingDate(null)
  setBookingEndTime(null)
setAvailableResourceType(null)
  setSelectedLocation(locationId)
}


function resourceTypeCategoryHandler(RTCId){
setRTC(RTCId)
setAccessType(null)
setAvailableResourceType(null)
setBookingStartTime(null)
setBookingEndTime(null)
}
const bookingDateHandler = (date) => {
  const ISOBookingDate = moment(date[0]).toISOString();
  setBookingDate(date[0]);
  setBookingStartTime(null)
  setBookingEndTime(null)
};

const startTimeHandler = (startTime) => {

  const selectedTime = moment(startTime[0]);
  const minute = selectedTime.minute();

  // Check if the selected minute is a multiple of 15
  if (minute % 15 !== 0) {
    setBookingStartTime(null)
    toast.error("Please select a time in 15-minute intervals, e.g., 12:15, 12:30, 12:45");
    return;
  }

  if (bookingDate) {
    const combinedDateTime = moment(bookingDate)
      .set({
        hour: moment(startTime[0]).hour(),
        minute: moment(startTime[0]).minute(), 
        second: moment(startTime[0]).second()
      })
      .toISOString();

setBookingStartTime(combinedDateTime)

// if(bookingEndTime){
//   checkAvailableResourceTypeMutateAsync({
//      fromTime: combinedDateTime,
//      toTime: bookingEndTime,
//      timezone: timeZone
//  })
// }
    
  }
};

const endTimeHandler = (endTime) => {
 
   // Parse both times into moment objects
   const endMoment = moment(endTime[0]);
   const minute = endMoment.minute();

   // Check if the selected minute is a multiple of 15
   if (minute % 15 !== 0) {
    setAvailableResourceType(null);
    setBookingEndTime(null)
     toast.error("Please select a time in 15-minute intervals, e.g., 12:15, 12:30, 12:45");
     return;
   }
   
   const startMoment = moment(bookingStartTime);
 
   // Set the date of endMoment to be the same as startMoment
   endMoment.year(startMoment.year());
   endMoment.month(startMoment.month());
   endMoment.date(startMoment.date());
 
   // Calculate the difference in minutes
   const duration = moment.duration(endMoment.diff(startMoment));
   const minutesDifference = duration.asMinutes();
 
   // Check if the difference is less than 30 minutes
   if (minutesDifference < 30) {
     setAvailableResourceType(null);
    setBookingEndTime(null)
     return toast.error("Booking duration cannot be less than 30 minutes");
   }

  if (bookingDate) {
    const combinedDateTime = moment(bookingDate)
      .set({
        hour: moment(endTime[0]).hour(),
        minute: moment(endTime[0]).minute(),
        second: moment(endTime[0]).second()
      })
      .toISOString();
    
      setBookingEndTime(combinedDateTime)
     
if(bookingStartTime){
      checkAvailableResourceTypeMutateAsync({
         fromTime: bookingStartTime,
         toTime: combinedDateTime,
         timezone: timeZone
     })
    }
  }
};




function rangeDateHandler(date) {
  if (date.length !== 2) {
    setBookingStartTime(null)
    setBookingEndTime(null)
    setBookingDate(null)
    setAvailableResourceType(null)
    setBookingDate(date[0])
    
   
    return;
  }

  let startDate;
  let endDate;
  setBookingDayRangeDate(date)
    startDate = moment(date[0]).startOf("day").toISOString();
    endDate = moment(date[1])
      .endOf("day")
      .subtract(15, "minutes")
      .toISOString();

      // set DateTime
      setBookingStartTime(startDate)
      setBookingEndTime(endDate)
    checkAvailableResourceTypeMutateAsync({
      fromTime: startDate,
      toTime: endDate,
      timezone: timeZone,
    });
  
}


const accessTypeOptions = [
   {
     label: 'Membership',
     value: 'Membership',
     isDisabled: true
   },
   {
     label: 'Pass',
     value: 'Pass',
     isDisabled: !selectedRTC?.isPassAvailable
   }
 ];


 function selectResourceTypeHandler(RT){
 
  setSelectedResourceType(RT.id)

 }

 function getLockTimeAndTTL(hoursAhead) {
  const lockTime = moment().add(hoursAhead, 'hours').toISOString();
  const ttl = moment(lockTime).add(1, 'minute').toISOString();
  return { lockTime, ttl };
}

const { lockTime, ttl } = getLockTimeAndTTL(2);

// Create Guest Booking

function guestBookingHandler(){
  if(!selectedUser){
return toast.error("booking cannot be created without user")
  }


  if (bookingDayRangeDate.length=== 2) {
    
    const selectedStartDate = moment(bookingDayRangeDate[0]);
    const selectedEndDate = moment(bookingDayRangeDate[1]);
  
    // Check if both dates are the same day
    if (selectedStartDate.isSame(selectedEndDate, "day")) {
  
   const dayMappings = {
        0: "sundayOpenTime",
        1: "mondayOpenTime",
        2: "tuesdayOpenTime",
        3: "wednesdayOpenTime",
        4: "thursdayOpenTime",
        5: "fridayOpenTime",
        6: "saturdayOpenTime",
      };
  
      const RTStartTime = availableResourceType[0];
  
  
      let openTime;
      bookingDayRangeDate.splice(1, 1).forEach((selectedDate) => {
        const dayIndex = moment(selectedDate).day();
        const openTimeKey = dayMappings[dayIndex];
        openTime = RTStartTime[openTimeKey];
      });
  
      // Covert minute into actuall time
      const RTOpenTime = convertMinutesToTime(openTime);
      // Add time into selected start date and convert into ISO
      const startDate = addTimeToISODate(bookingDayRangeDate[0], RTOpenTime);
      const requestBody={
      
        businessId: selectedLocation.value,
        resourceTypeId: selectedResourceType,
         fromTime: startDate,
        toTime: bookingEndTime,
        bookingType: "Standard",
        timeZone: timeZone,
        lockTime:lockTime,
        ttl:ttl,
        userDevice: "SM-A515F",
        appVersion: "3.3.8.26",
        osVersion: "11",
        userId:selectedUser?.value,
        deviceBrandName: "samsung",
        osName: "ANDROID"
    
    }


  
    createAdminGuestBookingMutateAsync(requestBody)
  
    }else{
    
    const requestBody={
        businessId: selectedLocation.value,
        resourceTypeId: selectedResourceType,
         fromTime: bookingStartTime,
        toTime: bookingEndTime,
        bookingType: "Standard",
        timeZone: timeZone,
        lockTime:lockTime,
        ttl:ttl,
        userDevice: "SM-A515F",
        appVersion: "3.3.8.26",
        osVersion: "11",
        userId:selectedUser?.value,
        deviceBrandName: "samsung",
        osName: "ANDROID"
    
    }
    
    createAdminGuestBookingMutateAsync(requestBody)
  }
   
  
  }else{

    const requestBody={
      
      businessId: selectedLocation.value,
      resourceTypeId: selectedResourceType,
       fromTime: bookingStartTime,
      toTime: bookingEndTime,
      bookingType: "Standard",
      timeZone: timeZone,
      lockTime:lockTime,
      ttl:ttl,
      userDevice: "SM-A515F",
      appVersion: "3.3.8.26",
      osVersion: "11",
      userId:selectedUser?.value,
      deviceBrandName: "samsung",
      osName: "ANDROID"
  
  }

  createAdminGuestBookingMutateAsync(requestBody)

  }



}


const toggle = () => setIsCreateUserModatOpen(!isCreateUserModalOpen);
const closeBtn = (
  <button className="close" onClick={toggle} type="button">
    &times;
  </button>
);

async  function onSubmit(data) {

 
  setIsUserCreatedLoading(true)

const requestBody ={
  firstName:data.firstName,
  lastName:data.lastName,
  email:`appdiom+${data.phoneNumber}@gmail.com`,
  password:`${data.phoneNumber}Admin@`,
  sso:false,
  phoneNumber:data.phoneNumber,
  positionId:"61eea1d9991f236b8467c981",
   positionName: "Consultant",
   industryId:"61ee8a026c6f3154ec6c0fe6",
   industryName: "Healthcare",
   fcmToken:"eq6jkPZPRBW5-jYGgfSh7-:APA91bHg_R96ZQVX1tputfC6vAS1_D_yxeVgDWqwxDtDGw4-qxlt0jtp1Rm4gFp-kYT_eDvZXZmWDohC8XymjaFeIMPASMWvq_EBzzBLnqS3k9t57vciyR695T70sVF-KGHyiRXKHWAw"
  
  
  
  }

  const response = await fetch(`${DIOM_BASED_URLS}/users/signup`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(requestBody),
  });

  const ResponseData = await response.json();

  if (!response.ok) {
    setIsUserCreatedLoading(false)
  if(ResponseData.error.statusCode ===400){
    return toast.error(`This Phone number already exist`)
  }
    
    return  toast.error(`${ResponseData.error.message}`);
  
  }


  if (ResponseData.statusCode === 200) {
   
setSelectedUser({label:ResponseData?.user?.Email  , value:ResponseData?.user?.id})

    setIsUserCreatedLoading(false)
    toast.success(ResponseData.message);
    setIsCreateUserModatOpen(false);
  }
}


function userSelectionHandler(values){

  
setSelectedUser(values)
}

function passTypeHandler(passType){
  setAvailableResourceType(null)
setBookingDate(null)
setBookingDayRangeDate([])
  setSelectedPassType(passType.value)
}

return{
      userQuery , 
      setUserQuery,
      userData,
      searchUserDataLoading,
      selectedUser ,
       setSelectedUser,
       userData,
       LocationData,
       isLocationDataLoading,
       selectedLocation , 
       setSelectedLocation,
       RTCOptions,
       setRTC,
       accessTypeOptions,
       selectedAcessType ,
        setAccessType,
        selectedRTC,
        bookingDateHandler,
        startTimeHandler,
        endTimeHandler,
        availableResourceType,
        isCheckAvailableResourceType,
        locationHandler,
        resourceTypeCategoryHandler,
        rangeDateHandler,
        bookingDate,
        guestBookingHandler,
        selectResourceTypeHandler,
        EnableBtn,
        isAdminGuestBookingTypeLoading,
        isCreateUserModalOpen,
         setIsCreateUserModatOpen,
         toggle,
         closeBtn,
         onSubmit,
         isUserCreatedLoading,
         userSelectionHandler,
         bookingStartTime,
         bookingEndTime,
         selectedPassType, 
         setSelectedPassType,
         passTypeHandler
        

       
    }

}