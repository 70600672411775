import { useEffect, useState } from "react";
import { useQueryClient, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { getuserprofile, getsearcuserprofile } from "../../APIS/userProfle";
import { usePaginatedQuery } from "../../hooks/query";

export const useUserProfile = () => {
  let history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const token = localStorage.getItem("Token");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [showForGetModel, setShowForgetModel] = useState(false);
  const [userEmail, setUserEmail] = useState([]);
  const [updatedRoleUserData, setUpdatedRoleUserData] = useState([]);
  const [userSearchedData, setUserSearchedData] = useState(null);
  const [isLoadingSearchUsers, setIsLoadingSearchUsers] = useState(false);
  const [searchUserTerm, setSearchUserTerm] = useState("");
  const changeCurrentPage = (pageNum) => setCurrentPage(pageNum);
  const queryClient = useQueryClient();
  //*1) get all user profiles
  const {
    data: { data: userProfileData, hasNextPage, hasPreviousPage, total },
    isLoading,
  } = usePaginatedQuery(["userprofile", `${currentPage}`, `${pageSize}`], () =>
    getuserprofile(pageSize, currentPage, token, history)
  );
  //*2) Add isSelect property Initially for ResetPassword DropDown...
  useEffect(() => {
    if (!isLoading) {
      const upDatededData = userProfileData.map((roleUser) => ({
        ...roleUser,
        isSelected: false,
      }));

      // 1)Set upDatedUserData
      setUpdatedRoleUserData(upDatededData);
    }
  }, [isLoading, userProfileData]);

  //*3) Search User API
  useEffect(() => {
    // Define a function to fetch data based on the search term
    async function getUserData() {
      const searchUsers = await getsearcuserprofile(
        token,
        searchUserTerm,
        setIsLoadingSearchUsers
      );

      // Set User-Searched-Data
      setUserSearchedData(searchUsers?.aPIData);
    }
    // call the fn when have search Term
    if (searchUserTerm.length >= 1) {
      getUserData();
    } else {
      // Set User-Searched-Data to  initally fetch data
      setUserSearchedData(updatedRoleUserData);
    }
  }, [searchUserTerm, token, updatedRoleUserData]);

  //*4) Update isSelectedproperty and show the dropDown
  function toggleDropdown(rowId) {
    if (userSearchedData) {
      const updatedData = userSearchedData.map((userData) =>
        userData._id === rowId
          ? { ...userData, isSelected: !userData.isSelected }
          : { ...userData, isSelected: false }
      );
      setUserSearchedData(updatedData);
    } else {
      const updatedData = updatedRoleUserData.map((userData) =>
        userData._id === rowId
          ? { ...userData, isSelected: !userData.isSelected }
          : { ...userData, isSelected: false }
      );
      setUpdatedRoleUserData(updatedData);
    }
  }

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const userDelFunc = () => {};
  const pagelengthnum = (e) => {
    if (e.target.value === "1") {
      setPageSize(10);
    } else if (e.target.value === "2") {
      setPageSize(30);
    } else if (e.target.value === "3") {
      setPageSize(50);
    }
  };

  const pageOptions = {
    sizePerPage: pageSize,
    totalSize: total ?? 0,
    custom: true,
  };

  useEffect(() => {
    // FOR PRE-FETCHING NEXT PAGE
    if (hasNextPage) {
      const nextPage = currentPage + 1;
      queryClient.prefetchQuery(
        ["userprofile", `${currentPage}`, `${pageSize}`],
        () => getuserprofile(pageSize, nextPage, token, history)
      );
    }
  }, [currentPage, queryClient]);
  // *5) ForGet password medel handel
  function forGetConfirmationModel() {}
  // console.log(userProfileData, "userProfileData");
  return {
    currentPage,
    pageOptions,
    userProfileData,
    hasNextPage,
    hasPreviousPage,
    total,
    pagelengthnum,
    pageSize,
    toggle,
    isOpen,
    isLoading,
    userDelFunc,
    changeCurrentPage,
    setShowForgetModel,
    showForGetModel,
    forGetConfirmationModel,
    setUserEmail,
    userEmail,
    updatedRoleUserData,
    toggleDropdown,
    setSearchUserTerm,
    isLoadingSearchUsers,
    userSearchedData,
    searchUserTerm,
    setUpdatedRoleUserData,
  };
};
