import moment from "moment";

export function convertMinutesToTime(minutes) {
  // Create a moment duration for the given minutes
  const duration = moment.duration(minutes, "minutes");

  // Format the duration as HH:mm (24-hour format)
  const formattedTime = moment.utc(duration.asMilliseconds()).format("HH:mm");

  // Convert to 12-hour format with AM/PM
  const time12hr = moment(formattedTime, "HH:mm").format("hh:mm A");

  return time12hr;
}
