import React from "react";

import {
  Row,
  Col,
  Button,
  Card,
  Input,
  ModalHeader,
  ModalBody,
  Modal,
  Spinner,
} from "reactstrap";
import UseViewTeam from "./useViewTeam";
import { Link } from "react-router-dom";
import UnableToDel from "./../../.././assets/images/team/unableToDel.svg";
import deleteLogo from "./../../.././assets/images/team/baseline-delete-logo.svg";
import ".././css/styles.css";
import UserPermissionFetcher from "../../../helpers/getUserPerimissions";
import Loader from "react-spinners/BounceLoader";
import NoAccess from "../../../components/Common/NoAccess";

const ViewTeam = () => {
  const userPermissions = UserPermissionFetcher("teams");

  const {
    id,
    errMsg,
    canDeleteTeam,
    discountNumber,
    selectedDiscountRadio,
    delLoading,
    singleTeamsData,
    delCheck1,
    delCheck2,
    delCheck3,
    modal_static1,
    modal_static2,
    delButtonClicked,
    saveDiscountFunc,
    setDiscountNumber,
    handleOptionChange,
    deleteTeamFunc,
    setDelCheck1,
    setDelCheck2,
    setDelCheck3,
    setModal_static1,
    tog_static1,
    setModal_static2,
    tog_static2,
  } = UseViewTeam();
  return (
    <>
      {userPermissions === null ? (
        <Loader loaded={true} className="spinner" />
      ) : userPermissions.read ? (
        <div className="page-content">
          <Row className="mb-3">
            <Col md={4}>
              <Link to="/teams" className="link">
                <span className="fas fa-angle-left arrowheightwidth"></span>
              </Link>
              <span className="locationbrandtitle">
                {singleTeamsData?.name}
              </span>
            </Col>

            <Col md={1}></Col>
            <Col md={2}>
              {userPermissions.delete && (
                <Button
                  color="success"
                  outline
                  className="waves-effect waves-light mr-1  w-100"
                  block
                  onClick={() => {
                    delButtonClicked();
                  }}
                >
                  <span className="printbutton">Delete team</span>
                </Button>
              )}
            </Col>

            <Col md={3}>
              {userPermissions.update && (
                <Button
                  color="success"
                  outline
                  className="waves-effect waves-light mr-1  w-100"
                  block
                  onClick={() => {
                    setModal_static2(true);
                  }}
                >
                  <span className="printbutton">Discount settings</span>
                </Button>
              )}
            </Col>

            <Col md={2}>
              {userPermissions.update && (
                <Link to={`/edit_team/${id}`}>
                  <Button
                    color="success"
                    outline
                    className="waves-effect waves-light mr-1  w-100"
                    block
                  >
                    <span className="printbutton">Edit</span>
                  </Button>
                </Link>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <div className="d-flex justify-content-center p-5">
                  <div className="ct_c1 p-3">
                    <p className="ct_c2 mt-3 mx-3">
                      {singleTeamsData?.name
                        ? singleTeamsData?.name?.substring(0, 2)?.toUpperCase()
                        : "PD"}
                    </p>
                  </div>
                  <span className="searchTeamcss1">
                    {singleTeamsData?.name}
                  </span>
                </div>

                <Row className="mb-4">
                  <Col md={4}></Col>
                  <Col md={4}>
                    <div></div>
                  </Col>
                  <Col md={4}></Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card className="p-4">
                <Row>
                  <Col md={7}>
                    <p className="ct_c3">Summary</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <p className="sumary_l1">Bookings Discount</p>
                    <p className="sumary_l2 ">
                      {singleTeamsData?.discountOnBookings !== null
                        ? singleTeamsData?.discountOnBookings
                        : 0}
                    </p>
                  </Col>
                  <Col md={3}>
                    <p className="sumary_l1">Total Spent</p>
                    <p className="sumary_l2 ">
                      {singleTeamsData?.totalAmountSpent}
                    </p>
                  </Col>
                  <Col md={3}>
                    <p className="sumary_l1">Total Bookings</p>
                    <p className="sumary_l2 ">
                      {singleTeamsData?.totalBookings}
                    </p>
                  </Col>
                  <Col md={3}>
                    <p className="sumary_l1">Last Transaction</p>
                    <p className="sumary_l2 ">
                      {singleTeamsData?.recentTransactionTime
                        ? singleTeamsData?.recentTransactionTime
                        : "N / A"}
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          {singleTeamsData?.teamMembers?.length === 0 ? (
            ""
          ) : (
            <Row>
              <Col md={12}>
                <Card className="p-4">
                  <Row>
                    <Col md={7}>
                      <p className="ct_c3">Team members</p>
                    </Col>
                  </Row>

                  <Row className="mt-3 mb-3">
                    {singleTeamsData?.teamMembers?.map((item) => {
                      if (item?.status !== "removed")
                        return (
                          <Col className="my-3 " md={3}>
                            <Card className="   h-100 teamCardbg ">
                              <Row>
                                <Col md={12}>
                                  <div className="d-flex justify-content-end">
                                    {item?.role === "teamAdmin" ? (
                                      <p className="teamLeadTag"> Team Lead </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col md={12}>
                                  <div className="">
                                    <div>
                                      <div className=" d-flex justify-content-center ">
                                        <p
                                          className={
                                            item.name
                                              .substring(0, 1)
                                              .toUpperCase() >= "A" &&
                                            item.name
                                              .substring(0, 1)
                                              .toUpperCase() <= "F"
                                              ? "teamNameC4"
                                              : item.name
                                                  .substring(0, 1)
                                                  .toUpperCase() >= "G" &&
                                                item.name
                                                  .substring(0, 1)
                                                  .toUpperCase() <= "P"
                                              ? "teamNameC3"
                                              : item.name
                                                  .substring(0, 1)
                                                  .toUpperCase() >= "Q" &&
                                                item.name
                                                  .substring(0, 1)
                                                  .toUpperCase() <= "V"
                                              ? "teamNameC2"
                                              : item.name
                                                  .substring(0, 1)
                                                  .toUpperCase() >= "W" &&
                                                item.name
                                                  .substring(0, 1)
                                                  .toUpperCase() <= "Z"
                                              ? "teamNameC5"
                                              : ""
                                          }
                                        >
                                          {item.name
                                            .substring(0, 2)
                                            .toUpperCase()}
                                        </p>
                                      </div>

                                      <div className=" d-flex justify-content-center">
                                        <p className="teamNameC">
                                          {item?.name === "No Name No Name"
                                            ? "---"
                                            : item?.name}
                                        </p>
                                      </div>
                                    </div>

                                    <div className=" d-flex justify-content-center">
                                      <p className="teamEmailC">
                                        {item?.email}
                                      </p>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        );
                    })}
                  </Row>
                </Card>
              </Col>
            </Row>
          )}

          <div>
            <Modal
              isOpen={modal_static1}
              size="lg"
              toggle={tog_static1}
              centered={true}
            >
              <Row>
                <Col md={10}></Col>
                <Col md={1}>
                  <ModalHeader
                    toggle={() => setModal_static1(false)}
                  ></ModalHeader>
                </Col>
                <Col md={1}></Col>
              </Row>

              <ModalBody>
                <div className=" d-flex justify-content-center">
                  <div className="del_team_c7 ">
                    <img className="del_team_c8" src={deleteLogo} />{" "}
                  </div>
                </div>
                <p className="del_team_l1 mt-3"> Delete team</p>
                <p className="del_team_l2">
                  For deleting all of your team accounts permanently
                </p>
                <p className="del_team_l3">
                  Please agree with the following terms!
                </p>
                <div className="">
                  <Card className="del_team_c1 my-2 p-2">
                    <div className="d-flex align-items-center">
                      <Input
                        className=""
                        type="checkbox"
                        checked={delCheck1}
                        onChange={(e) => {
                          setDelCheck1(!delCheck1);
                        }}
                      />

                      <p className="mx-2  del_team_c2 mt-3">
                        Team will no longer be able to use your remaining
                        credits.
                      </p>
                    </div>
                  </Card>

                  <Card className="del_team_c1 my-2 p-2">
                    <div className="d-flex align-items-center">
                      <Input
                        className=""
                        type="checkbox"
                        checked={delCheck2}
                        onChange={(e) => {
                          setDelCheck2(!delCheck2);
                        }}
                      />

                      <p className="mx-2  del_team_c2 mt-3">
                        Team personal and professional information will be
                        deleted from all of our data storages.
                      </p>
                    </div>
                  </Card>

                  <Card className="del_team_c1 my-2 p-2">
                    <div className="d-flex align-items-center">
                      <Input
                        className=""
                        type="checkbox"
                        checked={delCheck3}
                        onChange={(e) => {
                          setDelCheck3(!delCheck3);
                        }}
                      />

                      <p className="mx-2  del_team_c2 mt-3">
                        Team invoices for past bookings and purchases cannot be
                        deleted because it is prohibited by state laws.
                      </p>
                    </div>
                  </Card>
                  {!canDeleteTeam ? (
                    <Card className="del_team_c4 my-2 p-2 mt-3">
                      <div className="d-flex align-items-center">
                        <img src={UnableToDel} className="team_info_logo" />
                        <p className="del_team_c5 mt-3 mx-2">
                          {" "}
                          Unable to delete!{" "}
                        </p>
                      </div>
                      <p className="del_team_c6 mx-4">{errMsg}</p>
                    </Card>
                  ) : (
                    <Card className="del_team_c4 my-2 p-2 mt-3">
                      <div className="d-flex align-items-center">
                        <img src={UnableToDel} className="team_info_logo" />
                        <p className="del_team_c5 mt-3 mx-2"> Warning! </p>
                      </div>
                      <p className="del_team_c6 mx-4">
                        You are about to permanently delete your account and
                        data
                      </p>
                    </Card>
                  )}
                </div>
                <Row className="my-4">
                  <Col md={4}></Col>

                  <Col md={4}>
                    {delLoading ? (
                      <Button
                        disabled={
                          !delCheck1 ||
                          !delCheck2 ||
                          !delCheck3 ||
                          !canDeleteTeam
                        }
                        color="dark"
                        outline
                        className="w-100"
                      >
                        <Spinner />
                      </Button>
                    ) : (
                      <Button
                        disabled={!delCheck1 || !delCheck2 || !delCheck3}
                        color="dark"
                        outline
                        className="w-100"
                        onClick={() => {
                          deleteTeamFunc();
                        }}
                      >
                        Delete Request
                      </Button>
                    )}
                  </Col>
                  <Col md={4}></Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>

          <div>
            <Modal
              isOpen={modal_static2}
              size="lg"
              toggle={tog_static2}
              centered={true}
            >
              <Row>
                <Col md={10}></Col>
                <Col md={1}>
                  <ModalHeader
                    toggle={() => setModal_static2(false)}
                  ></ModalHeader>
                </Col>
                <Col md={1}></Col>
              </Row>

              <ModalBody>
                <Row>
                  <Col md={12}>
                    <Card className="p-4">
                      <Row>
                        <Col md={12}>
                          <p className="ct_c3">Discount settings </p>
                          <p className="ct_l1">
                            Select if members of this team are eligible to
                            discounts when making bookings, purchasing passes,
                            plans or other charges
                          </p>
                          <div className="ct_l2 p-3">
                            <p className="ct_l4">Discounts on booking</p>
                            <p className="ct_l3">
                              <Input
                                checked={selectedDiscountRadio === "noDiscount"}
                                onChange={handleOptionChange}
                                value="noDiscount"
                                type="radio"
                                className="ct_r1"
                              />
                              Members of this team do not get a discount in
                              their bookings
                            </p>
                            <p className="ct_l3 ">
                              <Input
                                checked={selectedDiscountRadio === "discount"}
                                onChange={handleOptionChange}
                                value="discount"
                                type="radio"
                                className="ct_r1"
                              />
                              Members of this team get a discount of their
                              bookings.
                              {selectedDiscountRadio === "discount" ? (
                                <Input
                                  type="number"
                                  className="ct_discountInput"
                                  value={discountNumber}
                                  max={100}
                                  onChange={(e) => {
                                    const value = parseInt(e.target.value);
                                    if (!isNaN(value) && value >= 0) {
                                      const clampedValue = Math.min(value, 100);
                                      setDiscountNumber(clampedValue);
                                    } else {
                                      setDiscountNumber(0);
                                    }
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col md={10}></Col>
                        <Col md={2}>
                          <Button
                            color="success"
                            className="w-100"
                            onClick={() => {
                              saveDiscountFunc();
                              setModal_static2(false);
                            }}
                          >
                            save
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default ViewTeam;
