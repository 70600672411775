import BootstrapTable from "react-bootstrap-table-next";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import {
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useRoleUsers } from "./useRoleUsers";
import Spinner from "../../../components/Common/Loader";

import "../css/userroles.css";
const { SearchBar } = Search;
function RoleUsers() {
  const {
    pageOptions,
    isLoading,
    pagelengthnum,
    isOpen,
    toggle,
    pageSize,
    currentPage,
    changeCurrentPage,
    hasNextPage,
    role,
    showModel,
    getUserData,
    setshowModel,
    selectedUserData,
    dropDownData,
    setSelectedRole,
    activeSubmitBtn,
    setacticeSubmitBtn,
    updateUserRole,
    setShowForgetModel,
    showForgetModel,
    passwordChangeHandler,
    setUserEmail,
    updatedRoleUserData,
    toggleDropdown,
    
  } = useRoleUsers();

  // Columns
  const columns = [
    {
      dataField: "id",
      text: "No.",
      sort: true,
      style: { width: "10%" },
    },
    {
      dataField: "_id",
      text: "User Id",
      sort: true,
      formatter: (cell, row) => (
        <Link to={`/user_profile_detail/${row._id}`} className="link">
          {cell}
        </Link>
      ),
      style: { width: "10%" },
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      style: { width: "15%" },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      style: { width: "15%" },
    },
    {
      dataField: "mobileNumber",
      text: "Phone Number ",
      sort: true,
      style: { width: "15%" },
    },
    {
      dataField: "assignedRole",
      text: "Role",
      sort: true,

      style: { width: "15%" },
    },
    // {
    //   dataField: "Edit Role",
    //   text: "Edit Role",
    //   sort: true,
    //   formatter: (cell, row) => (
    //     // getUserData()
    //     <span
    //       className="editLink ms-3 mt-1"
    //       onClick={() => {
    //         setshowModel(true);
    //         getUserData(row);
    //       }}
    //     >
    //       Edit
    //     </span>
    //   ),
    //   style: { width: "15%" },
    // },
    {
      dataField: "dummyField",
      text: "Actions",
      sort: true,
      formatter: (cell, row) => (
        <div>
          <span
            className="ms-3 ri-settings-4-fill"
            style={{ cursor: "pointer", fontSize: "20px" }}
            onClick={() => toggleDropdown(row._id)}
          ></span>
          <Dropdown
            isOpen={row.isSelected}
            toggle={() => {
              console.log();
            }}
          >
            <DropdownToggle tag="span" className="d-none"></DropdownToggle>
            <DropdownMenu className="dropdown-menu-end" right>
              <DropdownItem
                onClick={() => {
                  //1) Take User Data
                  setUserEmail(row.email);
                  // 2) show Model
                  setShowForgetModel(true);
                }}
              >
                <span className="mdi mdi-key me-2"> </span> Reset Password
              </DropdownItem>
           {role==="User"?"":   <DropdownItem
                onClick={() => {
                  setshowModel(true);
                  getUserData(row);
                }}
              >
                <span className="mdi mdi-account-edit me-2"> </span>
                Edit Role
              </DropdownItem>}
              {/* Add more DropdownItems as needed */}
            </DropdownMenu>
          </Dropdown>
        </div>
      ),
    },
  ];
  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];
  // initaill value of DropDown
  const initialValue = { label: role, value: role };
  return (
    <div className="page-content">
      {/* Heading Row */}
      <div>
        <Row className="mb-3">
          <Col md={4}>
            <Link to="/user_roles" className="link">
              <span className="fas fa-angle-left arrowheightwidth"></span>
            </Link>
            <span className="locationbrandtitle ml-4 ">{role}</span>
          </Col>
          <Col md={2}>
            {/* <div>
              <span
                className="ms-5 ri-more-2-line"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();

                  toggleDropdown();
                }}
              ></span>
              <Dropdown isOpen={dropdownOpen} onClick={toggleDropdown}>
                <DropdownToggle
                  tag="span"
                  data-toggle="dropdown"
                  aria-expanded={dropdownOpen}
                  className="d-none"
                ></DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem onClick={() => {}}>Reset Password</DropdownItem>
                  <DropdownItem onClick={() => {}}>LogOut</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div> */}
          </Col>
          <Col md={6}></Col>
        </Row>
      </div>
      {/* Table Row */}
      {isLoading ? (
        <Spinner />
      ) : updatedRoleUserData ? (
        <div className="tablebgcolor">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="_id"
                    columns={columns}
                    data={updatedRoleUserData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        columns={columns}
                        data={updatedRoleUserData}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            {/* Search Bar Row */}
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="search-box chat-search-box" />
                                  </div>
                                </div>
                              </Col>
                              <Col md="4"></Col>
                              <Col md="4"></Col>
                            </Row>
                            {/* Table Row */}
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    hover
                                    keyField="_id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            {/* Pagination btn row */}
                            <Row className="align-items-md-center mt-3">
                              <Col className="inner-custom-pagination d-flex">
                                <span className="paginationitmes ">Items</span>
                                <div className="d-inline">
                                  {/* <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    /> */}
                                  <ButtonDropdown
                                    isOpen={isOpen}
                                    toggle={toggle}
                                    onClick={(e) => pagelengthnum(e)}
                                  >
                                    <DropdownToggle caret color="secondary">
                                      {pageSize}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem value="1">10</DropdownItem>
                                      <DropdownItem value="2">30</DropdownItem>
                                      <DropdownItem />
                                      <DropdownItem value="3">50</DropdownItem>
                                    </DropdownMenu>
                                  </ButtonDropdown>
                                </div>
                                <span className="paginationitmes1 ">show</span>
                                <div className="text-md-right ms-auto">
                                  {/* <PaginationListStandalone
                                      {...paginationProps}
                                    /> */}

                                  {/* Right Side Btn */}
                                  <Button
                                    color="secondary"
                                    className="waves-effect "
                                    style={{
                                      marginLeft: 7,
                                      marginRight: 7,
                                    }}
                                    disabled={currentPage <= 1}
                                    onClick={() =>
                                      currentPage === 1
                                        ? null
                                        : changeCurrentPage((prev) => prev - 1)
                                    }
                                  >
                                    <i className="dripicons-chevron-left"></i>
                                  </Button>

                                  <Button
                                    style={{
                                      marginLeft: 7,
                                      marginRight: 7,
                                    }}
                                    color="success"
                                    className="btn-rounded waves-effect waves-light me-1 mr-2 ml-2"
                                  >
                                    {currentPage}
                                  </Button>
                                  <Button
                                    style={{
                                      marginLeft: 7,
                                      marginRight: 7,
                                    }}
                                    color="secondary"
                                    className="waves-effect"
                                    disabled={!hasNextPage}
                                    onClick={() =>
                                      changeCurrentPage((prev) => prev + 1)
                                    }
                                  >
                                    <i className="dripicons-chevron-right"></i>
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <Spinner />
      )}
      
      {/* Role Change Model */}
      <Modal isOpen={showModel} centered={true}>
        <Row>
          <Col md={2}></Col>
          <Col md={10}>
            <ModalHeader >
              <span style={{ marginLeft: "45px" }}>Change User Role </span>
            </ModalHeader>
          </Col>
          {/* <Col md={2}>
            <button
              type="button"
              onClick={() => setshowModel(false)}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Col> */}
        </Row>

        <ModalBody>
          <Row>
            <Col md={6}>
              <Label htmlFor="userName" className="logininboxpanels">
                User Name
              </Label>
              <Input
                value={selectedUserData.name}
                id="userName"
                disabled={true}
              ></Input>
            </Col>
            <Col md={6}>
              <div>
                <Label htmlFor="role" className="logininboxpanels">
                  Assign Role
                </Label>
                <FormGroup className="select2-container">
                  <Select
                    onChange={(opt) => {
                      setacticeSubmitBtn(true);
                      setSelectedRole(opt.value);
                    }}
                    options={dropDownData}
                    defaultValue={initialValue}
                    placeholder="select role"
                    classNamePrefix="select2-selection"
                    id="role"
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col md={2}></Col>

            <Col md={4}>
              <Button
                color="success"
                className="waves-effect waves-light  w-100"
                onClick={() => {
                  setacticeSubmitBtn(false);
                  setshowModel(false);
                }}
              >
                cancel
              </Button>
            </Col>
            <Col md={4}>
              <Button
                disabled={!activeSubmitBtn}
                color="success"
                className="waves-effect waves-light  w-100"
                onClick={() => {
                  updateUserRole();
                  setshowModel(false);
                }}
              >
                Submit
              </Button>
            </Col>

            <Col md={2}></Col>
          </Row>
        </ModalBody>
      </Modal>

      {/* PasswordForGetModel */}
      <Modal isOpen={showForgetModel} centered={true}>
        <Row>
          <Col md={2}></Col>
          <Col md={8}>
            <ModalHeader>Forget Password Confirmation!</ModalHeader>
          </Col>
          <Col md={2}></Col>
        </Row>

        <ModalBody>
          <Row>
            <Col md={2}></Col>
            <Col md={9}>
              <p>{`Are you sure want to change password for this user?`}</p>
            </Col>
            <Col md={1}></Col>
          </Row>
          <hr />
          <Row>
            <Col md={2}></Col>

            <Col md={4}>
              <Button
                color="success"
                outline
                className="waves-effect waves-light w-100"
                onClick={() => setShowForgetModel(false)}
              >
                No
              </Button>
            </Col>
            <Col md={4}>
              <Button
                color="success"
                outline
                className="waves-effect waves-light w-100"
                onClick={() => {
                  // Hide Model
                  setShowForgetModel(false);
                  passwordChangeHandler();
                }}
              >
                Yes
              </Button>
            </Col>

            <Col md={2}></Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default RoleUsers;
