import { useEffect, useState } from "react";
import { usePaginatedQuery } from "../../../hooks/query";
import { getuserroles } from "../../../APIS/userProfle";
import { useHistory } from "react-router-dom";
import { DIOM_BASED_URLS } from "../../../config/url";
import { toast } from "react-toastify";
import { useUserRole } from "../../userRoles/useUserRoles";

export const useCreateUser = () => {
  let history = useHistory();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [Email, setEmail] = useState();
  const [password, setpassword] = useState();
  const [phoneNumber, setphoneNumber] = useState();
  const [role, setRole] = useState();

  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("Token");

  
  // Get data For Role DropDown
  // const {
  //   data: { data: userRolesData },
  //   isLoading,
  // } = usePaginatedQuery(["userRole"], () => getuserroles(token, history));

  const { userRoleData } = useUserRole();

  // const initialData=()=>{}
  const DropDownData = userRoleData
    .filter((role) => (role.name !== "Admin" && role.name !== "User"))
    .map((role) => {
      return {
        value: role.name,
        label: role.name,
      };
    });

  const checkEmailIsValid = (event) => {
    setEmail(event.target.value);
    const emailInput = event.target.value;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    setIsValidEmail(emailRegex.test(emailInput));
  };

  const createUserHandler = async () => {
    // Show loading Btn
    setIsLoading(true);
    const body = {
      firstName: firstName,
      lastName: lastName,
      email: Email,
      password: password,
      phoneNumber: phoneNumber,
      assignedRole: role,
    };

    try {
      const response = await fetch(
        `${DIOM_BASED_URLS}/diom/spaces/admin/signup`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(body),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setIsLoading(false);
        toast.success(data.message || "Admin User Created Successfully");
        // set empty after submit
        setEmail("");
        setFirstName("");
        setLastName("");
        setpassword("");
        setRole("");
        setphoneNumber("");
        // redirect to listing page
        history.push("/user_profile");
      } else {
        setIsLoading(false);
        toast.error(data?.error?.message || "Something Went Wrong");
      }
    } catch (error) {
      console.error("Request failed:", error);
    }

    // fetch(`${DIOM_BASED_URLS}/diom/spaces/admin/signup`, {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json, text/plain",
    //     "Content-Type": "application/json;charset=UTF-8",
    //     Authorization: "Bearer " + token,
    //   },
    //   body: JSON.stringify(body),
    // })
    //   .then((res) => {
    //     console.log(res, ">>>>");
    //     if (!res.ok) {
    //       toast.error(`Email already registered`);
    //       return;
    //     }
    //     const respone = res.json();
    //     return respone;
    //   })
    //   .then((data) => {

    //     data?.message ? data.message : "Email already registered"
    //      toast.success(
    //       `${}`
    //     );
    //   });
  };
  return {
    setFirstName,
    createUserHandler,
    setLastName,
    setEmail,
    setpassword,
    Email,
    setIsValidEmail,
    isValidEmail,
    checkEmailIsValid,
    setphoneNumber,
    setRole,
    DropDownData,
    firstName,
    lastName,
    password,
    phoneNumber,
    role,
    isLoading,

    // isLoading,
  };
};
