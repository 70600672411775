import { useState, useEffect, useRef } from "react";
import { DIOM_BASED_URLS } from "../../../.././config/url";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { getBooking } from "../../../../APIS/bookings";
import { getGuest } from "../../../../APIS/bookings";
import { getbookinguser } from "../../../../APIS/bookings";
import { toast } from "react-toastify";

export const useBookingDetails = () => {
  const [error, setError] = useState(null);
  const [bookingDetails, setBookingDetails] = useState({});
  const [checkInCheckout, setCheckInCheckout] = useState([]);
  const [checkedoutClicked, setCheckedoutClicked] = useState(false);
  const [disableCheckoutBtn, setdisableCheckoutBtn] = useState(false);
  // const [bookingUserName, setBookingUserName] = useState([]);
  // const [userBookings, setUserBookings] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [abc, setAbc] = useState(true);
  const [guestData, setGuestData] = useState([]);
  const [revokeBookingStatus, setRevokeBookingStatus] = useState();
  const history = useHistory();
  const token = localStorage.getItem("Token");
  const { id } = useParams();
  const [modelShow, setModelShow] = useState(false);

  // Getting Booking Data By id..
  const bookingData = useQuery(["bookingsDetails", id], () =>
    getBooking(token, id, history)
  );
  const bookingsDailedData = bookingData?.data?.data;
  // Set booking data to state Variable
  const bookingdetails = async () => {
    setBookingDetails(bookingsDailedData);
    // bookingusername();
    checkinCheckoutfunc(bookingsDailedData);
  };


  const bookingguestData = useQuery(["guestDetails", id], () =>
    getGuest(token, id, history)
  );

  const bookingsguestData = bookingguestData?.data?.data;

  const guestinfofunc = async () => {
    setGuestData(bookingsguestData);
  };

  // const condition = bookingsDailedData?.userId.length !==0? true : false
  const bookinguserData = useQuery(
    ["userDetails", id],

    () => getbookinguser(token, bookingsDailedData?.userId, history),
    { enabled: bookingsDailedData ? true : false }
  );

  const userbookingData = bookinguserData?.data?.data;

  const isLoading = bookinguserData.isLoading;

  // ********
  // const bookingusername = async () => {
  // setBookingUserName(userbookingData?.username);
  // setUserBookings(userbookingData ? userbookingData : null);
  // };

  const checkinCheckoutfunc = (result) => {
    const newArr = [...result.timeLogs];
    const sortedData = newArr.reverse();
    setCheckInCheckout(sortedData);
    return [sortedData];
  };

  //   const getrevokedbookings = async () => {
  //     fetch(`${DIOM_BASED_URLS}/admin-diom-bookings/revoked/${id}`, {
  //       method: "GET",
  //       redirect: "follow",
  //     })
  //       .then((response) => response.json())
  //       .then((result) => {
  //         const mappeddata = result.data.map((e) => ({
  //           bookingStatus: e.bookingStatus,
  //         }));
  //         setRevokeBookingStatus(mappeddata);
  //       })
  //       .catch((error) => console.log("error", error));
  //   };

  useEffect(() => {
    (async () => {
      bookingsDailedData && (await bookingdetails());
      bookingsguestData && (await guestinfofunc());
      //   await getrevokedbookings();
    })();
  }, [bookingsDailedData, bookingsguestData]);

  //  user check out function
  const checkoutFunc = () => {
    // set to true setdisableCheckoutBtn
    setdisableCheckoutBtn(true);

    fetch(`${DIOM_BASED_URLS}/admin-diom-bookings/${id}/run-commands`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        command: "FORCE_USER_CHECKOUT",
        value: "",
        key: "",
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          // Success response
          setdisableCheckoutBtn(false);
          toast.success("successfully checked out");
          setCheckedoutClicked(true);
        } else {
          setdisableCheckoutBtn(false);
          // Error response
          return response.json().then((data) => {
            toast?.error(data.error?.message);
          });
        }
      })
      .catch((error) => {
        setdisableCheckoutBtn(false);
        console.log(error);
        toast.error(" Something went wrong");
      });
  };

  return {
    checkedoutClicked,
    bookingsDailedData,
    error,
    setError,
    bookingDetails,
    setBookingDetails,
    checkInCheckout,
    setCheckInCheckout,
    checkoutFunc,
    disableCheckoutBtn,
    loaded,
    setLoaded,
    guestData,
    setGuestData,
    revokeBookingStatus,
    setRevokeBookingStatus,
    bookingdetails,
    guestinfofunc,

    abc,
    isLoading,
    setAbc,
    checkinCheckoutfunc,
    modelShow,
    setModelShow,
    userbookingData,
    bookingsDailedData
  };
};
