// ***********Get All DiscountAmounts  ************

import { DIOM_BASED_URLS } from "../config/url";

export const getPackages = async (
  size,
  page,
  token = "",
  history,
  activeInactiveState,
  tabsNum
) => {
  const res = await fetch(
    tabsNum === 3
      ? `${DIOM_BASED_URLS}/packages?size=${size}&page=${page}&filter={"where":{"archived":"true"}}`
      : `${DIOM_BASED_URLS}/packages?size=${size}&page=${page}&filter={"where":{"visibility":${activeInactiveState}, "archived":"false"}}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resJson = await res.json();
  const Packages = resJson?.data?.map((element, index) => ({
    id: element.id,
    name: element.name,
    price: element.price,
    validity: element.validity,
    categories: element.categories?.map((e) => e?.name),
    recentlyPurchasedAt: element?.recentlyPurchasedAt,
    quantitySold: element?.quantitySold,
  }));

  return {
    data: Packages.length > 0 ? Packages : [],
    total: resJson ? resJson.total : 0,
    hasNextPage: resJson.hasNextPage ?? false,
    hasPreviousPage: resJson.hasPreviousPage ?? false,
  };
};

//  ************ getPackge  ************
export const getPackage = async (token = "", history, id) => {
  console.log("hasPreviousPage", id);
  if (id) {
    const res = await fetch(`${DIOM_BASED_URLS}/packages/${id}`, {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    if (!res.ok) {
      if (res.status === 401) {
        history.push("/login");
        throw new Error(resJson.error.message);
      }
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    return {
      data: resJson,
    };
  }
};

// ************Locations **************

export const getLocationPackges = async (token) => {
  const res = (
    await (
      await fetch(
        `${DIOM_BASED_URLS}/admin-business-locations?filter={"where":{"visibility":true}}`,

        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
    ).json()
  )?.map((element) => ({
    value: element.id,
    label: element.Name,
  }));
  return [
    // label: "All",

    ...res,
  ];
};

// ************packages customer************

export const getcustomersPackages = async (token = "") => {
  const res = await fetch(
    `${DIOM_BASED_URLS}/users`,

    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  if (!res.ok) {
    if (res.status === 401) {
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resJson = await res.json();
  const userProfileData = resJson.users.map((element, index) => ({
    value: element.id,
    label: element.fullName,
  }));

  return [...userProfileData];
};

// ************packages customer************

export const getcustomersSearchPackages = async (
  token = "",
  history,
  customerSearchVer
) => {
  if (customerSearchVer) {
    const res = await fetch(
      `${DIOM_BASED_URLS}/users-search/${customerSearchVer}`,

      {
        method: "GET",
        redirect: "follow",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (!res.ok) {
      if (res.status === 401) {
        throw new Error(resJson.error.message);
      }
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    const userProfileSearchData = resJson?.users?.map((element, index) => ({
      value: element.id,
      label: element.fullName,
    }));

    return { data: userProfileSearchData };
  }
};
