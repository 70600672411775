import "./Loader.css";
const Loader = require("react-loader");

function Spinner() {
  return (
    <div className="spinner-container">
      <Loader loaded={false} className="spinner" />
    </div>
  );
}

export default Spinner;
