import React, { useState } from "react";
import moment from "moment";
import Chart from "./Chart";

import { chart_Data_Urls } from "../../config/chartsData";
import { Row, Col } from "reactstrap";
import { useQuery } from "react-query";
import {
  getBrandCard,
  getFinanceCard,
  getGenaralCard,
  getLocationCard,
  getUsersCard,
  getPackagesCard,
  getTeamsCard,
} from "../../APIS/dashboard";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
const UseDashboard = () => {
  let history = useHistory();
  const today = moment().format("YYYY-MM ");
  const token = localStorage.getItem("Token");
  const t_ID = "1";
  // This State use in Tab to set Which tab is active now.
  const [activeTabJustify, setActiveTabJustify] = useState("1");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // Filter State use to apply filter on Charts..
  const [maxDataAge, setMaxDataAge] = useState({
    createdAt: {
      $gte: new Date(moment(today).startOf("month").toISOString()),
      $lte: new Date(moment().toISOString()),
    },
  });


  const [activeButton, setActiveButton] = useState("today"); // Track the active button

  const [loaded] = useState(false);
  const Loader = require("react-loader");

  const toggleCustomJustified = (tab) => {
    // if (activeTabJustify === tab) {
    //   setActiveTabJustify(tab);
    // } // I comment out repeat code...
    if (activeTabJustify !== tab) {
      setActiveTabJustify(tab);
    }
  };

  // *******Finance******
  const dashboardFinanceCardddata = useQuery(["FinanceCards"], () =>
    getFinanceCard(token, history)
  );

  const financeCardddata = dashboardFinanceCardddata?.data?.data;
  // *******General******
  const dashboardGeneralCardddata = useQuery(["GeneralCards"], () =>
    getGenaralCard(token, history)
  );
  const generalCardddata = dashboardGeneralCardddata?.data?.data;
  // *******Location******
  const dashboardLocationCardddata = useQuery(["LocationCards"], () =>
    getLocationCard(token, history)
  );
  const locationCardddata = dashboardLocationCardddata?.data?.data;
  // *******Brand******
  const dashboardBrandCardddata = useQuery(["BrandCards"], () =>
    getBrandCard(token, history)
  );
  const brandCardddata = dashboardBrandCardddata?.data?.data;
  // *******Brand******
  const dashboardUsersCardddata = useQuery(["UsersCards"], () =>
    getUsersCard(token, history)
  );
  const usersCardddata = dashboardUsersCardddata?.data?.data;
  // *************Package State************
  const dashboardPackageCardddata = useQuery(["PackageCards"], () =>
    getPackagesCard(token, history)
  );

  const packageCardddata = dashboardPackageCardddata?.data?.data;

  // *********** teams stats**************
  const dashboardTeamCardddata = useQuery(["dashboardTeamCardddata"], () =>
    getTeamsCard(token, history)
  );
  const teamsCardddata = dashboardTeamCardddata?.data?.data;

  // Chart-View-fn
  // This is ChartView
  const chartsView = () => {
    if (activeTabJustify === "1") {
      // General Tab Charts..
      return (
        <div key={activeTabJustify}>
          <Row className="mt-3">
            <Col md={6}>
              <div className="charts">
                <Chart
                  height={"350px"}
                  width={"480px"}
                  filter={maxDataAge}
                  chartId={chart_Data_Urls.Revenue_By_Locations}
                  
                />
              </div>
            </Col>

            <Col md={6}>
              <div className="charts">
                <Chart
                  height={"350px"}
                  width={"480px"}
                  filter={maxDataAge}
                  // chartId={"625139bf-45ca-4d14-85ba-9a0e96d9ab76"}
                  chartId={chart_Data_Urls.Bookings_By_Brands}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <div className="charts">
                <Chart
                  height={"350px"}
                  width={"480px"}
                  filter={maxDataAge}
                  chartId={chart_Data_Urls.Revenue_by_Brands}
                  // chartId={"6256af9a-04a5-4f01-8929-ba406cf900cb"}
                />
              </div>
            </Col>

            <Col md={6}>
              <div className="charts">
                <Chart
                  height={"350px"}
                  width={"480px"}
                  filter={maxDataAge}
                  chartId={chart_Data_Urls.Revenue_By_Booking_Type}
                  // chartId={"62514999-70f4-431b-8d96-68f6a4c6a942"}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <div className="charts">
                <Chart
                  height={"350px"}
                  width={"480px"}
                  filter={maxDataAge}
                  chartId={chart_Data_Urls.Bookings_By_Resource_Type}
                  
                />
              </div>
            </Col>

            <Col md={6}>
              <div className="charts">
                <Chart
                  height={"350px"}
                  width={"480px"}
                  filter={maxDataAge}
                  chartId={chart_Data_Urls.Revenue_By_Resource_Type}
                  // chartId={"62514d58-905f-40e2-83f4-4c6b1b2877eb"}
                />
              </div>
            </Col>
          </Row>
        </div>
      );
    }
    if (activeTabJustify === "2") {
      return (
        <div key={activeTabJustify}>
          <Row className="mt-3">
            <Col md={6}>
              <div className="charts">
                <Chart
                  height={"350px"}
                  width={"480px"}
                  filter={maxDataAge}
                  chartId={chart_Data_Urls.Revenue_By_Locations}
                  // chartId={"625134b0-5d61-46db-86c0-48eef0760b6e"}
                />
              </div>
            </Col>

            <Col md={6}>
              <div className="charts">
                <Chart
                  height={"350px"}
                  width={"480px"}
                  filter={maxDataAge}
                  chartId={chart_Data_Urls.Revenue_By_Resource_Type}
                  // chartId={"62514d58-905f-40e2-83f4-4c6b1b2877eb"}
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col md={6}>
              <div className="charts">
                <Chart
                  height={"350px"}
                  width={"480px"}
                  filter={maxDataAge}
                  chartId={chart_Data_Urls.Revenue_by_Brands}
                  // chartId={"6256af9a-04a5-4f01-8929-ba406cf900cb"}
                />
              </div>
            </Col>

            <Col md={6}>
              {/* <div className="charts">
                <Chart
                  height={"350px"}
                  width={"480px"}
                  filter={maxDataAge}
                  chartId={"62514d58-905f-40e2-83f4-4c6b1b2877eb"}
                />
              </div> */}
            </Col>
          </Row>
        </div>
      );
    }
    if (activeTabJustify === "3") {
      return (
        <div key={activeTabJustify}>
          <Row className="mt-3">
            <Col md={6}>
              <div className="charts">
                <Chart
                  height={"350px"}
                  width={"480px"}
                  filter={maxDataAge}
                  chartId={chart_Data_Urls.Revenue_By_Locations}
                  // chartId={"625134b0-5d61-46db-86c0-48eef0760b6e"}
                />
              </div>
            </Col>

            <Col md={6}>
              <div className="charts">
                <Chart
                  height={"350px"}
                  width={"480px"}
                  filter={maxDataAge}
                  chartId={chart_Data_Urls.Bookings_By_Locations}
                  // chartId={"62515345-2438-4c2b-8a7d-0245e2511098"}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <div className="charts">
                <Chart
                  height={"350px"}
                  width={"480px"}
                  filter={maxDataAge}
                  chartId={chart_Data_Urls.Bookings_By_Resource_Type}
                  
                />
              </div>
            </Col>

            <Col md={6}>
              <div className="charts">
                <Chart
                  height={"350px"}
                  width={"480px"}
                  filter={maxDataAge}
                  chartId={chart_Data_Urls.Locations_With_Booking_Types}
                  // chartId={"6257b292-342b-4a2c-83f5-dfcb02f4bae1"}
                />
              </div>
            </Col>
          </Row>
        </div>
      );
    }
    if (activeTabJustify === "4") {
      return (
        <div key={activeTabJustify}>
          <Row className="mt-3">
            <Col md={6}>
              <div className="charts">
                <Chart
                  height={"350px"}
                  width={"480px"}
                  filter={maxDataAge}
                  chartId={chart_Data_Urls.Bookings_By_Brands}
                  // chartId={"625139bf-45ca-4d14-85ba-9a0e96d9ab76"}
                />
              </div>
            </Col>

            <Col md={6}>
              <div className="charts">
                <Chart
                  height={"350px"}
                  width={"480px"}
                  filter={maxDataAge}
                  chartId={chart_Data_Urls.Revenue_by_Brands}
                  // chartId={"6256af9a-04a5-4f01-8929-ba406cf900cb"}
                />
              </div>
            </Col>
          </Row>
        </div>
      );
    }
    if (activeTabJustify === "5") {
      return (
        <div key={activeTabJustify}>
          <Row className="mt-3">
            <Col md={6}>
              <div className="charts">
                <Chart
                  height={"350px"}
                  width={"480px"}
                  filter={maxDataAge}
                  chartId={chart_Data_Urls.Users_By_Industry}
                  // chartId={"6256aed7-adc9-4235-8e2e-c4d5e33c4cd1"}
                />
              </div>
            </Col>

            <Col md={6}>
              <div className="charts">
                <Chart
                  height={"350px"}
                  width={"480px"}
                  filter={maxDataAge}
                  chartId={chart_Data_Urls.Users_By_Position}
                  // chartId={"6256af21-ad42-491c-886b-c379e048fc5a"}
                />
              </div>
            </Col>
          </Row>
        </div>
      );
    }
    if (activeTabJustify === "6") {
      return (
        <div key={activeTabJustify}>
          <Row className="mt-3">
            <Col md={12}>
              <div className="charts">
                <Chart
                  height={"450px"}
                  width={"1000px"}
                  filter={maxDataAge}
                  chartId={chart_Data_Urls.Package_Id}
                  // chartId={"6256aed7-adc9-4235-8e2e-c4d5e33c4cd1"}
                />
              </div>
            </Col>

            {/* <Col md={6}>
              <div className="charts">
                <Chart
                  height={"350px"}
                  width={"480px"}
                  filter={maxDataAge}
                  chartId={chart_Data_Urls.Users_By_Position}
                  // chartId={"6256af21-ad42-491c-886b-c379e048fc5a"}
                />
              </div>
            </Col> */}
          </Row>
        </div>
      );
    }

    // if (activeTabJustify === "7") {
    //   return (
    //     <div key={activeTabJustify}>
    //       <Row className='mt-3'>
    //         <Col md={6}>
    //           <div className='charts'>
    //             <Chart
    //               height={"350px"}
    //               width={"480px"}
    //               filter={maxDataAge}
    //               chartId={chart_Data_Urls.Booking_By_ResourceType_team}
    //             />
    //           </div>
    //         </Col>

    //         <Col md={6}>
    //           <div className='charts'>
    //             <Chart
    //               height={"350px"}
    //               width={"480px"}
    //               filter={maxDataAge}
    //               chartId={chart_Data_Urls.Revenue_team}
    //             />
    //           </div>
    //         </Col>
    //       </Row>
    //       <Row className='mt-3'>
    //         <Col md={6}>
    //           <div className='charts'>
    //             <Chart
    //               height={"350px"}
    //               width={"480px"}
    //               filter={maxDataAge}
    //               chartId={chart_Data_Urls.Top_Five_Teams}
    //             />
    //           </div>
    //         </Col>

    //         <Col md={6}>
    //           <div className='charts'>
    //             <Chart
    //               height={"350px"}
    //               width={"480px"}
    //               filter={maxDataAge}
    //               chartId={chart_Data_Urls.Revenue_By_Location_Teams}
    //             />
    //           </div>
    //         </Col>
    //       </Row>
    //     </div>
    //   );
    // }

    return;
  };

  // TabActive fn
  const setMaxDataAgeForButton = (buttonName) => {
    // Set the active button when a button is clicked
    setStartDate('')
    setEndDate('')
    setActiveButton(buttonName);

    // Set the maxDataAge based on the button clicked
    switch (buttonName) {
      case "today":
        setMaxDataAge({
          createdAt: {
            $gte: new Date(moment().startOf("day").toISOString()),
            $lte: new Date(),
          },
        });
        break;
      case "yesterday":
        setMaxDataAge({
          // Handle yesterday case
          createdAt: {
            $gte: new Date(
              moment().subtract(1, "day").startOf("day").toISOString()
            ),

            $lte: new Date(
              moment().subtract(1, "day").endOf("day").toISOString()
            ),
          },
        });
        break;
      case "lastWeek":
        setMaxDataAge({
          // Handle last week case
          createdAt: {
            $gte: new Date(
              moment().subtract(1, "weeks").startOf("isoWeek").toISOString()
            ),
            $lte: new Date(
              moment().subtract(1, "weeks").endOf("isoWeek").toISOString()
            ),
          },
        });
        break;
      case "Thismonth":
        const todayDate = moment().format("YYYY-MM ");
        setMaxDataAge({
          createdAt: {
            $gte: new Date(moment(todayDate).startOf("month").toISOString()),
            $lte: new Date(moment().toISOString()),
          },
        });
        break;
      case "Lastmonth":
        const today = moment().format("YYYY-MM ");
        setMaxDataAge({
          createdAt: {
            $gte: new Date(
              moment(today + "1")
                .subtract(1, "months")
                .startOf("month")
                .toISOString()
            ),
            $lte: new Date(
              moment(today + "1")
                .subtract(1, "months")
                .endOf("month")
                .toISOString()
            ),
          },
        });
        break;
      // Add cases for other buttons
      default:
        break;
    }
  };

function CustomStartDate(date){
  if( endDate&& date>endDate){
setStartDate('')
   return  toast.error(`From Date should be less than To date`);
  }

    setStartDate(date)
  

}
function CustomEndDate(date){

if( date<startDate){
setEndDate('')
 return toast.error(`To Date should be greater than From date`);
}
  setEndDate(date)


}

console.log(startDate , endDate ,">>>>>>>>Dates>>>>>>>>>>>>>")

function customFilterHandler(){

  setMaxDataAge({
    createdAt: {
      $gte: new Date(
        moment(startDate)
          
          .toISOString()
      ),
      $lte: new Date(
        moment(endDate)
          .toISOString()
      ),
    },
  });

  setActiveButton('')
}

  // InlineStyle
  const filterActiveBtnStyle = { backgroundColor: " #03b2a5" };
  const filterInActiveBtnStyle = { backgroundColor: "white" };
  let activeBtnHeading = { color: "white" };
console.log(maxDataAge,">>>>>>>>maxDataAge>>>>>>>>")

  return {
    CustomStartDate,
    CustomEndDate,
    customFilterHandler,
    loaded,
    activeTabJustify,
    setActiveTabJustify,
    t_ID,
    toggleCustomJustified,
    Loader,
    maxDataAge,
    setMaxDataAge,
    financeCardddata,
    generalCardddata,
    locationCardddata,
    brandCardddata,
    usersCardddata,
    packageCardddata,
    teamsCardddata,
    chartsView,
    activeButton,
    setMaxDataAgeForButton,
    filterActiveBtnStyle,
    filterInActiveBtnStyle,
    activeBtnHeading,
    startDate,
    endDate
  };
};

export default UseDashboard;
