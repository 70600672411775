import {
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import "../css/userroles.css";
import Input from "reactstrap/lib/Input";
import { Link } from "react-router-dom";
import { useUserRoleDetail } from "./useRoleDetailPage";
import { useEffect, useState } from "react";
import UserPermissionFetcher from "../../../helpers/getUserPerimissions";
import "../css/userroles.css";
import NoAccess from "../../../components/Common/NoAccess";
import Spinner from "../../../components/Common/Loader";

function UserDetailRole() {
  const assignedRole = localStorage.getItem("assignedRole");

  const { userdetailsData, isLoading, UpdateUserRole } = useUserRoleDetail();
  const [userClick, setUserClick] = useState(true);
  const [cancelBtnActive, setCancelBtnActive] = useState(true);
  const [submitbtnIsActive, setSubmitBtnIsActive] = useState(true);
  const [updatedPermissions, setUpdatedPermissions] = useState(
    userdetailsData.permissions
  );
  const [modelShow, setModelShow] = useState(false);
  const [modelShowCancel, setModelShowCancel] = useState(false);

  // Use an effect to set updatedPermissions when userdetailsData changes
  useEffect(() => {
    if (userdetailsData && userdetailsData.permissions) {
      
      setUpdatedPermissions(userdetailsData.permissions);
    }
  }, [userdetailsData]);

  // Initialize state variables to manage checkbox values
  const handleCheckboxChange = (resourceName, action) => {
    setCancelBtnActive(false);
    setSubmitBtnIsActive(false);

    // Here we updating initial permission data with user updating data...
    setUpdatedPermissions((prevPermissions) => {
      return prevPermissions.map((permission) => {
        if (permission.resourceName === resourceName) {
          return {
            ...permission,
            actions: {
              ...permission.actions,
              [action]: !permission.actions[action],
            },
          };
        }
        return permission;
      });
    });
  };
  // Cancel Action Btn set permissions as defualt
  function SetStateToIniatiall() {
    setUpdatedPermissions(userdetailsData.permissions);
  }

  // Handle the case where updatedPermissions is not yet available
  if (isLoading || !updatedPermissions) {
    return <Spinner loaded={false} className="spinner" />;
  }

  return (
    <>
      {assignedRole === "Admin" ? (
        <div className="page-content">
          <div>
            <Row className="mb-3">
              <Col md={4}>
                <Link to="/user_roles" className="link">
                  <span className="fas fa-angle-left arrowheightwidth"></span>
                </Link>
                <span className="locationbrandtitle ml-4 ">
                  {userdetailsData.name}
                </span>
              </Col>
              <Col md={!cancelBtnActive ? 2 : 4}></Col>
              <Col md={2}>
                <Link to={`/role_users/${userdetailsData.name}`}>
                  <Button color="success" className="w-100" onClick={() => {}}>
                    Role Users
                  </Button>
                </Link>
              </Col>

              {assignedRole === "Admin"
                ? !cancelBtnActive && (
                    <Col md={2}>
                      <Button
                        disabled={cancelBtnActive}
                        color="danger"
                        className="w-100"
                        onClick={() => {
                          // setUserClick(true);
                          // setCancelBtnActive(true);
                          setModelShowCancel(true);
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                  )
                : ""}
              {assignedRole === "Admin" ? (
                <Col md={2}>
                  {userClick ? (
                    <Button
                      color="success"
                      className="w-100"
                      onClick={() => {
                        setUserClick(false);
                        setSubmitBtnIsActive(true);
                      }}
                    >
                      Edit
                    </Button>
                  ) : (
                    <Button
                      disabled={submitbtnIsActive}
                      color="success"
                      className="w-100"
                      onClick={() => {
                        setModelShow(true);
                      }}
                    >
                      Submit
                    </Button>
                  )}
                </Col>
              ) : (
                ""
              )}
            </Row>
          </div>
          {isLoading ? (
            <Spinner />
          ) : (
            <Card style={{ marginTop: "20px" }}>
              <CardBody>
                {/* First Row */}
                <Row style={{ marginLeft: "5px" }}>
                  {/* <Col md={1}></Col> */}
                  <Col md={12}>
                    <Row>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <p className="lead">Resource Name</p>
                      </Col>
                      <Col md={2}>
                        <p className="lead" style={{ marginLeft: "5px" }}>
                          Create
                        </p>
                      </Col>
                      <Col md={2}>
                        <p className="lead">Read</p>
                      </Col>
                      <Col md={2}>
                        <p className="lead">Update</p>
                      </Col>

                      <Col md={1}>
                        <p className="lead" style={{ marginRight: "5px" }}>
                          Delete
                        </p>
                      </Col>
                      {/* <Col md={1}></Col> */}
                      {/* <Col md={3}>
                        <div>
                          <p
                            className="lead commands-column"
                            style={{ marginLeft: "60px" }}
                          >
                            Commands
                          </p>
                        </div>
                      </Col> */}
                    </Row>
                  </Col>
                </Row>
                {/* Second Row */}

                {updatedPermissions.map((userPermission) => {
                  return (
                    <Row
                      style={{ marginTop: "35px" }}
                      key={userPermission.resourceName}
                    >
                      {/* <Col md={1}></Col> */}
                      <Col md={12}>
                        <Row style={{ marginLeft: "30px" }}>
                          <Col md={1}></Col>
                          <Col md={2}>
                            <p> {userPermission.resourceName}</p>
                          </Col>
                          <Col md={2} style={{ marginLeft: "5px" }}>
                            <span>
                              <Input
                                disabled={userClick}
                                className={`form-check-input`}
                                style={
                                  userPermission.actions.create
                                    ? {
                                        backgroundColor: "#03B2A5",
                                        borderColor: "#03B2A5",
                                      }
                                    : { borderColor: "#03B2A5" }
                                }
                                type="checkbox"
                                value=""
                                id="defaultCheck1"
                                checked={userPermission.actions.create} // For "create" action
                                onChange={() =>
                                  handleCheckboxChange(
                                    userPermission.resourceName,
                                    "create"
                                  )
                                }
                                //   disabled
                              />
                            </span>
                          </Col>
                          <Col md={2}>
                            <span>
                              <Input
                                disabled={userClick}
                                className={`form-check-input
                              `}
                                style={
                                  userPermission.actions.read
                                    ? {
                                        backgroundColor: "#03B2A5",
                                        borderColor: "#03B2A5",
                                      }
                                    : { borderColor: "#03B2A5" }
                                }
                                type="checkbox"
                                value=""
                                id="defaultCheck1"
                                checked={userPermission.actions.read} // For "create" action
                                onChange={() =>
                                  handleCheckboxChange(
                                    userPermission.resourceName,
                                    "read"
                                  )
                                }
                              />
                            </span>
                          </Col>
                          <Col md={2}>
                            <span>
                              <Input
                                disabled={userClick}
                                className="form-check-input "
                                style={
                                  userPermission.actions.update
                                    ? {
                                        backgroundColor: "#03B2A5",
                                        borderColor: "#03B2A5",
                                      }
                                    : { borderColor: "#03B2A5" }
                                }
                                type="checkbox"
                                value=""
                                id="defaultCheck1"
                                checked={userPermission.actions.update} // For "create" action
                                onChange={() =>
                                  handleCheckboxChange(
                                    userPermission.resourceName,
                                    "update"
                                  )
                                }
                                //   disabled
                              />
                            </span>
                          </Col>
                          <Col md={2}>
                            <span>
                              <Input
                                disabled={userClick}
                                className="form-check-input "
                                style={
                                  userPermission.actions.delete
                                    ? {
                                        backgroundColor: "#03B2A5",
                                        borderColor: "#03B2A5",
                                      }
                                    : { borderColor: "#03B2A5" }
                                }
                                type="checkbox"
                                value=""
                                id="defaultCheck1"
                                checked={userPermission.actions.delete} // For "create" action
                                onChange={() =>
                                  handleCheckboxChange(
                                    userPermission.resourceName,
                                    "delete"
                                  )
                                }
                                //   disabled
                              />
                            </span>
                          </Col>
                          {/* <Col md={2}>
                            <span>
                              <Input
                                disabled={userClick}
                                className="form-check-input "
                                style={
                                  userPermission.actions.commands
                                    ? {
                                        backgroundColor: "#03B2A5",
                                        borderColor: "#03B2A5",
                                      }
                                    : { borderColor: "#03B2A5" }
                                }
                                type="checkbox"
                                value=""
                                id="defaultCheck1"
                                checked={userPermission.actions.commands} // For "create" action
                                onChange={() =>
                                  handleCheckboxChange(
                                    userPermission.resourceName,
                                    "commands"
                                  )
                                }
                                //   disabled
                              />
                            </span>
                          </Col> */}
                        </Row>
                      </Col>
                    </Row>
                  );
                })}
              </CardBody>
            </Card>
          )}
          {/* Model to confirm chages */}
          <Modal
            isOpen={modelShow}
            // toggle={() => {
            //   tog_static();
            // }}
            centered={true}
          >
            <Row>
              <Col md={2}></Col>
              <Col md={8}>
                <ModalHeader>
                  <h4 style={{ marginLeft: "25px" }}> Chage Role Actions</h4>
                </ModalHeader>
              </Col>
              <Col md={2}></Col>
            </Row>

            <ModalBody>
              <Row>
                <Col md={2}></Col>
                <Col md={9}>
                  <p>{`Are you sure you want to change Actions?`}</p>
                </Col>
                <Col md={1}></Col>
              </Row>
              <hr />
              <Row>
                <Col md={2}></Col>

                <Col md={4}>
                  <Button
                    color="success"
                    outline
                    className="waves-effect waves-light w-100"
                    onClick={() => setModelShow(false)}
                  >
                    No
                  </Button>
                </Col>
                <Col md={4}>
                  <Button
                    color="success"
                    className="waves-effect waves-light  w-100"
                    onClick={() => {
                      setCancelBtnActive(true);
                      setSubmitBtnIsActive(true);
                      setUserClick(true);
                      UpdateUserRole(updatedPermissions);
                      setModelShow(false);
                    }}
                  >
                    Yes
                  </Button>
                </Col>

                <Col md={2}></Col>
              </Row>
            </ModalBody>
          </Modal>

          {/* Model to confirm cancel */}
          <Modal
            isOpen={modelShowCancel}
            // toggle={() => {
            //   tog_static();
            // }}
            centered={true}
          >
            <Row>
              <Col md={2}></Col>
              <Col md={8}>
                <ModalHeader>
                  <h4 style={{ marginLeft: "25px" }}> Cancel Changes</h4>
                </ModalHeader>
              </Col>
              <Col md={2}></Col>
            </Row>

            <ModalBody>
              <Row>
                <Col md={2}></Col>
                <Col md={9}>
                  <p>{`Are you sure you want to cancel changes?`}</p>
                </Col>
                <Col md={1}></Col>
              </Row>
              <hr />
              <Row>
                <Col md={2}></Col>

                <Col md={4}>
                  <Button
                    color="success"
                    outline
                    className="waves-effect waves-light w-100"
                    onClick={() => setModelShowCancel(false)}
                  >
                    No
                  </Button>
                </Col>
                <Col md={4}>
                  <Button
                    color="success"
                    className="waves-effect waves-light  w-100"
                    onClick={() => {
                      // UpdateUserRole(updatedPermissions);
                      SetStateToIniatiall();
                      setUserClick(true);
                      setCancelBtnActive(true);
                      setModelShowCancel(false);
                    }}
                  >
                    Yes
                  </Button>
                </Col>

                <Col md={2}></Col>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
}

export default UserDetailRole;
