import { DIOM_BASED_URLS } from "../config/url";

// **********resourceTypes of instantcheckin*********

export const getResourcetypeinstantCheckin = async (token = "") => {
  const res = await fetch(
    `${DIOM_BASED_URLS}/admin-resource-types-inventories?filter={"where":{"visibility":true}}`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  // ).json();
  if (!res.ok) {
    if (res.status === 401) {
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson);
  }

  const resJson = await res.json();
  const resData = resJson?.data.map((e, index) => ({
    value: e?.id,
    label: `${e?.Name} (${e?.businessName})`,
    // label: e?.Name,
    businessName: e?.businessName,
  }));

  return {
    // data: resJson?.data,
    data: resData,
    total: resJson.total,
    hasNextPage: resJson.hasNextPage,
    hasPreviousPage: resJson.hasPreviousPage,
  };
};

// ************instant checkin get Api****************

export const getInstantCheckin = async (token = "") => {
  const res = await fetch(`${DIOM_BASED_URLS}/instant-checkins`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  // ).json();
  if (!res.ok) {
    if (res.status === 401) {
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson);
  }

  const resJson = await res.json();
  // const resData = resJson?.data.map((e, index) => ({
  //   value: e?.id,
  //   label: e?.Name,
  // }));

  return {
    data: resJson,
  };
};
