import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getInstantCheckin,
  getResourcetypeinstantCheckin,
} from "../../../../APIS/instantCheckin";
import { DIOM_BASED_URLS } from "../../../../config/url";

const UseEditInstantCheckin = () => {
  const [instantData, setInstantData] = useState();
  let history = useHistory();
  const token = localStorage.getItem("Token");
  function Offsymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }

  function OnSymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }

  // *************resourcetypes**********
  const resourceTypedropdownData = useQuery(
    ["resourceTypedropdowninstantCheckin"],
    () => getResourcetypeinstantCheckin(token)
  );
  const resourceTypesDropDown = resourceTypedropdownData?.data?.data;

  // *************instant checkin**********
  const { data: instantCheckInDataa } = useQuery(["instantCheckInData1"], () =>
    getInstantCheckin(token)
  );
  const instantCheckInData = instantCheckInDataa?.data;

  useEffect(() => {
    var format = /^[(]*$/;

    setInstantData({
      enabled: instantCheckInData?.enabled,
      resourceTypes: instantCheckInData?.resourceTypes?.map((e) => {
        return {
          value: e?.id,
          label: `${e?.name}`.includes("(")
            ? `${e?.name}`
            : `${e?.name} (${e?.businessName})`,
          name: e.name,
          businessName: e?.businessName,
        };
      }),
      bookingDuration: instantCheckInData?.bookingDuration,
      geofenceDistance: instantCheckInData?.geofenceDistance,
      minimumCredits: instantCheckInData?.minimumCredits,
      isLowBalanceNotificationEnabled:
        instantCheckInData?.isLowBalanceNotificationEnabled,
      lowBalanceNotification: instantCheckInData?.lowBalanceNotification,
    });
  }, [instantCheckInData]);

  // *************
  const saveInstantCheckinData = () => {
    const apiObject = {
      enabled: instantData?.enabled,

      resourceTypes: instantData?.resourceTypes?.map((e) => {
        return {
          id: e.value,
          name: e?.label,
          businessName: e?.businessName,
        };
      }),
      bookingDuration: Number(instantData?.bookingDuration),
      geofenceDistance: Number(instantData?.geofenceDistance),
      minimumCredits: Number(instantData?.minimumCredits),
      lowBalanceNotification: Number(instantData?.lowBalanceNotification),
      isLowBalanceNotificationEnabled:
        instantData?.isLowBalanceNotificationEnabled,
    };

    fetch(`${DIOM_BASED_URLS}/instant-checkins`, {
      method: "PATCH",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(apiObject),
    })
      // .then((response) => response.json())
      .then((result3) => {
        if (result3.ok === true) {
          history.push("/instant_checkin");
          toast.success("Successfully Updated");
        } else if (result3.ok === false) {
          toast.error("please set correct value");
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  return {
    Offsymbol,
    OnSymbol,
    resourceTypesDropDown,
    instantData,
    setInstantData,
    saveInstantCheckinData,
  };
};

export default UseEditInstantCheckin;
