import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { getErpApi, getInvoice } from "../../../../APIS/invoices";
import { usePaginatedQuery } from "../../../../hooks/query";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { DIOM_BASED_URLS } from "../../../../config/url";

export const useInvoice = () => {
  const QueryClient = useQueryClient();
  let history = useHistory();
  const token = localStorage.getItem("Token");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(50);
  const changeCurrentPage = (pageNum) => setCurrentPage(pageNum);
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [refundSt, setRefundSt] = useState(false);
  const [refundInput, setRefundInput] = useState(null);
  const [applyButton, setApplyButton] = useState(true);
  const [refundQuantity, setRefundQuantity] = useState(null);
  const [totalRefund, setTotalRefund] = useState(null);
  const [vatRefund, setVatRefund] = useState(null);
  const [modal_static1, setModal_static1] = useState(false);
  const [otpState, setOtpState] = useState();
  const [statusPaidByEmail, setstatusPaidByEmail] = useState(false);

  // **** Invoice Get Api*********
  const {
    data: { data: InvoiceData, hasNextPage, hasPreviousPage, total },
    isLoading,
  } = usePaginatedQuery(["Invoice", currentPage], () =>
    getInvoice(pageSize, currentPage, token, id, history)
  );

  // **** erp get Api  *********
  const { data: ErpData, isLoading: ErpLoading } = useQuery(
    ["ErpInvoice"],
    () => getErpApi(token, id)
  );

  const ErpLink = ErpData?.data?.url;

  const pageOptions = {
    sizePerPage: pageSize,
    totalSize: total ?? 0,
    custom: true,
  };
  const refundApplyFunc = () => {
    // if(parseFloat(refundInput) < parseFloat(InvoiceData?.total)){
    fetch(`${DIOM_BASED_URLS}/invoices/refund/${id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        partialRefund: refundSt,
        amount: Number(refundInput),
      }),
    })
      .then((response) => response.json())
      .then((result3) => {
        if (result3?.statusCode === 200) {
          toast.success("refund Added");
        } else if (result3?.statusCode === 204) {
          toast.success("refund Added");
        }
        // else if (result3?.status.ok){
        //   toast.success("refund Added");
        // }
        else {
          toast.error(result3?.error?.message);
        }
      })
      .catch((error) => console.log("error", error));
  };
  // else{
  //   toast.error("something went wronge")
  // }
  // }

  const refundfullfunc = (e) => {
    setRefundSt(false);
    setApplyButton(false);
  };
  const refundPartialfunc = (e) => {
    setRefundSt(true);
    setApplyButton(false);
  };

  const pRefundInput = (e) => {
    if (e < InvoiceData?.total) {
      const refundQuan = Number((e / InvoiceData?.unitPrice).toFixed(2));
      setRefundQuantity(refundQuan);
      setTotalRefund(+parseFloat(refundQuan * InvoiceData?.total).toFixed(2));

      setVatRefund(Number(((totalRefund / 100) * 15).toFixed(2)));
      setRefundInput(e);
    } else {
      toast.error(`Please enter the value less then ${InvoiceData?.total}`);
    }
  };

  useEffect(() => {
    // FOR PRE-FETCHING NEXT PAGE

    if (hasNextPage) {
      const nextPage = currentPage + 1;
      queryClient.prefetchQuery(["Invoice", nextPage], () =>
        getInvoice(pageSize, nextPage, token, id, history)
      );
    }
  }, [currentPage, queryClient]);

  // **** mark as Paid Function ****
  const handleMarkPaid = () => {
    fetch(`${DIOM_BASED_URLS}/invoices/${id}/run-commands`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        command: "MARK_INVOICE_AS_PAID",
        key: "",
        value: otpState,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          // Success response
          QueryClient.invalidateQueries("Package");
          toast.success("Verified");
          setstatusPaidByEmail(true);
          setModal_static1(false);
        } else {
          // Error response
          return response.json().then((data) => {
            toast?.error(data.error?.message);
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(" Something went wrong");
      });
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const tog_static1 = () => {
    setModal_static1(!modal_static1);

    removeBodyCss();
  };

  const markedpaidFunc = () => {
    fetch(`${DIOM_BASED_URLS}/invoices/${id}/run-commands`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        command: "SEND_OTP_TO_MARK_INVOICE_AS_PAID",
        key: "",
        value: "",
      }),
    })
      .then((result3) => {
        if (result3.status === 200) {
          toast.success("OTP is sent to your mail");
          tog_static1();
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(" Something went wrong");
      });
  };

  return {
    ErpLink,
    statusPaidByEmail,
    otpState,
    setOtpState,
    markedpaidFunc,
    modal_static1,
    setModal_static1,
    tog_static1,
    handleMarkPaid,
    currentPage,
    pageOptions,
    InvoiceData,
    hasNextPage,
    hasPreviousPage,
    total,
    isLoading,
    refundSt,
    refundApplyFunc,
    changeCurrentPage,
    refundPartialfunc,
    pRefundInput,
    refundfullfunc,
    refundInput,
    applyButton,
    refundInput,
    vatRefund,
    totalRefund,
    refundQuantity,
  };
};
