import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Row, Col, Button, Card } from "reactstrap";
import Instant_checkin from "../../.././assets/images/instant_checkin.svg";
import "./css/instantCheckin.css";
import UseInstantCheckIn from "./useInstantCheckIn";
import UserPromissionFetcher from "../../../helpers/getUserPerimissions";
import Spinner from "../../../components/Common/Loader";
import NoAccess from "../../../components/Common/NoAccess";

const InstantCheckIn = () => {
  const { instantCheckInData } = UseInstantCheckIn();
  const userPermissions = UserPromissionFetcher("bookings");

  return (
    <>
      {userPermissions == null ? (
        <Spinner />
      ) : userPermissions.read ? (
        <div className="page-content ci_bg">
          <Row>
            <Col md={12}>
              <Row>
                <Col md={10} className="ci_Title">
                  Instant Check-In Settings
                </Col>
                <Col md={2}>
                  {userPermissions.update && (
                    <Link to="/edit_instant_checkin">
                      <Button color="success" className="w-100">
                        Edit
                      </Button>
                    </Link>
                  )}
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Card className="ci_card  p-4 mt-4">
                    <Row>
                      <Col md={12}>
                        <p className="ci_card_heading1">
                          Instant Check-in Feature
                        </p>
                        <span>
                          <img src={Instant_checkin} />
                        </span>
                        <span className="ci_card_guide">Guide</span>
                        <p className="ci_card_hesading2">
                          This setting controls whether instant checkin is
                          enable or disabled for the users.
                        </p>
                        <p className="ci_card_guide">
                          Instant Check-In{" "}
                          {instantCheckInData?.enabled === true
                            ? "True"
                            : "False"}
                        </p>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Card className="ci_card  p-4">
                    <Row>
                      <Col md={12}>
                        <p className="ci_card_heading1">Resource type</p>
                        <span>
                          <img src={Instant_checkin} />
                        </span>
                        <span className="ci_card_guide">Guide</span>
                        <p className="ci_card_hesading2">
                          The following list of resources will be available in
                          the instant check-in menu for the customers
                        </p>
                        <Row>
                          {instantCheckInData?.resourceTypes?.map(
                            (e, index) => {
                              return (
                                <Col md={2} className=" mt-2" key={index}>
                                  <Button
                                    color=""
                                    className="ci_resourceTypes_Items ci_resourceTypes w-100"
                                  >
                                    {e?.name}
                                  </Button>
                                </Col>
                              );
                            }
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Card className="ci_card  p-4">
                    <Row>
                      <Col md={12}>
                        <p className="ci_card_heading1">Booking Duration</p>
                        <span>
                          <img src={Instant_checkin} />
                        </span>
                        <span className="ci_card_guide">Guide</span>
                        <p className="ci_card_hesading2">
                          Every Instant check-in booking is created for the this
                          duration if the DIOM space time allows it. If a space
                          closes earlier, then a booking will end at the closing
                          time.
                        </p>
                        <Row>
                          <Col md={2} className="">
                            <Button color="" className="ci_booking_duration">
                              {/* {moment
                            .utc()
                            .hours(bookingDuration_h)
                            .minutes(bookingDuration_m)
                            .format("hh:mm ")} */}
                              {instantCheckInData?.bookingDuration} Minutes
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Card className="ci_card  p-4">
                    <Row>
                      <Col md={12}>
                        <p className="ci_card_heading1">Geofence distance</p>
                        <span>
                          <img src={Instant_checkin} />
                        </span>
                        <span className="ci_card_guide">Guide</span>
                        <p className="ci_card_hesading2">
                          GeoFence Radius and distance verification interval.
                        </p>
                        <Row>
                          <Col md={2} className="">
                            <Button color="" className="ci_booking_duration">
                              {instantCheckInData?.geofenceDistance} m
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Card className="ci_card  p-4">
                    <Row>
                      <Col md={12}>
                        <p className="ci_card_heading1">
                          Minimum credits availability
                        </p>
                        <span>
                          <img src={Instant_checkin} />
                        </span>
                        <span className="ci_card_guide">Guide</span>
                        <p className="ci_card_hesading2">
                          The minimum amount of credits that should be available
                          in the user’s wallet for them to be eligible for
                          instant check-in.
                        </p>
                        <Row>
                          <Col md={2} className="">
                            <Button color="" className="ci_booking_duration">
                              {instantCheckInData?.minimumCredits}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Card className="ci_card p-4">
                    <Row>
                      <Col md={12}>
                        <p className="ci_card_heading1">
                          Low Balance Notification
                        </p>
                        <span>
                          <img src={Instant_checkin} />
                        </span>
                        <span className="ci_card_guide">Guide</span>
                        <p className="ci_card_hesading2">
                          Set a notification that is sent to the user everytime
                          their credits for any package category falls below the
                          set amount.
                        </p>
                        <Row>
                          <Col md={2} className="">
                            <Button color="" className="ci_booking_duration">
                              {instantCheckInData?.lowBalanceNotification}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default InstantCheckIn;
