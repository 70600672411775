import React from "react";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import UseDiscountLog from "./useDiscountLog";
import {
  Button,
  Col,
  Row,
  Card,
  TabContent,
  NavLink,
  NavItem,
  Nav,
  TabPane,
  CardBody,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  ButtonGroup,
  Container,
} from "reactstrap";
import classnames from "classnames";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../../Tables/datatables.scss";
import UserPromissionFetcher from "../../../../helpers/getUserPerimissions";
import Spinner from "../../../../components/Common/Loader";
import NoAccess from "../../../../components/Common/NoAccess";
const activeTabJustify = "1";
const { SearchBar } = Search;

const reportTypeOptions = [
  { label: "Booking Report", value: "BOOKING_REPORTS" },
  { label: "User Report", value: "USER_REPORTS" },
  { label: "User Ananlysis Report", value: "USER_ANALYSIS_REPORTS" },
  { label: "Cancelled Bookings", value: "CANCELLED_BOOKINGS_REPORTS" },
];

const data = [
  {
    code: "122",
    type: "3434",
    amount: "100",
    timesUsed: "3093",
    lastUsed: "3894",
  },
];

const DiscountLog = () => {
  const userPermissions = UserPromissionFetcher("discountCodes");
  const {
    defaultSorted,
    data,
    currentPage,
    pageOptions,
    userdetailsData,
    toggleCustomJustified,
    activeTabJustify,
    setActiveTabJustify,
    userActivebookingsData,
    userPastBookingsData,
    userScheduledBookingsData,
    hasNextPage,
    hasPreviousPage,
    total,
    pastHasNextPage,
    scheduledHasNextPage,
    isLoading,
    pagelengthnum,
    pageSize,
    toggle,
    isOpen,
    DiscountLogsData,
    DiscountLogsNames,
    DiscountLogsCode,
    DiscountLogsNamesFunc,
    SeacrhFunc,
    DiscountLogsCodeFunc,
    changeCurrentPage,
  } = UseDiscountLog();

  const location = useLocation();

  const columns = [
    {
      dataField: "discountCode",
      text: " Code",
      sort: true,
    },
    {
      dataField: "userName",
      text: "Customer Name",
      sort: true,
    },
    {
      dataField: "discountAmount",
      text: "Discount Amount",
      sort: true,
    },
    {
      dataField: "bookingId",
      text: "Booking ID",
      sort: true,
      formatter: (cell, row) => (
        <Link
          to={{
            pathname: `/booking_detail/${row.bookingId}/1`,
            state: { prevPath: location.pathname },
          }}
          className="link"
        >
          {cell}
        </Link>
      ),
    },
    {
      dataField: "timesUsed",
      text: "Time Used",
      sort: true,
    },
  ];

  return (
    <>
      {userPermissions === null ? (
        <Spinner />
      ) : userPermissions.read ? (
        <div className="page-content">
          <div>
            <Row className="mb-4">
              <Col md={6}>
                <Link to="/discount" className="link">
                  {" "}
                  <span className="fas fa-angle-left arrowheightwidth"></span>
                </Link>
                <span className="profiletitle ">Discount Code Usage Log</span>
              </Col>
              <Col md={4}></Col>
              <Col md={2}></Col>
            </Row>
          </div>
          <Row>
            <Col md={12}>
              {/* ***********table start********** */}
              <Card>
                {/* <div>  
                       </div> */}
                <div>
                  {/* {userActiveBookings ? ( */}
                  <Row>
                    <Col className="col-12 ">
                      <Card>
                        <CardBody>
                          <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                            keyField="id"
                            columns={columns}
                            //   data={
                            //     activeTabJustify === "1"
                            //       ? userActivebookingsData
                            //       : activeTabJustify === "2"
                            //       ? userScheduledBookingsData:""
                            //   }

                            data={DiscountLogsData ? DiscountLogsData : data}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                columns={columns}
                                data={
                                  DiscountLogsData ? DiscountLogsData : data
                                }
                                search
                              >
                                {(toolkitProps) => (
                                  <React.Fragment>
                                    <Row className="mb-2">
                                      <Col md={3}>
                                        <Select
                                          placeholder="Name"
                                          options={DiscountLogsNames}
                                          onChange={(e) =>
                                            DiscountLogsNamesFunc(e)
                                          }
                                          classNamePrefix="select2-selection"
                                        />
                                      </Col>
                                      <Col md={3}>
                                        <Select
                                          placeholder="Code"
                                          options={DiscountLogsCode}
                                          onChange={(e) =>
                                            DiscountLogsCodeFunc(e)
                                          }
                                          classNamePrefix="select2-selection"
                                        />
                                      </Col>
                                      <Col md={2}>
                                        <Button
                                          color="success"
                                          onClick={SeacrhFunc}
                                        >
                                          Search
                                        </Button>
                                      </Col>
                                      <Col md={4}></Col>
                                    </Row>

                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            hover
                                            keyField={"id"}
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            // selectRow={selectRow}
                                            classes={
                                              "table align-middle table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-3">
                                      <Col className="inner-custom-pagination d-flex">
                                        <span className="paginationitmes ">
                                          Items
                                        </span>
                                        <div className="d-inline">
                                          {/* <SizePerPageDropdownStandalone
                                              {...paginationProps}
                                            /> */}
                                          <ButtonDropdown
                                            isOpen={isOpen}
                                            toggle={toggle}
                                            onClick={(e) => pagelengthnum(e)}
                                          >
                                            <DropdownToggle
                                              caret
                                              color="secondary"
                                            >
                                              {pageSize}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                              <DropdownItem value="1">
                                                10
                                              </DropdownItem>
                                              <DropdownItem value="2">
                                                30
                                              </DropdownItem>
                                              <DropdownItem />
                                              <DropdownItem value="3">
                                                50
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </ButtonDropdown>
                                        </div>
                                        <span className="paginationitmes1 ">
                                          show
                                        </span>
                                        <div className="text-md-right ms-auto">
                                          {/* <PaginationListStandalone
                                                {...paginationProps}
                                              /> */}

                                          <Button
                                            color="secondary"
                                            className="waves-effect "
                                            style={{
                                              marginLeft: 7,
                                              marginRight: 7,
                                            }}
                                            disabled={currentPage <= 1}
                                            onClick={() =>
                                              currentPage === 1
                                                ? null
                                                : changeCurrentPage(
                                                    (prev) => prev - 1
                                                  )
                                            }
                                          >
                                            <i className="dripicons-chevron-left"></i>
                                          </Button>

                                          <Button
                                            style={{
                                              marginLeft: 7,
                                              marginRight: 7,
                                            }}
                                            color="success"
                                            className="btn-rounded waves-effect waves-light me-1 mr-2 ml-2"
                                          >
                                            {currentPage}
                                          </Button>
                                          {activeTabJustify === "1" ? (
                                            <Button
                                              style={{
                                                marginLeft: 7,
                                                marginRight: 7,
                                              }}
                                              color="secondary"
                                              className="waves-effect"
                                              disabled={!hasNextPage}
                                              onClick={() =>
                                                changeCurrentPage(
                                                  (prev) => prev + 1
                                                )
                                              }
                                            >
                                              <i className="dripicons-chevron-right"></i>
                                            </Button>
                                          ) : activeTabJustify === "2" ? (
                                            <Button
                                              style={{
                                                marginLeft: 7,
                                                marginRight: 7,
                                              }}
                                              color="secondary"
                                              className="waves-effect"
                                              disabled={!scheduledHasNextPage}
                                              onClick={() =>
                                                changeCurrentPage(
                                                  (prev) => prev + 1
                                                )
                                              }
                                            >
                                              <i className="dripicons-chevron-right"></i>
                                            </Button>
                                          ) : activeTabJustify === "3" ? (
                                            <Button
                                              style={{
                                                marginLeft: 7,
                                                marginRight: 7,
                                              }}
                                              color="secondary"
                                              className="waves-effect"
                                              disabled={!pastHasNextPage}
                                              onClick={() =>
                                                changeCurrentPage(
                                                  (prev) => prev + 1
                                                )
                                              }
                                            >
                                              <i className="dripicons-chevron-right"></i>
                                            </Button>
                                          ) : null}
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  {/* ) : null} */}
                </div>
              </Card>
              {/* *******table ends******* */}
            </Col>
          </Row>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default DiscountLog;
