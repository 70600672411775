import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import "react-toastify/dist/ReactToastify.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../Tables/datatables.scss";
import { Link, useParams } from "react-router-dom";

import { UseAnnouncementListing } from "./useAnnouncementListing";
import moment from "moment";

const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: "createdAt",
    order: "desc",
  },
];

const AnnountmentListing = () => {
  const { PreviousUrl } = useParams();
  // const titleOptions = [
  //   { label: "Booking Report", value: "BOOKING_REPORTS" },
  //   { label: "User Report", value: "USER_REPORTS" },
  //   { label: "User Ananlysis Report", value: "USER_ANALYSIS_REPORTS" },
  //   { label: "Cancelled Bookings", value: "CANCELLED_BOOKINGS_REPORTS" },
  // ];
  const {
    currentPage,
    pageOptions,
    isLoading,
    hasNextPage,
    announcmentData,
    announcmentsData,
    pagelengthnum,
    pageSize,
    toggle,
    isOpen,
    changeCurrentPage,
    titleFunc,
    bodyFunc,
    sendButtonFunc,
    announcmet_modal_static,
    setAnnouncmet_modal_static,
    createAnnouncmentFunc,
    setAnnouncmet_modal_specific,
    announcmet_modal_specific,
    specificAnnouncmentFunc,
  } = UseAnnouncementListing();
  // const Loader = require("react-loader");

  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "body",
      text: "Description",
      sort: true,
      formatter: (cell, row) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => specificAnnouncmentFunc(row.id)}
        >
          {cell.length > 30
            ? cell.substring(0, 30) + " ..."
            : cell.substring(0, 30)}
        </span>
      ),
    },

    {
      dataField: "createdAt",
      text: " Time",
      sort: true,
    },
  ];

  return (
    <div className='page-content'>
      <Row>
        {/* <Col md={1}> */}
        {/* <Link to={`/${PreviousUrl}`} className="link" style={{ paddingLeft: 40 }}>
            <span className="fas fa-angle-left arrowheightwidth"></span>
          </Link> */}
        {/* </Col> */}
        <Col md={8}>
          <h3 className='mb-5'>All Anouncements</h3>
        </Col>
        <Col md={1}></Col>
        <Col md={3}>
          <Button
            onClick={createAnnouncmentFunc}
            color='success'
            className='w-100'
            outline
          >
            Create Announcment
          </Button>
        </Col>
      </Row>

      <div>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField='id'
                  columns={columns}
                  data={announcmentsData}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField='id'
                      columns={columns}
                      data={announcmentsData}
                      search
                    >
                      {(toolkitProps) => (
                        <React.Fragment>
                          <Row className='mb-2'>
                            <Col md='4'>
                              <div className='search-box me-2 mb-2 d-inline-block'>
                                <div className='position-relative'>
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className='search-box chat-search-box' />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xl='12'>
                              <div className='table-responsive'>
                                <BootstrapTable
                                  hover
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row className='align-items-md-center mt-30 mt-3'>
                            <Col className='inner-custom-pagination d-flex'>
                              <span className='paginationitmes '>Items</span>
                              <div className='d-inline'>
                                {/* <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  /> */}

                                <ButtonDropdown
                                  isOpen={isOpen}
                                  toggle={toggle}
                                  onClick={(e) => pagelengthnum(e)}
                                >
                                  <DropdownToggle caret color='secondary'>
                                    {pageSize}
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem value='1'>10</DropdownItem>
                                    <DropdownItem value='2'>30</DropdownItem>
                                    <DropdownItem />
                                    <DropdownItem value='3'>50</DropdownItem>
                                  </DropdownMenu>
                                </ButtonDropdown>
                              </div>
                              <span className='paginationitmes1 '>show</span>
                              <div className='text-md-right ms-auto'>
                                {/* <PaginationListStandalone
                                    {...paginationProps} */}
                                {/* /> */}

                                <Button
                                  color='secondary'
                                  className='waves-effect '
                                  style={{ marginLeft: 7, marginRight: 7 }}
                                  disabled={currentPage <= 1}
                                  onClick={() =>
                                    currentPage === 1
                                      ? null
                                      : changeCurrentPage((prev) => prev - 1)
                                  }
                                >
                                  {" "}
                                  <i className='dripicons-chevron-left'></i>
                                </Button>

                                <Button
                                  style={{ marginLeft: 7, marginRight: 7 }}
                                  color='success'
                                  className='btn-rounded waves-effect waves-light me-1 mr-2 ml-2'
                                >
                                  {currentPage}
                                </Button>
                                <Button
                                  style={{ marginLeft: 7, marginRight: 7 }}
                                  color='secondary'
                                  className='waves-effect'
                                  disabled={!hasNextPage}
                                  onClick={() =>
                                    changeCurrentPage((prev) => prev + 1)
                                  }
                                >
                                  <i className='dripicons-chevron-right'></i>
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      {/* <Modal
        isOpen={announcmet_modal_static}
        toggle={() => setAnnouncmet_modal_static(false)}
        centered={true}
      >
        <Row>
          <Col md={2}></Col>
          <Col md={9}>
            <ModalHeader toggle={() => setAnnouncmet_modal_static(false)}>
              <p className="hdannouncmentTitle">Send Notifications To Users</p>
            </ModalHeader>
          </Col>
          <Col md={1}></Col>
        </Row>

        <ModalBody className="mb-3">
          <Row>
            <Col md={1}></Col>
            <Col md={3}>
              <p className="hdannouncmentBody">Title</p>
            </Col>
            <Col md={7}>
              <Input type="text" onChange={titleFunc} />
            </Col>
            <Col md={1}></Col>
          </Row>

          <Row className="mt-3">
            <Col md={1}></Col>
            <Col md={3}>
              <p className="hdannouncmentBody">Notification</p>
            </Col>
            <Col md={7}>
              <Input type="textarea" rows="5" onChange={bodyFunc} />
            </Col>
            <Col md={1}></Col>
          </Row>

          <Row className="mt-5">
            <Col md={1}></Col>
            <Col md={5}>
              <Button
                color="success"
                outline
                className="invoicesdetailbuttons waves-effect waves-light  w-100  "
                onClick={() => setAnnouncmet_modal_static(false)}
              >
                Cancel
              </Button>
            </Col>
            <Col md={5}>
              <Button
                color="success"
                className="invoicesdetailbuttons waves-effect waves-light  w-100  "
                onClick={sendButtonFunc}
              >
                Send
              </Button>
            </Col>
            <Col md={1}></Col>
          </Row>
        </ModalBody>
      </Modal> */}

      {/* <Modal
        isOpen={announcmet_modal_specific}
        toggle={() => setAnnouncmet_modal_specific(false)}
        centered={true}
      >
        <Row>
          <Col md={3}></Col>
          <Col md={8}>
            <ModalHeader toggle={() => setAnnouncmet_modal_specific(false)}>
              <p className="hdannouncmentTitle">Announcement</p>
            </ModalHeader>
          </Col>
          <Col md={1}></Col>
        </Row>

        <ModalBody className="mb-3">
          <Row>
            <Col md={1}></Col>
            <Col md={3}>
              <p className="hdannouncmentBody">Title</p>
            </Col>
            <Col md={7}>{announcmentData?.title}</Col>
            <Col md={1}></Col>
          </Row>

          <Row className="mt-3">
            <Col md={1}></Col>
            <Col md={3}>
              <p className="hdannouncmentBody">Description</p>
            </Col>
            <Col md={7}>{announcmentData?.body}</Col>
            <Col md={1}></Col>
          </Row>

          <Row className="mt-3">
            <Col md={1}></Col>
            <Col md={3}>
              <p className="hdannouncmentBody">Time</p>
            </Col>
            <Col md={7}>
              {moment(announcmentData.createdAt).format("YYYY-MM-DD HH:MM")}
            </Col>
            <Col md={1}></Col>
          </Row>

          <Row className="mt-3">
            <Col md={1}></Col>
            <Col md={3}>
              <p className="hdannouncmentBody">Admin Id</p>
            </Col>
            <Col md={7}>{announcmentData?.userId}</Col>
            <Col md={1}></Col>
          </Row>
        </ModalBody>
      </Modal> */}

      <Modal
        isOpen={announcmet_modal_specific}
        toggle={() => setAnnouncmet_modal_specific(false)}
        centered={true}
        closeButton
        size='lg'
      >
        <Row>
          <Col md={12}>
            <div
              style={{ backgroundColor: "#F7F8FC", borderRadius: 5 }}
              className='py-3 '
            >
              <Row>
                <Col md={8}>
                  <p className='hdannouncmentTitle mx-3'>
                    Announcement Details
                  </p>
                </Col>
                <Col md={3}></Col>
                <Col md={1}>
                  <Button onClick={() => setAnnouncmet_modal_specific(false)}>
                    x
                  </Button>
                </Col>
              </Row>
              <Row className='px-3'>
                <Col md={3}>
                  <span className='hdannouncmentBody'>
                    <small>Time:</small>
                  </span>
                  <p className='hdannouncmentBody'>
                    <small>Admin Id:</small>
                  </p>
                </Col>
                <Col md={8}>
                  <span>
                    {" "}
                    <small>
                      {moment(announcmentData.createdAt).format(
                        "YYYY-MM-DD HH:MM"
                      )}
                    </small>
                  </span>
                  <p>
                    {" "}
                    <small>{announcmentData?.userId}</small>
                  </p>
                </Col>
              </Row>
              {/* <Row className="px-3">
                <Col md={3}>
                  <p className="hdannouncmentBody">
                    <small>Admin Id:</small>
                  </p>
                </Col>
                <Col md={8}>
                  <small>{announcmentData?.userId}</small>
                </Col>
              </Row> */}
            </div>
            <div>
              {/* <Row className="mt-1 px-3">
                <Col md={2}>
                  <p className="hdannouncmentBody">
                    <small>Time:</small>
                  </p>
                </Col>
                <Col md={9}>
                  <small>
                    {moment(announcmentData.createdAt).format(
                      "YYYY-MM-DD HH:MM"
                    )}
                  </small>
                </Col>
              </Row> */}

              {/* <Row className="px-3">
                <Col md={3}>
                  <p className="hdannouncmentBody">
                    <small>Admin Id:</small>
                  </p>
                </Col>
                <Col md={8}>
                  <small>{announcmentData?.userId}</small>
                </Col>
              </Row> */}
              {/* <hr /> */}
            </div>
          </Col>
        </Row>

        <ModalBody className='mb-3'>
          <Row>
            <Col md={3}>
              <p className='hdannouncmentBody'>Title</p>
            </Col>
            <Col md={9}></Col>
          </Row>

          <Row>
            <Col md={12}>
              <Input value={announcmentData?.title} />
            </Col>
          </Row>

          <Row className='mt-3'>
            <Col md={3}>
              <p className='hdannouncmentBody'>Description</p>
            </Col>
            <Col md={7}></Col>
            <Col md={1}></Col>
          </Row>
          <Row>
            <Col md={12}>
              <Input type='textarea' rows={4} value={announcmentData?.body} />
            </Col>
          </Row>

          <Row className='mt-3'>
            <Col md={8}></Col>
            <Col md={4}>
              <Button
                color='success'
                outline
                className='invoicesdetailbuttons waves-effect waves-light  w-100  '
                onClick={() => setAnnouncmet_modal_specific(false)}
              >
                Close
              </Button>
            </Col>

            <Col md={1}></Col>
          </Row>
        </ModalBody>
      </Modal>
      {/* () => setAnnouncmet_modal_static(false) */}
      <Modal
        isOpen={announcmet_modal_static}
        toggle={() => setAnnouncmet_modal_static(false)}
        centered={true}
        size='lg'
      >
        <Row>
          <Col md={12}>
            <div
              style={{ backgroundColor: "#F7F8FC", borderRadius: 5 }}
              className='py-3 '
            >
              <Row>
                <Col md={8}>
                  <p className='hdannouncmentTitle mx-3'>
                    Send Notifications To Users
                  </p>
                </Col>
                <Col md={3}></Col>
                <Col md={1}>
                  <Button onClick={() => setAnnouncmet_modal_static(false)}>
                    x
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        {/* <Row>
          <Col md={2}></Col>
          <Col md={9}>
            <ModalHeader toggle={() => setAnnouncmet_modal_static(false)}>
              <p className="hdannouncmentTitle">Send Notifications To Users</p>
            </ModalHeader>
          </Col>
          <Col md={1}></Col>
        </Row> */}

        <ModalBody className='mb-3'>
          <Row>
            <Col md={3}>
              <p className='hdannouncmentBody'>Title</p>
            </Col>
            <Col md={7}></Col>
            <Col md={1}></Col>
          </Row>

          <Row>
            <Col md={12}>
              <Input
                type='text'
                onChange={titleFunc}
                placeholder='Enter Title'
              />
            </Col>
          </Row>

          <Row className='mt-3'>
            <Col md={3}>
              <p className='hdannouncmentBody'>Details</p>
            </Col>
            <Col md={7}></Col>
            <Col md={1}></Col>
          </Row>

          <Row className='mt-3'>
            <Col md={12}>
              <Input
                type='textarea'
                rows='5'
                onChange={bodyFunc}
                placeholder='Enter Details'
              />
            </Col>
          </Row>

          <Row className='mt-5'>
            <Col md={1}></Col>
            <Col md={5}></Col>
            <Col md={3}>
              <Button
                color='success'
                outline
                className='invoicesdetailbuttons waves-effect waves-light  w-100  '
                onClick={() => setAnnouncmet_modal_static(false)}
              >
                Cancel
              </Button>
            </Col>
            <Col md={3}>
              <Button
                color='success'
                className='invoicesdetailbuttons waves-effect waves-light  w-100  '
                onClick={sendButtonFunc}
              >
                Send
              </Button>
            </Col>
            {/* <Col md={1}></Col> */}
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AnnountmentListing;
