import { useParams, useHistory } from "react-router-dom";
import { usePaginatedQuery } from "../../../hooks/query";
import { getuserroledetails } from "../../../APIS/userProfle";
import { DIOM_BASED_URLS } from "../../../config/url";
import { toast } from "react-toastify";

export const useUserRoleDetail = () => {
  let history = useHistory();
  const { id } = useParams();
  const token = localStorage.getItem("Token");
  //1) Get user RecourcePermissions
  const {
    data: { data: userdetailsData },
    isLoading,
  } = usePaginatedQuery(["userroledetails", id], () =>
    getuserroledetails(token, id, history)
  );
  //2)Update user Actions Access
  const UpdateUserRole = (actions) => {
    fetch(`${DIOM_BASED_URLS}/roles/${id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        permissions: actions,
      }),
    }).then((res) => {
      if (!res.ok) {
        if (res.status === 400) {
          toast.error(`Admin Actions cannot be changed`);
          // throw new Error(res.error.message);
          history.push("/user_roles");
        }

        toast.error(`Error${res.error.message}`);
      } else {
        toast.success(" Role Actions Updated Successfully");
      }
    });
  };
  return {
    userdetailsData,
    isLoading,
    UpdateUserRole,
  };
};
