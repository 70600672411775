import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Button,
  Col,
  Row,
  Card,
  TabContent,
  NavLink,
  NavItem,
  Nav,
  TabPane,
  CardBody,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Modal,
  ModalBody,
  Input,
} from "reactstrap";
import classnames from "classnames";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../Tables/datatables.scss";
import UsePackages from "./usePackages";
import UserPromissionFetcher from "../../../helpers/getUserPerimissions";
import Spinner from "../../../components/Common/Loader";
import NoAccess from "../../../components/Common/NoAccess";

const { SearchBar } = Search;

const Packages = () => {
  const userPermissions = UserPromissionFetcher("packages");

  const {
    setActiveInactiveState,
    packageActiveInactiveFunc,
    deletePackageFunc,
    setModal_static5,
    modal_static5,
    tog_static5,
    setModal_static4,
    modal_static4,
    tog_static4,
    setModal_static3,
    modal_static3,
    tog_static3,
    data,
    defaultSorted,
    currentPage,
    pageOptions,
    packagesDataa,
    userdetailsData,
    toggleCustomJustified,
    activeTabJustify,
    setActiveTabJustify,
    userActivebookingsData,
    userPastBookingsData,
    userScheduledBookingsData,
    hasNextPage,
    hasPreviousPage,
    total,
    pastHasNextPage,
    scheduledHasNextPage,
    isLoading,
    pagelengthnum,
    pageSize,
    toggle,
    isOpen,
    setActiveTabState,
    changeCurrentPage,
    tabsNum,
    setTabsNum,
  } = UsePackages();

  const columns = [
    {
      dataField: "name",
      text: "Package Name",
      sort: true,
      style: { width: "20%" },

      formatter: (cell, row) =>
        tabsNum === 3 ? (
          <p className="mt-3">{cell}</p>
        ) : (
          <Link
            to={{
              pathname: `/detailed_Package/${row.id}`,
              state: "",
            }}
            className="link"
          >
            {cell}
          </Link>
        ),
    },
    {
      dataField: "validity",
      text: "Hours",
      sort: true,
      style: { width: "20%" },
    },
    {
      dataField: "price",
      text: "Price",
      sort: true,
      style: { width: "20%" },
    },
    {
      dataField: "categories",
      text: "Categories",
      sort: true,
      style: { width: "10%" },
      formatter: (cell, row) => {
        return (
          <p
            style={{
              // width: "50%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {row.categories}
          </p>
        );
      },
    },
    {
      dataField: "quantitySold",
      text: "Quantity Sold",
      sort: true,
      style: { width: "10%" },
    },
    {
      dataField: "recentlyPurchasedAt",
      text: " Last Purchased ",
      sort: true,
      style: { width: "20%" },
    },
    {
      dataField: "",
      text: "Actions",
      sort: true,
      formatter: (cell, row) =>
        tabsNum === 3 ? (
          ""
        ) : row?.nodata === true ? (
          ""
        ) : (
          <div>
            <Link to={`/edit_Package/${row?.id}`}>
              <Label
                className="ds_edit_label"
                style={
                  userPermissions.update
                    ? { cursor: "pointer" }
                    : { pointerEvents: "none", cursor: "pointer" }
                }
              >
                Edit
              </Label>
            </Link>
          </div>
        ),
      style: { width: "20%" },
    },
    {
      dataField: "",
      text: "",
      sort: true,
      formatter: (cell, row) =>
        tabsNum === 1 ? (
          row?.nodata === true ? (
            ""
          ) : (
            // <Link to={`/editDiscount/${row?.id}`}>

            <Label
              className="ds_edit_label"
              style={
                userPermissions.update
                  ? { cursor: "pointer" }
                  : { pointerEvents: "none", cursor: "pointer" }
              }
              onClick={() => tog_static3(row?.id)}
            >
              Deactivate
            </Label>
            // </Link>
          )
        ) : tabsNum === 2 ? (
          row?.nodata === true ? (
            ""
          ) : (
            // <Link to={`/editDiscount/${row?.id}`}>

            <Label
              style={
                userPermissions.update
                  ? { cursor: "pointer" }
                  : { pointerEvents: "none", cursor: "pointer" }
              }
              className="ds_edit_label"
              onClick={() => tog_static4(row?.id)}
            >
              Active
            </Label>
            // </Link>
          )
        ) : tabsNum === 3 ? (
          ""
        ) : (
          ""
        ),
      style: { width: "20%" },
    },
    {
      dataField: "",
      text: "",
      sort: true,
      formatter: (cell, row) =>
        tabsNum === 3 ? (
          ""
        ) : row?.nodata === true ? (
          ""
        ) : (
          // <Link to={`/editDiscount/${row?.id}`}>

          <Label
            style={
              userPermissions.update
                ? { cursor: "pointer" }
                : { pointerEvents: "none", cursor: "pointer" }
            }
            className="ds_edit_label"
            onClick={() => tog_static5(row?.id)}
          >
            <i className="delIcon mdi mdi-delete  deliconsize ml-5"></i>
          </Label>
        ),
      style: { width: "20%" },
    },
  ];

  
  return (
    <>
      {userPermissions === null ? (
        <Spinner />
      ) : userPermissions.read ? (
        <div className="page-content">
          <>
            <div>
              <Row className="mb-4">
                <Col md={4}>
                  <span className="bookingtitle ml-4">Packages</span>
                </Col>
                <Col md={userPermissions.create ? 2 : 4}></Col>
                <Col md={2}>
                  <Link to="/faqs">
                    <Button
                      // onClick={handlePrint}
                      color="success"
                      className=" mr-1 w-100 "
                      block
                    >
                      <span className="e_a_btns ">FAQs</span>
                    </Button>
                  </Link>
                </Col>
                <Col md={2}>
                  <Link to="/package_categories">
                    {" "}
                    <Button
                      // onClick={handlePrint}
                      color="success"
                      className=" mr-1 w-100 "
                      block
                    >
                      <span className="e_a_btns ">Categories</span>
                    </Button>
                  </Link>
                </Col>

                {userPermissions.create && (
                  <Col md={2}>
                    {/* <Link to="/discountLog"> */}{" "}
                    <Link to="/create_packages">
                      <Button
                        // onClick={handlePrint}
                        color="success"
                        className=" mr-1 w-100 "
                        block
                      >
                        <span className="e_a_btns ">Create package</span>
                      </Button>
                    </Link>
                    {/* </Link> */}
                  </Col>
                )}
              </Row>

              {/* *******table start******** */}
              <Row>
                <Col md={12}>
                  <Card>
                    <div>
                      <Row className="mt-2  ">
                        <Col md={5}>
                          <Nav tabs className="nav-tabs-custom nav-justified">
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTabJustify === "1",
                                })}
                                onClick={() => {
                                  toggleCustomJustified("1");
                                  setActiveTabState("true");
                                  setTabsNum(1);
                                  setActiveInactiveState("true");
                                  // getUseractivebookings();
                                }}
                              >
                                <span className="d-none d-sm-block">
                                  Active
                                </span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTabJustify === "2",
                                })}
                                onClick={() => {
                                  toggleCustomJustified("2");
                                  setActiveTabState("false");
                                  setTabsNum(2);
                                  setActiveInactiveState("false");
                                  // fetchScheduledBookings();
                                }}
                              >
                                <span className="d-none d-sm-block">
                                  Inactive
                                </span>
                              </NavLink>
                            </NavItem>

                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTabJustify === "3",
                                })}
                                onClick={() => {
                                  toggleCustomJustified("3");
                                  setActiveTabState("true");
                                  setTabsNum(3);
                                  // fetchScheduledBookings();
                                }}
                              >
                                <span className="d-none d-sm-block">
                                  Archived
                                </span>
                              </NavLink>
                            </NavItem>
                          </Nav>

                          <TabContent activeTab={activeTabJustify}>
                            <TabPane tabId="1" className="p-1"></TabPane>
                            <TabPane tabId="2" className="p-1"></TabPane>
                            <TabPane tabId="3" className="p-1"></TabPane>
                          </TabContent>
                        </Col>
                        <Col md={3}></Col>
                        <Col md={4}></Col>
                      </Row>
                    </div>
                    <div>
                      {/* {userActiveBookings ? ( */}
                      <Row>
                        <Col className="col-12 ">
                          <Card>
                            <CardBody>
                              {isLoading ? (
                                <Spinner
                                  loaded={false}
                                  className="spinner loader-container"
                                />
                              ) : (
                                <PaginationProvider
                                  pagination={paginationFactory(pageOptions)}
                                  keyField="idrgjweroi"
                                  columns={columns}
                                  //   data={
                                  //     activeTabJustify === "1"
                                  //       ? userActivebookingsData
                                  //       : activeTabJustify === "2"
                                  //       ? userScheduledBookingsData:""
                                  //   }

                                  data={packagesDataa ? packagesDataa : data}
                                >
                                  {({
                                    paginationProps,
                                    paginationTableProps,
                                  }) => (
                                    <ToolkitProvider
                                      keyField="rgkljd"
                                      columns={columns}
                                      //   data={
                                      //     activeTabJustify === "1"
                                      //       ? userActivebookingsData
                                      //       : activeTabJustify === "2"
                                      //       ? userScheduledBookingsData:""
                                      //   }
                                      data={
                                        packagesDataa ? packagesDataa : data
                                      }
                                      search
                                    >
                                      {(toolkitProps) => (
                                        <React.Fragment>
                                          <Row className="mb-2">
                                            <Col md="4">
                                              <div className="search-box me-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                  <SearchBar
                                                    {...toolkitProps.searchProps}
                                                  />
                                                  <i className="search-box chat-search-box" />
                                                </div>
                                              </div>
                                            </Col>
                                            <Col md="4"></Col>
                                            <Col md="4"></Col>
                                          </Row>

                                          <Row>
                                            <Col xl="12">
                                              <div className="table-responsive">
                                                <BootstrapTable
                                                  hover
                                                  keyField="fiodfjid"
                                                  responsive
                                                  bordered={false}
                                                  striped={false}
                                                  defaultSorted={defaultSorted}
                                                  // selectRow={selectRow}
                                                  classes={
                                                    "table align-middle table-nowrap"
                                                  }
                                                  headerWrapperClasses={
                                                    "thead-light"
                                                  }
                                                  {...toolkitProps.baseProps}
                                                  {...paginationTableProps}
                                                />
                                              </div>
                                            </Col>
                                          </Row>

                                          <Row className="align-items-md-center mt-3">
                                            <Col className="inner-custom-pagination d-flex">
                                              <span className="paginationitmes ">
                                                Items
                                              </span>
                                              <div className="d-inline">
                                                {/* <SizePerPageDropdownStandalone
                                              {...paginationProps}
                                            /> */}
                                                <ButtonDropdown
                                                  isOpen={isOpen}
                                                  toggle={toggle}
                                                  onClick={(e) =>
                                                    pagelengthnum(e)
                                                  }
                                                >
                                                  <DropdownToggle
                                                    caret
                                                    color="secondary"
                                                  >
                                                    {pageSize}
                                                  </DropdownToggle>
                                                  <DropdownMenu>
                                                    <DropdownItem value="1">
                                                      10
                                                    </DropdownItem>
                                                    <DropdownItem value="2">
                                                      30
                                                    </DropdownItem>
                                                    <DropdownItem />
                                                    <DropdownItem value="3">
                                                      50
                                                    </DropdownItem>
                                                  </DropdownMenu>
                                                </ButtonDropdown>
                                              </div>
                                              <span className="paginationitmes1 ">
                                                show
                                              </span>
                                              <div className="text-md-right ms-auto">
                                                {/* <PaginationListStandalone
                                                {...paginationProps}
                                              /> */}

                                                <Button
                                                  color="secondary"
                                                  className="waves-effect "
                                                  style={{
                                                    marginLeft: 7,
                                                    marginRight: 7,
                                                  }}
                                                  disabled={currentPage <= 1}
                                                  onClick={() =>
                                                    currentPage === 1
                                                      ? null
                                                      : changeCurrentPage(
                                                          (prev) => prev - 1
                                                        )
                                                  }
                                                >
                                                  <i className="dripicons-chevron-left"></i>
                                                </Button>

                                                <Button
                                                  style={{
                                                    marginLeft: 7,
                                                    marginRight: 7,
                                                  }}
                                                  color="success"
                                                  className="btn-rounded waves-effect waves-light me-1 mr-2 ml-2"
                                                >
                                                  {currentPage}
                                                </Button>
                                                {activeTabJustify === "1" ? (
                                                  <Button
                                                    style={{
                                                      marginLeft: 7,
                                                      marginRight: 7,
                                                    }}
                                                    color="secondary"
                                                    className="waves-effect"
                                                    disabled={!hasNextPage}
                                                    onClick={() =>
                                                      changeCurrentPage(
                                                        (prev) => prev + 1
                                                      )
                                                    }
                                                  >
                                                    <i className="dripicons-chevron-right"></i>
                                                  </Button>
                                                ) : activeTabJustify === "2" ? (
                                                  <Button
                                                    style={{
                                                      marginLeft: 7,
                                                      marginRight: 7,
                                                    }}
                                                    color="secondary"
                                                    className="waves-effect"
                                                    disabled={
                                                      !scheduledHasNextPage
                                                    }
                                                    onClick={() =>
                                                      changeCurrentPage(
                                                        (prev) => prev + 1
                                                      )
                                                    }
                                                  >
                                                    <i className="dripicons-chevron-right"></i>
                                                  </Button>
                                                ) : activeTabJustify === "3" ? (
                                                  <Button
                                                    style={{
                                                      marginLeft: 7,
                                                      marginRight: 7,
                                                    }}
                                                    color="secondary"
                                                    className="waves-effect"
                                                    disabled={!pastHasNextPage}
                                                    onClick={() =>
                                                      changeCurrentPage(
                                                        (prev) => prev + 1
                                                      )
                                                    }
                                                  >
                                                    <i className="dripicons-chevron-right"></i>
                                                  </Button>
                                                ) : null}
                                              </div>
                                            </Col>
                                          </Row>
                                        </React.Fragment>
                                      )}
                                    </ToolkitProvider>
                                  )}
                                </PaginationProvider>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      {/* ) : null} */}
                    </div>
                  </Card>
                  {/* *******table ends******* */}
                </Col>
              </Row>
            </div>
{/*------------------------ Models-------------------------------- */}
            <div>
              <Modal
                isOpen={modal_static3}
                toggle={tog_static3}
                centered={true}
              >
                <ModalBody>
                  <div style={{ textAlign: "center" }}>
                    <p className="modeltitlecss mt-3">Deativate Package</p>
                  </div>

                  <hr />
                  <div style={{ textAlign: "center" }}>
                    <p className="mt-4 mb-4">
                      Are you sure you want to deactivate this package??
                    </p>
                  </div>

                  <hr />
                  <Row>
                    <Col md={2}></Col>

                    <Col md={4}>
                      <Button
                        color="success"
                        outline
                        className="waves-effect waves-light w-100"
                        onClick={() => setModal_static3(false)}
                      >
                        No
                      </Button>
                    </Col>

                    <Col md={4}>
                      <Button
                        color="success"
                        className="waves-effect waves-light w-100"
                        onClick={() => packageActiveInactiveFunc(false)}
                      >
                        Deactivate
                      </Button>
                    </Col>
                    <Col md={2}></Col>
                  </Row>
                </ModalBody>
              </Modal>
            </div>

            <div>
              <Modal
                isOpen={modal_static4}
                toggle={tog_static4}
                centered={true}
              >
                <ModalBody>
                  <div style={{ textAlign: "center" }}>
                    <p className="modeltitlecss mt-3">Ativate Package</p>
                  </div>

                  <hr />
                  <Row>
                    <Col md={12}>
                      <p className="mt-4 mb-4">
                        Are you sure you want to activate this package??
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={8}>
                      <p>Please add inventory for activating this package.</p>
                    </Col>
                    <Col md={4}>
                      <Input />
                    </Col>
                  </Row>

                  <hr />
                  <Row>
                    <Col md={2}></Col>

                    <Col md={4}>
                      <Button
                        color="success"
                        outline
                        className="waves-effect waves-light w-100"
                        onClick={() => setModal_static4(false)}
                      >
                        No
                      </Button>
                    </Col>

                    <Col md={4}>
                      <Button
                        color="success"
                        className="waves-effect waves-light w-100"
                        onClick={() => packageActiveInactiveFunc(true)}
                      >
                        Activate
                      </Button>
                    </Col>
                    <Col md={2}></Col>
                  </Row>
                </ModalBody>
              </Modal>
            </div>

            <div>
              <Modal
                isOpen={modal_static5}
                toggle={tog_static5}
                centered={true}
              >
                <ModalBody>
                  <div style={{ textAlign: "center" }}>
                    <p className="modeltitlecss mt-3">Delete Package</p>
                  </div>

                  <hr />
                  <div style={{ textAlign: "center" }}>
                    <p className="mt-4 mb-4">
                      Are you sure you want to delete this package??
                    </p>
                  </div>

                  <hr />
                  <Row>
                    <Col md={2}></Col>

                    <Col md={4}>
                      <Button
                        color="success"
                        outline
                        className="waves-effect waves-light w-100"
                        onClick={() => setModal_static5(false)}
                      >
                        No
                      </Button>
                    </Col>

                    <Col md={4}>
                      <Button
                        color="success"
                        className="waves-effect waves-light w-100"
                        onClick={deletePackageFunc}
                      >
                        Delete
                      </Button>
                    </Col>
                    <Col md={2}></Col>
                  </Row>
                </ModalBody>
              </Modal>
            </div>
          </>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default Packages;
