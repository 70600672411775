import React, { useState } from "react";
import { DIOM_BASED_URLS } from "../../../../config/url";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import uuid from "react-uuid";
import { useHistory } from "react-router-dom";

export const UseCreateCategory = () => {
  let history = useHistory();
  const [passCheck, setPassCheck] = useState(false);
  const [membershipCheck, setMembershipCheck] = useState(false);
  const uniqueId = uuid();
  const [selectedFilesUrl, setSelectedFilesUrl] = useState("");
  const [selectedFilesLoading, setSelectedFilesLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [error] = useState(null);
  const [selectedMulti] = useState([]);
  const [modal_static, setModal_static] = useState(false);
  const [modal_static1, setModal_static1] = useState(false);
  const [categoryDetail, setCategoryDetail] = useState({});
  const [categoryDetailname] = useState();
  const [selectedvalue] = useState({});
  const [cateLoading, setCateLoading] = useState(false);

  const { id } = useParams();
  const token = localStorage.getItem("Token");

  const updateNameAndDescription = () => {
    setCateLoading(true);
    fetch(`${DIOM_BASED_URLS}/resource-type-categories`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        name: categoryDetail.name,
        images:
          selectedFilesUrl !== ""
            ? [
                {
                  id: uniqueId,
                  url: selectedFilesUrl,
                },
              ]
            : [],
        locationTitle: selectedMulti,
        isPassAvailable: passCheck,
        isMembershipAvailable: membershipCheck,
      }),
    })
      .then((result3) => {
        setCateLoading(false);
        if (result3.status === 200) {
          history.push("/categories");
          toast.success("Updated Successfully");
        } else if (result3.status === 204) {
          history.push("/categories");
          toast.success("Updated Successfully");
        } else {
          toast.error(" Something went wrong");
        }
        setModal_static(false);
      })
      .catch((error) => {
        setCateLoading(false);
        toast.error(" Something went wrong");
      });
  };

  const categoryNamechangefunc = (event) => {
    setCategoryDetail((prev) => ({ ...prev, name: event.target.value }));
  };

  const FetchbrandLocations = () => {
    fetch(
      `${DIOM_BASED_URLS}/admin-business-locations/${selectedvalue}/category`,
      {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          locationCategoryId: `${id}`,
        }),
      }
    )
      .then((result3) => {
        if (result3.status === 200) {
          toast.success("Updated Successfully");
        } else if (result3.status === 204) {
          toast.success("Updated Successfully");
        } else {
          toast.error(" Something went wrong");
        }
        setModal_static(false);
      })
      .catch((error) => toast.error(" Something went wrong"));

    setModal_static1(false);
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const tog_static = () => {
    setModal_static(!modal_static);

    removeBodyCss();
  };
  const tog_static1 = () => {
    setModal_static1(!modal_static1);

    removeBodyCss();
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  const handleAcceptedFiles = async (files) => {
    setSelectedFilesLoading(true);
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        htmlFormattedSize: formatBytes(file.size),
      })
    );

    setSelectedFiles(files);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("Token")}`
    );

    var formdata = new FormData();
    formdata.append("file", files[0], files[0].preview);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    await fetch(`${DIOM_BASED_URLS}/file-uploads`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setSelectedFilesLoading(false);
        setSelectedFilesUrl(result[0]?.Location);
      })
      .catch((error) => {
        setSelectedFilesLoading(false);
        toast.error(" Something went wrong");
      });
  };

  return {
    cateLoading,
    error,
    modal_static,
    setModal_static,
    modal_static1,
    setModal_static1,
    categoryDetail,
    setCategoryDetail,
    categoryDetailname,
    selectedvalue,
    updateNameAndDescription,
    categoryNamechangefunc,
    FetchbrandLocations,
    tog_static,
    tog_static1,
    categoryDetail,
    selectedMulti,
    selectedFiles,
    setSelectedFiles,
    handleAcceptedFiles,
    selectedFilesUrl,
    setSelectedFilesUrl,
    selectedFilesLoading,
    setSelectedFilesLoading,
    setMembershipCheck,
    membershipCheck,
    passCheck,
    setPassCheck,
  };
};
