import moment from "moment";

export function addTimeToISODate(dateString, timeString) {
  // Parse the input date string using Moment.js
  const date = moment(dateString);

  // Parse the input time string using Moment.js
  const time = moment(timeString, "hh:mm A");

  // Set the time part of the date to the parsed time
  date.set({
    hour: time.hour(),
    minute: time.minute(),
    second: 0, // Assuming seconds should be set to 0
    millisecond: 0, // Assuming milliseconds should be set to 0
  });

  // Format the resulting date to ISO 8601 format
  const isoDate = date.toISOString();

  return isoDate;
}
