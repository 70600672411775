import React from 'react'
import { Card, CardBody, Col, Row , Label, Input, FormGroup, Button ,Spinner } from 'reactstrap'
import CreatableSelect from "react-select/creatable";
import Select from 'react-select';
import DatePicker from 'react-flatpickr';

const MeetingRoomSelection = ({bookingDateHandler,startTimeHandler,endTimeHandler ,selectedRTC ,accessTypeOptions ,setAccessType ,bookingDate,bookingStartTime,bookingEndTime}) => {
  
  
  return (
    <Row>
      <Col md={3} > 
                    <Label
                        htmlFor="access_type_meeting_room"
                        className="logininboxpanels"
                        style={{ pointerEvents: "none" }}
                      >
                        Access Type <span className="cp_starik">*</span>
                      </Label>  <Select placeholder="Select" id='access_type_meeting_room' options={selectedRTC?accessTypeOptions:[]}  defaultValue={[{ value: 'Pass', label: 'Pass' }]}   onChange={(accessType)=>{ setAccessType(accessType)}} ></Select>
                     </Col>
                    <Col  md={3}> <Label
                        htmlFor="booking_date_meeting_room"
                        className="logininboxpanels"
                        style={{ pointerEvents: "none" }}
                      >
                        Booking Date <span className="cp_starik">*</span>
                      </Label> 
                      <DatePicker
                          name="bookingDate"
                         onChange={(date)=>bookingDateHandler(date)}
                          className="datepickercss_exceptions"
                          placeholder={
                            "Select date"
                          }
                          id="booking_date_meeting_room"
                          options={{
                              minDate: "today"
                          
                        }}
                          
                          
                        />
                      
                      </Col>
                 {bookingDate&& 
                 
                 <>
                 <Col md={3}  >
                      
                       <Label
                        htmlFor="start_time_meeting_room"
                        className="logininboxpanels"
                        style={{ pointerEvents: "none" }}
                      >
                        Check-in Time <span className="cp_starik">*</span>
                      </Label>  <DatePicker
                           onChange={(date)=> startTimeHandler(date)}
                          name="startTime"
                          value={bookingStartTime}
                          // {...register("endDate")}
                          className="datepickercss_exceptions"
                          placeholder={
                            "Select start time"
                          }
                          options={{
                            enableTime: true,
                            noCalendar:true,
                            // time_24hr: true, 
    minuteIncrement: 15 
                             
                          
                        }}
                          id="start_time_meeting_room"/> 
                          </Col>
                    <Col md={3} > 
                    <Label
                       htmlFor='end_time_meeting_room'
                        className="logininboxpanels"
                        style={{ pointerEvents: "none" }}
                      >
                        Check-out Time <span className="cp_starik">*</span>
                      </Label> 
                       <DatePicker
                          onChange={(date)=> endTimeHandler(date)}
                          name="endTime1"
                          value={bookingEndTime}
                          className="datepickercss_exceptions"
                          placeholder={
                            "Select end time"
                          }
                          options={{
                            enableTime: true,
                            noCalendar:true,
    minuteIncrement: 15 

                             
                          
                        }}
                          id="end_time_meeting_room"/> 
 </Col> </>}
    </Row>
  )
}

export default MeetingRoomSelection
