import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getPackageCategory,
  getPackagesCategories,
  getResourcetypePackages,
} from "../../../../APIS/packagesCategory";
import { DIOM_BASED_URLS } from "../../../../config/url";

const UsePackageCategory = () => {
  const [modal_static1, setModal_static1] = useState(false);
  const [modal_static2, setModal_static2] = useState(false);
  const [packageCategoryName, setPackageCategoryName] = useState("");
  const [idForDelete, setidForDelete] = useState("");
  const [categoryLoading, setCategoryLoading] = useState(true);
  let history = useHistory();
  const token = localStorage.getItem("Token");
  const queryClient = useQueryClient();

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const tog_static1 = (id) => {
    setModal_static1(!modal_static1);
    removeBodyCss();
  };
  const tog_static2 = (id) => {
    setModal_static2(!modal_static2);
    removeBodyCss();
  };
  const PackageCategoryNameFunc = (e) => {
    setPackageCategoryName(e.target.value);
  };

  // ******All Categories *****
  const { data: allCategories, isLoading: CategoryLoading } = useQuery(
    ["allCategoriesData"],
    () => getPackagesCategories(token, history)
  );
  const allCategoriesData = allCategories?.data?.data;

  // *************resourceTypes

  const { data: resourceTypePackages } = useQuery(
    ["resourceTypedropdownPackages"],
    () => getResourcetypePackages(token)
  );

  const resourceTypePackagesData = resourceTypePackages?.data;

  // *************

  // ******One Category *****
  //    const {
  //     data: oneCategory
  //  }= useQuery(["oneCategoriesData"], () =>
  //  getPackageCategory(token,history,id)
  //  );
  //  const oneCategoriesData = oneCategory?.data;

  //  ***********************************************************

  // *************Patch Categories**********

  //   const UpdateCategoryData = () => {
  //  const apiObject = {
  //     "name": `${packageCategoryName}`
  //   };

  //     fetch(`${DIOM_BASED_URLS}/package-categories//${id}`, {
  //       method: "PATCH",
  //       headers: {
  //         Accept: "application/json, text/plain",
  //         "Content-Type": "application/json;charset=UTF-8",
  //         Authorization: "Bearer " + token,
  //       },

  //       body: JSON.stringify(apiObject),
  //     })
  //       // .then((response) => response.json())
  //       .then((result3) => {
  //         setModal_static1(false);
  //         if (result3.ok) {
  //           toast.success("Successfully Updated");
  //         } else {
  //           toast.error("Error: ", error);
  //         }
  //       })
  //       .catch((error) => {
  //         setModal_static1(false);
  //         toast.error("Error: ", error);
  //         console.log(error);
  //       });
  //   };

  // *********************************************************

  // *************Create Categories**********

  const saveCategoryData = () => {
    const apiObject = {
      name: `${packageCategoryName}`,
    };

    fetch(`${DIOM_BASED_URLS}/package-categories`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(apiObject),
    })
      // .then((response) => response.json())
      .then((result3) => {
        setModal_static1(false);
        if (result3.ok) {
          toast.success("Successfully Created");
          queryClient.invalidateQueries("resourceTypedropdownPackages");
          queryClient.invalidateQueries("allCategoriesData");
        } else {
          toast.error("Error: ");
        }
      })
      .catch((error) => {
        setModal_static1(false);
        toast.error("Error: ", error);
        console.log(error);
      });
  };

  // *********************************************************

  //  ******Delete category**********
  const deleteCategoryFunc = (e) => {
    fetch(`${DIOM_BASED_URLS}/package-categories/${idForDelete}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({}),
    })
      .then((result3) => {
        if (result3.status === 200) {
          queryClient.invalidateQueries("resourceTypedropdownPackages");
          queryClient.invalidateQueries("allCategoriesData");
          setModal_static2(false);
          toast.success(" Successfully Deleted");
        } else if (result3.status === 204) {
          queryClient.invalidateQueries("resourceTypedropdownPackages");
          queryClient.invalidateQueries("allCategoriesData");
          setModal_static2(false);
          toast.success(" Successfully Deleted");
        } else {
          toast.error(" Something went wrong");
        }
      })
      .catch((error) => {
        toast.error(" Something went wrong");
        console.log(error);
      });
  };

  // *************Add ResourceTypes Categories**********

  const addResourceTypesCategoryFunc = (destColumnId, AddId) => {
    setTimeout(() => {
      if (destColumnId !== 43266) {
        setCategoryLoading(false);
        const apiObject = {
          command: "ADD_RESOURCE_TYPE",
          resourceTypeId: Number(AddId),
        };

        fetch(
          `${DIOM_BASED_URLS}/package-categories/${destColumnId}/commands`,
          {
            method: "POST",
            headers: {
              Accept: "application/json, text/plain",
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: "Bearer " + token,
            },

            body: JSON.stringify(apiObject),
          }
        )
          .then((response) => response.json())
          .then((result3) => {
            setModal_static1(false);

            if (result3.ok) {
              //  toast.success("Successfully Created");
              queryClient.invalidateQueries("resourceTypedropdownPackages");
              queryClient.invalidateQueries("allCategoriesData");
              setCategoryLoading(true);
            } else {
              queryClient.invalidateQueries("resourceTypedropdownPackages");
              queryClient.invalidateQueries("allCategoriesData");
              toast.error(result3?.error?.message);
              setCategoryLoading(true);
            }
          })
          .catch((error) => {
            setModal_static1(false);
            toast.error("Error: ", error);
            console.log(error);
          });
      }
    }, 1000);
  };

  // *********************************************************
  // *************Remove ResourceTypes Categories**********

  const removeResourceTypesCategoryFunc = (sourceColumn, removeId) => {
    if (sourceColumn !== 43266) {
      setCategoryLoading(false);
      const apiObject = {
        command: "REMOVE_RESOURCE_TYPE",
        resourceTypeId: Number(removeId),
      };

      fetch(`${DIOM_BASED_URLS}/package-categories/${sourceColumn}/commands`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },

        body: JSON.stringify(apiObject),
      })
        .then((response) => response.json())
        .then((result3) => {
          setModal_static1(false);
          if (result3?.message) {
            queryClient.invalidateQueries("resourceTypedropdownPackages");
            queryClient.invalidateQueries("allCategoriesData");
            setCategoryLoading(true);
          } else {
            toast.error("Error: ", result3?.error?.message);
            setCategoryLoading(true);
          }
        })
        .catch((error) => {
          setModal_static1(false);
          toast.error("Error: ", error);
          console.log(error);
        });
    }
  };

  // *********************************************************

  return {
    CategoryLoading,
    setModal_static1,
    modal_static1,
    categoryLoading,
    tog_static1,
    setModal_static2,
    modal_static2,
    tog_static2,
    resourceTypePackagesData,
    allCategoriesData,
    PackageCategoryNameFunc,
    packageCategoryName,
    setPackageCategoryName,
    saveCategoryData,
    setidForDelete,
    deleteCategoryFunc,
    addResourceTypesCategoryFunc,
    removeResourceTypesCategoryFunc,
  };
};

export default UsePackageCategory;
