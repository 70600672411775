// YourComponent.js
import React from "react";
import "./css/style.css"; // Import the CSS file
import {
  Row,
  Card,
  CardBody,
  Col,
  Label,
  FormGroup,
  Input,
  Button,
  ModalBody,
  ModalHeader,
  Modal,
  ModalFooter,
  Tooltip,
} from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useCreatePlanDiscount } from "./useCreatePlanDiscount";
import { useForm } from "react-hook-form";
import Spinner from "../../../../components/Common/Loader";
import { useNextStep } from "./useNextStep";
import { useState } from "react";
import UserPromissionFetcher from "../../../../helpers/getUserPerimissions";
import NoAccess from "../../../../components/Common/NoAccess";

function NextStep() {
  const userPermissions = UserPromissionFetcher("planDiscountCodes");
  const {
    isLocationGet,
    isLoading,
    locations,
    selectedLocationIdHandler,
    locationRTC,
    selectRTCHandler,
    selectedRTC,
    handleResourceTypeChange,
    selectPlansHandler,
    assignPlanHandler,
    allRTPlansSelectedHandler,
    locationModelHandle,
    locationModel,
    locationId,
    setlocationModel,
    planEditHandler,
    selectedPlanForEdit,
    editModel,
    selectedResourceTypeForPlans,
    submitHandler,
    allLocationSelectedHandler,
    allSubmitBtnDisable,
    hideMessageRow,
    allLocationSubmitHandler,
    selectedLocationsForDiscount,
    editBtnShow,
    formDisable,
    setFormDisable,
    activeCancelBtn,
    cancelBtnHandler,
    showCancelConfirmModel,
    setShowCancelConfirmModel,
    chnageCancelHandler,
    activeSubmit2,
    setactiveCancelBtn,
    inactiveLocation,
    setEditBtnShow,
    isAllLocationsSelected,
    setEditModel,
    id
  } = useNextStep();
  // console.log(locationRTC, "locationRTC", selectedRTC, ">>>>>>selectedRTC>>>>");
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm();
  // console.log(activeCancelBtn, "activeCancelBtn>>>");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const onSubmit = (data) => {
    // console.log(data, ">>>>>>>>");
  };

  return (
    <>
      {isLocationGet ? (
        <Spinner />
      ) : isLoading ? (
        <Spinner />
      ) : userPermissions === null ? (
        <Spinner />
      ) : userPermissions.read ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-content">
            <Row>
              <Row>
                <Col md={4}>
                  <Link to="/plan-discounts" className="link">
                    <span className="fas fa-angle-left arrowheightwidth"></span>
                  </Link>
                  {/* <span className="locationbrandtitle  "> Next Step</span> */}
                </Col>
                <Col md={editBtnShow ? 6 : 6}></Col>
                {activeCancelBtn ? (
                  <Col md={2} className="">
                    <Button
                      className="w-100"
                      color="danger"
                      onClick={() => cancelBtnHandler()}
                    >
                      Cancel
                    </Button>
                  </Col>
                ) : (
                  ""
                )}
                {editBtnShow ? (
                  <Col md={2}>
                    {userPermissions.update ? (
                      <Button
                        className="w-100 "
                        color="success"
                        onClick={() => {
                          setactiveCancelBtn(true);
                          setFormDisable(false);
                          setEditBtnShow(false);
                        }}
                      >
                        Edit
                      </Button>
                    ) : (
                      ""
                    )}
                  </Col>
                ) : (
                  ""
                )}
              </Row>

              <Row
                className=" mt-4"
                // style={{
                //   pointerEvents:
                //     userPermissions.create && selectedRTC.length > 0
                //       ? "auto"
                //       : userPermissions.update && selectedRTC.length > 0
                //       ? "auto"
                //       : "none",
                // }}
              >
                <Card>
                  <CardBody className=" h-100">
                    <Row>
                      <Col md={7}>
                        <Label htmlFor="locations" className="logininboxpanels">
                          Select Location:
                        </Label>
                        {locations.map((location) => {
                          return (
                            <span key={location.id}>
                              <Button
                                className="g-2 ms-5 "
                                style={{
                                  color: location.selected ? "white" : "white", // Text color
                                  backgroundColor: location.selected
                                    ? "rgb(7, 115, 83)"
                                    : !location.selected && inactiveLocation
                                    ? "rgb(7, 115, 83)"
                                    : "", // Background color
                                }}
                                color="success"
                                value={location.value}
                                {...register("location")}
                                name="location"
                                defaultValue={location.value}
                                disabled={inactiveLocation}
                                onClick={() => {
                                  locationModelHandle(location.id);
                                }}
                              >
                                {`${location.name}`}
                                {inactiveLocation ? (
                                  <span className="mdi mdi-check-bold ms-2"></span>
                                ) : location.selected ? (
                                  <span className="mdi mdi-check-bold ms-2"></span>
                                ) : (
                                  ""
                                )}
                              </Button>
                            </span>
                          );
                        })}
                      </Col>
                    
                      <Col md={4} className="mt-2 me-5" style={{
                pointerEvents: id&&userPermissions.update?"auto":id&&userPermissions.create?"none":userPermissions.create?"auto":"none"
              }}>
                        <FormGroup check inline>
                          <Input
                            disabled={formDisable || isAllLocationsSelected}
                            checked={inactiveLocation || isAllLocationsSelected}
                            // className="checked-box-styling"
                            style={
                              inactiveLocation || isAllLocationsSelected
                                ? {
                                    backgroundColor: "#03B2A5",
                                    borderColor: "#03B2A5",
                                    transform: "scale(1.5)",
                                    marginRight: "5px",
                                  }
                                : {
                                    transform: "scale(1.5)",
                                    marginRight: "5px",
                                  }
                            }
                            type="checkbox"
                            id="RTC"
                            onChange={() => allLocationSelectedHandler()}
                          />

                          <span>
                            <Label
                              htmlFor="RTC"
                              style={{
                                fontFamily: "Montserrat",
                                fontSize: "1rem",
                                marginLeft: "4px",
                                pointerEvents: "none",
                              }}
                            >
                              {` All Locations ${
                                isAllLocationsSelected ? "(selected)" : ""
                              }`}
                            </Label>
                          </span>
                        </FormGroup>
                      </Col>
                    </Row>
                    {hideMessageRow ? (
                      ""
                    ) : (
                      <Row className="ms-2 mt-5">
                        <Col md={12}>
                          {allSubmitBtnDisable
                            ? "This Code is now Applicable on all locations"
                            : "Please select location"}
                        </Col>
                      </Row>
                    )}
                    {locationRTC?.length > 0 ? (
                      <Row className=" mt-5">
                        <Col md={5} sm={12} className="g-2 ">
                          <h3> Select Resource Category:</h3>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                    <Row className="ms-2 mt-1">
                      {locationRTC?.length > 0 ? (
                        <Col md={4} className="g-2">
                          <FormGroup check inline>
                            <Input
                              disabled={formDisable}
                              type="checkbox"
                              checked={locationRTC.every(
                                (LRTC) => LRTC.selected
                              )}
                              style={
                                locationRTC.every((LRTC) => LRTC.selected)
                                  ? {
                                      backgroundColor: "#03B2A5",
                                      borderColor: "#03B2A5",
                                      transform: "scale(1.5)",
                                      marginRight: "5px",
                                    }
                                  : {
                                      transform: "scale(1.5)",
                                      marginRight: "5px",
                                    }
                              }
                              id="RTC"
                              onChange={() => selectRTCHandler("all")}
                            />

                            <span>
                              <Label
                                htmlFor="RTC"
                                style={{
                                  fontFamily: "Montserrat",
                                  fontSize: "1rem",
                                  marginLeft: "4px",
                                  pointerEvents: "none", // Adjust the font size as needed
                                }}
                              >
                                Select All
                              </Label>
                            </span>
                          </FormGroup>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                    {/* Check Box RTC */}
                    <Row className="ms-2">
                      {locationRTC?.map((RTC) => {
                        return (
                          <Col md={4} className="g-2" key={RTC.id}>
                            <FormGroup check inline>
                              <Input
                                // disabled={formDisable || !RTC.available}
                                disabled={formDisable}
                                type="checkbox"
                                id="RTC"
                                checked={RTC.selected}
                                style={
                                  RTC.selected
                                    ? {
                                        backgroundColor: "#03B2A5",
                                        borderColor: "#03B2A5",
                                        transform: "scale(1.5)",
                                        marginRight: "5px",
                                      }
                                    : {
                                        transform: "scale(1.5)",
                                        marginRight: "5px",
                                      }
                                }
                                onChange={() => selectRTCHandler(RTC)}
                              />

                              <span>
                                <Label
                                  // htmlFor="RTC"
                                  disabled={true}
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontSize: "1rem",
                                    marginLeft: "4px",
                                    cursor: "pointer",
                                    // pointerEvents: "none",
                                  }}
                                >
                                  {RTC.name}
                                  {/* {RTC.available ? (
                                    ""
                                  ) : (
                                    <span
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      title="This resource type category is not yet available for this location."
                                    >
                                      <span className="totipalignment ms-1">
                                        <li
                                          className="fas fa-info-circle  "
                                          id="TooltipExample"
                                        ></li>
                                      </span>
                                    </span>
                                  )} */}
                                </Label>
                              </span>
                            </FormGroup>
                          </Col>
                        );
                      })}
                    </Row>
                  </CardBody>
                </Card>
              </Row>
              {/* Selected RTC Row Card*/}
              {selectedRTC.map((RTC) => {
                const isChecked =
                  RTC.userSelectedPlans?.every((userPlan) =>
                    userPlan?.plans?.every((plan) => plan?.selected)
                  ) &&
                  RTC.userSelectedPlans.length === RTC.resourceTypes.length;
                return (
                  <Row key={RTC.id}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col md={4}>
                            <h4> {RTC.name}</h4>
                          </Col>
                          <Col md={4} className="me-5">
                            <div className="me-5">
                              <FormGroup check inline>
                                <Input
                                  type="checkbox"
                                  id="RTC"
                                  disabled={
                                    RTC.resourceTypes.every(
                                      (resourceType) =>
                                        !resourceType.resourceTypePlans ||
                                        resourceType.resourceTypePlans
                                          .length === 0
                                    ) || formDisable
                                  }
                                  checked={isChecked}
                                  style={
                                    isChecked
                                      ? {
                                          backgroundColor: "#03B2A5",
                                          borderColor: "#03B2A5",
                                          transform: "scale(1.5)",
                                          marginRight: "5px",
                                        }
                                      : {
                                          transform: "scale(1.5)",
                                          marginRight: "5px",
                                        }
                                  }
                                  onChange={() =>
                                    allRTPlansSelectedHandler(
                                      RTC.resourceTypes,
                                      RTC.id
                                    )
                                  }
                                />

                                <span>
                                  <Label
                                    htmlFor="RTC"
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontSize: "1rem",
                                      marginLeft: "4px",
                                      pointerEvents: "none",
                                    }}
                                  >
                                    {RTC.userSelectedPlans?.every((userPlan) =>
                                      userPlan?.plans?.every(
                                        (plan) => plan?.selected
                                      )
                                    ) &&
                                    RTC.userSelectedPlans.length ===
                                      RTC.resourceTypes.length
                                      ? "All Selected"
                                      : `Select All`}
                                  </Label>
                                </span>
                              </FormGroup>
                            </div>
                          </Col>
                          {RTC.userSelectedPlans?.length > 0 ? (
                            ""
                          ) : (
                            <Col md={6}>
                              <p className="text-danger mt-2 mb-0">
                                Please select at least one plan for this
                                Resource Type Category:
                              </p>
                            </Col>
                          )}
                        </Row>
                        <Row className="mt-3">
                          <Col md={3} sm={12}>
                            <div>
                              <Label
                                htmlFor="users"
                                className="logininboxpanels"
                              >
                                Select Resource Type
                              </Label>
                              <FormGroup className="select2-container">
                                <Select
                                  name="selectedUsers"
                                  isDisabled={formDisable}
                                  value={
                                    RTC.resourceTypes.some(
                                      (resourceType) =>
                                        resourceType.id ===
                                        selectedResourceTypeForPlans.value
                                    )
                                      ? selectedResourceTypeForPlans
                                      : ""
                                  }
                                  options={RTC.resourceTypes
                                    ?.map((resourceType) => {
                                      return {
                                        value: resourceType.id,
                                        label: `${resourceType.name}`,
                                      };
                                    })
                                    .filter((option) => {
                                      return !RTC.userSelectedPlans?.some(
                                        (userSelectedPlan) =>
                                          userSelectedPlan.resourceTypeId ===
                                          option.value
                                      );
                                    })}
                                  placeholder="All resourceTypes"
                                  onChange={(selectedRT) =>
                                    handleResourceTypeChange(
                                      RTC.resourceTypes,
                                      selectedRT,
                                      RTC.id
                                    )
                                  }
                                />
                              </FormGroup>
                            </div>
                          </Col>
                          {/*....... Plans column.... */}
                          <Col md={7} style={{ marginTop: "10px" }}>
                            <Row className="mt-4">
                              {RTC.RTPlans?.map((plan) => {
                                return (
                                  <Col md={4} key={plan.id}>
                                    <FormGroup check inline>
                                      <Input
                                        disabled={formDisable}
                                        type="checkbox"
                                        id="RTC"
                                        checked={plan.selected}
                                        style={
                                          plan.selected
                                            ? {
                                                backgroundColor: "#03B2A5",
                                                borderColor: "#03B2A5",
                                                transform: "scale(1.5)",
                                                marginRight: "5px",
                                              }
                                            : {
                                                transform: "scale(1.5)",
                                                marginRight: "5px",
                                              }
                                        }
                                        onChange={() =>
                                          selectPlansHandler(
                                            plan,
                                            RTC.id,
                                            RTC.RTPlans
                                          )
                                        }
                                      />

                                      <span>
                                        <Label
                                          htmlFor="RTC"
                                          style={{
                                            fontFamily: "Montserrat",
                                            fontSize: "1rem",
                                            marginLeft: "4px",
                                            pointerEvents: "none",
                                          }}
                                        >
                                          {plan.name}
                                        </Label>
                                      </span>
                                    </FormGroup>
                                  </Col>
                                );
                              })}
                            </Row>
                          </Col>

                          {RTC.RTPlans.length > 0 && (
                            <Col md={2} style={{ marginTop: "30px" }}>
                              <Button
                                color="success"
                                disabled={RTC.RTPlans.every(
                                  (LRTC) => !LRTC.selected
                                )}
                                onClick={() =>
                                  assignPlanHandler(
                                    RTC.id,
                                    RTC.RTPlans[0],
                                    RTC.RTPlans
                                  )
                                }
                              >
                                Assign
                              </Button>
                            </Col>
                          )}
                        </Row>

                        {/* User Selected Plans */}
                        {RTC.userSelectedPlans.length > 0 && (
                          <Row className="mt-5">
                            <Col>
                              <h4> Selected Resources:</h4>
                            </Col>
                          </Row>
                        )}

                        <Row>
                          {RTC.userSelectedPlans.map((USPlan) => {
                            return (
                              <Row key={USPlan.resourceTypeId}>
                                <Card>
                                  <CardBody>
                                    <Row>
                                      <Col md={4}>
                                        <h5 className="logininboxpanels">
                                          {`${USPlan.resourceTypeName}:`}
                                        </h5>
                                      </Col>
                                      <Col md={4}></Col>
                                      <Col md={4}>
                                        <div style={{ marginLeft: "200px" }}>
                                          <h5
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            <span
                                              id="TooltipExample"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              title="In Progress"
                                              className=" mdi mdi-pencil-box-outline text-success"
                                              onClick={() =>

                                                planEditHandler(USPlan, RTC.id)
                                                
                                              }
                                            ></span>
                                            <span
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              title="In Progress"
                                              className="mdi mdi-delete text-danger"
                                            ></span>
                                          </h5>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mt-4">
                                      {USPlan.plans?.map((plan) => {
                                        return (
                                          <Col md={4} key={plan.planId}>
                                            <FormGroup check inline>
                                              <Input
                                                disabled={formDisable}
                                                type="checkbox"
                                                id="RTC"
                                                checked={plan.selected}
                                                style={
                                                  plan.selected
                                                    ? {
                                                        backgroundColor:
                                                          "#03B2A5",
                                                        borderColor: "#03B2A5",
                                                        transform: "scale(1.5)",
                                                        marginRight: "5px",
                                                      }
                                                    : {
                                                        transform: "scale(1.5)",
                                                        marginRight: "5px",
                                                      }
                                                }
                                                onChange={() =>
                                                 
                                                  function set() {}
                                                }
                                              />

                                              <span>
                                                <Label
                                                  htmlFor="RTC"
                                                  style={{
                                                    fontFamily: "Montserrat",
                                                    fontSize: "1rem",
                                                    marginLeft: "4px",
                                                    pointerEvents: "none",
                                                  }}
                                                >
                                                  {plan.name}
                                                </Label>
                                              </span>
                                            </FormGroup>
                                          </Col>
                                        );
                                      })}
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Row>
                            );
                          })}
                        </Row>
                      </CardBody>
                    </Card>
                  </Row>
                );
              })}
            </Row>

            {/* Submit Btn Row */}
            {selectedRTC.length > 0 ? (
              <Row>
                <Col md={4}></Col>
                <Col md={4}>
                  <Button
                    color="success w-100"
                    disabled={
                      !(
                        selectedRTC.every(
                          (selectedRTC) =>
                            selectedRTC.userSelectedPlans.length > 0
                        ) && activeSubmit2
                      )
                    }
                    onClick={() => {
                      submitHandler();
                    }}
                  >
                    Submit
                  </Button>
                </Col>
                <Col md={4}></Col>
              </Row>
            ) : (
              allSubmitBtnDisable && (
                <Row>
                  <Col md={4}></Col>
                  <Col md={4}>
                    <Button
                      color="success w-100"
                      onClick={allLocationSubmitHandler}
                    >
                      Submit
                    </Button>
                  </Col>
                  <Col md={4}></Col>
                </Row>
              )
            )}
            {/* Location Model */}
            <Modal isOpen={locationModel} centered={true}>
              <Row>
                <Col md={2}></Col>
                <Col md={8}>
                  <ModalHeader>Change Location Warning!</ModalHeader>
                </Col>
                <Col md={2}></Col>
              </Row>

              <ModalBody>
                <Row>
                  <Col md={2}></Col>
                  <Col md={9}>
                    <p>{`Are you sure want to chnage location?`}</p>
                  </Col>
                  <Col md={1}></Col>
                </Row>
                <hr />
                <Row>
                  <Col md={2}></Col>

                  <Col md={4}>
                    <Button
                      color="success"
                      outline
                      className="waves-effect waves-light w-100"
                      onClick={() => setlocationModel(false)}
                    >
                      No
                    </Button>
                  </Col>
                  <Col md={4}>
                    <Button
                      color="success"
                      outline
                      className="waves-effect waves-light w-100"
                      onClick={() => {
                        setFormDisable(false);
                        setactiveCancelBtn(false);
                        selectedLocationIdHandler(locationId);
                        // setEditBtnShow(false);
                      }}
                    >
                      Yes
                    </Button>
                  </Col>

                  <Col md={2}></Col>
                </Row>
              </ModalBody>
            </Modal>

            {/* Selected Plan Change Model */}

            <Modal isOpen={editModel}>
              <ModalHeader>
                Change Plan
                <span
                  style={{ marginLeft: "330px" }}
                  onClick={() => setEditModel(false)}
                  className="mdi mdi-close-thick"
                ></span>
              </ModalHeader>
              <ModalBody>
                {selectedPlanForEdit?.map((selectedPlan ,index) => {
                  return (
                    <>
                    <div key={index}>
                      <Row>
                        <Col md={6}>
                          <h5 className="logininboxpanels">
                            {`${selectedPlan.resourceTypeName}:`}
                          </h5>
                        </Col>
                        <Col md={4}></Col>
                        <Col md={4}></Col>
                      </Row>
                      <Row className="ms-3">
                        {selectedPlan.plans.map((plan) => {
                          return (
                            
                            <Col md={4} key={index}>
                              <FormGroup check inline>
                                <Input
                                  disabled={formDisable}
                                  type="checkbox"
                                  id="RTC"
                                  checked={plan.selected}
                                  style={{
                                    transform: "scale(1.5)",
                                    marginRight: "5px",
                                  }}
                                  // onChange={() => function set() {}}
                                />

                                <span>
                                  <Label
                                    htmlFor="RTC"
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontSize: "1rem",
                                      marginLeft: "4px", // Adjust the font size as needed
                                    }}
                                  >
                                    {plan.name}
                                  </Label>
                                </span>
                              </FormGroup>
                            </Col>
                           
                          );
                        })}
                      </Row>
                      </div>
                    </>
                  );
                })}
              </ModalBody>
              <ModalFooter>
                <Button color="success">Save Changes</Button>
              </ModalFooter>
            </Modal>

            {/* Selected location plan cancel model */}
            <Modal isOpen={showCancelConfirmModel}>
            <Row>
                
                <Col md={12}>
                  <ModalHeader><p>Cancel Changes</p></ModalHeader>
                </Col>
               
              </Row>
              <ModalBody>
                <p> Are You Sure Want to Cancel Changes?</p>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="success"
                  style={{ width: "100px" }}
                  onClick={() => chnageCancelHandler()}
                >
                  Yes
                </Button>
                <Button
                  color="success"
                  style={{ width: "100px" }}
                  onClick={() => {
                    // Hide Cancel Model
                    setShowCancelConfirmModel(false);
                  }}
                >
                  No
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </form>
      ) : (
        <NoAccess />
      )}
    </>
  );
}

export default NextStep;
