import { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { DIOM_BASED_URLS } from "../../.././config/url";
import { toast } from "react-toastify";
import { useQuery, useQueryClient } from "react-query";
import { useCustomQuery, usePaginatedQuery } from "../../../hooks/query";
import { getGuestbookings, getrevokeddbookings, gettodaysbookings, searchTodayBooking } from "../../../APIS/bookings";
import { getLocations, getFilterLocations } from "../../../APIS";
import { useAllDataQuery } from "../../../hooks/query";
import { getschduleddbookings } from "../../../APIS/bookings";
import { getPasttbookings } from "../../../APIS/bookings";
import { getResourcetypeOperationalHours } from "../../../APIS/operationalHours";
import { getMobileUsers } from "../../../APIS/userProfle";

const UseRequestBookings = () => {
  const selectInputRefResourceType = useRef("");
  let history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [categoryDropDown, setCategoryDropDown] = useState([]);
  const [locationValues, setLocationValues] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const changeCurrentPage = (pageNum) => setCurrentPage(pageNum);
  const queryClient = useQueryClient();
  // const { t_ID } = useParams();
  const [activeTabJustify, setActiveTabJustify] = useState("1");
  const [diomLocation, setDiomLocation] = useState([]);
  const [resourcetypeApplyFilter, setResourcetypeApplyFilter] = useState(false);
  const [resourceTypeFilter, setResourceTypeFilter] = useState("All");
  const [loaded, setLoaded] = useState(false);
  const [bookingsToday, setBookingsToday] = useState([]);
  const [modal_static, setModal_static] = useState(false);
  const [modal_static1, setModal_static1] = useState(false);
  const [revokeTextArea, setRevokeTextArea] = useState();
  const [idfSelectedRow, setIdfSelectedRow] = useState();
  const [error, setError] = useState(null);
  const [applyLocationFilter, setApplyFilter] = useState(false);
  const [filter, setFilter] = useState("All");
  const [searchBookingQuery, setSearcbookingQuery] = useState("");
  const [searchAPI, setSearchAPI] = useState("today");

  const token = localStorage.getItem("Token");
  const [selectedOptionOfBooking, setSelectedOptionOfBooking] = useState(null);

  
// 1) Search and Get today bookings
const { data: searchBookingData , isLoading:isSearchBookingDataLoading } = useCustomQuery(["SearchBookingToday" ,searchBookingQuery], () =>
  searchTodayBooking(token,searchBookingQuery ,history ,searchAPI ),
searchBookingQuery?true:false
  );

// 2)When user Search show search Data otherwise show selected Tab Data

  useEffect( async () => {
    if (searchBookingQuery.length > 0 && searchBookingData?.data) {
      setBookingsToday(searchBookingData.data);
    }else if(searchBookingQuery.length > 0 && searchBookingData?.data==="undefined"){

      setBookingsToday(searchBookingData?.data)
    }
    else{

      if (activeTabJustify === "1") {
        todayBookingsdata && (await gettodaybookings());
      } else if (activeTabJustify === "3") {
        await fetchScheduledBookings();
      } else if (activeTabJustify === "6") {
        await fetchPastBookings();
      } else if (activeTabJustify === "4") {
        await fetchRevokedBookings();
      }
      
    }
  }, [searchBookingQuery, searchBookingData]);


  // Model Logic
  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const tog_static = (__id) => {
    setModal_static(!modal_static);
    setIdfSelectedRow(__id);

    removeBodyCss();
  };
  const tog_static1 = () => {
    setModal_static1(!modal_static1);

    removeBodyCss();
  };

  // Tab Toggler fn
  const toggleCustomJustified = (tab) => {
    if (activeTabJustify !== tab) {
      setActiveTabJustify(tab);
    }
  };

  // Get All location data...
  const { data: locationsData } = useAllDataQuery(
    ["locations", "visible"],
    () => getLocations(token)
    // () => getFilterLocations(token)
  );
  // This is useState get all the data of resourceType..
  useEffect(() => {
    const allLocationsIds = locationsData?.map((e) => {
      return e?.options?.map((item) => item?.value);
    });

    if (locationValues.length === 0) {
      setLocationValues(allLocationsIds);
    }
  }, [locationValues, locationsData]);

  const textareachange = (e) => {
    setRevokeTextArea(e.target.value);
  };

  // Revoke Booking Fn..
  const delRevokeBookings = () => {
    fetch(`${DIOM_BASED_URLS}/admin-diom-bookings/revoke/${idfSelectedRow}`, {
      method: "DELETE",
      redirect: "follow",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        body: revokeTextArea,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success("Booking Revoked");
          queryClient.invalidateQueries("getschduledbookings");
          queryClient.invalidateQueries("getrevokedbookings");
        } else if (result.statusCode === 204) {
          toast.success("Booking Revoked");
          queryClient.invalidateQueries("getschduledbookings");
          queryClient.invalidateQueries("getrevokedbookings");
        } else {
          toast.error("You can not revoke a past booking");
        }

        setModal_static1(false);
        if (activeTabJustify === "1") {
          gettodaybookings();
          queryClient.invalidateQueries("gettodaysbookings");
        } else if (activeTabJustify === "3") {
          fetchScheduledBookings();
          queryClient.invalidateQueries("getschduledbookings");
        }
      })
      .catch((error) => toast.error(error.message));
  };

  // Set Location filter fns when Location DropDown called...
  const fetchFilteredTodayBookings = async (val) => {
    if (val === "All") {
      setCurrentPage(1);
      setApplyFilter(false);
      setFilter("All");
      // queryClient.invalidateQueries("gettodaysbookings");
    } else {
      setCurrentPage(1);
      setApplyFilter(true);
      setFilter(val);

      // queryClient.invalidateQueries("gettodaysbookings");
    }
  };
  const fetchFilteredPastBookings = async (val) => {
    if (val === "All") {
      setCurrentPage(1);
      setApplyFilter(false);
      setFilter("All");
      // queryClient.invalidateQueries("getpastbookings");
    } else {
      setCurrentPage(1);
      setApplyFilter(true);
      setFilter(val);
      // queryClient.invalidateQueries("getpastbookings");
    }
  };
  const fetchFilteredRevokedBookings = async (val) => {
    if (val === "All") {
      setCurrentPage(1);
      setApplyFilter(false);
      setFilter("All");
      // setBookingsToday(RevokedBookingsdata);
      // queryClient.invalidateQueries("getrevokedbookings");
    } else {
      setCurrentPage(1);
      setApplyFilter(true);
      setFilter(val);
      // queryClient.invalidateQueries("getrevokedbookings");
    }
  };

  const fetchFilteredScheduledBookings = async (val) => {
    if (val === "All") {
      setCurrentPage(1);
      setApplyFilter(false);
      setFilter("All");
      // queryClient.invalidateQueries("getschduledbookings");
    } else {
      // queryClient.invalidateQueries("getschduledbookings");
      setCurrentPage(1);
      setApplyFilter(true);
      setFilter(val);
    }
  };

  //--------------- ResourceType filter fns When ResourceType DropDown called----------------
  const fetchResourceFilteredShecduleBookings = async (val) => {
    if (val === "All") {
      // queryClient.invalidateQueries("getschduledbookings");

      setCurrentPage(1);
      setResourcetypeApplyFilter(false);
      setResourceTypeFilter("All");
    } else {
      // queryClient.invalidateQueries("getschduledbookings");

      setCurrentPage(1);
      setResourcetypeApplyFilter(true);
      setResourceTypeFilter(val);
    }
  };

  const fetchResourceFilteredTodayBookings = async (val) => {
    if (val === "All") {
      // queryClient.invalidateQueries("gettodaysbookings");
      setCurrentPage(1);
      setResourcetypeApplyFilter(false);
      setResourceTypeFilter("All");
    } else {
      // queryClient.invalidateQueries("gettodaysbookings");

      setCurrentPage(1);
      setResourcetypeApplyFilter(true);
      setResourceTypeFilter(val);
    }
  };

  // This fn set Location filter...
  const fetchFilteredrevokeBookings = async (val) => {
    if (val === "All") {
      // queryClient.invalidateQueries("getrevokedbookings");

      setCurrentPage(1);
      setResourcetypeApplyFilter(false);
      setResourceTypeFilter("All");
    } else {
      // queryClient.invalidateQueries("getrevokedbookings");

      setCurrentPage(1);
      setResourcetypeApplyFilter(true);
      setResourceTypeFilter(val);
    }
  };

  // This fn set Location filter...
  const fetchFilterePastBookings = async (val) => {
    if (val === "All") {
      // queryClient.invalidateQueries("getpastbookings");

      setCurrentPage(1);
      setResourcetypeApplyFilter(false);
      setResourceTypeFilter("All");
    } else {
      // queryClient.invalidateQueries("getpastbookings");

      setCurrentPage(1);
      setResourcetypeApplyFilter(true);
      setResourceTypeFilter(val);
    }
  };

  // ----------------------------------React Query Fn Start ---------------------------
  //get today bookings API***************
  const {
    data: { data: todayBookingsdata, hasNextPage, hasPreviousPage, total },
    isLoading,
  } = usePaginatedQuery(
    [
      "gettodaysbookings",
      `${pageSize}`,
      `${currentPage}`,
      `${filter}`,
      `${resourceTypeFilter?.value}`,
    ],
    () =>
      gettodaysbookings(
        pageSize,
        currentPage,
        token,
        applyLocationFilter,
        filter,
        history,
        resourceTypeFilter
      )
  );

  useEffect(() => {
    // FOR PRE-FETCHING NEXT PAGE
    if (hasNextPage) {
      const nextPage = currentPage + 1;
      queryClient.prefetchQuery(
        [
          "gettodaysbookings",
          `${pageSize}`,
          `${currentPage}`,
          `${filter}`,
          `${resourceTypeFilter?.value}`,
        ],
        () =>
          gettodaysbookings(
            pageSize,
            nextPage,
            token,
            applyLocationFilter,
            filter,
            history,
            resourceTypeFilter
          )
      );
    }
  }, [
    currentPage,
    queryClient,
    applyLocationFilter,
    resourceTypeFilter,
    filter,
    hasNextPage,
    history,
    pageSize,
    token,
  ]);

// get Admin Guest Booking**************************

const {
  data: { data: guestBookingsdata, hasNextPage:guestHasNextPage,hasPreviousPage: guestHasPreviousPage, total:guesTtotal },
  isLoading: isLoadingGuestBookingLoading,
} = usePaginatedQuery(
  [
    "getGuestBookings",
    `${pageSize}`,
    `${currentPage}`,
    `${filter}`,
    `${resourceTypeFilter?.value}`,
  ],
  () =>
    getGuestbookings(
      pageSize,
      currentPage,
      token,
      applyLocationFilter,
      filter,
      history,
      resourceTypeFilter
    )
);

// TODO Write preFetch Logic for Admin Quest Bookings


  //get Past bookings API***************
  const {
    data: {
      data: pastBookingsdata,
      hasNextPage: pastNextPage,
      hasPreviousPage: pastPreviousPage,
      total: pastTotal,
    },
  } = usePaginatedQuery(
    [
      "getpastbookings",
      `${pageSize}`,
      `${currentPage}`,
      `${filter}`,
      `${resourceTypeFilter?.value}`,
    ],
    () =>
      getPasttbookings(
        pageSize,
        currentPage,
        token,
        applyLocationFilter,
        filter,
        history,
        resourceTypeFilter
      )
  );
  // console.log(pastBookingsdata, "pastBookingsdata");
  useEffect(() => {
    // FOR PRE-FETCHING NEXT PAGE
    if (hasNextPage) {
      const nextPage = currentPage + 1;
      queryClient.prefetchQuery(
        ["getpastbookings", `${pageSize}`, `${currentPage}`, `${filter}`],
        () =>
          getPasttbookings(
            pageSize,
            nextPage,
            token,
            applyLocationFilter,
            filter,
            history
          )
      );
    }
  }, [
    queryClient,
    applyLocationFilter,
    filter,
    pageSize,
    currentPage,
    hasNextPage,
    history,
    token,
  ]);

  // **********

  //get scheduled bookings API***************
  const {
    data: {
      data: schduledBookingsdata,
      hasNextPage: ScheduledNextPage,
      hasPreviousPage: ScheduledPreviousPage,
      total: ScheduledTotal,
    },
  } = usePaginatedQuery(
    [
      "getschduledbookings",
      `${pageSize}`,
      `${currentPage}`,
      `${filter}`,
      `${resourceTypeFilter?.value}`,
    ],
    () =>
      getschduleddbookings(
        pageSize,
        currentPage,
        token,
        applyLocationFilter,
        filter,
        history,
        resourceTypeFilter // Here add resouTypeFilter
      )
  );
  // console.log(schduledBookingsdata, "SchduleBookingData");

  useEffect(() => {
    // FOR PRE-FETCHING NEXT PAGE
    if (hasNextPage) {
      const nextPage = currentPage + 1;
      queryClient.prefetchQuery(
        ["getschduledbookings", `${pageSize}`, `${currentPage}`, `${filter}`],
        () =>
          getschduleddbookings(
            pageSize,
            nextPage,
            token,
            applyLocationFilter,
            filter,
            history
          )
      );
    }
  }, [
    queryClient,
    applyLocationFilter,
    filter,
    pageSize,
    currentPage,
    hasNextPage,
    history,
    token,
  ]);

  //get revoked bookings API***************
  const {
    data: {
      data: RevokedBookingsdata,
      hasNextPage: RevokedNextPage,
      hasPreviousPage: RevokedPreviousPage,
      total: RevokedTotal,
    },
  } = usePaginatedQuery(
    [
      "getrevokedbookings",
      `${pageSize}`,
      `${currentPage}`,
      `${filter}`,
      `${resourceTypeFilter?.value}`,
      `${locationValues}`,
    ],
    () =>
      getrevokeddbookings(
        pageSize,
        currentPage,
        token,
        applyLocationFilter,
        filter,
        history,
        resourceTypeFilter
      )
  );

  useEffect(() => {
    // FOR PRE-FETCHING NEXT PAGE
    if (RevokedNextPage) {
      const nextPage = currentPage + 1;
      queryClient.prefetchQuery(
        [
          "getrevokedbookings",
          `${pageSize}`,
          `${currentPage}`,
          `${filter}`,
          `${locationValues}`,
        ],
        () =>
          getrevokeddbookings(
            (pageSize, nextPage, token, applyLocationFilter, filter, history)
          )
      );
    }
  }, [currentPage, queryClient, applyLocationFilter]);
  // Update booking State variable when user on Revoked Tab
  useEffect(() => {
    if (activeTabJustify === "4") {
      setBookingsToday(RevokedBookingsdata);
    }
  }, [RevokedBookingsdata]);
  
  //---------------------Get  resourceTypes data BY Applying location dropdwon------------------
  const resourceTypedropdownData = useQuery(
    ["resourceTypedropdownoperationalHpours", locationValues],
    () =>
      getResourcetypeOperationalHours(
        token,
        resourcetypeApplyFilter,
        locationValues
      )
  );

  const resourceTypedropdownDailedData = resourceTypedropdownData.data;

  // *************
  const Resourcetypesdropdown = async () => {
    // Adding the option as ALL  in first Index
    // resourceTypedropdownDailedData.data.length > 0;
    // {
    //   resourceTypedropdownDailedData.data.unshift({
    //     id: resourceTypedropdownDailedData.data.map((e) => e?.id),
    //     Name: "All",
    //     isAvailableInWnpl: true,
    //     startTime: "0",
    //     endTime: "0",
    //   });
    // }
    // Here we rebuilding object and return needed propeties
    const matadata = resourceTypedropdownDailedData.data.map((element) => {
      return {
        label: element.Name,
        value: element.id,

        UniqueId: element.UniqueId,
        resourceTypeCategoryId: element.resourceTypeCategoryId,
        // visibility: element.visibility,
      };
    });

    setCategoryDropDown(matadata);
  };

  useEffect(
    // FOR PRE-FETCHING NEXT PAGE
    async () => {
      resourceTypedropdownDailedData && (await Resourcetypesdropdown());
    },
    [resourceTypedropdownDailedData]
  );
  // --------------------------------ReactQuery fn End ---------------------------------
  //-------------------------------------- Tab Fn Start ---------------------------------
  const fetchPastBookings = async () => {
    setBookingsToday([]);
    setBookingsToday(pastBookingsdata);
  };

  const fetchRevokedBookings = async () => {
    setBookingsToday([]);

    setBookingsToday(RevokedBookingsdata);
  };

  const fetchScheduledBookings = async () => {
    setBookingsToday([]);

    setBookingsToday(schduledBookingsdata);
  };

  const gettodaybookings = async () => {
    setBookingsToday([]);

    setBookingsToday(todayBookingsdata);
  };

  //pending
  const fetchRequestsBookings = () => {
    setBookingsToday([]);
    setBookingsToday(guestBookingsdata);
    

  };
  const fetchPendingBookings = () => {
    setBookingsToday([]);
  };
  //pending

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const pagelengthnum = (e) => {
    if (e.target.value === "1") {
      setPageSize(10);
    } else if (e.target.value === "2") {
      setPageSize(30);
    } else if (e.target.value === "3") {
      setPageSize(50);
    }
  };
  const pageOptions = {
    sizePerPage: pageSize,
    totalSize: total ?? 0,
    custom: true,
  };

  useEffect(() => {
    (async () => {
      if (activeTabJustify === "1") {
        todayBookingsdata && (await gettodaybookings());
      } else if (activeTabJustify === "3") {
        await fetchScheduledBookings();
      } else if (activeTabJustify === "6") {
        await fetchPastBookings();
      } else if (activeTabJustify === "4") {
        await fetchRevokedBookings();
      }
      // else {
      //   gettodaybookings(); // Remove this becuase in defualt today data show
      // }
    })();
  }, [todayBookingsdata, filter]);

  //---------------------------------------- Handler Start-----------------------------------

  const locationHandler = async (a) => {
    // console.log("Location Handler>>>>>>>>");
    const allLocationsIds = locationsData?.map((e) => {
      return e?.options?.map((item) => item?.value);
    });
    selectInputRefResourceType?.current.select.clearValue();

    if (a === "All") {
      setLocationValues(allLocationsIds);
    } else {
      setLocationValues([a]);
    }

    setResourcetypeApplyFilter(true);

    // This if Block check first which Tab open and then calles location filtered fn..
    if (activeTabJustify === "1") {
      fetchFilteredTodayBookings(a);
    } else if (activeTabJustify === "3") {
      console.log("Schedule tab");
      fetchFilteredScheduledBookings(a);
    } else if (activeTabJustify === "4") {
      fetchFilteredRevokedBookings(a);
    } else if (activeTabJustify === "6") {
      fetchFilteredPastBookings(a);
    }
  };

  // ..........ResourceTypeHandler......................
  const resourceTypeHandler = (a) => {
    if (activeTabJustify === "1") {
      fetchResourceFilteredTodayBookings(a);
    } else if (activeTabJustify === "3") {
      fetchResourceFilteredShecduleBookings(a);
    } else if (activeTabJustify === "4") {
      queryClient.invalidateQueries("getrevokedbookings");
      fetchFilteredrevokeBookings(a);
    } else if (activeTabJustify === "6") {
      queryClient.invalidateQueries("getpastbookings");
      fetchFilterePastBookings(a);
    }
  };
  // OptionData for bookingDropDown....
  const bookingOptionData = [
    { value: "1", label: "Today Bookings" },
    { value: "3", label: "Shecdule Bookings" },
    { value: "4", label: "Revoke Bookings" },
    { value: "6", label: "Past Bookings" },
  ];

  const bookingHandler = (val) => {

  
    if (val.value === "1") {
      setSelectedOptionOfBooking(val.label);
     
      gettodaybookings();
      setActiveTabJustify(val.value);
    } else if (val.value === "3") {
      
      fetchScheduledBookings();
      setActiveTabJustify(val.value);
      setSelectedOptionOfBooking(val.label);
    } else if (val.value === "4") {
     
      fetchRevokedBookings();
      setActiveTabJustify(val.value);
      setSelectedOptionOfBooking(val.label);
    } else if (val.value === "6") {
     
      fetchPastBookings();
      setActiveTabJustify(val.value);
      setSelectedOptionOfBooking(val.label);
    }
  };

  //-------------------------------------------- Handler End ---------------------------
  return {
    resourceTypeHandler,
    selectInputRefResourceType,
    categoryDropDown,
    RevokedTotal,
    RevokedPreviousPage,
    RevokedNextPage,
    activeTabJustify,
    setActiveTabJustify,
    diomLocation,
    setDiomLocation,
    loaded,
    setLoaded,
    bookingsToday,
    setBookingsToday,
    modal_static,
    setModal_static,
    modal_static1,
    setModal_static1,
    revokeTextArea,
    setRevokeTextArea,
    idfSelectedRow,
    setIdfSelectedRow,
    error,
    isLoading,
    setError,
    tog_static,
    tog_static1,
    toggleCustomJustified,
    locationHandler,
    textareachange,
    fetchFilteredPastBookings,
    fetchFilteredRevokedBookings,
    fetchFilteredScheduledBookings,
    fetchFilteredTodayBookings,
    fetchRequestsBookings,
    fetchRevokedBookings,
    fetchPendingBookings,
    fetchPastBookings,
    fetchScheduledBookings,
    gettodaybookings,
    delRevokeBookings,
    pagelengthnum,
    locationsData,
    pageSize,
    toggle,
    isOpen,
    pageOptions,
    hasNextPage,
    currentPage,
    pastNextPage,
    pastPreviousPage,
    pastTotal,
    ScheduledNextPage,
    changeCurrentPage,
    setLocationValues,
    Resourcetypesdropdown,
    bookingOptionData,
    bookingHandler,
    selectedOptionOfBooking,
    searchBookingQuery, 
    setSearcbookingQuery,
    isSearchBookingDataLoading,
    setSearchAPI
  };
};
export default UseRequestBookings;
