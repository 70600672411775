import { useEffect, useState } from "react";
import { getallplandiscounts } from "../../../APIS/planDiscounts";
import { usePaginatedQuery } from "../../../hooks/query";
import { useQuery, useQueryClient } from "react-query";

const token = localStorage.getItem("Token");
export const usePlanDiscounts = () => {
  const [pageSize, setPageSize] = useState(30);
  const [activeTabJustify, setActiveTabJustify] = useState("1");
  const [activeTabState, setActiveTabState] = useState(true);

  const QueryClient = useQueryClient();
  // const {
  //   data: { data: planDiscounts },
  //   isLoading,
  // } = useQuery(["planDiscounts"], () =>
  //   getallplandiscounts(token, activeTabState)
  // );

  const { data: planDiscounts, isLoading } = useQuery(
    ["planDiscounts", activeTabState],
    () => getallplandiscounts(token, activeTabState)
  );
  // console.log(planDiscounts, isLoading, "planDiscountsData");

  useEffect(() => {
    QueryClient.invalidateQueries("planDiscounts");
  }, [activeTabState, QueryClient]);

  // Set Which Tab is active
  const toggleCustomJustified = (tab) => {
    if (activeTabJustify !== tab) {
      setActiveTabJustify(tab);
    }
  };

  const pageOptions = {
    sizePerPage: pageSize,
    // totalSize: total ?? 0,
    totalSize: 0,
    custom: true,
  };
  return {
    planDiscounts,
    isLoading,
    pageOptions,
    activeTabJustify,
    toggleCustomJustified,
    setActiveTabState,
  };
};
