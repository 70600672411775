import React, { useRef } from "react";
import {
  Row,
  Col,
  CardBody,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

import "../../css/InvoicesDetailPage.css";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import InvoicesDetailPrint from "./components/InvoicesDetailPrint.js";
import { useInvoice } from "./useInvoicesDetail";
import BounceLoader from "react-spinners/BounceLoader";
import { Accordion } from "react-bootstrap-accordion";

function InvoicesDetailPage() {
  const {
    ErpLink,
    otpState,
    setOtpState,
    markedpaidFunc,
    modal_static1,
    setModal_static1,
    tog_static1,
    handleMarkPaid,
    InvoiceData,
    isLoading,
    refundPartialfunc,
    refundfullfunc,
    refundSt,
    refundApplyFunc,
    pRefundInput,
    refundInput,
    applyButton,
    statusPaidByEmail,
    vatRefund,
    totalRefund,
  } = useInvoice();

  const history = useHistory();

  // const [loaded, setLoaded] = useState(false);
  const createdat = moment(InvoiceData.createdAt).format("YYYY-MM-DD ");
  const bookingfrom = moment(InvoiceData.bookingFromTime).format("YYYY-MM-DD ");
  const bookingto = moment(InvoiceData.bookingToTime).format("YYYY-MM-DD ");
  const location = useLocation();

  const Loader = require("react-loader");
  const { id } = useParams();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
  });

  const invoiceWithWnplDownload = () => {
    var FileSaver = require("file-saver");
    const d = InvoiceData.erpInvoiceUrl;
    FileSaver.saveAs(d, "image.jpg");
    // <a href={d} download />
  };

  return (
    <>
      {isLoading ? (
        <Loader loaded={false} className="spinner" />
      ) : (
        <div className="page-content">
          <Row className="mb-3">
            <Col md={3}>
              <Link to={history?.location?.state?.prevPath} className="link">
                <span className="fas fa-angle-left arrowheightwidth"></span>
              </Link>

              <span className=" ml-4 invoicedetailfirstID">
                {id ? id : "---/-"}
              </span>
            </Col>
            <Col md={9}></Col>
          </Row>
          {/* Card Start */}
          <Row>
            <Col md={8}>
              <CardBody style={{ background: "white" }}>
                <Row style={{ marginLeft: "2px", marginRight: "2px" }}>
                  <Col md={7}>
                    <p className="invoicedetailname">
                      {InvoiceData.customerData
                        ? InvoiceData.customerData.username
                        : "---/-"}
                    </p>
                    <p className="invoicedetailemail">
                      {InvoiceData.customerData
                        ? InvoiceData.customerData.email
                        : "---/-"}
                    </p>
                    <p className="invoicedetailemail">
                      {InvoiceData.customerData
                        ? InvoiceData.customerData.MobilePhone
                        : "---/-"}
                    </p>
                  </Col>
                  <Col md={5} className="rightaligncss">
                    <p className="invoicedetailid">{id ? id : "---/-"}</p>
                    <Row>
                      <Col md={7}>
                        <p className="invoicedetailname">Invoice Status :</p>
                      </Col>
                      <Col md={5} className="rightaligncss">
                        {InvoiceData.paymentStatus === "UNPAID" ? (
                          <span className="invoiceunpaidstatuscss">
                            {InvoiceData ? InvoiceData.paymentStatus : "---/-"}
                          </span>
                        ) : (
                          <span className="invoicestatuscss">
                            {InvoiceData ? InvoiceData.paymentStatus : "---/-"}
                          </span>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={7}>
                        {" "}
                        <p className="invoicedetailname">Booking id :</p>
                      </Col>
                      <Col md={5} className="rightaligncss">
                        <Link
                          to={{
                            pathname: `/booking_detail/${InvoiceData?.bookingId}/1`,
                            state: { prevPath: location.pathname },
                          }}
                          className="link"
                        >
                          {InvoiceData ? InvoiceData.bookingId : "---/-"}
                        </Link>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={7}>
                        <p className="invoicedetailname">Refunded :</p>
                      </Col>
                      <Col md={5} className="rightaligncss">
                        {InvoiceData
                          ? JSON.stringify(InvoiceData.refunded)
                          : "---/-"}
                      </Col>
                    </Row>

                    <Row>
                      <Col md={7}>
                        {" "}
                        <p className="invoicedetailname">Invoice Erp id :</p>
                      </Col>
                      <Col md={5} className="rightaligncss">
                        {" "}
                        {InvoiceData.paymentStatus === "UNPAID" ? (
                          InvoiceData ? (
                            InvoiceData.erpInvoiceId
                          ) : (
                            "---/-"
                          )
                        ) : (
                          <a href={ErpLink} target="_blank">
                            {InvoiceData ? InvoiceData.erpInvoiceId : "---/-"}
                          </a>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr />

                <Row style={{ marginLeft: "2px", marginRight: "2px" }}>
                  <Col md={3}>
                    <p className="invoicesdetailslabel">Resource Type</p>
                  </Col>
                  <Col md={3}>
                    <p className="invoicedetailsdata">
                      {InvoiceData.resourceTypeName
                        ? InvoiceData.resourceTypeName
                        : "---/-"}
                    </p>
                  </Col>
                  <Col md={3}>
                    <p className="invoicesdetailslabel">Location</p>
                  </Col>
                  <Col md={3} className="rightaligncss">
                    <p className="invoicedetailsdata">
                      {InvoiceData.businessName
                        ? InvoiceData.businessName
                        : "---/-"}
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginLeft: "2px", marginRight: "2px" }}>
                  <Col>
                    <p className="invoicesdetailslabel">Resource Name</p>
                  </Col>
                  <Col>
                    <p className="invoicedetailsdata">
                      {InvoiceData.resourceName
                        ? InvoiceData.resourceName
                        : "---/-"}
                    </p>
                  </Col>
                  <Col className="">
                    <p className="invoicesdetailslabel">Booking Date</p>
                  </Col>
                  <Col className="rightaligncss">
                    <p className="invoicedetailsdata">
                      {createdat ? createdat : "---/-"}
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginLeft: "2px", marginRight: "2px" }}>
                  <Col>
                    <p className="invoicesdetailslabel">Unit Price</p>
                  </Col>
                  <Col>
                    <p className="invoicedetailsdata">
                      {InvoiceData.unitPrice ? InvoiceData.unitPrice : "---/-"}
                    </p>
                  </Col>
                  <Col className="">
                    <p className="invoicesdetailslabel">Booking Type</p>
                  </Col>
                  <Col className="rightaligncss">
                    <p className="invoicedetailsdata">
                      {InvoiceData.bookingType
                        ? InvoiceData.bookingType
                        : "---/-"}
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginLeft: "2px", marginRight: "2px" }}>
                  <Col>
                    {" "}
                    <p className="invoicesdetailslabel">Discount Code</p>
                  </Col>

                  <Col>
                    <p className="invoicedetailsdata">
                      {InvoiceData ? InvoiceData?.discountCode : "---/-"}
                    </p>
                  </Col>
                  <Col className="">
                    <p className="invoicesdetailslabel">Booking Start</p>
                  </Col>
                  <Col className="rightaligncss">
                    <p className="invoicedetailsdata">
                      {bookingfrom ? bookingfrom : "---/-"}
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginLeft: "2px", marginRight: "2px" }}>
                  <Col>
                    {" "}
                    <p className="invoicesdetailslabel">Discount Amount</p>
                  </Col>
                  <Col>
                    <p className="invoicedetailsdata">
                      {InvoiceData ? InvoiceData.discountAmount : "---/-"}
                    </p>
                  </Col>
                  <Col className="">
                    <p className="invoicesdetailslabel">Booking End</p>
                  </Col>
                  <Col className="rightaligncss">
                    <p className="invoicedetailsdata">
                      {bookingto ? bookingto : "---/-"}
                    </p>
                  </Col>
                </Row>
                <hr />
                <Row style={{ marginLeft: "2px", marginRight: "2px" }}>
                  <p className="invoicedetailsBD">Billing Details</p>
                  <Col md={2}>
                    <p className="invoicesdetailslabel">
                      {InvoiceData.resourceTypeName
                        ? InvoiceData.resourceTypeName
                        : "---/-"}
                    </p>
                  </Col>
                  <Col md={8}></Col>
                  <Col md={2} className="rightaligncss">
                    <p
                      className="invoicedetailsBD"
                      style={{ overflowWrap: "break-word" }}
                    >
                      {InvoiceData ? InvoiceData?.balanceWithVat : "---/-"}
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginLeft: "2px", marginRight: "2px" }}>
                  <Col md={5}>
                    <p className="invoicesdetailtotalpay">Total Payable</p>
                  </Col>
                  <Col md={4}></Col>
                  <Col md={3} className="rightaligncss"></Col>
                </Row>
                <Row style={{ marginRight: "4px", marginBottom: "2px" }}>
                  <Col md={6}></Col>
                  <Col md={6} className="pkg_invoice_SubTotal_bg">
                    <Row>
                      <Col md={8}>
                        <p> Sub Total </p>
                      </Col>
                      <Col md={4} className="rightaligncss">
                        {" "}
                        {InvoiceData?.total}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={8}>
                        <p> Vat </p>
                      </Col>
                      <Col md={4} className="rightaligncss">
                        {InvoiceData?.vatPrice}
                      </Col>
                    </Row>

                    <Row>
                      <Col md={8}>
                        <p className="invoicedetailsBD">Total Payable </p>
                      </Col>
                      <Col
                        md={4}
                        className="rightaligncss"
                        style={{ overflowWrap: "break-word" }}
                      >
                        {" "}
                        <p className="invoicedetailsBD">
                          {InvoiceData?.balanceWithVat}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Col>
            <Col md={4}>
              {/* {InvoiceData.id && (
                // <PDFDownloadLink
                //   stopColor={true}
                //   document={<GeneratePdf InvoiceData={InvoiceData} />}
                //   fileName="invoice.pdf"
                // >
               
                <a
                          className=" link text-muted font-weight-bold "
                          // href={`${InvoiceData.erpInvoiceUrl}`}
                          // download
                          
                        >
                  <Button
                    color="success"
                    outline
                    className="invoicesdetailbuttons waves-effect waves-light  w-100  "
                    onClick={invoiceWithWnplDownload}
                  >
                    {/* <p className="invoicepdfcss "> */}
              {/* Export */}
              {/* </p> */}
              {/* </Button>
                     </a>
                // </PDFDownloadLink> */}
              {/* )} */}
              {/* */}
              {/* </Button> */}
              <Button
                onClick={handlePrint}
                color="success"
                outline
                className="waves-effect waves-light me-1 w-100 mt-3 invoicesdetailbuttons"
              >
                Print
              </Button>

              <div className="mt-3 ">
                {/* {InvoiceData.refunded === false ? ( */}
                {InvoiceData.paymentStatus === "PAID" ? (
                  <Accordion
                    className="  w-100 mt-3 invoicerefundcss "
                    title="Revert Refund"
                  >
                    <Row className="mt-2">
                      <Col md={5}></Col>
                      <Col md={2}>
                        <p className="invoicerefundcss">Refund</p>
                      </Col>
                      <Col md={5}></Col>
                    </Row>

                    <Row className="mt-2">
                      <Col md={1}></Col>
                      <Col md={1}>
                        {" "}
                        <Input
                          className="form-check-input "
                          type="radio"
                          value="option1"
                          id="exampleRadios1"
                          name="customRadio"
                          onClick={(e) => {
                            refundPartialfunc(e);
                          }}
                          // checked={true}
                        />
                      </Col>
                      <Col md={2}>
                        <span>partial</span>
                      </Col>
                      <Col md={2}></Col>
                      <Col md={2}></Col>
                      <Col md={1}>
                        <Input
                          className="form-check-input"
                          type="radio"
                          value="option2"
                          id="exampleRadios1"
                          name="customRadio"
                          // checked={true}
                          onClick={(e) => {
                            refundfullfunc(e);
                          }}
                        />
                      </Col>
                      <Col md={2}>
                        <span>full</span>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    {/* {refundSt? */}
                    <div>
                      <Row className="mt-4">
                        <Col md={1}></Col>
                        <Col md={10}>
                          <p>
                            <b>Enter Amount</b>
                          </p>
                        </Col>
                        <Col md={1}></Col>
                      </Row>

                      <Row>
                        <Col md={1}></Col>
                        <Col md={10}>
                          <Input
                            type="text"
                            onChange={(e) => {
                              pRefundInput(e.target.value);
                            }}
                            disabled={!refundSt}
                          />
                        </Col>
                        <Col md={1}></Col>
                      </Row>
                    </div>
                    {/* :null} */}
                    <Row>
                      <Col md={1}></Col>
                      <Col md={10}>
                        {refundSt === true ? (
                          refundInput < InvoiceData?.total ? (
                            <Button
                              color="success"
                              outline
                              className="invoicesdetailbuttons waves-effect waves-light  w-100 mt-3 "
                              disabled={false}
                              onClick={refundApplyFunc}
                            >
                              Apply
                            </Button>
                          ) : (
                            <Button
                              color="success"
                              outline
                              className="invoicesdetailbuttons waves-effect waves-light  w-100 mt-3 "
                              disabled={true}
                              onClick={refundApplyFunc}
                            >
                              Apply
                            </Button>
                          )
                        ) : (
                          <Button
                            color="success"
                            outline
                            className="invoicesdetailbuttons waves-effect waves-light  w-100 mt-3 "
                            onClick={refundApplyFunc}
                            disabled={applyButton}
                          >
                            Apply
                          </Button>
                        )}
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    {refundSt === true ? (
                      <div className="p-2">
                        <Row className="mt-3">
                          <Col md={6}>
                            <p>Sub Total</p>
                          </Col>
                          <Col md={6} className="rightaligncss">
                            {refundInput ? refundInput : "--/-"}
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            <p>Vat</p>
                          </Col>
                          <Col md={6} className="rightaligncss">
                            {refundInput ? (refundInput * 15) / 100 : "--/-"}
                            {/* {vatRefund?vatRefund:"--/-"} */}
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            <p>Total Payable </p>
                          </Col>
                          <Col md={6} className="rightaligncss">
                            {refundInput
                              ? parseFloat(refundInput) +
                                parseFloat((refundInput * 15) / 100)
                              : "--/-"}
                            {/* {totalRefund? (Number(totalRefund +vatRefund).toFixed(2)) :"-/--"} */}
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      ""
                    )}
                  </Accordion>
                ) : !statusPaidByEmail ? (
                  <Button
                    // onClick={handleMarkPaid}
                    onClick={markedpaidFunc}
                    color="success"
                    className="waves-effect waves-light me-1 w-100 mt-3 invoicesdetailbuttons"
                  >
                    Mark as Paid
                  </Button>
                ) : (
                  ""
                )}
                {/* <div className="p-2">
                <Row className="mt-3">
                  <Col md={6}>
                    <p>Sub Total</p>
                  </Col>
                  <Col md={6} className="rightaligncss">
                    {refundInput?refundInput:"--/-"}
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <p>Vat</p>
                  </Col>
                  <Col md={6} className="rightaligncss">
                  {refundInput?(refundInput*15)/100:"--/-"}
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                  <p>Total Payable </p>
                  </Col>
                  <Col md={6} className="rightaligncss">
                    {refundInput?(parseFloat(refundInput)+parseFloat((refundInput*15)/100)):"--/-"}
                  </Col>
                </Row>
                </div> */}
              </div>
            </Col>
          </Row>
          <div style={{ display: "none" }}>
            <div ref={componentRef}>
              <InvoicesDetailPrint bookings={InvoiceData} />
            </div>
          </div>

          {/* // ****model for mark as paid ********** */}

          <Modal isOpen={modal_static1} toggle={tog_static1} centered={true}>
            <ModalBody>
              <Row>
                <Col md={3}></Col>
                <Col md={9}>
                  <p className="markPaid_p1"> Authorization Required</p>
                </Col>
              </Row>

              <Row>
                <Col md={2}></Col>
                <Col md={8}>
                  <p className="markPaid_p2">
                    We have send you a verification email OTP on your
                    administrative email
                    <span className="markPaid_p3 mx-2">
                      administrator@diom.sa.
                    </span>
                    Please enter thec code.
                  </p>
                </Col>
                <Col md={2}></Col>
              </Row>

              <Row className="my-3">
                <Col md={2}></Col>
                <Col md={6}>
                  <Input
                    placeholder="Enter code here"
                    value={otpState}
                    onChange={(e) => {
                      setOtpState(e?.target?.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Button className="markPaid_p5 ">
                    <p className="markPaid_p4">Resend</p>
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col md={2}></Col>
                <Col md={10}>
                  {" "}
                  <span className="markPaid_p6">
                    Choose payment method (Optional)
                  </span>
                  <Input type="radio" checked={true} className="custom-radio" />
                  <span className="mx-2 markPaid_p7">
                    <span className="markPaid_p7">Manual payment</span>
                  </span>
                </Col>
              </Row>

              <Row className="mt-4 mb-2">
                <Col md={1}></Col>

                <Col md={5}>
                  <Button
                    color="success"
                    outline
                    className="waves-effect waves-light w-100"
                    onClick={() => setModal_static1(false)}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col md={5}>
                  <Button
                    color="success"
                    className="waves-effect waves-light  w-100"
                    onClick={handleMarkPaid}
                  >
                    Mark as paid
                  </Button>
                </Col>

                <Col md={2}></Col>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      )}
    </>
  );
}
// }
export default InvoicesDetailPage;
