import React, { useState, useRef, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import {
  getLocationsOperationalHours,
  getoperationHoursBrand,
  getResourcetypeOperationalHours,
} from "../../../APIS/operationalHours";
import { DIOM_BASED_URLS } from "../../../config/url";
import { useAllDataQuery } from "../../../hooks/query";
import { toast } from "react-toastify";
import { isArray } from "lodash";

export const UseOperationalHours = () => {
  const selectInputRefResourceType = useRef("");
  const selectInputRefLocations = useRef("");
  const queryClient = useQueryClient();
  const token = localStorage.getItem("Token");
  const [dayTimeValue, setDayTimeValue] = useState({});
  const [brandValues, setBrandValues] = useState([]);
  const [locationApplyFilter, setLocationApplyFilter] = useState(false);
  const [locationValues, setLocationValues] = useState([]);
  const [categoryDropDown, setCategoryDropDown] = useState([]);
  const [resourcetypeApplyFilter, setResourcetypeApplyFilter] = useState(false);
  const [resourceTypesValues, setResourceTypesValues] = useState([]);
  const [activeOnWholeday, setActiveOnWholeday] = useState(true);
  const [modal_static1, setModal_static1] = useState(false);
  const [modal_static3, setModal_static3] = useState(false);
  const [modal_static4, setModal_static4] = useState(false);
  const [revokeReason, setRevokeReason] = useState("");
  const [editDisable, setEditDisable] = useState(false);
  const [daysActiveStutus, setDaysActiveStutus] = useState([]);
  const [resetBtn, setResetBtn] = useState(true);
  const [saveBtnDisable, setSaveBtnDisable]=useState(true);
  const [operationalHourHidden, setOperationalHourHidden] = useState(false);
  const [operationalHours, setOperationalHours] = useState(true);



  // ********** initial disabled states (activInactivestate)***************
  const [activInactivestate, setActivInactivestate] = useState({
    sunday: true,
    monday: true,
    tuesday: true,
    wednessday: true,
    thursday: true,
    wednessday: true,
    thursday: true,
    friday: true,
    saturday: true,
  });

  const timeOptions = [
    { value: 0, label: "12:00 A.M" },
    { value: 30, label: "12:30 A.M" },
    { value: 60, label: "01:00 A.M" },
    { value: 90, label: "01:30 A.M" },
    { value: 120, label: "02:00 A.M" },
    { value: 150, label: "02:30 A.M" },
    { value: 180, label: "03:00 A.M" },
    { value: 210, label: "03:30 A.M" },
    { value: 240, label: "04:00 A.M" },
    { value: 270, label: "04:30 A.M" },
    { value: 300, label: "05:00 A.M" },
    { value: 330, label: "05:30 A.M" },
    { value: 360, label: "06:00 A.M" },
    { value: 390, label: "06:30 A.M" },
    { value: 420, label: "07:00 A.M" },
    { value: 450, label: "07:30 A.M" },
    { value: 480, label: "08:00 A.M" },
    { value: 510, label: "08:30 A.M" },
    { value: 540, label: "09:00 A.M" },
    { value: 570, label: "09:30 A.M" },
    { value: 600, label: "10:00 A.M" },
    { value: 630, label: "10:30 A.M" },
    { value: 660, label: "11:00 A.M" },
    { value: 690, label: "11:30 A.M" },
    { value: 720, label: "12:00 P.M" },
    { value: 750, label: "12:30 P.M" },
    { value: 780, label: "01:00 P.M" },
    { value: 810, label: "01:30 P.M" },
    { value: 840, label: "02:00 P.M" },
    { value: 870, label: "02:30 P.M" },
    { value: 900, label: "03:00 P.M" },
    { value: 930, label: "03:30 P.M" },
    { value: 960, label: "04:00 P.M" },
    { value: 990, label: "04:30 P.M" },
    { value: 1020, label: "05:00 P.M" },
    { value: 1050, label: "05:30 P.M" },
    { value: 1080, label: "06:00 P.M" },
    { value: 1110, label: "06:30 P.M" },
    { value: 1140, label: "07:00 P.M" },
    { value: 1170, label: "07:30 P.M" },
    { value: 1200, label: "08:00 P.M" },
    { value: 1230, label: "08:30 P.M" },
    { value: 1260, label: "09:00 P.M" },
    { value: 1290, label: "09:30 P.M" },
    { value: 1320, label: "10:00 P.M" },
    { value: 1350, label: "10:30 P.M" },
    { value: 1380, label: "11:00 P.M" },
    { value: 1410, label: "11:30 P.M" },
    { value: 1425, label: "11:45 P.M" },
  ];

  function Offsymbolhours(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }

  function OnSymbolhours(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }
  function Offsymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }

  function OnSymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const tog_static1 = () => {
    setModal_static1(!modal_static1);

    removeBodyCss();
  };

  const tog_static3 = () => {
    setModal_static3(!modal_static3);
    removeBodyCss();
  };

  const tog_static4 = () => {
    setModal_static4(!modal_static4);
    removeBodyCss();
  };

  const datePickerUntillSunday = (e) => {
    setSaveBtnDisable(false)
    if (e.value > dayTimeValue?.sundayOpenTime) {
      setDayTimeValue({ ...dayTimeValue, sundayCloseTime: e.value });
    } else {
      toast.error("Untill time should be greater than from start time");
    }
  };
  const datePickerFromSunday = (e) => {
    setSaveBtnDisable(false)

    setDayTimeValue({ ...dayTimeValue, sundayOpenTime: e.value });
    if (e.value > dayTimeValue?.sundayCloseTime) {
      toast.error("Start time should be less than from Untill time");
    }
  };

  const datePickerFromMonday = (e) => {
    setSaveBtnDisable(false)
    setDayTimeValue({ ...dayTimeValue, mondayOpenTime: e.value });
    if (e.value > dayTimeValue?.mondayCloseTime) {
      toast.error("Start time should be less than from Untill time");
    }
  };
  const datePickerUntillMonday = (e) => {
    setSaveBtnDisable(false)
    if (e.value > dayTimeValue?.mondayOpenTime) {
      setDayTimeValue({ ...dayTimeValue, mondayCloseTime: e.value });
    } else {
      toast.error("Untill time should be greater than from start time");
    }
  };

  const datePickerFromTuesday = (e) => {
    setSaveBtnDisable(false)
    if (e.value > dayTimeValue?.tuesdayCloseTime) {
      toast.error("Start time should be less than from Untill time");
    }
    setDayTimeValue({ ...dayTimeValue, tuesdayOpenTime: e.value });
  };
  const datePickerUntillTuesday = (e) => {
    setSaveBtnDisable(false)
    if (e.value > dayTimeValue?.tuesdayOpenTime) {
      setDayTimeValue({ ...dayTimeValue, tuesdayCloseTime: e.value });
    } else {
      toast.error("Untill time should be greater than from start time");
    }
  };
  const datePickerFromWednessday = (e) => {
    setSaveBtnDisable(false)
    if (e.value > dayTimeValue?.wednesdayCloseTime) {
      toast.error("Start time should be less than from Untill time");
    }
    setDayTimeValue({ ...dayTimeValue, wednesdayOpenTime: e.value });
  };
  const datePickerUntillWednessday = (e) => {
    setSaveBtnDisable(false)
    if (e.value > dayTimeValue?.wednesdayOpenTime) {
      setDayTimeValue({ ...dayTimeValue, wednesdayCloseTime: e.value });
    } else {
      toast.error("Untill time should be greater than from start time");
    }
  };

  const datePickerFromThursday = (e) => {
    setSaveBtnDisable(false)
    if (e.value > dayTimeValue?.thursdayCloseTime) {
      toast.error("Start time should be less than from Untill time");
    }
    setDayTimeValue({ ...dayTimeValue, thursdayOpenTime: e.value });
  };
  const datePickerUntillThursday = (e) => {
    setSaveBtnDisable(false)
    if (e.value > dayTimeValue?.thursdayOpenTime) {
      setDayTimeValue({ ...dayTimeValue, thursdayCloseTime: e.value });
    } else {
      toast.error("Untill time should be greater than from start time");
    }
  };
  const datePickerFromFriday = (e) => {
    setSaveBtnDisable(false)
    if (e.value > dayTimeValue?.fridayCloseTime) {
      toast.error("Start time should be less than from Untill time");
    }
    setDayTimeValue({ ...dayTimeValue, fridayOpenTime: e.value });
  };
  const datePickerUntillFriday = (e) => {
    setSaveBtnDisable(false)
    if (e.value > dayTimeValue?.fridayOpenTime) {
      setDayTimeValue({ ...dayTimeValue, fridayCloseTime: e.value });
    } else {
      toast.error("Untill time should be greater than from start time");
    }
  };
  const datePickerFromSaturday = (e) => {
    setSaveBtnDisable(false)
    if (e.value > dayTimeValue?.saturdayCloseTime) {
      toast.error("Start time should be less than from Untill time");
    }
    setDayTimeValue({ ...dayTimeValue, saturdayOpenTime: e.value });
  };
  const datePickerUntillSaturday = (e) => {
    setSaveBtnDisable(false)
    if (e.value > dayTimeValue?.saturdayOpenTime) {
      setDayTimeValue({ ...dayTimeValue, saturdayCloseTime: e.value });
    } else {
      toast.error("Untill time should be greater than from start time");
    }
  };
  const diomLocationHandler = async (e) => {
    selectInputRefResourceType?.current.select.clearValue();
    setLocationValues([e?.value]);
    queryClient.invalidateQueries("resourceTypedropdownoperationalHpours");
    setResourcetypeApplyFilter(true);
    getSelectedLocations1(e?.value);
  };
  const revokeReasonText = (e) => {
    setRevokeReason(e.target.value);
  };

  const getSelectedLocations1 = async (e) => {
    const lValues = [e]?.flat(2);
    const response = await fetch(
      `${DIOM_BASED_URLS}/admin-business-locations?filter={"where":{"id":{"inq":${JSON.stringify(
        lValues
      )}}}}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    const data = await response.json();
    setTimeout(() => {
      setDayTimeValue(data[0]);
      setDaysActiveStutus({
        sunday: !data[0]?.sundayClosed,
        monday: !data[0]?.mondayClosed,
        tuesday: !data[0]?.tuesdayClosed,
        wednessday: !data[0]?.wednesdayClosed,
        thursday: !data[0]?.thursdayClosed,
        friday: !data[0]?.fridayClosed,
        saturday: !data[0]?.saturdayClosed,
      });

      setActivInactivestate({
        sunday: data[0]?.sundayClosed,
        monday: data[0]?.mondayClosed,
        tuesday: data[0]?.tuesdayClosed,
        wednessday: data[0]?.wednesdayClosed,
        thursday: data[0]?.thursdayClosed,
        friday: data[0]?.fridayClosed,
        saturday: data[0]?.saturdayClosed,
      });
      // }
    }, 1000);
  };

  const diomBrandHandler = (e) => {
    setOperationalHours(false);
    setResetBtn(false);
    setBrandValues([e.value]);
    setLocationApplyFilter(true);
    selectInputRefLocations?.current.select.clearValue();
    getSelectedBrand(token, e.value);
  };
  // Im calling this fn to
  // diomBrandHandler();
  const getSelectedBrand = async (token, e) => {
    const response = await fetch(
      `${DIOM_BASED_URLS}/admin-location-categories/${e}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    const data = await response.json();

    setTimeout(() => {
      setDayTimeValue(data);
      setDaysActiveStutus({
        sunday: !data?.sundayClosed,
        monday: !data?.mondayClosed,
        tuesday: !data?.tuesdayClosed,
        wednessday: !data?.wednesdayClosed,
        thursday: !data?.thursdayClosed,
        friday: !data?.fridayClosed,
        saturday: !data?.saturdayClosed,
      });

      setActivInactivestate({
        sunday: data?.sundayClosed,
        monday: data?.mondayClosed,
        tuesday: data?.tuesdayClosed,
        wednessday: data?.wednesdayClosed,
        thursday: data?.thursdayClosed,
        friday: data?.fridayClosed,
        saturday: data?.saturdayClosed,
      });
      // }
    }, 1000);
  };
  //  ***********resourcetype selection*********

  const resourceTypeHandler = (e) => {
    const resourceTypeValue = e.map((element) => {
      return element.value;
    });
    setResourceTypesValues({
      ...resourceTypesValues,
      resourceTypeId: resourceTypeValue,
    });
    const resourceTypeValue1 = e.map((element) => {
      return element.value;
    });
    getSelectedResourceType(token, resourceTypeValue1);
  };
  const getSelectedResourceType = async (token, e) => {
    let allDataValues = isArray(e[0]) ? e[0] : e;

    const response = await fetch(
      `${DIOM_BASED_URLS}/admin-resource-types-inventories?filter={"where":{"id":{"inq":${JSON.stringify(
        // e
        allDataValues
      )}}}}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    const res1 = await response.json();
    const data = res1?.data;

    const data1 = data?.map((item) => {
      return {
        sundayClosed: !item?.sundayClosed,
        mondayClosed: !item?.mondayClosed,
        tuesdayClosed: !item?.tuesdayClosed,
        wednesdayClosed: !item?.wednesdayClosed,
        thursdayClosed: !item?.thursdayClosed,
        fridayClosed: !item?.fridayClosed,
        saturdayClosed: !item?.saturdayClosed,
        sundayOpenTime: item?.sundayOpenTime,
        sundayCloseTime: item?.sundayCloseTime,
        mondayOpenTime: item?.mondayOpenTime,
        mondayCloseTime: item?.mondayCloseTime,
        tuesdayOpenTime: item?.tuesdayOpenTime,
        tuesdayCloseTime: item?.tuesdayCloseTime,
        wednesdayOpenTime: item?.wednesdayOpenTime,
        wednesdayCloseTime: item?.wednesdayCloseTime,
        thursdayOpenTime: item?.thursdayOpenTime,
        thursdayCloseTime: item?.thursdayCloseTime,
        fridayOpenTime: item?.fridayOpenTime,
        fridayCloseTime: item?.fridayCloseTime,
        saturdayOpenTime: item?.saturdayOpenTime,
        saturdayCloseTime: item?.saturdayCloseTime,
      };
    });

    // const isEqual =
    //   data1?.length &&
    //   !!data1?.reduce(function (a, b) {
    //     console.log("data a",a ,"data of b",b)
    //     return JSON.stringify(a) === JSON.stringify(b)
    //       ? JSON.stringify(a)
    //       : NaN;
    //   });

    // let isEqual = true
    // for (const currentIndex in data1)
    // {
    //   const nextIndex = parseInt(currentIndex) + 1
    //   if(data1[nextIndex])
    //   {
    //     isEqual =  JSON.stringify(data1[currentIndex]) === JSON.stringify(data1[nextIndex]) ? true : false
    //   }
    // }
    // const allequal = data1 => {
    let isEqual = false;

    isEqual =
      data1?.length === 1
        ? true
        : data1.every(
            (val) => JSON.stringify(val) === JSON.stringify(data1[0])
          );

    if (isEqual === true) {
      setEditDisable(false);
      setOperationalHourHidden(false);

      setDayTimeValue(data1[0]);

      setDaysActiveStutus({
        sunday: data1[0]?.sundayClosed,
        monday: data1[0]?.mondayClosed,
        tuesday: data1[0]?.tuesdayClosed,
        wednessday: data1[0]?.wednesdayClosed,
        thursday: data1[0]?.thursdayClosed,
        friday: data1[0]?.fridayClosed,
        saturday: data1[0]?.saturdayClosed,
      });
      // active inactave state ***
      setActivInactivestate({
        sunday: !data1[0]?.sundayClosed,
        monday: !data1[0]?.mondayClosed,
        tuesday: !data1[0]?.tuesdayClosed,
        wednessday: !data1[0]?.wednesdayClosed,
        thursday: !data1[0]?.thursdayClosed,
        friday: !data1[0]?.fridayClosed,
        saturday: !data1[0]?.saturdayClosed,
      });
    } else {
      // setOperationalHourHidden(true);
      tog_static3();
      // setEditDisable(true);
      // setDaysActiveStutus({
      //   sunday: false,
      //   monday: false,
      //   tuesday: false,
      //   wednessday: false,
      //   thursday: false,
      //   friday: false,
      //   saturday: false,
      // });
      // // active instave state ***
      // setActivInactivestate({
      //   sunday: true,
      //   monday: true,
      //   tuesday: true,
      //   wednessday: true,
      //   thursday: true,
      //   friday: true,
      //   saturday: true,
      // });
      setEditDisable(false);
      //turn false down
      setOperationalHourHidden(true);

      setDayTimeValue(data1[0]);

      setDaysActiveStutus({
        sunday: data1[0]?.sundayClosed,
        monday: data1[0]?.mondayClosed,
        tuesday: data1[0]?.tuesdayClosed,
        wednessday: data1[0]?.wednesdayClosed,
        thursday: data1[0]?.thursdayClosed,
        friday: data1[0]?.fridayClosed,
        saturday: data1[0]?.saturdayClosed,
      });
      // active inactave state ***
      setActivInactivestate({
        sunday: !data1[0]?.sundayClosed,
        monday: !data1[0]?.mondayClosed,
        tuesday: !data1[0]?.tuesdayClosed,
        wednessday: !data1[0]?.wednesdayClosed,
        thursday: !data1[0]?.thursdayClosed,
        friday: !data1[0]?.fridayClosed,
        saturday: !data1[0]?.saturdayClosed,
      });
    }
  };

  // Active fns
  const mondayActiveFunc = (e) => {
    setSaveBtnDisable(false)
    if (e === true) {
      setActivInactivestate({ ...activInactivestate, monday: false });
      setDaysActiveStutus({ ...daysActiveStutus, monday: e });
        } else {
      setActivInactivestate({ ...activInactivestate, monday: true });
      setDaysActiveStutus({ ...daysActiveStutus, monday: e });
    }
  };
  const tuesdayActiveFunc = (e) => {
    setSaveBtnDisable(false)
    if (e === true) {
      setActivInactivestate({ ...activInactivestate, tuesday: false });
      setDaysActiveStutus({ ...daysActiveStutus, tuesday: e });
       } else {
      setActivInactivestate({ ...activInactivestate, tuesday: true });
      setDaysActiveStutus({ ...daysActiveStutus, tuesday: e });
    }
  };
  const wednessdayActiveFunc = (e) => {
    setSaveBtnDisable(false)
    if (e === true) {
      setActivInactivestate({ ...activInactivestate, wednessday: false });
      setDaysActiveStutus({ ...daysActiveStutus, wednessday: e });
    } else {
      setActivInactivestate({ ...activInactivestate, wednessday: true });
      setDaysActiveStutus({ ...daysActiveStutus, wednessday: e });
    }
  };
  const thursdayActiveFunc = (e) => {
    setSaveBtnDisable(false)
    if (e === true) {
      setActivInactivestate({ ...activInactivestate, thursday: false });
      setDaysActiveStutus({ ...daysActiveStutus, thursday: e });
    } else {
      setActivInactivestate({ ...activInactivestate, thursday: true });
      setDaysActiveStutus({ ...daysActiveStutus, thursday: e });
    }
  };
  const fridayActiveFunc = (e) => {
    setSaveBtnDisable(false)
    if (e === true) {
      setActivInactivestate({ ...activInactivestate, friday: false });
      setDaysActiveStutus({ ...daysActiveStutus, friday: e });
    } else {
      setActivInactivestate({ ...activInactivestate, friday: true });
      setDaysActiveStutus({ ...daysActiveStutus, friday: e });
    }
  };
  const saturdayActiveFunc = (e) => {
    setSaveBtnDisable(false)
    if (e === true) {
      setActivInactivestate({ ...activInactivestate, saturday: false });
      setDaysActiveStutus({ ...daysActiveStutus, saturday: e });
    } else {
      setActivInactivestate({ ...activInactivestate, saturday: true });
      setDaysActiveStutus({ ...daysActiveStutus, saturday: e });
    }
  };
  const sundayActiveFunc = (e) => {
    setSaveBtnDisable(false)
    if (e === true) {
      setActivInactivestate({ ...activInactivestate, sunday: false });
      setDaysActiveStutus({ ...daysActiveStutus, sunday: e });
    } else {
      setActivInactivestate({ ...activInactivestate, sunday: true });
      setDaysActiveStutus({ ...daysActiveStutus, sunday: e });
    }
  };

  // Whole Day fn
  const saturdayWholeDayFunc = (e) => {
    setSaveBtnDisable(false)
    if (e === true) {
      setActiveOnWholeday({ ...activeOnWholeday, saturday: true });
      setDayTimeValue({
        ...dayTimeValue,
        saturdayOpenTime: 0,
        saturdayCloseTime: 1440,
      });
    } else {
      setActiveOnWholeday({ ...activeOnWholeday, saturday: false });
    }
  };

  const sundayWholeDayFunc = (e) => {
    setSaveBtnDisable(false)

    if (e === true) {
      setDayTimeValue({
        ...dayTimeValue,
        sundayOpenTime: 0,
        sundayCloseTime: 1440,
      });
      setActiveOnWholeday({ ...activeOnWholeday, sunday: true });
    } else {
      setActiveOnWholeday({ ...activeOnWholeday, sunday: false });
    }
  };


  const mondayWholeDayFunc = (e) => {
    setSaveBtnDisable(false)
    if (e === true) {
      setActiveOnWholeday({ ...activeOnWholeday, monday: true });
      setDayTimeValue({
        ...dayTimeValue,
        mondayOpenTime: 0,
        mondayCloseTime: 1440,
      });
    } else {
      setActiveOnWholeday({ ...activeOnWholeday, monday: false });
    }
  };

  const tuesdayWholeDayFunc = (e) => {
    setSaveBtnDisable(false)
    if (e === true) {
      setDayTimeValue({
        ...dayTimeValue,
        tuesdayOpenTime: 0,
        tuesdayCloseTime: 1440,
      });
      setActiveOnWholeday({ ...activeOnWholeday, tuesday: true });
    } else {
      setActiveOnWholeday({ ...activeOnWholeday, tuesday: false });
    }
  };
  const wednessdayWholeDayFunc = (e) => {
    setSaveBtnDisable(false)
    if (e === true) {
      setActiveOnWholeday({ ...activeOnWholeday, wednessday: true });
      setDayTimeValue({
        ...dayTimeValue,
        wednessdayOpenTime: 0,
        wednessdayCloseTime: 1440,
      });
    } else {
      setActiveOnWholeday({ ...activeOnWholeday, wednessday: false });
    }
  };
  const thursdayWholeDayFunc = (e) => {
    setSaveBtnDisable(false)
    if (e === true) {
      setActiveOnWholeday({ ...activeOnWholeday, thursday: true });
      setDayTimeValue({
        ...dayTimeValue,
        thursdayOpenTime: 0,
        thursdayCloseTime: 1440,
      });
    } else {
      setActiveOnWholeday({ ...activeOnWholeday, thursday: false });
    }
  };
  const fridayWholeDayFunc = (e) => {
    setSaveBtnDisable(false)
    if (e === true) {
      setActiveOnWholeday({ ...activeOnWholeday, friday: true });
      setDayTimeValue({
        ...dayTimeValue,
        fridayOpenTime: 0,
        fridayCloseTime: 1440,
      });
    } else {
      setActiveOnWholeday({ ...activeOnWholeday, friday: false });
    }
  };
  // **************Brands Get****************
  // *******************
  const { data: brandData } = useAllDataQuery(
    ["reportlocationbrandData", "visible"],
    () => getoperationHoursBrand(token)
  );

  // *************Locations**********

  // const { data: locationsData, isLoading: loadingLocations } = useAllDataQuery(
  const { data: locationsData, isLoading: loadingLocations } = useQuery(
    ["brandValuesFunc", brandValues],
    () => getLocationsOperationalHours(token, locationApplyFilter, brandValues)
  );
  const locationData = locationsData;
  // *******************

  // *************resourcetypes**********
  const resourceTypedropdownData = useQuery(
    ["resourceTypedropdownoperationalHpours", locationValues],
    () =>
      getResourcetypeOperationalHours(
        token,
        resourcetypeApplyFilter,
        locationValues
      )
  );

  const resourceTypedropdownDailedData = resourceTypedropdownData.data;

  // *************
  const Resourcetypesdropdown = async () => {
    // Adding the option as ALL  in first Index
    const result = resourceTypedropdownDailedData.data.length > 0;
    {
      resourceTypedropdownDailedData.data.unshift({
        id: resourceTypedropdownDailedData.data.map((e) => e?.id),
        Name: "All",
        isAvailableInWnpl: true,
        startTime: "0",
        endTime: "0",
      });
    }
    const matadata = resourceTypedropdownDailedData.data.map((element) => {
      return {
        label: element.Name,
        value: element.id,

        UniqueId: element.UniqueId,
        resourceTypeCategoryId: element.resourceTypeCategoryId,
        // visibility: element.visibility,
      };
    });

    setCategoryDropDown(matadata);
  };

  useEffect(
    // FOR PRE-FETCHING NEXT PAGE
    async () => {
      resourceTypedropdownDailedData && (await Resourcetypesdropdown());
    },
    [resourceTypedropdownDailedData]
  );
  useEffect(async () => {}, [daysActiveStutus]);

  // **************

  const operationHoursSaveFunc = () => {
    fetch(`${DIOM_BASED_URLS}/operational-hours`, {
      method: "PATCH",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify({
        revokeMessage: revokeReason,
        // locationBrands: brandData,
        locationBrands: brandValues,
        businesses: typeof locationValues[0] !== "number" ? [] : locationValues,
        resourceTypes:
          resourceTypesValues?.resourceTypeId ??
          resourceTypesValues?.resourceTypeId?.flat(2),
        operationsHours: {
          mondayOpenTime: dayTimeValue?.mondayOpenTime,
          mondayCloseTime: dayTimeValue?.mondayCloseTime,
          tuesdayOpenTime: dayTimeValue?.tuesdayOpenTime,
          tuesdayCloseTime: dayTimeValue?.tuesdayCloseTime,
          wednesdayOpenTime: dayTimeValue?.wednesdayOpenTime,
          wednesdayCloseTime: dayTimeValue?.wednesdayCloseTime,
          thursdayOpenTime: dayTimeValue?.thursdayOpenTime,
          thursdayCloseTime: dayTimeValue?.thursdayCloseTime,
          fridayOpenTime: dayTimeValue?.fridayOpenTime,
          fridayCloseTime: dayTimeValue?.fridayCloseTime,
          saturdayOpenTime: dayTimeValue?.saturdayOpenTime,
          saturdayCloseTime: dayTimeValue?.saturdayCloseTime,
          sundayOpenTime: dayTimeValue?.sundayOpenTime,
          sundayCloseTime: dayTimeValue?.sundayCloseTime,
          mondayClosed: !daysActiveStutus?.monday,
          tuesdayClosed: !daysActiveStutus?.tuesday,
          wednesdayClosed: !daysActiveStutus?.wednessday,
          thursdayClosed: !daysActiveStutus?.thursday,
          fridayClosed: !daysActiveStutus?.friday,
          saturdayClosed: !daysActiveStutus?.saturday,
          sundayClosed: !daysActiveStutus?.sunday,
        },
      }),
    })
      .then((response) => response.json())
      .then((result3) => {
        setModal_static1(false);
        if (result3?.statusCode === 200) {
          setModal_static1(false);
          toast.success(result3?.message);
          queryClient.invalidateQueries(
            "resourceTypedropdownoperationalHpours"
          );
          queryClient.invalidateQueries("locationsOperationalHours1");
          queryClient.invalidateQueries("reportlocationbrandData");
        }
        //  else if (result3?.statusCode === 204) {
        //   setModal_static1(false)
        //   toast.success(result3?.message);
        //   queryClient.invalidateQueries("resourceTypedropdownoperationalHpours");
        //   queryClient.invalidateQueries("locationsOperationalHours1");
        //   queryClient.invalidateQueries("reportlocationbrandData");
        // }
        // else if (result3?.status.ok){
        //   toast.success("refund Added");
        // }
        else {
          setModal_static1(false);
          toast.error(result3?.error?.message);
        }
      })
      .catch((error) => toast.error("Error: ", error));
  };

  return {
    operationalHourHidden,
    resetBtn,
    setResetBtn,
    setModal_static4,
    modal_static4,
    tog_static4,
    setModal_static3,
    modal_static3,
    tog_static3,
    tog_static1,
    revokeReasonText,
    saturdayWholeDayFunc,
    sundayWholeDayFunc,
    mondayWholeDayFunc,
    tuesdayWholeDayFunc,
    wednessdayWholeDayFunc,
    thursdayWholeDayFunc,
    fridayWholeDayFunc,
    modal_static1,
    setModal_static1,
    saturdayActiveFunc,
    fridayActiveFunc,
    thursdayActiveFunc,
    wednessdayActiveFunc,
    tuesdayActiveFunc,
    mondayActiveFunc,
    sundayActiveFunc,
    daysActiveStutus,
    Offsymbol,
    OnSymbol,
    Offsymbolhours,
    OnSymbolhours,
    datePickerFromMonday,
    datePickerUntillMonday,
    datePickerUntillSaturday,
    datePickerFromSaturday,
    datePickerUntillFriday,
    datePickerUntillSunday,
    datePickerFromSunday,
    datePickerFromFriday,
    datePickerUntillThursday,
    datePickerFromThursday,
    datePickerUntillWednessday,
    datePickerFromWednessday,
    datePickerUntillTuesday,
    datePickerFromTuesday,
    timeOptions,
    brandData,
    locationData,
    diomBrandHandler,
    setOperationalHours,
    operationalHours,
    resourceTypedropdownData,
    categoryDropDown,
    diomLocationHandler,
    operationHoursSaveFunc,
    resourceTypeHandler,
    activeOnWholeday,
    activInactivestate,
    dayTimeValue,
    editDisable,
    resourceTypesValues,
    selectInputRefResourceType,
    selectInputRefLocations,
    setOperationalHourHidden,
    brandValues,
    locationValues,
    saveBtnDisable, 
    setSaveBtnDisable
  };
};

