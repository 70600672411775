import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Input,
  ModalHeader,
  ModalBody,
  Modal,
  Spinner,
  Label,
} from "reactstrap";
import UnableToDel from "./../../.././assets/images/team/unableToDel.svg";
import DelMem from "./../../.././assets/images/team/deleteMem.svg";
import deleteLogo from "./../../.././assets/images/team/baseline-delete-logo.svg";
import Switch from "react-switch";
import { Link } from "react-router-dom";
import UseEditTeam from "./useEditTeam";
import ".././css/styles.css";
import UserPermissionFetcher from "../../../helpers/getUserPerimissions";
import Loader from "react-spinners/BounceLoader";
import NoAccess from "../../../components/Common/NoAccess";

const EditTeam = () => {
  const userPermissions = UserPermissionFetcher("teams");

  const {
    errMsg,
    canDeleteTeam,
    delButtonClicked,
    delMmemberFunc,
    updateAdminFunc,
    newMemberEmail,
    setNewMemberEmail,
    updateTeamMmeber,
    setDelCheck1,
    setDelCheck2,
    setDelCheck3,
    delCheck1,
    delCheck2,
    delCheck3,
    delLoading,
    deleteTeamFunc,
    modal_static2,
    setModal_static2,
    tog_static2,
    createLoading,
    teamExist,
    handleSearch,
    createTeamFunc,
    setModal_static1,
    tog_static1,
    modal_static1,
    changeAdmin,
    Offsymbol,
    OnSymbol,
    tog_static,
    modal_static,
    setModal_static,
    setTeamName,
    setTeamExist,
    teamName,
    teamArr,
    initialTeamName,
  } = UseEditTeam();

  // Empty dependency array means this effect runs once when the component mounts

  return (
    <>
      {userPermissions === null ? (
        <Loader />
      ) : userPermissions.update ? (
        <div className="page-content">
          <Row className="mb-3">
            <Col md={4}>
              <Link to="/teams" className="link">
                <span className="fas fa-angle-left arrowheightwidth"></span>
              </Link>
              <span className="locationbrandtitle  ">{`${initialTeamName}`}</span>
            </Col>

            <Col md={2}></Col>
            <Col md={2}></Col>

            <Col md={2}>
              {userPermissions.delete && (
                <Button
                  color="success"
                  outline
                  className="waves-effect waves-light mr-1  w-100"
                  block
                  onClick={() => {
                    delButtonClicked();
                  }}
                >
                  <span className="printbutton">Delete Team</span>
                </Button>
              )}
            </Col>

            <Col md={2}>
              <Button
                disabled={teamExist}
                color="success"
                className="waves-effect waves-light mr-1  w-100"
                block
                outline
                onClick={() => {
                  // setModal_static1(true);
                  createTeamFunc();
                }}
              >
                <span className="printbutton">Save</span>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <div className="d-flex justify-content-center p-5">
                  <div className="ct_c1 p-3">
                    <p className="ct_c2 mt-3 mx-3">
                      {teamName
                        ? teamName?.substring(0, 2)?.toUpperCase()
                        : "PD"}
                    </p>
                  </div>
                </div>
                <Row className="mb-4">
                  <Col md={4}></Col>
                  <Col md={4}>
                    <div>
                      <Input
                        type="search"
                        className={
                          !teamExist ? `searchTeamcss` : `searchTeamcssfalse`
                        }
                        value={teamName}
                        onChange={(ele) => {
                          // setTeamName(ele?.target?.value);
                          // setTeamExist(false);
                          const inputValue = ele.target.value;
                          if (inputValue.length <= 29) {
                            // Check if input length is within limit
                            setTeamName(inputValue);
                            setTeamExist(false);
                          }
                        }}
                        onBlur={() => {
                          handleSearch(); // Call the API when the input loses focus
                        }}
                      />
                      {!teamExist ? (
                        ""
                      ) : (
                        <p className="teamExistcss mt-1">
                          {" "}
                          This team is already exist
                        </p>
                      )}
                    </div>
                  </Col>
                  <Col md={4}></Col>
                </Row>
              </Card>
            </Col>
          </Row>

          {teamArr?.length === 0 ? (
            ""
          ) : (
            <Row>
              <Col md={12}>
                <Card className="p-4">
                  <Row>
                    <Col md={7}>
                      <p className="ct_c3">Team admin</p>
                    </Col>

                    <Col md={2}></Col>

                    <Col md={3}>
                      <Button
                        color="success"
                        outline
                        className="waves-effect waves-light mr-1  w-100"
                        block
                        onClick={() => {
                          setModal_static(true);
                        }}
                      >
                        <span className="printbutton">Change team lead</span>
                      </Button>
                    </Col>
                  </Row>

                  {teamArr?.map((item) => {
                    if (item?.role === "teamAdmin") {
                      return (
                        <Row className="mt-3">
                          <Col md={6}>
                            <p className="ct_c4">Name </p>
                            <Input
                              value={
                                item?.name === "No Name No Name"
                                  ? "---"
                                  : item?.name
                              }
                            />
                          </Col>
                          <Col md={6}>
                            <p className="ct_c4">Email address </p>
                            <Input value={item?.email} />
                          </Col>
                        </Row>
                      );
                    }
                  })}

                  <Row>
                    <Col md={7}>
                      <p className="ct_c3 mt-5">Edit team members</p>
                    </Col>
                  </Row>

                  <Row className="">
                    <Row className="mt-2 mb-2">
                      {teamArr?.map((item) => {
                        if (
                          item?.role !== "teamAdmin" &&
                          item?.status !== "removed"
                        ) {
                          return (
                            <Col md={6} className="mb-3">
                              <div className="d-flex justify-content-between">
                                <Label className="ct_c4">
                                  {item?.name === "No Name No Name"
                                    ? "---"
                                    : item?.name}{" "}
                                </Label>
                                <img
                                  src={DelMem}
                                  alt="del"
                                  role="button"
                                  onClick={() => {
                                    delMmemberFunc(item);
                                  }}
                                />{" "}
                              </div>
                              <p className="ct_c4">Email address </p>
                              <Input value={item?.email} />
                            </Col>
                          );
                        }
                      })}
                    </Row>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={7}>
                      <p className="ct_c3 mt-5">Add team members</p>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-2">
                    <Col md={6}>
                      <p className="ct_c4">Email address </p>
                      <Input
                        value={newMemberEmail}
                        onChange={(e) => {
                          setNewMemberEmail(e?.target?.value);
                        }}
                      />
                    </Col>
                    <Col md={4}></Col>

                    <Col md={2}>
                      <Button
                        color="success"
                        onClick={() => {
                          updateTeamMmeber();
                        }}
                        className="w-100 add_t_m_btn"
                      >
                        Add
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          )}

          <div>
            <Modal isOpen={modal_static} toggle={tog_static} centered={true}>
              <Row>
                <Col md={4}></Col>
                <Col md={7}>
                  <ModalHeader toggle={() => setModal_static(false)}>
                    Change team admin
                  </ModalHeader>
                </Col>
                <Col md={1}></Col>
              </Row>

              <ModalBody>
                {teamArr?.map((item, index) => {
                  if (item?.status !== "removed")
                    return (
                      <Row>
                        <Col md={6}>
                          <div className="d-flex">
                            <div className=" d-flex justify-content-center ">
                              <p
                                className={
                                  item.name.substring(0, 1).toUpperCase() >=
                                    "A" &&
                                  item.name.substring(0, 1).toUpperCase() <= "F"
                                    ? "teamNameC4"
                                    : item.name.substring(0, 1).toUpperCase() >=
                                        "G" &&
                                      item.name.substring(0, 1).toUpperCase() <=
                                        "P"
                                    ? "teamNameC3"
                                    : item.name.substring(0, 1).toUpperCase() >=
                                        "Q" &&
                                      item.name.substring(0, 1).toUpperCase() <=
                                        "V"
                                    ? "teamNameC2"
                                    : item.name.substring(0, 1).toUpperCase() >=
                                        "W" &&
                                      item.name.substring(0, 1).toUpperCase() <=
                                        "Z"
                                    ? "teamNameC5"
                                    : ""
                                }
                              >
                                {item.name.substring(0, 2).toUpperCase()}
                              </p>
                            </div>
                            <b className="ct_chng_lead_l1  ">
                              {item?.name === "No Name No Name"
                                ? item?.email
                                : item?.name}
                            </b>
                          </div>
                        </Col>
                        <Col md={4}>
                          {/* <div className='d-flex'> */}

                          {item?.role === "teamAdmin" ? (
                            <p className="ct_chng_lead_l2">
                              Administrator{" "}
                              <span style={{ color: "red" }}>*</span>
                            </p>
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col md={2}>
                          <div className="d-flex justify-content-end">
                            <Switch
                              name="always"
                              onHandleColor="#16b185"
                              width={70}
                              uncheckedIcon={Offsymbol(<small>NO</small>)}
                              checkedIcon={OnSymbol(<small>YES</small>)}
                              onColor="#a2a2a2"
                              checked={
                                item?.role === "teamAdmin" ? true : false
                              }
                              onChange={(e) => changeAdmin(e, item, index)}
                              className="mr-1 mt-1 switchercss "
                            />
                          </div>
                          {/* </div> */}
                        </Col>
                      </Row>
                    );
                })}

                <Row>
                  <Col md={4}> </Col>
                  <Col md={4}>
                    {" "}
                    <Button
                      color="success"
                      outline
                      className="waves-effect waves-light mr-1  w-100"
                      block
                      onClick={() => {
                        updateAdminFunc();
                      }}
                    >
                      <span className="printbutton">Save</span>
                    </Button>{" "}
                  </Col>
                  <Col md={4}> </Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>

          <div>
            <Modal isOpen={modal_static1} toggle={tog_static1} centered={true}>
              <Row>
                <Col md={4}></Col>
                <Col md={7}>
                  <ModalHeader toggle={() => setModal_static1(false)}>
                    Create Team
                  </ModalHeader>
                </Col>
                <Col md={1}></Col>
              </Row>

              <ModalBody>
                <div className="d-flex justify-content-center">
                  <p className="createpopcss_l1">
                    Create Team Are you sure you want to create this team ?
                  </p>
                </div>
                <Row className="mt-2">
                  <Col md={2}></Col>
                  <Col md={4}>
                    <Link to="/teams">
                      {" "}
                      <Button
                        color="success"
                        outline
                        className="w-100"
                        onClick={() => {
                          setModal_static1(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Link>
                  </Col>
                  <Col md={4}>
                    {createLoading ? (
                      <Button color="success" outline className="w-100">
                        <Spinner />
                      </Button>
                    ) : (
                      <Button
                        color="success"
                        className="w-100"
                        onClick={() => createTeamFunc()}
                      >
                        Create
                      </Button>
                    )}
                  </Col>
                  <Col md={2}></Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>

          <div>
            <Modal
              isOpen={modal_static2}
              size="lg"
              toggle={tog_static2}
              centered={true}
            >
              <Row>
                <Col md={10}></Col>
                <Col md={1}>
                  <ModalHeader
                    toggle={() => setModal_static2(false)}
                  ></ModalHeader>
                </Col>
                <Col md={1}></Col>
              </Row>

              <ModalBody>
                <div className=" d-flex justify-content-center">
                  <div className="del_team_c7 ">
                    <img className="del_team_c8" src={deleteLogo} />{" "}
                  </div>
                </div>
                <p className="del_team_l1 mt-3"> Delete team</p>
                <p className="del_team_l2">
                  For deleting all of your team accounts permanently
                </p>
                <p className="del_team_l3">
                  Please agree with the following terms!
                </p>
                <div className="">
                  <Card className="del_team_c1 my-2 p-2">
                    <div className="d-flex align-items-center">
                      <Input
                        className=""
                        type="checkbox"
                        checked={delCheck1}
                        onChange={(e) => {
                          setDelCheck1(!delCheck1);
                        }}
                      />

                      <p className="mx-2  del_team_c2 mt-3">
                        Team will no longer be able to use your remaining
                        credits.
                      </p>
                    </div>
                  </Card>

                  <Card className="del_team_c1 my-2 p-2">
                    <div className="d-flex align-items-center">
                      <Input
                        className=""
                        type="checkbox"
                        checked={delCheck2}
                        onChange={(e) => {
                          setDelCheck2(!delCheck2);
                        }}
                      />

                      <p className="mx-2  del_team_c2 mt-3">
                        Team personal and professional information will be
                        deleted from all of our data storages.
                      </p>
                    </div>
                  </Card>

                  <Card className="del_team_c1 my-2 p-2">
                    <div className="d-flex align-items-center">
                      <Input
                        className=""
                        type="box"
                        checked={delCheck3}
                        onChange={(e) => {
                          setDelCheck3(!delCheck3);
                        }}
                      />

                      <p className="mx-2  del_team_c2 mt-3">
                        Team invoices for past bookings and purchases cannot be
                        deleted because it is prohibited by state laws.
                      </p>
                    </div>
                  </Card>
                  {!canDeleteTeam ? (
                    <Card className="del_team_c4 my-2 p-2 mt-3">
                      <div className="d-flex align-items-center">
                        <img src={UnableToDel} className="team_info_logo" />
                        <p className="del_team_c5 mt-3 mx-2">
                          {" "}
                          Unable to delete!{" "}
                        </p>
                      </div>

                      <p className="del_team_c6 mx-4">
                        {/* Your team must not have any active or scheduled booking to
                    be able to submit this request. */}
                        {errMsg}
                      </p>
                    </Card>
                  ) : (
                    <Card className="del_team_c4 my-2 p-2 mt-3">
                      <div className="d-flex align-items-center">
                        <img src={UnableToDel} className="team_info_logo" />
                        <p className="del_team_c5 mt-3 mx-2"> Warning! </p>
                      </div>
                      <p className="del_team_c6 mx-4">
                        You are about to permanently delete your account and
                        data
                      </p>
                    </Card>
                  )}
                </div>
                <Row className="my-4">
                  <Col md={4}></Col>

                  <Col md={4}>
                    {delLoading ? (
                      <Button
                        disabled={!delCheck1 || !delCheck2 || !delCheck3}
                        color="dark"
                        outline
                        className="w-100"
                      >
                        <Spinner />
                      </Button>
                    ) : (
                      <Button
                        disabled={
                          !delCheck1 ||
                          !delCheck2 ||
                          !delCheck3 ||
                          !canDeleteTeam
                        }
                        color="dark"
                        outline
                        className="w-100"
                        onClick={() => {
                          deleteTeamFunc();
                        }}
                      >
                        Delete Request
                      </Button>
                    )}
                  </Col>
                  <Col md={4}></Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default EditTeam;
