import React from "react";
import { Link, useLocation } from "react-router-dom";
import UseDiscount from "./useDiscount";
import "./css/discount.css";
import { now } from "moment";
import {
  Button,
  Col,
  Row,
  Card,
  TabContent,
  NavLink,
  NavItem,
  Nav,
  TabPane,
  CardBody,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
} from "reactstrap";
import classnames from "classnames";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../Tables/datatables.scss";
import Spinner from "../../../components/Common/Loader";
import UserPromissionFetcher from "../../../helpers/getUserPerimissions";
import NoAccess from "../../../components/Common/NoAccess";
const activeTabJustify = "1";
const { SearchBar } = Search;

const Discount = () => {
  const userPermissions = UserPromissionFetcher("discountCodes");
  const {
    data,
    defaultSorted,
    currentPage,
    pageOptions,
    discountAmountData,
    userdetailsData,
    toggleCustomJustified,
    activeTabJustify,
    setActiveTabJustify,
    userActivebookingsData,
    userPastBookingsData,
    userScheduledBookingsData,
    hasNextPage,
    hasPreviousPage,
    total,
    pastHasNextPage,
    scheduledHasNextPage,
    isLoading,
    pagelengthnum,
    pageSize,
    toggle,
    isOpen,
    setActiveTabState,
    changeCurrentPage,
  } = UseDiscount();

  const columns = [
    {
      dataField: "code",
      text: "Code",
      sort: true,
      style: { width: "20%" },
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
      style: { width: "20%" },
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      style: { width: "20%" },
    },

    {
      dataField: "cumulativeSpentTimeInMinutes",
      text: "Cumulative Time Booked",
      sort: true,
      style: { width: "15%" },
    },
    {
      dataField: "timesUsed",
      text: "Times Used",
      sort: true,
      style: { width: "15%" },
    },

    {
      dataField: "lastUsed",
      text: "Last Used",
      sort: true,
      style: { width: "15%" },
    },
    {
      dataField: "expires",
      text: "Expired",
      sort: true,
      style: { width: "20%" },
    },
    {
      dataField: "bookingType",
      text: "Actions",
      sort: true,
      style: { width: "20%" },
      formatter: (cell, row) =>
        row?.nodata === true ? (
          ""
        ) : userPermissions.update ? (
          <Link to={`/edit_discount/${row?.id}`}>
            <Label className="ds_edit_label" style={{ cursor: "pointer" }}>
              Edit
            </Label>
          </Link>
        ) : (
          <div>
            <Label className="ds_edit_label" style={{ cursor: "pointer" }}>
              Edit
            </Label>
          </div>
        ),
    },
  ];

  console.log(isLoading,">>>>>>>>>>>")
  return (
    <>
      {userPermissions === null ? (
        <Spinner />
      ) : userPermissions.read ? (
        <div className="page-content">
          <div>
            <Row className="mb-4">
              <Col md={4}>
                <span className="bookingtitle ml-4">Discount</span>
              </Col>
              <Col md={6}></Col>
              <Col md={2}>
                <Link to="/discount_log">
                  {" "}
                  <Button
                    // onClick={handlePrint}
                    color="success"
                    className=" mr-1 w-100 "
                    block
                  >
                    <span className="e_a_btns ">Discount Log</span>
                  </Button>
                </Link>
              </Col>
            </Row>

            {/* *******table start******** */}
            <Row>
              <Col md={12}>
                <Card>
                  <div>
                    <Row className="mt-2  ">
                      <Col md={5}>
                        <Nav tabs className="nav-tabs-custom nav-justified">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: activeTabJustify === "1",
                              })}
                              onClick={() => {
                                toggleCustomJustified("1");
                                setActiveTabState("true");
                                // getUseractivebookings();
                              }}
                            >
                              <span className="d-none d-sm-block">Active</span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: activeTabJustify === "2",
                              })}
                              onClick={() => {
                                toggleCustomJustified("2");
                                setActiveTabState("false");
                                // fetchScheduledBookings();
                              }}
                            >
                              <span className="d-none d-sm-block">
                                Inactive
                              </span>
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent activeTab={activeTabJustify}>
                          <TabPane tabId="1" className="p-1"></TabPane>
                          <TabPane tabId="2" className="p-1"></TabPane>
                        </TabContent>
                      </Col>
                      <Col md={3}></Col>
                      <Col md={4}></Col>
                    </Row>
                  </div>
                  <div>
                    {/* {userActiveBookings ? ( */}
                  {isLoading? <Spinner/>:  <Row>
                      <Col className="col-12 ">
                        <Card>
                          <CardBody>
                            <PaginationProvider
                              pagination={paginationFactory(pageOptions)}
                              keyField="id"
                              columns={columns}
                              //   data={
                              //     activeTabJustify === "1"
                              //       ? userActivebookingsData
                              //       : activeTabJustify === "2"
                              //       ? userScheduledBookingsData:""
                              //   }

                              data={
                                discountAmountData ? discountAmountData : data
                              }
                            >
                              {({ paginationProps, paginationTableProps }) => (
                                <ToolkitProvider
                                  keyField="id"
                                  columns={columns}
                                  //   data={
                                  //     activeTabJustify === "1"
                                  //       ? userActivebookingsData
                                  //       : activeTabJustify === "2"
                                  //       ? userScheduledBookingsData:""
                                  //   }
                                  data={
                                    discountAmountData
                                      ? discountAmountData
                                      : data
                                  }
                                  search
                                >
                                  {(toolkitProps) => (
                                    <React.Fragment>
                                      <Row className="mb-2">
                                        <Col md="4">
                                          <div className="search-box me-2 mb-2 d-inline-block">
                                            <div className="position-relative">
                                              <SearchBar
                                                {...toolkitProps.searchProps}
                                              />
                                              <i className="search-box chat-search-box" />
                                            </div>
                                          </div>
                                        </Col>
                                        <Col md="4"></Col>
                                        <Col md="4"></Col>
                                      </Row>

                                      <Row>
                                        <Col xl="12">
                                          <div className="table-responsive">
                                            <BootstrapTable
                                              hover
                                              keyField={"id"}
                                              responsive
                                              bordered={false}
                                              striped={false}
                                              defaultSorted={defaultSorted}
                                              // selectRow={selectRow}
                                              classes={
                                                "table align-middle table-nowrap"
                                              }
                                              headerWrapperClasses={
                                                "thead-light"
                                              }
                                              {...toolkitProps.baseProps}
                                              {...paginationTableProps}
                                            />
                                          </div>
                                        </Col>
                                      </Row>

                                      <Row className="align-items-md-center mt-3">
                                        <Col className="inner-custom-pagination d-flex">
                                          <span className="paginationitmes ">
                                            Items
                                          </span>
                                          <div className="d-inline">
                                            {/* <SizePerPageDropdownStandalone
                                              {...paginationProps}
                                            /> */}
                                            <ButtonDropdown
                                              isOpen={isOpen}
                                              toggle={toggle}
                                              onClick={(e) => pagelengthnum(e)}
                                            >
                                              <DropdownToggle
                                                caret
                                                color="secondary"
                                              >
                                                {pageSize}
                                              </DropdownToggle>
                                              <DropdownMenu>
                                                <DropdownItem value="1">
                                                  10
                                                </DropdownItem>
                                                <DropdownItem value="2">
                                                  30
                                                </DropdownItem>
                                                <DropdownItem />
                                                <DropdownItem value="3">
                                                  50
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </ButtonDropdown>
                                          </div>
                                          <span className="paginationitmes1 ">
                                            show
                                          </span>
                                          <div className="text-md-right ms-auto">
                                            {/* <PaginationListStandalone
                                                {...paginationProps}
                                              /> */}

                                            <Button
                                              color="secondary"
                                              className="waves-effect "
                                              style={{
                                                marginLeft: 7,
                                                marginRight: 7,
                                              }}
                                              disabled={currentPage <= 1}
                                              onClick={() =>
                                                currentPage === 1
                                                  ? null
                                                  : changeCurrentPage(
                                                      (prev) => prev - 1
                                                    )
                                              }
                                            >
                                              <i className="dripicons-chevron-left"></i>
                                            </Button>

                                            <Button
                                              style={{
                                                marginLeft: 7,
                                                marginRight: 7,
                                              }}
                                              color="success"
                                              className="btn-rounded waves-effect waves-light me-1 mr-2 ml-2"
                                            >
                                              {currentPage}
                                            </Button>
                                            {activeTabJustify === "1" ? (
                                              <Button
                                                style={{
                                                  marginLeft: 7,
                                                  marginRight: 7,
                                                }}
                                                color="secondary"
                                                className="waves-effect"
                                                disabled={!hasNextPage}
                                                onClick={() =>
                                                  changeCurrentPage(
                                                    (prev) => prev + 1
                                                  )
                                                }
                                              >
                                                <i className="dripicons-chevron-right"></i>
                                              </Button>
                                            ) : activeTabJustify === "2" ? (
                                              <Button
                                                style={{
                                                  marginLeft: 7,
                                                  marginRight: 7,
                                                }}
                                                color="secondary"
                                                className="waves-effect"
                                                disabled={!scheduledHasNextPage}
                                                onClick={() =>
                                                  changeCurrentPage(
                                                    (prev) => prev + 1
                                                  )
                                                }
                                              >
                                                <i className="dripicons-chevron-right"></i>
                                              </Button>
                                            ) : activeTabJustify === "3" ? (
                                              <Button
                                                style={{
                                                  marginLeft: 7,
                                                  marginRight: 7,
                                                }}
                                                color="secondary"
                                                className="waves-effect"
                                                disabled={!pastHasNextPage}
                                                onClick={() =>
                                                  changeCurrentPage(
                                                    (prev) => prev + 1
                                                  )
                                                }
                                              >
                                                <i className="dripicons-chevron-right"></i>
                                              </Button>
                                            ) : null}
                                          </div>
                                        </Col>
                                      </Row>
                                    </React.Fragment>
                                  )}
                                </ToolkitProvider>
                              )}
                            </PaginationProvider>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>}
                    {/* ) : null} */}
                  </div>
                </Card>
                {/* *******table ends******* */}
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default Discount;
