import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner as BtnLoader,
} from "reactstrap";
import { useCreateUser } from "./useCreateUser";
import UserPermissionFetcher from "../../../helpers/getUserPerimissions";
import { Link } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";
import "./createuser.css";
import Select from "react-select";

import Spinner from "../../../components/Common/Loader";
import NoAccess from "../../../components/Common/NoAccess";
function CreateUser() {
  const assignedRole = localStorage.getItem("assignedRole");

  const {
    setFirstName,
    setLastName,
    setEmail,
    setpassword,
    Email,
    createUserHandler,
    checkEmailIsValid,
    setphoneNumber,
    setRole,
    DropDownData,
    role,
    isValidEmail,
    firstName,
    lastName,
    password,
    phoneNumber,
    isLoading,
  } = useCreateUser();

  return (
    <>
      {assignedRole === "Admin" ? (
        <div className="page-content">
          <div>
            <Row style={{ margin: "12px", marginTop: "8px" }}>
              <Col md={4}>
                <Link to="/user_profile" className="link">
                  <span className="fas fa-angle-left arrowheightwidth"></span>
                </Link>
                <span className="locationbrandtitle "> Create User</span>
              </Col>
              <Col md={8}></Col>
            </Row>
            <Card>
              <CardBody>
                <AvForm
                  className="form-horizontal "
                  onValidSubmit={createUserHandler}
                >
                  <Row style={{ marginTop: "12px" }}>
                    <Col md={1}></Col>

                    <Col md={5}>
                      <Label htmlFor="first name" className="logininboxpanels">
                        First Name
                      </Label>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text form-control ">
                            <i className="mdi mdi-account-circle-outline icon-style"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          value={firstName}
                          className={`form-control  `}
                          placeholder="Enter first name"
                          id="first name"
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                        <div class="invalid-feedback">
                          Please enter first name.
                        </div>
                      </div>
                    </Col>
                    <Col md={1}></Col>
                    <Col md={5}>
                      <Label htmlFor="last name" className="logininboxpanels">
                        Last Name
                      </Label>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text form-control ">
                            <i className="mdi mdi-account-circle icon-style"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          value={lastName}
                          className={`form-control  `}
                          placeholder="Enter last name"
                          id="last name"
                          onChange={(e) => setLastName(e.target.value)}
                        />
                        <div class="invalid-feedback">
                          Please enter last name.
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "12px" }}>
                    <Col md={1}></Col>

                    <Col md={5}>
                      <Label htmlFor="email" className="logininboxpanels">
                        Email
                      </Label>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text form-control ">
                            <i
                              class=" ri-mail-forbid-line"
                              style={{
                                lineHeight: "30px",
                                fontSize: "20px",
                              }}
                            ></i>
                          </span>
                        </div>
                        <input
                          type="email"
                          value={Email}
                          className={`form-control   ${
                            !!Email &&
                            (isValidEmail ? "is-valid" : "is-invalid")
                          }`}
                          placeholder="Enter email"
                          id="email"
                          onChange={(event) => checkEmailIsValid(event)}
                        />
                        <div class="invalid-feedback">Please enter email.</div>
                      </div>
                    </Col>
                    <Col md={1}></Col>
                    <Col md={5}>
                      <Label htmlFor="password" className="logininboxpanels">
                        Password
                      </Label>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text form-control ">
                            <i className="ri-lock-2-line auti-custom-input-icon icon-style"></i>
                          </span>
                        </div>
                        <input
                          type="password"
                          value={password}
                          className={`form-control  `}
                          placeholder="Enter password"
                          id="password"
                          onChange={(e) => setpassword(e.target.value)}
                        />
                        <div class="invalid-feedback">
                          Please enter Password.
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "12px" }}>
                    <Col md={1}></Col>

                    <Col md={5}>
                      <Label htmlFor="phone" className="logininboxpanels">
                        Phone
                      </Label>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text form-control ">
                            <i className=" mdi mdi-phone-log icon-style"></i>
                          </span>
                        </div>
                        <input
                          type="number"
                          value={phoneNumber}
                          className={`form-control   `}
                          placeholder="Enter Number"
                          id="phone"
                          onChange={(event) =>
                            setphoneNumber(event.target.value)
                          }
                        />
                        <div class="invalid-feedback">Please enter email.</div>
                      </div>
                    </Col>
                    <Col md={1}></Col>
                    <Col md={5}>
                      <div>
                        <Label htmlFor="role" className="logininboxpanels">
                          Assign Role
                        </Label>
                        <FormGroup className="select2-container">
                          {DropDownData ? (
                            <Select
                              onChange={(opt) => {
                                setRole(opt.value);
                              }}
                              options={DropDownData}
                              // value={role}
                              placeholder="select role"
                              classNamePrefix="select2-selection"
                              id="role"
                            />
                          ) : (
                            <Spinner />
                          )}
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ margin: "12px", marginTop: "30px" }}>
                    <Col md={4}></Col>
                    <Col md={4}>
                      {isLoading ? (
                        <Button color="success" className="w-100 " disabled>
                          <span>
                            <BtnLoader size="sm" className=" me-2" />
                          </span>
                          <span>Loading.....</span>
                        </Button>
                      ) : (
                        <Button
                          color="success"
                          className="w-100 "
                          disabled={
                            !role ||
                            !firstName ||
                            !lastName ||
                            !phoneNumber ||
                            !Email
                          }
                        >
                          Create New User
                        </Button>
                      )}
                    </Col>
                    <Col md={4}></Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </div>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
}

export default CreateUser;
