import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { getAllPAckageFaqs, getPAckageFaq } from "../../../../APIS/packageFaqs";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DIOM_BASED_URLS } from "../../../../config/url";

const UseFaqs = () => {
  let history = useHistory();
  const token = localStorage.getItem("Token");
  const [modal_static1, setModal_static1] = useState(false);
  const [modal_static2, setModal_static2] = useState(false);
  const [modal_static3, setModal_static3] = useState(false);
  const [oneFaqData, setOneFaqData] = useState();
  const [fqEditBody, setFqEditBody] = useState();
  const [fqEditTitle, setFqEditTitle] = useState();
  const [faqIdUpDate, setFaqIdUpDate] = useState();
  const [faqId, setFaqId] = useState();
  const [fqTitle, setFqTitle] = useState();
  const [fqBody, setFqBody] = useState();
  const queryClient = useQueryClient();

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const tog_static1 = (id) => {
    setModal_static1(!modal_static1);
    removeBodyCss();
  };

  const tog_static2 = async (id) => {
    setFaqIdUpDate(id);
    queryClient.invalidateQueries("getOnePAckageFaqs");
    setModal_static2(!modal_static2);
    removeBodyCss();
  };

  const tog_static3 = (id) => {
    setFaqId(id);
    setModal_static3(!modal_static3);
    removeBodyCss();
  };

  const faqsTitleChange = (e) => {
    setFqTitle(e.target.value);
  };
  const faqsBodyChange = (e) => {
    setFqBody(e.target.value);
  };

  const faqEditBody = (e) => {
    setFqEditBody(e.target.value);
  };
  const faqEditTitle = (e) => {
    setFqEditTitle(e.target.value);
  };

  // ******get FAQS*********
  const { data: packageFaq, isLoading } = useQuery(["getAllPAckageFaqs"], () =>
    getAllPAckageFaqs(token, history)
  );
  const packageFaqData = packageFaq?.data;
  // *************
  // ******get one FAQ*********

  const { data: packageFaqOne } = useQuery(
    ["getOnePAckageFaqs", faqIdUpDate],
    () => getPAckageFaq(token, history, faqIdUpDate)
  );
  const packageFaqDataOne = packageFaqOne?.data?.data;
  // *************

  const saveFAQData = () => {
    const apiObject = {
      title: `${fqTitle}`,
      body: `${fqBody}`,
    };
    fetch(`${DIOM_BASED_URLS}/packages-faqs`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(apiObject),
    })
      .then((response) => response.json())
      .then((result3) => {
        setModal_static1(false);
        if (result3?.id) {
          toast.success("Successfully Added");
          queryClient.invalidateQueries("getAllPAckageFaqs");
          setModal_static1(false);
        } else {
          toast.error(result3?.error?.message);
        }
      })
      .catch((error) => {
        setModal_static1(false);
        toast.error("Error: ", error);
        console.log(error);
      });
  };

  const deleteFaq = () => {
    fetch(`${DIOM_BASED_URLS}/packages-faqs/${faqId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({}),
    })
      .then((result3) => {
        if (result3.status === 200) {
          queryClient.invalidateQueries("getAllPAckageFaqs");
          toast.success(" Successfully Deleted");
          setOneFaqData("");
          setModal_static3(false);
        } else if (result3.status === 204) {
          queryClient.invalidateQueries("getAllPAckageFaqs");
          toast.success(" Successfully Deleted");
          setOneFaqData("");
          setModal_static3(false);
        } else {
          toast.error(" Something went wrong");
        }
      })
      .catch((error) => {
        toast.error(" Something went wrong");
        console.log(error);
      });
  };

  // ********UPDATE FAQS********
  const updateFaqFunc = () => {
    // if(parseFloat(refundInput) < parseFloat(InvoiceData?.total)){
    fetch(`${DIOM_BASED_URLS}/packages-faqs/${faqIdUpDate}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        title: fqEditTitle,
        body: fqEditBody,
      }),
    })
      .then((result3) => {
        if (result3?.status === 200) {
          toast.success("Updated Succesfully");
          queryClient.invalidateQueries("getAllPAckageFaqs");
          setModal_static2(false);
        } else if (result3?.status === 204) {
          toast.success("Updated Succesfully");
          queryClient.invalidateQueries("getAllPAckageFaqs");
          setModal_static2(false);
        } else {
          toast.error(result3?.error?.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  // ******end*********
  return {
    isLoading,
    setModal_static1,
    modal_static1,
    tog_static1,
    setModal_static2,
    modal_static2,
    tog_static2,
    setModal_static3,
    modal_static3,
    tog_static3,
    packageFaqData,
    saveFAQData,
    faqsTitleChange,
    faqsBodyChange,
    deleteFaq,
    oneFaqData,
    faqEditBody,
    faqEditTitle,
    updateFaqFunc,
    packageFaqDataOne,
  };
};

export default UseFaqs;
