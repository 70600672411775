import React, { useRef } from "react";
import { Row, Col, CardBody, Button, Input } from "reactstrap";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { saveAs } from "file-saver";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import GeneratePdf from "./components/GeneratePdf";
import { PDFDownloadLink } from "@react-pdf/font";
import InvoicesDetailPrint from "./components/InvoicesDetailPrint.js";
import { Accordion } from "react-bootstrap-accordion";
import { UsePackageInvoiceDetails } from "./usePackageInvoiceDetails";
import "../../css/InvoicesDetailPage.css";

function PackageInvoiceDetail() {
  const {
    InvoiceData,
    isLoading,
    refundPartialfunc,
    refundfullfunc,
    refundSt,
    refundApplyFunc,
    pRefundInput,
    refundInput,
    applyButton,
  } = UsePackageInvoiceDetails();

  const Loader = require("react-loader");
  const { id } = useParams();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
  });

  const invoiceWithWnplDownload = () => {
    var FileSaver = require("file-saver");
    const d = InvoiceData.erpInvoiceUrl;
    FileSaver.saveAs(d, "image.jpg");
    // <a href={d} download />
  };

  return (
    <>
      {isLoading ? (
        <Loader loaded={false} className="spinner" />
      ) : (
        <div className="page-content">
          <Row className="mb-3">
            <Col md={3}>
              <Link to="/invoices" className="link">
                <span className="fas fa-angle-left arrowheightwidth"></span>
              </Link>

              <span className=" ml-4 invoicedetailfirstID">
                {id ? id : "---/-"}
              </span>
            </Col>
            <Col md={9}></Col>
          </Row>

          <Row>
            <Col md={8}>
              <CardBody style={{ background: "white" }}>
                <Row>
                  <Col md={7}>
                    <p className="invoicedetailname">
                      {InvoiceData.userDetails
                        ? InvoiceData?.userDetails?.fullName
                        : "---/-"}
                    </p>
                    <p className="invoicedetailemail">
                      {InvoiceData?.userDetails
                        ? InvoiceData?.userDetails?.email
                        : "---/-"}
                    </p>
                    <p className="invoicedetailemail">
                      {InvoiceData?.userDetails
                        ? InvoiceData?.userDetails?.phoneNumber
                        : "---/-"}
                    </p>
                  </Col>
                  <Col md={5} className="rightaligncss">
                    <p className="invoicedetailid">{id ? id : "---/-"}</p>
                    <Row>
                      <Col md={7}>
                        <p className="invoicedetailname">Invoice Status :</p>
                      </Col>
                      <Col md={5} className="rightaligncss">
                        {InvoiceData.paymentStatus === "UNPAID" ? (
                          <span className="invoiceunpaidstatuscss">
                            {" "}
                            {InvoiceData ? InvoiceData.paymentStatus : "---/-"}
                          </span>
                        ) : (
                          <span className="invoicestatuscss">
                            {" "}
                            {InvoiceData ? InvoiceData.paymentStatus : "---/-"}
                          </span>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={7}>
                        <p className="invoicedetailname">Refunded :</p>
                      </Col>
                      <Col md={5} className="rightaligncss">
                        <span className="invoicestatuscss">
                          {" "}
                          {InvoiceData.isChildInvoice === false &&
                          InvoiceData.refunded === false
                            ? "False"
                            : "True"}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr className="pkg_invoice_devider" />

                <Row>
                  <Col md={3}>
                    <p className="invoicesdetailslabel">Package Name</p>
                  </Col>
                  <Col md={3}>
                    <p className="invoicedetailsdata">
                      {InvoiceData.packageName
                        ? InvoiceData.packageName
                        : "---/-"}
                    </p>
                  </Col>
                  <Col md={3}>
                    <p className="invoicesdetailslabel">Purchase Date</p>
                  </Col>
                  <Col md={3} className="rightaligncss">
                    <p className="invoicedetailsdata">
                      {InvoiceData.purchasedOn
                        ? moment(InvoiceData.purchasedOn).format("YYYY-MM-DD ")
                        : "---/-"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <p className="invoicesdetailslabel">Unit Price</p>
                  </Col>
                  <Col md={3}>
                    <p className="invoicedetailsdata">
                      {InvoiceData?.unitPrice
                        ? InvoiceData?.unitPrice
                        : "---/-"}
                    </p>
                  </Col>
                  <Col className=""></Col>
                </Row>

                <hr className="pkg_invoice_devider" />
                <Row>
                  <p className="invoicedetailsBD">Billing Details</p>
                </Row>
                <Row>
                  <Col md={5}>
                    <p className="invoicesdetailslabel">Total Payable</p>
                  </Col>
                  <Col md={4}></Col>
                  <Col md={3} className="rightaligncss">
                    <p
                      className="invoicesdetailslabel"
                      style={{ overflowWrap: "break-word" }}
                    >
                      {InvoiceData ? InvoiceData.totalPaid : "---/-"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}></Col>
                  <Col md={6} className="pkg_invoice_SubTotal_bg">
                    <Row>
                      <Col md={8}>
                        <p> Sub Total </p>
                      </Col>
                      <Col md={4} className="rightaligncss">
                        {" "}
                        {/* {InvoiceData?.total} */}
                        {InvoiceData?.subTotal}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={8}>
                        <p> Vat </p>
                      </Col>
                      <Col md={4} className="rightaligncss">
                        {" "}
                        {InvoiceData?.vatPrice}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={8}>{/* <p> Rubeen12 </p> */}</Col>
                      <Col md={4} className="rightaligncss">
                        {" "}
                        {/* 12 */}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={8}>
                        <p>Total Payable </p>
                      </Col>
                      <Col
                        md={4}
                        className="rightaligncss"
                        style={{ overflowWrap: "break-word" }}
                      >
                        {" "}
                        {InvoiceData?.totalPaid}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Col>
            <Col md={4}>
              <Button
                onClick={handlePrint}
                color="success"
                outline
                className="waves-effect waves-light me-1 w-100 mt-3 invoicesdetailbuttons"
              >
                Print
              </Button>
              {/* // hide for production start */}
              <div className="mt-3 ">
                {InvoiceData.isChildInvoice === false &&
                InvoiceData.refunded === false ? (
                  <Accordion
                    className="  w-100 mt-3 invoicerefundcss "
                    title="Revert Refund"
                  >
                    <Row className="mt-2">
                      <Col md={5}></Col>
                      <Col md={2}>
                        <p className="invoicerefundcss">Refund</p>
                      </Col>
                      <Col md={5}></Col>
                    </Row>

                    <Row className="mt-2">
                      <Col md={1}></Col>
                      <Col md={1}>
                        {" "}
                        <Input
                          className="form-check-input "
                          type="radio"
                          value="option1"
                          id="exampleRadios1"
                          name="customRadio"
                          onClick={(e) => {
                            refundPartialfunc(e);
                          }}
                          // checked={true}
                        />
                      </Col>
                      <Col md={2}>
                        <span>partial</span>
                      </Col>
                      <Col md={2}></Col>
                      <Col md={2}></Col>
                      <Col md={1}>
                        <Input
                          className="form-check-input"
                          type="radio"
                          value="option2"
                          id="exampleRadios1"
                          name="customRadio"
                          // checked={true}
                          onClick={(e) => {
                            refundfullfunc(e);
                          }}
                        />
                      </Col>
                      <Col md={2}>
                        <span>full</span>
                      </Col>
                      <Col md={1}></Col>
                    </Row>

                    <div>
                      <Row className="mt-4">
                        <Col md={1}></Col>
                        <Col md={10}>
                          <p>
                            <b>Enter Amount</b>
                          </p>
                        </Col>
                        <Col md={1}></Col>
                      </Row>

                      <Row>
                        <Col md={1}></Col>
                        <Col md={10}>
                          <Input
                            type="text"
                            value={refundInput}
                            onChange={(e) => {
                              pRefundInput(e.target.value);
                            }}
                            disabled={!refundSt}
                          />
                        </Col>
                        <Col md={1}></Col>
                      </Row>
                    </div>

                    <Row>
                      <Col md={1}></Col>
                      <Col md={10}>
                        {refundSt === true ? (
                          refundInput < InvoiceData?.subTotal ? (
                            <Button
                              color="success"
                              outline
                              className="invoicesdetailbuttons waves-effect waves-light  w-100 mt-3 "
                              disabled={false}
                              onClick={refundApplyFunc}
                            >
                              Apply
                            </Button>
                          ) : (
                            <Button
                              color="success"
                              outline
                              className="invoicesdetailbuttons waves-effect waves-light  w-100 mt-3 "
                              disabled={true}
                              onClick={refundApplyFunc}
                            >
                              Apply
                            </Button>
                          )
                        ) : (
                          <Button
                            color="success"
                            outline
                            className="invoicesdetailbuttons waves-effect waves-light  w-100 mt-3 "
                            onClick={refundApplyFunc}
                            disabled={applyButton}
                          >
                            Apply
                          </Button>
                        )}
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    {refundSt === true ? (
                      <div className="p-2">
                        <Row className="mt-3">
                          <Col md={6}>
                            <p>Sub Total</p>
                          </Col>
                          <Col md={6} className="rightaligncss">
                            {refundInput ? refundInput : "--/-"}
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            <p>Vat</p>
                          </Col>
                          <Col md={6} className="rightaligncss">
                            {refundInput ? (refundInput * 15) / 100 : "--/-"}
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            <p>Total Payable </p>
                          </Col>
                          <Col md={6} className="rightaligncss">
                            {refundInput
                              ? parseFloat(refundInput) +
                                parseFloat((refundInput * 15) / 100)
                              : "--/-"}
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      ""
                    )}
                  </Accordion>
                ) : (
                  ""
                )}
              </div>
              {/* // hide for production end */}
            </Col>
          </Row>
          <div style={{ display: "none" }}>
            <div ref={componentRef}>
              <InvoicesDetailPrint InvoiceData={InvoiceData} id={id} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
// }
export default PackageInvoiceDetail;
