import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  getAllTeamApi,
  getAllTeamSvgApi,
  getAllTeamWithouPaginationApi,
} from "../../APIS/allTeams";
import { useQuery, useQueryClient } from "react-query";
import { Button } from "reactstrap";

const UseTeams = () => {
  const [paginationStartButton, setPaginationStartButton] = useState(1);
  const [paginationEndButton, setPaginationEndButton] = useState(4);
  const token = localStorage.getItem("Token");
  const [isOpen, setIsOpen] = useState(false);
  let history = useHistory();
  const [pageSize, setPageSize] = useState(10);
  const [allBtns, setAllBtns] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchedData, setSearchedData] = useState("");

  // All Teams with pagination

  const { data: AllTeams, isLoading } = useQuery(
    ["AllTeamsData", pageSize, currentPage, searchedData],
    () => getAllTeamApi(token, history, pageSize, currentPage, searchedData)
  );

  const AllTeamsData = AllTeams?.data;
  const hasNextPage = AllTeams?.hasNextPage;
  const hasPreviousPage = AllTeams?.hasPrevisousPage;
  const total = AllTeams?.total;

  // for svg download

  const { data: AllTeamsSvgdata, isLoadingSvg } = useQuery(
    ["AllTeamsSvg", searchedData],
    () => getAllTeamSvgApi(token, history, searchedData)
  );

  const AllTeamsSvg = AllTeamsSvgdata?.data;

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const changeCurrentPage = (pageNum) => setCurrentPage(pageNum);

  const pagelengthnum = (e) => {
    if (e.target.value === "1") {
      setPageSize(10);
    } else if (e.target.value === "2") {
      setPageSize(30);
    } else if (e.target.value === "3") {
      setPageSize(50);
    }
  };

  const pageOptions = {
    sizePerPage: pageSize,
    totalSize: total ?? 0,
    custom: true,
  };

  const headers = [
    { label: "Team name", key: "name" },
    { label: "Created on", key: "createdAt" },
    { label: "Members", key: "teamMembersCount" },
    { label: "Total Amount Spent (SAR)", key: "totalAmountSpent" },
    { label: "Discount Applied %", key: "DiscountCharges" },
    { label: "Last Transaction Date", key: "recentTransactionTime" },
  ];

  const handlePageBtn = (btnNumber, totalBtns) => {
    if (totalBtns === btnNumber) {
    } else if (btnNumber === 1) {
      setPaginationStartButton(1);
      setPaginationEndButton(4);
    } else if (btnNumber === paginationStartButton) {
      setPaginationEndButton(paginationStartButton);
      setPaginationStartButton(paginationStartButton - 3);
    } else if (btnNumber === paginationEndButton) {
      setPaginationStartButton(paginationEndButton);
      setPaginationEndButton(paginationEndButton + 3);
    }
  };

  const buttonFunc = () => {
    const pages = Math.trunc(total / pageSize + 1);

    // setAllBtns(pages);

    let buttonArr = [];
    for (let i = paginationStartButton; i <= paginationEndButton; i++) {
      if (pages + 1 === i) {
        break;
      } else {
        buttonArr.push(
          <Button
            style={{
              marginLeft: 7,
              marginRight: 7,
            }}
            onClick={() => {
              setCurrentPage(i);
              handlePageBtn(i, pages);
            }}
            // color='success'
            className={`${
              currentPage === i
                ? "paginationButtonArrActive"
                : "paginationButtonArr"
            }
               waves-effect waves-light me-1 mr-2 ml-2`}
          >
            {i}
          </Button>
        );
      }
    }

    return buttonArr;
  };

  const handleIncrementBtn = (value) => {
    if (value === false) {
      if (currentPage === 1) {
      } else if (currentPage === paginationStartButton) {
        setCurrentPage(currentPage - 1);
        setPaginationEndButton(paginationStartButton);
        setPaginationStartButton(paginationStartButton - 3);
      } else if (currentPage !== 1) {
        setCurrentPage(currentPage - 1);
      }
    } else {
      if (allBtns === currentPage) {
      } else if (currentPage === paginationEndButton) {
        setCurrentPage(currentPage + 1);
        setPaginationStartButton(paginationEndButton);
        setPaginationEndButton(paginationEndButton + 3);
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  return {
    buttonFunc,

    paginationEndButton,
    setPaginationEndButton,
    paginationStartButton,
    setPaginationStartButton,
    setCurrentPage,
    AllTeamsSvg,
    headers,
    changeCurrentPage,
    AllTeamsData,
    handleIncrementBtn,
    currentPage,
    pageOptions,
    hasNextPage,
    hasPreviousPage,
    total,
    pageSize,
    isLoading,
    pagelengthnum,
    toggle,
    isOpen,
    setSearchedData,
    searchedData,
  };
};

export default UseTeams;
