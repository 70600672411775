// ************exceptionsListingPage**************

import moment from "moment";
import { DIOM_BASED_URLS } from "../config/url";

export const getExceptions = async (size, page, token = "", history) => {
  const res = await fetch(
    `${DIOM_BASED_URLS}/exceptions?size=${size}&page=${page}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resJson = await res.json();
  const exceptionData = resJson?.data?.map((element, index) => ({
    always: element.always,
    createdAt: moment(element?.createdAt).format("YYYY/MM/DD"),
    id: element.id,
    name: element.name,
    type: element.type,
    fromTime: moment(element?.fromTime).format("YYYY/MM/DD"),
    toTime: element.toTime,
    dateRange:
      moment(element?.fromTime).format("YYYY/MM/DD") +
      "-" +
      moment(element?.toTime).format("YYYY/MM/DD"),
    userName: element?.userName,
    appliedAt: element?.appliedAt,
    status: element?.status,
    mondayOpenTime: element?.mondayOpenTime,
    mondayCloseTime: element?.mondayCloseTime,
    tuesdayOpenTime: element?.tuesdayOpenTime,
    tuesdayCloseTime: element?.tuesdayCloseTime,
    wednesdayOpenTime: element?.wednesdayOpenTime,
    wednesdayCloseTime: element?.wednesdayCloseTime,
    thursdayOpenTime: element?.thursdayOpenTime,
    thursdayCloseTime: element?.thursdayCloseTime,
    fridayOpenTime: element?.fridayOpenTime,
    fridayCloseTime: element?.fridayCloseTime,
    saturdayOpenTime: element?.saturdayOpenTime,
    saturdayCloseTime: element?.saturdayCloseTime,
    sundayOpenTime: element?.sundayOpenTime,
    sundayCloseTime: element?.sundayCloseTime,
    MondayClosed: element?.MondayClosed,
    mondayClosed: element?.mondayClosed,
    tuesdayClosed: element?.tuesdayClosed,
    wednesdayClosed: element?.wednesdayClosed,
    thursdayClosed: element?.thursdayClosed,
    fridayClosed: element?.fridayClosed,
    saturdayClosed: element?.saturdayClosed,
    sundayClosed: element?.sundayClosed,
    locationBrands: element?.locationBrands?.map((e) => {
      return {
        value: e?.id,
        label: e?.name,
      };
    }),
    locations: element?.locations?.map((e) => {
      return {
        value: e?.id,
        label: e?.name,
      };
    }),
    resourceTypes: element?.resourceTypes?.map((e) => {
      return {
        value: e?.id,
        label: e?.name,
      };
    }),
    resources: element?.resources?.map((e) => {
      return {
        value: e?.id,
        label: e?.name,
      };
    }),
  }));

  return {
    data: exceptionData.length > 0 ? exceptionData : [],
    total: resJson ? resJson.total : 0,
    hasNextPage: resJson.hasNextPage ?? false,
    hasPreviousPage: resJson.hasPreviousPage ?? false,
  };
};

// ************Locations of Operational Hours**************

export const getLocationsEsxceptions = async (
  token,
  locationApplyFilter,
  brandValues
) => {
  if (locationApplyFilter) {
    var brandValuesArray = brandValues.flat(2);
    const res = (
      await (
        await fetch(
          `${DIOM_BASED_URLS}/admin-business-locations?filter={"where":{"locationCategoriesId":{"inq":${JSON.stringify(
            brandValuesArray
          )}}}}`,

          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
      ).json()
    )?.map((element) => ({
      value: element.id,
      label: element.Name,
    }));
    return [
      {
        // label: "Please select locations",

        options: [
          {
            value: res.map((e) => e?.value),
            label: "All",
          },
          ...res,
        ],
      },
    ];
  }
};

// ***********Get Exception on the Base OF iD  ************

// export const getException = async (
//   token,
//   id
// ) => {

//     const res = (
//       await (
//         await fetch(
//           `${DIOM_BASED_URLS}/exceptions/${id}` ,
//           {
//             method: "GET",
//             headers: {
//               Authorization: "Bearer " + token,
//             },
//           }
//         )
//       ).json()
//     )
//     return {
//    res
//     }

// };
