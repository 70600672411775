import React from "react";
import { Row, Col, CardBody, Container } from "reactstrap";
import "../../../css/InvoicesDetailPage.css";
import moment from "moment";

const InvoicesDetailPrint = ({ InvoiceData, id }) => {
  return (
    <div>
      <Container>
        <Row>
          <Col xs={12}>
            <CardBody style={{ background: "white" }}>
              <Row>
                <Col xs={7}>
                  <p className='invoicedetailname'>
                    {InvoiceData?.userDetails
                      ? InvoiceData?.userDetails?.fullName
                      : "---/-"}
                  </p>
                  <p className='invoicedetailemail'>
                    {InvoiceData?.userDetails
                      ? InvoiceData?.userDetails?.email
                      : "---/-"}
                  </p>
                  <p className='invoicedetailemail'>
                    {InvoiceData?.userDetails
                      ? InvoiceData?.userDetails?.phoneNumber
                      : "---/-"}
                  </p>
                </Col>
                <Col xs={5} className='rightaligncss'>
                  <p className='invoicedetailid'>{id ? id : "---/-"}</p>
                  <Row>
                    <Col xs={7}>
                      <p className='invoicedetailname'>Invoice Status :</p>
                    </Col>
                    <Col xs={5} className='rightaligncss'>
                      {InvoiceData.paymentStatus === "UNPAID" ? (
                        <span className='invoiceunpaidstatuscss'>
                          {" "}
                          {InvoiceData ? InvoiceData.paymentStatus : "---/-"}
                        </span>
                      ) : (
                        <span className='invoicestatuscss'>
                          {" "}
                          {InvoiceData ? InvoiceData.paymentStatus : "---/-"}
                        </span>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr className='pkg_invoice_devider' />

              <Row>
                <Col xs={3}>
                  <p className='invoicesdetailslabel'>Package Name</p>
                </Col>
                <Col xs={3}>
                  <p className='invoicedetailsdata'>
                    {InvoiceData.packageName
                      ? InvoiceData.packageName
                      : "---/-"}
                  </p>
                </Col>
                <Col xs={3}>
                  <p className='invoicesdetailslabel'>Purchase Date</p>
                </Col>
                <Col xs={3} className='rightaligncss'>
                  <p className='invoicedetailsdata'>
                    {InvoiceData.purchasedOn
                      ? moment(InvoiceData.purchasedOn).format("YYYY-MM-DD ")
                      : "---/-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <p className='invoicesdetailslabel'>Unit Price</p>
                </Col>
                <Col xs={3}>
                  <p className='invoicedetailsdata'>
                    {InvoiceData.unitPrice ? InvoiceData.unitPrice : "---/-"}
                  </p>
                </Col>
                <Col className=''></Col>
              </Row>

              <hr className='pkg_invoice_devider' />
              <Row>
                <p className='invoicedetailsBD'>Billing Details</p>
              </Row>
              <Row>
                <Col xs={5}>
                  <p className='invoicesdetailslabel'>Total Payable</p>
                </Col>
                <Col xs={4}></Col>
                <Col xs={3} className='rightaligncss'>
                  <p
                    className='invoicesdetailslabel'
                    style={{ overflowWrap: "break-word" }}
                  >
                    {InvoiceData?.totalPaid ? InvoiceData.totalPaid : "---/-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={6}></Col>
                <Col xs={6} className='pkg_invoice_SubTotal_bg'>
                  <Row>
                    <Col xs={8}>
                      <p> Sub Total </p>
                    </Col>
                    <Col xs={4} className='rightaligncss'>
                      {" "}
                      {InvoiceData?.subTotal}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <p> Vat </p>
                    </Col>
                    <Col xs={4} className='rightaligncss'>
                      {" "}
                      {InvoiceData?.vatPrice}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}></Col>
                    <Col xs={4} className='rightaligncss'></Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <p>Total Payable </p>
                    </Col>
                    <Col
                      xs={4}
                      className='rightaligncss'
                      style={{ overflowWrap: "break-word" }}
                    >
                      {" "}
                      {InvoiceData?.totalPaid}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default InvoicesDetailPrint;
