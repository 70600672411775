import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { getInvoice, getPackageInvoice } from "../../../../APIS/invoices";
import { usePaginatedQuery } from "../../../../hooks/query";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { DIOM_BASED_URLS } from "../../../../config/url";

export const UsePackageInvoiceDetails = () => {
  let history = useHistory();
  const token = localStorage.getItem("Token");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(50);
  const changeCurrentPage = (pageNum) => setCurrentPage(pageNum);
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [refundSt, setRefundSt] = useState(false);
  const [refundInput, setRefundInput] = useState(null);
  const [applyButton, setApplyButton] = useState(true);
  const [refundQuantity, setRefundQuantity] = useState(null);
  const [totalRefund, setTotalRefund] = useState(null);
  const [vatRefund, setVatRefund] = useState(null);

  const {
    data: { data: InvoiceData, hasNextPage, hasPreviousPage, total },
    isLoading,
  } = usePaginatedQuery(["pkgInvoice", currentPage], () =>
    getPackageInvoice(pageSize, currentPage, token, id, history)
  );

  const pageOptions = {
    sizePerPage: pageSize,
    totalSize: total ?? 0,
    custom: true,
  };
  const refundApplyFunc = () => {
    // if(parseFloat(refundInput) < parseFloat(InvoiceData?.total)){
    fetch(`${DIOM_BASED_URLS}/user-package-invoices/refund/${id}`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        partialRefund: refundSt,
        amount: Number(refundInput),
      }),
    })
      .then((response) => response.json())
      .then((result3) => {
        if (result3?.statusCode === 200) {
          toast.success("refund Added");
        } else if (result3?.statusCode === 204) {
          toast.success("refund Added");
        }
        // else if (result3?.status.ok){
        //   toast.success("refund Added");
        // }
        else {
          toast.error(result3?.error?.message);
        }
      })
      .catch((error) => console.log("error", error));
  };
  // else{
  //   toast.error("something went wronge")
  // }
  // }

  const refundfullfunc = (e) => {
    setRefundSt(false);
    setApplyButton(false);
  };
  const refundPartialfunc = (e) => {
    setRefundSt(true);
    setApplyButton(false);
  };

  const pRefundInput = (e) => {
    if (e < InvoiceData?.subTotal) {
      const refundQuan = Number(
        (e / InvoiceData?.InvoiceData?.subTotal).toFixed(2)
      );
      setRefundQuantity(refundQuan);
      setTotalRefund(
        +parseFloat(refundQuan * InvoiceData?.totalPaid).toFixed(2)
      );

      setVatRefund(Number(((totalRefund / 100) * 15).toFixed(2)));
      setRefundInput(e);
    } else {
      toast.error(`Please enter the value less then ${InvoiceData?.subTotal}`);
    }
  };

  useEffect(() => {
    // FOR PRE-FETCHING NEXT PAGE

    if (hasNextPage) {
      const nextPage = currentPage + 1;
      queryClient.prefetchQuery(["Invoice", nextPage], () =>
        getInvoice(pageSize, nextPage, token, id, history)
      );
    }
  }, [currentPage, queryClient]);

  return {
    currentPage,
    pageOptions,
    InvoiceData,
    hasNextPage,
    hasPreviousPage,
    total,
    isLoading,
    refundSt,
    refundApplyFunc,
    changeCurrentPage,
    refundPartialfunc,
    pRefundInput,
    refundfullfunc,
    refundInput,
    applyButton,
    refundInput,
    vatRefund,
    totalRefund,
    refundQuantity,
  };
};
