import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
  Button,
  ModalBody,
  ModalHeader,
  Modal,
  Spinner as BtnLoader,
} from "reactstrap";
import Switch from "react-switch";
import CreatableSelect from "react-select/creatable";
import DatePicker from "react-flatpickr";
import { useCreatePlanDiscount } from "./useCreatePlanDiscount";
import moment from "moment";
import { toast } from "react-toastify";
import "./css/style.css";
import UserPromissionFetcher from "../../../../helpers/getUserPerimissions";
import NoAccess from "../../../../components/Common/NoAccess";
import Spinner from "../../../../components/Common/Loader";

function Index2() {
  const userPermissions = UserPromissionFetcher("planDiscountCodes");
  const [selectedUsersAll, setSelectedUsersAll] = useState([]);
  const [isCreateOptionDisabled, setIsCreateOptionDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,

    formState: { errors },
  } = useForm();

  const {
    Offsymbol,
    OnSymbol,
    searchResults,
    setSearchTerm,
    isLoading,
    onSubmit,
    modelShow,
    setModelShow,
    createDiscount,
    discountCodeDate,
    id,
    edit,
    setActiveSaveChangesBtn,
    activeSaveChangesBtn,
    changeHandler,
    setCanceBtnClicked,
    setEditModelShow,
    editModelShow,
    isUserFetch,
  } = useCreatePlanDiscount();
 

  useEffect(() => {
    // Disable the creation of new options if no results are found
    setIsCreateOptionDisabled(searchResults.length === 0);
  }, [searchResults]);

  const showCreateOption = searchResults.length > 0;

  const {
    status,
    endDate,
    startDate,
    selectedUsers,
    codeName,
    percentage,
    maxUsePerUser,
  } = watch();
  // console.log(
  //   status,
  //   codeName,
  //   maxUsePerUser,
  //   percentage,
  //   endDate,
  //   startDate,
  //   selectedUsers,
  //   edit,
  //   "edit",

  //   ">>>>watch Fn index file>>>"
  // );
  //1) Update the field value
  useEffect(() => {
    if (id) {
      // Reset the form values when discountCodeDate changes
      setValue("status", discountCodeDate.status);
      setValue("codeName", discountCodeDate.name);
      setValue("maxUsePerUser", discountCodeDate.maxUsePerUser);
      setValue("percentage", discountCodeDate.percentage);
      setValue("startDate", discountCodeDate.startDate);
      setValue("endDate", discountCodeDate.endDate);
      setSelectedUsersAll(
        discountCodeDate?.allowedUsers?.map((user) => ({
          value: user.id,
          label: user.name,
        }))
      );
      // setValue(
      //   "selectedUsers",
      //   discountCodeDate?.allowedUsers?.map((user) => [
      //     { label: user.name, value: user.id },
      //   ])
      // );
      // setValue("selectedUsers", [{ id: "ID-2321", name: "TestUSer" }]);
    } else {
      // Reset the form values when discountCodeDate changes
      setValue("status", false);
      setValue("codeName", "");
      setValue("maxUsePerUser", null);
      setValue("percentage", null);
      setValue("startDate", null);
      setValue("endDate", null);
      setValue("selectedUsers", []);
    }
  }, [discountCodeDate, id, setValue]);
  // console.log(discountCodeDate, "discountCodeDate>>>>>>");

  return (
    <>
      {userPermissions === null ? (
        <Spinner />
      ) : userPermissions.read ? (
        <div className="page-content">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-4">
              <Col md={4}>
                <Link to="/plan-discounts" className="link">
                  <span className="fas fa-angle-left arrowheightwidth"></span>
                </Link>
                <span className="locationbrandtitle ml-2 ">
                  Create Plan Discount
                </span>
              </Col>
              <Col md={6}></Col>
              {/* <Col md={2}>
            <Button color="danger" className="w-100">
              Cancel
            </Button>
          </Col>
          <Col md={2}>
            <Button color="success" className="w-100">
              Edit
            </Button>
          </Col> */}
              {/* /next_step/ */}
              <Col md={2}>
                {id ? (
                  <Link to={`/next_step/${id}`} className="link">
                    <Button color="success" className="w-100">
                      Skip
                    </Button>
                  </Link>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row
              className=" g-2"
              style={{
                pointerEvents: id&&userPermissions.update?"auto":id&&userPermissions.create?"none":userPermissions.create?"auto":"none"
              }}
            >
              <Card>
                <CardBody>
                  <Row>
                    <h3> Conditions:</h3>
                  </Row>
                  <Row className="mt-3">
                    <Col md={4}>
                      <Label
                        htmlFor="codeStatus"
                        className="logininboxpanels"
                        style={{ pointerEvents: "none" }}
                      >
                        Status <span className="cp_starik">*</span>
                      </Label>
                      <div className="input-group mb-1 ml-5">
                        <Switch
                          disabled={id ? !edit : false}
                          {...register("status")}
                          checked={status ?? false} // set false when status is undefined
                          width={70}
                          uncheckedIcon={Offsymbol(<small>Inactive</small>)}
                          checkedIcon={OnSymbol(<small>Active</small>)}
                          onChange={(selected) => {
                            setActiveSaveChangesBtn(false);
                            // * This is React Hook Method to set value against the key
                            setValue("status", selected);
                          }}
                          onColor="#a2a2a2"
                          onHandleColor="#16b185"
                          id="codeStatus"
                          name="status"
                        />
                      </div>
                    </Col>
                  </Row>
                  {/* Second Row */}
                  <Row className="mt-3">
                    <Col md={4}>
                      <Label
                        htmlFor="first name"
                        className="logininboxpanels"
                        style={{ pointerEvents: "none" }}
                      >
                        Code Name <span className="cp_starik">*</span>
                      </Label>
                      <div className="input-group mb-3">
                        <input
                          disabled={id ? !edit : false}
                          type="text"
                          {...register("codeName", {
                            required: true,
                            maxLength: 20,
                          })}
                          className={`form-control`}
                          placeholder="Enter Code Name"
                          id="codeName"
                          onChange={(e) => {
                            // Update activeSaveChangesBtn to false when the user enters anything
                            setActiveSaveChangesBtn(false);
                          }}
                        />
                        <div className="invalid-feedback">
                          Please enter Code name.
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <Label
                        htmlFor="max_user_per_code"
                        className="logininboxpanels"
                        style={{ pointerEvents: "none" }}
                      >
                        Max Use Per User
                      </Label>
                      <div className="input-group mb-3">
                        <input
                          disabled={id ? !edit : false}
                          type="number"
                          {...register("maxUsePerUser", {
                            required: false,
                            maxLength: 5,
                            min: {
                              value: 1,
                              message:
                                "Please enter a number greater than or equal to 1.",
                            },
                          })}
                          className={`form-control  `}
                          placeholder="Enter Max Use"
                          id="max_user_per_code"
                          onChange={(e) => {
                            // Update activeSaveChangesBtn to false when the user enters anything
                            setActiveSaveChangesBtn(false);
                          }}
                        />
                        <div className="invalid-feedback">
                          Please enter max use per user.
                        </div>
                      </div>
                    </Col>
                    <Col md={1}></Col>
                    <Col md={3} sm={12}>
                      <Label
                        htmlFor="Perecentage"
                        className="logininboxpanels"
                        style={{ pointerEvents: "none" }}
                      >
                        Percentage <span className="cp_starik">*</span>
                      </Label>
                      <div className="input-group mb-3">
                        <input
                          disabled={id ? !edit : false}
                          type="number"
                          {...register("percentage", {
                            required: true,
                            min: {
                              value: 1,
                              message:
                                "Please enter a value greater than or equal to 1",
                            },
                            max: {
                              value: 100,
                              message:
                                "Please enter a value less than or equal to 100",
                            },
                          })}
                          className={`form-control ${
                            errors.percentage ? "is-invalid" : ""
                          }`}
                          placeholder="Enter Percentage"
                          name="percentage"
                          id="Percentage"
                          onChange={(e) => {
                            const enteredValue = parseFloat(e.target.value);

                            // Check if the entered value is within the valid range
                            if (
                              !isNaN(enteredValue) &&
                              enteredValue >= 1 &&
                              enteredValue <= 100
                            ) {
                              setValue("percentage", enteredValue);
                              setActiveSaveChangesBtn(false);
                            } else {
                              // If the entered value is invalid, you might want to clear the input
                              setValue("percentage", ""); // or setValue("percentage", null) depending on your use case
                              setActiveSaveChangesBtn(true);
                            }
                          }}
                        />
                        <div className="invalid-feedback">
                          {errors.percentage && errors.percentage.message}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {/* Third Row */}
                  <Row className="mt-3">
                    <Col md={4}>
                      <Label
                        htmlFor="start_date"
                        className="logininboxpanels"
                        style={{ pointerEvents: "none" }}
                      >
                        Start Date <span className="cp_starik">*</span>
                      </Label>
                      <FormGroup>
                        <DatePicker
                          disabled={id ? !edit : false}
                          selected={discountCodeDate.startDate || null}
                          name="startDate"
                          {...register("startDate")}
                          className="datepickercss_exceptions"
                          placeholder={
                            discountCodeDate.startDate
                              ? moment(discountCodeDate.startDate).format(
                                  "YYYY-MM-DD"
                                )
                              : "Select start date"
                          }
                          id="start_date"
                          onChange={(date) => {
                            const currentDate = new Date();
                            const selectedDate = new Date(date);

                            //* 1)Check if the selected date is earlier than today
                            const pastDateSelected =
                              selectedDate <
                              new Date(
                                currentDate.getFullYear(),
                                currentDate.getMonth(),
                                currentDate.getDate()
                              );

                            if (pastDateSelected) {
                              toast.error("Start date cannot be in the past");

                              // Set SaveChanges btn InActive
                              setActiveSaveChangesBtn(true);
                              // Return true or perform additional actions if needed
                              return;
                            }

                            const start = date
                              ? moment(date[0]).format(
                                  "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                )
                              : null;

                            // * 2) Check Start date connot be greater than end date
                            // Check if the start date is greater than the end date
                            if (start > endDate) {
                              toast.error(
                                "Start date cannot be greater than end date"
                              );
                              // Inactive Save Change Btn
                              setActiveSaveChangesBtn(true);
                              return;
                            }

                            // Check if the start date is greater than today's date
                            if (start > currentDate) {
                              toast.error("Start date cannot be in the future");
                              // Inactive Save Change Btn
                              setActiveSaveChangesBtn(true);

                              return;
                            }

                            // Update activeSaveChangesBtn to false when the user enters anything
                            setActiveSaveChangesBtn(false);
                            setValue("startDate", start);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <Label className="logininboxpanels">
                        End Date
                        <span
                          className="tooltip-container"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          title="If You not select end date this code will valid forever"
                        >
                          <span
                            className="totipalignment ms-1"
                            style={{ cursor: "pointer" }}
                          >
                            <li
                              className="fas fa-info-circle  "
                              id="TooltipExample"
                            ></li>
                          </span>
                        </span>
                      </Label>
                      <FormGroup>
                        <DatePicker
                          disabled={id ? !edit : false}
                          name="endDate"
                          {...register("endDate")}
                          className="datepickercss_exceptions"
                          placeholder={
                            discountCodeDate.endDate
                              ? moment(discountCodeDate.endDate).format(
                                  "YYYY-MM-DD"
                                )
                              : "Select end date"
                          }
                          id="end_date"
                          // value={endDate ? endDate : "Select end date"}
                          onChange={(date) => {
                            const currentDate = new Date();
                            const selectedDate = new Date(date);

                            // Check if the selected date is earlier than today
                            const pastDateSelected =
                              selectedDate <
                              new Date(
                                currentDate.getFullYear(),
                                currentDate.getMonth(),
                                currentDate.getDate()
                              );

                            if (pastDateSelected) {
                              toast.error("End date cannot be in the past");

                              // Set SaveChanges btn InActive
                              setActiveSaveChangesBtn(true);
                              return;
                            }

                            // Update activeSaveChangesBtn to false when the user enters anything
                            setActiveSaveChangesBtn(false);
                            const end = moment(date[0]).format(
                              "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                            );

                            if (startDate >= end) {
                              toast.error(
                                "End Date Should be Greater Than Start Date"
                              );
                              // Set SaveChanges btn InActive
                              setActiveSaveChangesBtn(true);
                            } else {
                              setValue("endDate", end);
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={1} />
                    {/* Block User Module For Now */}
                    <Col md={3} sm={12}>
                      <div>
                        <Label
                          htmlFor="users"
                          className="logininboxpanels"
                          style={{ pointerEvents: "none" }}
                        >
                          Users
                        </Label>
                        <FormGroup className="select2-container">
                          <CreatableSelect
                            isDisabled={id ? !edit : false}
                            value={selectedUsersAll}
                            name="selectedUsers"
                            {...register("selectedUsers")}
                            options={showCreateOption ? searchResults : []}
                            placeholder="All users selected"
                            isMulti={true}
                            isLoading={isUserFetch}
                            isOptionDisabled={(option) =>
                              option.__isNew__ && isCreateOptionDisabled
                            }
                            isValidNewOption={(
                              inputValue,
                              selectValue,
                              selectOptions
                            ) => {
                              // Disable creating a new option if the inputValue is empty or if there are no search results
                              return (
                                inputValue.trim() !== "" &&
                                selectOptions.length === 0
                              );
                            }}
                            onChange={(selected) => {
                              //1) Active Save Btn
                              setActiveSaveChangesBtn(false);
                              // 2) Save new data
                              setSelectedUsersAll(selected);

                              const updateUserData = selected.map((user) => {
                                return {
                                  id: user.value,
                                  name: user.label,
                                };
                              });
                              console.log(updateUserData, "updateUserData");
                              setValue("selectedUsers", updateUserData);
                            }}
                            onInputChange={(inputValue) =>
                              setSearchTerm(inputValue)
                            }
                            // onCreateOption={handleCreateOption}
                          />
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>
                  {/* Fourth Row */}
                  <Row style={{ margin: "12px", marginTop: "50px" }}>
                    <Col md={4}></Col>
                    <Col md={4} className="me-5">
                      {/* <button type="submit">Submit</button> */}

                      {id ? (
                        <>
                          {edit ? (
                            <div>
                              <button
                                style={{ width: "250px" }}
                                disabled={activeSaveChangesBtn}
                                type="submit"
                                className="btn btn-success me-5"

                                // onClick={() => setCanceBtnClicked(true)}
                              >
                                Save Changes
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <>
                          {userPermissions.create ? (
                            <button
                              style={{ width: "250px" }}
                              type="submit"
                              className="btn btn-success me-5"
                              disabled={
                                !codeName ||
                                !startDate ||
                                !percentage ||
                                isLoading
                              }
                              // onClick={() => setModelShow(true)}
                            >
                              {isLoading ? (
                                <>
                                  <span>
                                    <BtnLoader size="sm me-2">
                                      Loading...
                                    </BtnLoader>
                                  </span>
                                  <span> Loading...</span>
                                </>
                              ) : (
                                "Create Plan Discount"
                              )}
                            </button>
                          ) : (
                            ""
                          )}
                        </>
                      )}

                      {/* Create Model */}
                      <Modal
                        isOpen={modelShow}
                        // toggle={() => {
                        //   tog_static();
                        // }}
                        centered={true}
                      >
                        <Row>
                          <Col md={2}></Col>
                          <Col md={8}>
                            <ModalHeader>
                              <h5 style={{ marginLeft: "25px" }}>
                                {" "}
                                Create Plan Discount
                              </h5>
                            </ModalHeader>
                          </Col>
                          <Col md={2}></Col>
                        </Row>

                        <ModalBody>
                          <Row>
                            <Col md={2}></Col>
                            <Col md={9}>
                              <p>{`Are you sure want to create this discount code?`}</p>
                            </Col>
                            <Col md={1}></Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col md={2}></Col>

                            <Col md={4}>
                              <Button
                                color="success"
                                outline
                                className="waves-effect waves-light w-100"
                                onClick={() => setModelShow(false)}
                              >
                                No
                              </Button>
                            </Col>
                            <Col md={4}>
                              <Button
                                color="success"
                                outline
                                className="waves-effect waves-light w-100"
                                onClick={() => {
                                  createDiscount();
                                  setModelShow(false);
                                }}
                              >
                                Yes
                              </Button>
                              {/* <Bu
                  type="submit"
                    color="success"
                    className="waves-effect waves-light  w-100"
                    onClick={() => {
setModelShow(false)
                    }}
                  >
                    Yes
                  </Bu> */}
                            </Col>

                            <Col md={2}></Col>
                          </Row>
                        </ModalBody>
                      </Modal>

                      {/* Edite Model */}
                      {/* <Modal isOpen={editModelShow} centered={true}>
                    <Row>
                      <Col md={2}></Col>
                      <Col md={8}>
                        <ModalHeader>
                          <h5 style={{ marginLeft: "25px" }}>
                            Edit Discount Confirmation
                          </h5>
                        </ModalHeader>
                      </Col>
                      <Col md={2}></Col>
                    </Row>

                    <ModalBody>
                      <Row>
                        <Col md={2}></Col>
                        <Col md={9}>
                          <p>{`Are you sure want to edit this code?`}</p>
                        </Col>
                        <Col md={1}></Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md={2}></Col>

                        <Col md={4}>
                          <Button
                            color="success"
                            outline
                            className="waves-effect waves-light w-100"
                            onClick={() => setEditModelShow(false)}
                          >
                            No
                          </Button>
                        </Col>
                        <Col md={4}>
                          <Button
                            color="success"
                            outline
                            className="waves-effect waves-light w-100"
                            onClick={() => {
                              changeHandler();
                              setEditModelShow(false);
                            }}
                          >
                            Yes
                          </Button>
                        </Col>

                        <Col md={2}></Col>
                      </Row>
                    </ModalBody>
                  </Modal> */}
                    </Col>
                    <Col md={4}></Col>
                  </Row>
                </CardBody>
              </Card>
            </Row>
          </form>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
}

export default Index2;
