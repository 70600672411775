import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
function ChangePassword() {
  return (
    <div className="page-content">
      <Row className="mb-3">
        <Col md={4}>
          <Link to="/dasboard" className="link">
            <span className="fas fa-angle-left arrowheightwidth"></span>
          </Link>
          <span className="locationbrandtitle ">Change Password</span>
        </Col>
        <Col md={6}></Col>
        <Col md={2}></Col>
      </Row>
    </div>
  );
}

export default ChangePassword;
