import { DIOM_BASED_URLS } from "../config/url";
import moment from "moment";

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns get all teams with pagination
 */
export const getAllTeamApi = async (
  token = "",
  history,
  pageSize,
  currentPage,
  searchedData
) => {
  const res = await fetch(
    // `${DIOM_BASED_URLS}/admin-teams?page=${currentPage}&size=${pageSize}`,

    `${DIOM_BASED_URLS}/admin-teams/search?name=${searchedData}&page=${currentPage}&size=${pageSize}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resDataa = await res.json();

  const resData = resDataa?.data?.map((ele) => {
    return {
      id: ele?.id,
      name: ele?.name,
      teamMembers: ele?.teamMembers,
      createdAt: moment(ele?.createdAt).format("DD-MM-YYYY"),
      teamMembersCount: ele?.teamMembers?.length,
      totalAmountSpent: ele?.totalAmountSpent,
      DiscountCharges: ele?.DiscountCharges,
      recentTransactionTime: ele?.recentTransactionTime,
    };
  });

  return {
    data: resData,
    total: resDataa.total,
    hasNextPage: resDataa.hasNextPage,
    hasPreviousPage: resDataa.hasPreviousPage,
  };
};

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns get all teams with pagination
 */
export const getAllTeamSvgApi = async (token = "", history, searchedData) => {
  const res = await fetch(
    // `${DIOM_BASED_URLS}/admin-teams?page=${currentPage}&size=${pageSize}`,

    `${DIOM_BASED_URLS}/admin-teams/search?name=${searchedData}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resDataa = await res.json();

  const resData = resDataa?.data?.map((ele) => {
    return {
      id: ele?.id,
      name: ele?.name,
      teamMembers: ele?.teamMembers,
      createdAt: moment(ele?.createdAt).format("DD-MM-YYYY"),
      teamMembersCount: ele?.teamMembers?.length,
      totalAmountSpent: ele?.totalAmountSpent,
      DiscountCharges: ele?.DiscountCharges,
      recentTransactionTime: ele?.recentTransactionTime,
    };
  });

  return {
    data: resData,
    total: resDataa.total,
    hasNextPage: resDataa.hasNextPage,
    hasPreviousPage: resDataa.hasPreviousPage,
  };
};

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns get all teams without any pagination
 */
export const getAllTeamWithouPaginationApi = async (
  token = "",
  history,
  pageSize,
  currentPage,
  searchedData
) => {
  // const res = await fetch(`${DIOM_BASED_URLS}/admin-teams`, {
  const res = await fetch(
    `${DIOM_BASED_URLS}/admin-teams/search?name=${searchedData}&page=${currentPage}&size=${pageSize}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resDataa = await res.json();

  const resData = resDataa?.data?.map((ele) => {
    return {
      id: ele?.id,
      name: ele?.name,
      teamMembers: ele?.teamMembers,
      createdAt: moment(ele?.createdAt).format("DD-MM-YYYY"),
      teamMembersCount: ele?.teamMembers?.length,
      totalAmountSpent: ele?.totalAmountSpent,
      DiscountCharges: ele?.DiscountCharges,
      recentTransactionTime: ele?.recentTransactionTime,
    };
  });

  return {
    data: resData,
    total: resDataa.total,
    hasNextPage: resDataa.hasNextPage,
    hasPreviousPage: resDataa.hasPreviousPage,
  };
};

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns get single team on the base of id
 */
export const getSingleTeamApi = async (token = "", history, id) => {
  if (id) {
    const res = await fetch(`${DIOM_BASED_URLS}/admin-teams/${id}`, {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    if (!res.ok) {
      if (res.status === 401) {
        history.push("/login");
        throw new Error(resJson.error.message);
      }
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const resData = await res.json();

    return {
      data: resData,
    };
  }
};
