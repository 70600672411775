import react, { useState } from "react";
import { useQueryClient } from "react-query";
import { getExceptions } from "../../../../APIS/exceptions";
import { DIOM_BASED_URLS } from "../../../../config/url";
import { usePaginatedQuery } from "../../../../hooks/query";
import { toast } from "react-toastify";

const UseExceptionListing = () => {
  const [isOpen, setIsOpen] = useState(false);
  const token = localStorage.getItem("Token");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const changeCurrentPage = (pageNum) => setCurrentPage(pageNum);
  const queryClient = useQueryClient();
  const [applyLocationFilter, setApplyFilter] = useState(false);
  const [modal_static1, setModal_static1] = useState(false);
  const [modal_static2, setModal_static2] = useState(false);
  const [filter, setFilter] = useState("All");
  const [dayTimeValue, setDayTimeValue] = useState({});
  const [deleteEntityId, setDeleteEntityId] = useState("");

  const noData = [
    {
      name: "",
      // dateRange: "",
      type: "",
      status: "No Data",
      // fromTime: "No Data",
      userName: "",
      toTime: "",
      actions: "No Data",
    },
  ];

  const {
    data: { data: exceptoionsLisitngData, hasNextPage, hasPreviousPage, total },
    isLoading,
  } = usePaginatedQuery(["exceptions", `${pageSize}`, `${currentPage}`], () =>
    getExceptions(pageSize, currentPage, token, applyLocationFilter, filter)
  );

  const pagelengthnum = (e) => {
    if (e.target.value === "1") {
      setPageSize(10);
    } else if (e.target.value === "2") {
      setPageSize(30);
    } else if (e.target.value === "3") {
      setPageSize(50);
    }
  };

  const pageOptions = {
    sizePerPage: pageSize,
    totalSize: total ?? 0,
    custom: true,
  };

  function Offsymbolhours(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }

  function OnSymbolhours(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }
  function Offsymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }

  function OnSymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const tog_static1 = (id) => {
    setDeleteEntityId(id);
    setModal_static1(!modal_static1);
    removeBodyCss();
  };
  const tog_static2 = (row) => {
    setDayTimeValue(row);
    setModal_static2(!modal_static1);
    removeBodyCss();
  };
  const DeleteModelFunc = () => {
    deleteExceptionFunc(deleteEntityId);
    setModal_static1(false);
  };

  const deleteExceptionFunc = (e) => {
    fetch(`${DIOM_BASED_URLS}/exceptions/${e}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({}),
    })
      .then((result3) => {
        if (result3.status === 200) {
          queryClient.invalidateQueries("exceptions");
          toast.success(" Successfully Deleted");
        } else if (result3.status === 204) {
          queryClient.invalidateQueries("exceptions");
          toast.success(" Successfully Deleted");
        } else {
          toast.error(" Something went wrong");
        }
      })
      .catch((error) => {
        toast.error(" Something went wrong");
        console.log(error);
      });
  };

  return {
    Offsymbol,
    OnSymbol,
    Offsymbolhours,
    OnSymbolhours,
    setModal_static2,
    modal_static2,
    tog_static2,
    setModal_static1,
    modal_static1,
    tog_static1,
    noData,
    currentPage,
    pageOptions,
    exceptoionsLisitngData,
    hasNextPage,
    hasPreviousPage,
    total,
    isLoading,
    pagelengthnum,
    pageSize,
    changeCurrentPage,
    filter,
    deleteExceptionFunc,
    DeleteModelFunc,
    dayTimeValue,
  };
};
export default UseExceptionListing;
