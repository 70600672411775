import React from "react";
import {
  Card,
  Row,
  Col,
  Input,
  Button,
  FormGroup,
  Form,
  Container,
} from "reactstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import Dropzone from "react-dropzone";
import UseCreatePackage from "./useCreatePackage";
import Cropper from "react-easy-crop";
// import { Spinner } from "reactstrap";
import UsePackageCategory from "../packageCategories/usePackageCategory";
import "react-image-crop/dist/ReactCrop.css";
import "../css/createPackage.css";
import "react-image-crop/dist/ReactCrop.css";
import UserPromissionFetcher from "../../../../helpers/getUserPerimissions";
import Spinner from "../../../../components/Common/Loader";
import NoAccess from "../../../../components/Common/NoAccess";

const CreatePackages = () => {
  const userPermissions = UserPromissionFetcher("packages");
  const { allCategoriesData } = UsePackageCategory();
  const CategoryOptions = allCategoriesData?.map((category) => {
    return {
      value: category?.id,
      label: category.name,
    };
  });

  const {
    setCreatePackageObj,
    CustomerSearchLoading,
    searchTillLoading,
    customerfinalOptions,
    detailedViewLoading,
    cardViewLoading,
    confirmHidden,
    testImg,
    packagesCustomerSearchData,
    handleCustomerInputChange,
    createPackageObj,
    loadingLocations,
    PackageValidityOption,
    locationsData,
    secondRowData,
    secondState,
    thirdRowData,
    thirdState,
    imgCC,
    timeOptions,
    zoomCover,
    cropCover,
    zoom,
    crop,
    detailImageButton,
    cardImageButton,
    cardViewBase64Func,
    detailedViewBase64Func,
    refundableMinutes,
    refundableHours,
    setTestImg,
    setImgCC,
    confirmActualImg,
    loadedImgCover,
    pkgValidityFunc,
    pkgLocationFunc,
    pkgInventoryFunc,
    pkgPriorityFunc,
    pkgCustomersFunc,
    pkgConditionsFunc,
    pkgCategory3Func,
    pkgDisplayHours3Func,
    pkgDisplayMinutes3Func,
    pkgActualHours3Func,
    pkgActualMinutes3Func,
    pkgCategory2Func,
    pkgDisplayHours2Func,
    pkgDisplayMinutes2Func,
    pkgActualHours2Func,
    pkgActualMinutes2Func,
    pkgNameFunc,
    pkgPriceFunc,
    pkgDescriptionFunc,
    pkgCategory1Func,
    pkgDisplayHours1Func,
    pkgDisplayMinutes1Func,
    pkgActualHours1Func,
    pkgActualMinutes1Func,
    pkgActiveateToggleFunc,
    pkgTagInputFunc,
    pkgTagToggleFunc,
    setSelectedFiles,
    selectedFiles,
    Offsymbol,
    OnSymbol,
    secondRowDataFunc,
    rowSecondDellFunc,
    thirdRowDataFunc,
    rowThreeDellFunc,
    handleAcceptedFiles,
    PostPackagesFunc,
    onCropCompleteCover,
    setCoverMedia,
    setZoomCover,
    setCropCover,
    setZoom,
    setProfileMedia,
    setCrop,
    onCropCompleteProfile,
    setConfirmHidden,
    createButtonLoad,
    teamArr,
    addmorepracticefunc,
  } = UseCreatePackage();

  return (
    <>
      {userPermissions === null ? (
        <Spinner />
      ) : userPermissions.create ? (
        <div className="page-content">
          <Row className="mb-3">
            <Col md={4}>
              <Link to="packages" className="link">
                <span className="fas fa-angle-left arrowheightwidth"></span>
              </Link>
              <span className="locationbrandtitle  ">Create package</span>

              {createButtonLoad ? (
                <Spinner
                  className="cat_loading"
                  animation="border"
                  role="status"
                ></Spinner>
              ) : (
                ""
              )}
            </Col>

            <Col md={4}></Col>
            <Col md={2}>
              <Link to="/packages">
                {" "}
                <Button
                  color="success"
                  outline
                  className="waves-effect waves-light mr-1  w-100"
                  block
                >
                  <span className="printbutton">Cancel</span>
                </Button>
              </Link>
            </Col>
            <Col md={2}>
              <Button
                disabled={createButtonLoad}
                color="success"
                className="waves-effect waves-light mr-1  w-100"
                block
                onClick={PostPackagesFunc}
              >
                <span className="printbutton">Create</span>
              </Button>
            </Col>
          </Row>

          <Card className="p-4 ">
            <Row className="mt-2">
              <Col md={12}>
                <p className="c_p_title">Activate Package</p>
              </Col>
            </Row>
            <Row>
              <Col md={1}>
                <Switch
                  name="always"
                  onHandleColor="#16b185"
                  width={70}
                  uncheckedIcon={Offsymbol(<small>NO</small>)}
                  checkedIcon={OnSymbol(<small>YES</small>)}
                  onColor="#a2a2a2"
                  checked={
                    createPackageObj?.ActivateToggle
                      ? createPackageObj?.ActivateToggle
                      : false
                  }
                  onChange={(e) => pkgActiveateToggleFunc(e)}
                  className="mr-1 mt-1  "
                />
              </Col>
              <Col md={10}>
                <p className="c_p_light_text">
                  Activate this package as soon as it is created
                </p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={6}>
                <p className="c_p_title">General Details</p>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <p className="c_p_title2">
                  {" "}
                  Package Name<span className="cp_starik">*</span>{" "}
                </p>
                <Input
                  placeholder="Enter Name"
                  onChange={(e) => pkgNameFunc(e)}
                  maxLength="23"
                />
              </Col>
              <Col md={6}>
                <p className="c_p_title2">
                  {" "}
                  Price<span className="cp_starik">*</span>{" "}
                </p>
                <Input
                  type="number"
                  placeholder="Enter Price"
                  onChange={(e) => pkgPriceFunc(e)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <p className="c_p_title2">
                  Description<span className="cp_starik">*</span>{" "}
                </p>
                <Input
                  type="textarea"
                  placeholder="Enter Description Here..."
                  onChange={(e) => pkgDescriptionFunc(e)}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md={12}>
                <p className="c_p_title">Define category and time credit</p>
              </Col>
            </Row>
            {/* **********First Row******** */}
            <Row className="mt-3">
              <Col md={4}>
                <p className="c_p_title2">
                  Select Category<span className="cp_starik">*</span>
                  {/* {CategoryLoading ? (
                <Spinner
                  className="cat_loading"
                  animation="border"
                  role="status"
                ></Spinner>
              ) : (
                ""
              )} */}
                </p>
                <FormGroup className="select2-container  ">
                  <Select
                    options={CategoryOptions}
                    onChange={(e) => pkgCategory1Func(e)}
                    placeholder="Select Category"
                    classNamePrefix="select2-selection "
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <p className="c_p_title2">
                  Display (Hours)<span className="cp_starik">*</span>
                </p>
                <Input
                  type="number"
                  placeholder="Enter Display Hours"
                  onChange={(e) => pkgDisplayHours1Func(e)}
                />
              </Col>
              <Col md={2}>
                <p className="c_p_title2">
                  Minutes<span className="cp_starik">*</span>
                </p>
                <FormGroup className="select2-container  ">
                  <Select
                    options={timeOptions}
                    onChange={(e) => pkgDisplayMinutes1Func(e)}
                    placeholder="0"
                    classNamePrefix="select2-selection "
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <p className="c_p_title2">
                  Actual (Hours)<span className="cp_starik">*</span>
                </p>
                <Input
                  type="number"
                  placeholder="Enter Actual Hours"
                  onChange={(e) => pkgActualHours1Func(e)}
                />
              </Col>
              <Col md={2}>
                <p className="c_p_title2">
                  Minutes<span className="cp_starik">*</span>
                </p>

                <FormGroup
                  className="select2-container  "
                  style={{ width: "80%" }}
                >
                  <Select
                    options={timeOptions}
                    onChange={(e) => pkgActualMinutes1Func(e)}
                    placeholder="0"
                    classNamePrefix="select2-selection "
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3" hidden={secondState}>
              <Col md={2}>
                <Button
                  color="info"
                  outline
                  onClick={() => {
                    secondRowDataFunc();
                  }}
                >
                  Add more
                </Button>
              </Col>
            </Row>
            {/* **********Second Row******** */}
            <Row className="mt-3" hidden={secondRowData}>
              <Col md={4}>
                <p className="c_p_title2">
                  Select Category<span className="cp_starik">*</span>
                </p>
                <FormGroup className="select2-container  ">
                  <Select
                    options={CategoryOptions}
                    onChange={(opt) => pkgCategory2Func(opt)}
                    placeholder="Select Category"
                    classNamePrefix="select2-selection "
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <p className="c_p_title2">
                  Display (Hours)<span className="cp_starik">*</span>
                </p>
                <Input
                  type="number"
                  placeholder="Enter Display Hours"
                  onChange={(opt) => pkgDisplayHours2Func(opt)}
                />
              </Col>
              <Col md={2}>
                <p className="c_p_title2">
                  Minutes<span className="cp_starik">*</span>
                </p>
                <FormGroup className="select2-container  ">
                  <Select
                    options={timeOptions}
                    onChange={(opt) => pkgDisplayMinutes2Func(opt)}
                    placeholder="0"
                    classNamePrefix="select2-selection "
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <p className="c_p_title2">
                  Actual (Hours)<span className="cp_starik">*</span>
                </p>
                <Input
                  type="number"
                  placeholder="Enter Actual Hours"
                  onChange={(opt) => pkgActualHours2Func(opt)}
                />
              </Col>
              <Col md={2}>
                <p className="c_p_title2">
                  Minutes<span className="cp_starik">*</span>
                </p>

                <span style={{ display: "flex" }}>
                  <FormGroup
                    className="select2-container"
                    style={{ width: "80%" }}
                  >
                    <Select
                      options={timeOptions}
                      onChange={(opt) => pkgActualMinutes2Func(opt)}
                      placeholder="0"
                      classNamePrefix="select2-selection "
                    />
                  </FormGroup>
                  <i
                    hidden={thirdState}
                    className="delIcon mdi mdi-delete c_p_delbtn   ml-5"
                    onClick={rowSecondDellFunc}
                  ></i>
                </span>
              </Col>
            </Row>
            <Row className="mt-3" hidden={thirdState}>
              <Col md={2}>
                <Button
                  color="info"
                  outline
                  onClick={() => {
                    thirdRowDataFunc();
                  }}
                >
                  Add more
                </Button>
              </Col>
            </Row>
            {/* **********Third Row******** */}
            <Row className="mt-3" hidden={thirdRowData}>
              <Col md={4}>
                <p className="c_p_title2">
                  Select Category<span className="cp_starik">*</span>
                </p>
                <FormGroup className="select2-container  ">
                  <Select
                    options={CategoryOptions}
                    onChange={(opt) => pkgCategory3Func(opt)}
                    placeholder="Select Category"
                    classNamePrefix="select2-selection "
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <p className="c_p_title2">
                  Display (Hours)<span className="cp_starik">*</span>
                </p>
                <Input
                  type="number"
                  placeholder="Enter Display Hours"
                  onChange={(opt) => pkgDisplayHours3Func(opt)}
                />
              </Col>
              <Col md={2}>
                <p className="c_p_title2">
                  Minutes<span className="cp_starik">*</span>
                </p>
                <FormGroup className="select2-container  ">
                  <Select
                    options={timeOptions}
                    onChange={(opt) => pkgDisplayMinutes3Func(opt)}
                    placeholder="0"
                    classNamePrefix="select2-selection "
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <p className="c_p_title2">
                  Actual (Hours)<span className="cp_starik">*</span>
                </p>
                <Input
                  type="number"
                  placeholder="Enter Actual Hours"
                  onChange={(opt) => pkgActualHours3Func(opt)}
                />
              </Col>
              <Col md={2}>
                <p className="c_p_title2">
                  Minutes<span className="cp_starik">*</span>
                </p>

                <span style={{ display: "flex" }}>
                  <FormGroup
                    className="select2-container"
                    style={{ width: "80%" }}
                  >
                    <Select
                      options={timeOptions}
                      onChange={(opt) => pkgActualMinutes3Func(opt)}
                      placeholder="0"
                      classNamePrefix="select2-selection "
                    />
                  </FormGroup>

                  <i
                    className="delIcon mdi mdi-delete c_p_delbtn  ml-5"
                    onClick={rowThreeDellFunc}
                  ></i>
                </span>
              </Col>
            </Row>

            {/* ******************end************************* */}

            {/* practice start************************ */}

            {/* {teamArr.map((member, index) => {
          return (
            <div style={{ marginTop: "20px" }} key={index}>
              <Row className='mt-3'>
                <Col md={4}>
                  <p className='c_p_title2'>
                    Select Category<span className='cp_starik'>*</span>
                  </p>
                  <p>{member.id}</p>
                  <FormGroup className='select2-container  '>
                    <Select
                      options={CategoryOptions}
                      onChange={(opt) => pkgCategory3Func(opt)}
                      placeholder='Select Category'
                      classNamePrefix='select2-selection '
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <p className='c_p_title2'>
                    Display (Hours)<span className='cp_starik'>*</span>
                  </p>
                  <Input
                    value={{
                      value: member.DisplayHours,
                      label: member.DisplayHours,
                    }}
                    type='number'
                    placeholder='Enter Display Hours'
                    onChange={(opt) => pkgDisplayHours3Func(opt)}
                  />
                </Col>
                <Col md={2}>
                  <p className='c_p_title2'>
                    Minutes<span className='cp_starik'>*</span>
                  </p>
                  <FormGroup className='select2-container  '>
                    <Select
                      options={timeOptions}
                      onChange={(opt) => pkgDisplayMinutes3Func(opt)}
                      placeholder='0'
                      classNamePrefix='select2-selection '
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <p className='c_p_title2'>
                    Actual (Hours)<span className='cp_starik'>*</span>
                  </p>
                  <Input
                    type='number'
                    placeholder='Enter Actual Hours'
                    onChange={(opt) => pkgActualHours3Func(opt)}
                  />
                </Col>
                <Col md={2}>
                  <p className='c_p_title2'>
                    Minutes<span className='cp_starik'>*</span>
                  </p>

                  <span style={{ display: "flex" }}>
                    <FormGroup
                      className='select2-container'
                      style={{ width: "80%" }}
                    >
                      <Select
                        options={timeOptions}
                        onChange={(opt) => pkgActualMinutes3Func(opt)}
                        placeholder='0'
                        classNamePrefix='select2-selection '
                      />
                    </FormGroup>

                    <i
                      className='delIcon mdi mdi-delete c_p_delbtn  ml-5'
                      onClick={rowThreeDellFunc}
                    ></i>
                  </span>
                </Col>
              </Row>
            </div>
          );
        })}
        <Button onClick={() => addmorepracticefunc()} className='mt-3'>
          Add more
        </Button> */}

            {/* practice end(((((***********<><<>>>>>>>>>></></>))))) */}

            <Row className="mt-5">
              <Col md={12}>
                <p className="c_p_title">Define package limits</p>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <span className="c_p_title2">
                  Validity<span className="cp_starik">*</span>
                </span>
                <FormGroup className="select2-container  ">
                  <Select
                    className="mt-3"
                    options={PackageValidityOption}
                    onChange={(opt) => pkgValidityFunc(opt)}
                    placeholder="Select Validity"
                    classNamePrefix="select2-selection "
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <span className="c_p_title2 ">
                  Location <span className="cp_starik">*</span>
                </span>
                {loadingLocations ? (
                  <Spinner
                    className="cat_loading"
                    animation="border"
                    role="status"
                  ></Spinner>
                ) : (
                  ""
                )}

                <FormGroup className="select2-container  ">
                  <Select
                    isMulti={true}
                    className="mt-3"
                    options={locationsData}
                    onChange={(opt) => pkgLocationFunc(opt)}
                    placeholder="Select Location"
                    classNamePrefix="select2-selection "
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <span className="c_p_title2">
                  Inventory<span className="cp_starik">*</span>
                </span>
                <Input
                  className="mt-3"
                  type="number"
                  placeholder="Enter Inventory"
                  onChange={(opt) => pkgInventoryFunc(opt)}
                />
              </Col>
              <Col md={6}>
                <span className="c_p_title2">
                  Priority<span className="cp_starik">*</span>
                </span>
                <Input
                  className="mt-3"
                  type="number"
                  placeholder="Enter Priority"
                  onChange={(opt) => pkgPriorityFunc(opt)}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={1}>
                <span className="c_p_title2 mt-2">Tag</span>
              </Col>
              <Col md={2}>
                <Switch
                  name="always"
                  onHandleColor="#16b185"
                  width={70}
                  uncheckedIcon={Offsymbol(<small>NO</small>)}
                  checkedIcon={OnSymbol(<small>YES</small>)}
                  onColor="#a2a2a2"
                  checked={
                    createPackageObj?.TagToggle
                      ? createPackageObj?.TagToggle
                      : false
                  }
                  onChange={(e) => pkgTagToggleFunc(e)}
                  className="mr-1 mt-1  "
                />
              </Col>
              <Col md={3}></Col>
              <Col md={6}>
                <span className="c_p_title2  mt-2">Customers</span>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={6}>
                <Input
                  maxLength="20"
                  placeholder="Enter Tag"
                  onChange={(e) => pkgTagInputFunc(e)}
                />
              </Col>
              <Col md={6}>
                <FormGroup className="select2-container  ">
                  <Select
                    cacheOptions={false}
                    isLoading={CustomerSearchLoading}
                    isMulti={true}
                    options={packagesCustomerSearchData?.data}
                    onChange={(opt) => pkgCustomersFunc(opt)}
                    onInputChange={handleCustomerInputChange}
                    placeholder="All Customers"
                    classNamePrefix="select2-selection "
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col md={12}>
                <p className="c_p_title">Refund Limits</p>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col md={3}>
                <p className="c_p_title2">
                  Hours<span className="cp_starik">*</span>
                </p>
              </Col>
              <Col md={3}>
                <p className="c_p_title2">
                  Minutes<span className="cp_starik">*</span>
                </p>
              </Col>
              <Col md={6}>
                <p className="c_p_title2">Category</p>
              </Col>
            </Row>
            <Row className="">
              <Col md={3}>
                <Input placeholder="i.e 10 hours" onChange={refundableHours} />
              </Col>
              <Col md={3}>
                <Input
                  placeholder="i.e 30 minutes"
                  onChange={refundableMinutes}
                />
              </Col>
              <Col md={6}>
                <Input
                  className="d_pkg_all_inp"
                  value={createPackageObj?.type ? createPackageObj?.type : ""}
                  onChange={(e) => {
                    setCreatePackageObj({
                      ...createPackageObj,
                      type: e?.target?.value,
                    });
                  }}
                />
              </Col>
            </Row>

            <Row className="">
              <Col md={6}>
                <p className="c_p_light_text">
                  If user's has used less than or equal to defined limit than
                  they will able to get a refund
                </p>
              </Col>
              <Col md={6}></Col>
            </Row>

            <Row className="mt-5">
              <Col md={8}>
                <p className="c_p_title">
                  Set Package Image<span className="cp_starik">*</span>
                </p>
              </Col>

              <Col md={2}>
                {testImg === undefined ? (
                  ""
                ) : (
                  <Button
                    hidden={confirmHidden}
                    color="success"
                    onClick={confirmActualImg}
                    className="w-100"
                  >
                    Confirm
                  </Button>
                )}
              </Col>
              <Col md={2}>
                {testImg === undefined ? (
                  ""
                ) : (
                  <Button
                    color="danger"
                    outline
                    className="c_p_title2 w-100"
                    onClick={() => {
                      setSelectedFiles("");
                      setImgCC();
                      setTestImg();
                      setConfirmHidden(false);
                    }}
                  >
                    Remove
                  </Button>
                )}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={12}>
                {/* *********start******* */}
                <div>
                  {testImg == null ? (
                    <Form className="dropzone">
                      <Dropzone
                        // accept={".pdf"}
                        onDrop={(acceptedFiles) => {
                          handleAcceptedFiles(acceptedFiles);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div>
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />

                              <div className="mb-3">
                                <i className="display-4 text-muted ri-upload-cloud-2-line" />
                              </div>
                              <h4>Drop Image here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </Form>
                  ) : (
                    <Form
                      className="dropzone"
                      style={{ overflowX: "auto", maxHeight: "30px" }}
                    >
                      <Dropzone>
                        {() => (
                          <img
                            key="dwejf9"
                            src={testImg[0]?.preview}
                            alt="img"
                          />
                        )}
                      </Dropzone>
                    </Form>
                  )}
                </div>

                {/* *********End*********** */}
              </Col>
            </Row>
            {testImg ? (
              <Row hidden={true}>
                <img src={testImg[0]?.preview} onLoad={loadedImgCover} />
              </Row>
            ) : (
              ""
            )}
            {/* ********Crop start******* */}
            <Row className=" mt-5 ">
              <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                {/* {imgCC ? ( */}
                {selectedFiles !== "" ? (
                  <Row>
                    <p className="c_p_title">
                      Package Card View<span className="cp_starik">*</span>
                    </p>
                    <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                      <p className="c_p_light_text">
                        Use click and drag to adjust image in view
                      </p>
                    </Col>
                    {cardImageButton ? (
                      <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                        <Button
                          color="success"
                          style={{ marginLeft: "13px" }}
                          disabled
                          className="w-100 "
                        >
                          Saved
                        </Button>
                      </Col>
                    ) : (
                      <Col
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        onClick={() => cardViewBase64Func()}
                      >
                        <Button
                          outline
                          color="secondary"
                          className="w-100 "
                          style={{ marginLeft: "13px" }}
                        >
                          <span className="">Save</span>
                        </Button>
                      </Col>
                    )}
                  </Row>
                ) : (
                  ""
                )}
              </Col>
              <Col xl={1} lg={1} md={1} sm={12} xs={12}></Col>
              <Col
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                // style={{ paddingRight: "50px" }}
              >
                {/* {imgCC ? ( */}
                {selectedFiles !== "" ? (
                  <>
                    <Row>
                      <p className="c_p_title">
                        Package Detail View<span className="cp_starik">*</span>
                      </p>
                      <Col md={8}>
                        <p className="c_p_light_text">
                          Use click and drag to adjust image in view
                        </p>
                      </Col>
                      {detailImageButton ? (
                        <Col md={4}>
                          <Button color="success" disabled className="w-100 ">
                            Saved
                          </Button>
                        </Col>
                      ) : (
                        <Col md={4} onClick={detailedViewBase64Func}>
                          <Button outline color="secondary" className="w-100 ">
                            <span className="">Save</span>
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Container fluid>
              <Row className="mb-4">
                <Col
                  xl={5}
                  lg={5}
                  md={5}
                  sm={12}
                  xs={12}
                  style={
                    {
                      // width: "40.3%",
                    }
                  }
                >
                  {/* {imgCC ? ( */}
                  {selectedFiles ? (
                    <Form className="dropzone ">
                      <Cropper
                        image={imgCC[0]?.preview}
                        crop={crop}
                        zoom={zoom}
                        crossorigin="anonymous"
                        objectFit="contain"
                        onCropChange={(e) => setCrop(e)}
                        onCropComplete={onCropCompleteProfile}
                        onZoomChange={(e) => setZoom(e)}
                        onMediaLoaded={(mediaSize) => {
                          setProfileMedia(mediaSize);
                        }}
                      />
                    </Form>
                  ) : (
                    ""
                  )}
                </Col>
                <Col xl={1} lg={1} md={1} sm={12} xs={12}></Col>

                <Col
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  style={
                    {
                      // width: "48.5%",
                      // marginLeft: "28px",
                    }
                  }
                >
                  {/* {imgCC ? ( */}
                  {selectedFiles ? (
                    <Form className="dropzone ">
                      <Cropper
                        image={imgCC[0]?.preview}
                        crop={cropCover}
                        zoom={zoomCover}
                        aspect={6 / 2}
                        crossorigin="anonymous"
                        objectFit="contain"
                        onCropChange={(e) => setCropCover(e)}
                        onCropComplete={onCropCompleteCover}
                        onZoomChange={(e) => setZoomCover(e)}
                        onMediaLoaded={(mediaSize) => {
                          setCoverMedia(mediaSize);
                        }}
                      />
                    </Form>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Container>
            {/* {imgCC ? ( */}
            {selectedFiles ? (
              <Row>
                <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                  <div className="controls">
                    <span className="c_p_title2">Set Card View Image</span>

                    <Input
                      style={{ marginLeft: "10px " }}
                      type="range"
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      onChange={(e) => {
                        setZoom(e.target.value);
                      }}
                      className="zoom-range"
                    />
                    {cardViewLoading ? (
                      <Spinner
                        className="cat_loading"
                        animation="border"
                        role="status"
                      ></Spinner>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                <Col xl={1} lg={1} md={1} sm={12} xs={12}></Col>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                  <div className="controls">
                    <span className="c_p_title2">Set Detail View Image</span>

                    <input
                      style={{ marginLeft: "10px " }}
                      type="range"
                      value={zoomCover}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      onChange={(e) => {
                        setZoomCover(e.target.value);
                      }}
                      className="zoom-range"
                    />

                    {detailedViewLoading ? (
                      <Spinner
                        className="cat_loading"
                        animation="border"
                        role="status"
                      ></Spinner>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}

            <Row className="mt-3">
              <Col md={1}></Col>
              <Col md={6}></Col>
            </Row>

            {imgCC ? <hr /> : ""}
            {/* **********crop end********** */}
            <Row className="mt-3">
              <Col md={12}>
                <p className="c_p_title">
                  Terms & Conditions<span className="cp_starik">*</span>
                </p>
                <Input
                  type="textarea"
                  onChange={(opt) => pkgConditionsFunc(opt)}
                  placeholder="Terms & Conditions"
                />
              </Col>
            </Row>
          </Card>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default CreatePackages;
