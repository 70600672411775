import { useEffect, useState } from "react";
import { getAllLocations } from "../../../../APIS";
import { useAllDataQuery } from "../../../../hooks/query";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { DIOM_BASED_URLS } from "../../../../config/url";
import { useHistory } from "react-router-dom";
import { getPlanDiscount } from "../../../../APIS/planDiscounts";
const token = localStorage.getItem("Token");

export const useNextStep = () => {
  const history = useHistory();
  const [locations, setLocations] = useState([]);
  const [locationRTC, setLocationRTC] = useState([]);
  const [selectedRTC, setSelectedRTC] = useState([]);
  const [selectedPlans, setSelectedPlans] = useState([]);
  // const [selectedRTCPlans, setselectedRTCPlans] = useState([]);
  const [selectedResourceTypeForPlans, setSelectedResourceTypeForPlans] =
    useState([]);
  const [locationModel, setlocationModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [submitBtnDisable, setsubmitBtnDisable] = useState(true);
  const [allSubmitBtnDisable, setAllSubmitBtnDisable] = useState(false);
  const [hideMessageRow, setHideMessageRow] = useState(false);
  const [editBtnShow, setEditBtnShow] = useState(false);
  const [formDisable, setFormDisable] = useState(false);
  const [activeCancelBtn, setactiveCancelBtn] = useState(false);
  const [activeSubmit2, setActiveSubmit2] = useState(false);
  const [inactiveLocation, setInactiveLocation] = useState(false);
  const [showCancelConfirmModel, setShowCancelConfirmModel] = useState(false);
  const [IdAllReadyselectedLocation, setIdAllReadyselectedLocation] =
    useState("");
  const [locationId, setlocationId] = useState("");
  const [RTCId, setRTCId] = useState("");
  const [selectedPlanForEdit, setSelectedPlanForEdit] = useState([]);
  const [selectedLocationRTC, setSelectedLocationRTC] = useState([]);
  const [selectedLocationRTCCard, setSelectedLocationRTCCard] = useState([]);
  const [allLocationData, setAllLocationData] = useState([]);
  const [isAllLocationsSelected, setIsAllLocationsSelected] = useState(false);
  const [selectedLocationDataForAPI, setSelectedLocationDataForAPI] = useState(
    []
  );
  // Get Discount ID from Url
  const { id } = useParams();

  //* 0)--> Get All Business Locations
  const { data: locationsData, isLoading: isLocationGet } = useAllDataQuery(
    ["allLocations"],
    () => getAllLocations(token)
  );

  //* 1)--> Get All Selected Business Locations
  const { data: selectedLocationsForDiscount, isLoading } = useAllDataQuery(
    ["planDiscount", id],
    () => getPlanDiscount(token, id)
  );

  //* 2)--> Get Only Locations
  useEffect(() => {
    if (!isLocationGet) {
      const locations = locationsData.map((location) => {
        return {
          id: location.id,
          name: location.Name,
          selected: false,
        };
      });

      setLocations(locations);
    }
  }, [isLocationGet, locationsData]);

  // Check All locations is Selected
  useEffect(() => {
    // 3) Check All locations selected
    const isAllSelected = locations.every((location) => {
      return selectedLocationsForDiscount.some(
        (selectedLocation) => selectedLocation.businessId === location.id
      );
    });
    //Disable All locations Btn
    setIsAllLocationsSelected(isAllSelected);
    // console.log(
    //   isAllSelected,
    //   locations,
    //   "locations",
    //   id,
    //   selectedLocationsForDiscount,
    //   ">>>>>>>isAllSelected"
    // );
  }, [selectedLocationsForDiscount, locations]);

  //* 3)--> Fn To handle location model
  function locationModelHandle(locationId) {
    // Check If data have in selectedRTC then model show and set value selectedRTC to initiall
    if (selectedRTC.length === 0) {
      selectedLocationIdHandler(locationId);
    } else {
      setlocationId(locationId);
      setlocationModel(true);
    }
  }
  // console.log(
  //   selectedLocationsForDiscount,
  //   "selectedLocationsForDiscount",
  //   isLoading,
  //   "isLoading",
  //   isLocationGet,
  //   "isLocationGet",
  //   "Before>>>>>>>>>>>"
  // );
  //* 4)--> Get Selected location Id and return RTC of that location
  function selectedLocationIdHandler(locationId) {
    // 0.1 Set Edit Btn Hide
    setEditBtnShow(false);
    // 0) Set this location this id will use to show cancel btn and active and deactive form
    setIdAllReadyselectedLocation(locationId);
    //1) setModel Hide
    setlocationModel(false);
    // 2)Here we Marked location selected.
    setLocations((pre) => {
      return pre.map((item) => {
        return {
          ...item,
          selected: item.id === locationId ? true : false,
        };
      });
    });
    // console.log(
    //   selectedLocationsForDiscount,
    //   "selectedLocationsForDiscount",
    //   isLoading,
    //   "isLoading",
    //   isLocationGet,
    //   "isLocationGet",
    //   "In IF Block"
    // );
    // 4) Check if this location is already selected then selected RTC set
    if (
      selectedLocationsForDiscount?.some(
        (location) => location.businessId === locationId
      )
    ) {
      //1) Filter selectedLocationsForDiscount array and take that click Location
      const alreadySelectedLocation = selectedLocationsForDiscount.filter(
        (location) => location.businessId === locationId
      );

      //2) Here we get new locationRTC
      const selectedLocationRTC = locationsData
        .filter((location) => location.id === locationId)
        .map((location) => location.resourceTypeCategories)
        .flat()
        .filter(
          (category) => category.isMembershipAvailable && category.available
        )
        .map((locationRTC) => {
          return {
            id: locationRTC.id,
            name: locationRTC.name,
            available: locationRTC.available,
            selected: alreadySelectedLocation[0].selectedRTC.some(
              (RTC) => RTC.id === locationRTC.id
            ),
            resourceTypes: locationRTC?.resourceTypes.map((resourceType) => ({
              ...resourceType,
              resourceTypePlans: resourceType?.resourceTypePlans?.map(
                (resourceTypePlan) => ({
                  Id: resourceTypePlan?.Id,
                  Name: resourceTypePlan?.Name,
                  resourceTypeName: resourceType.name,
                  resourceTypeId: resourceType.id,
                  selected: false,
                })
              ),
            })),
          };
        });

      // 3) set  selected RTC
      setLocationRTC(selectedLocationRTC);
      // 4) Hide Message Row
      setHideMessageRow(true);
      const locationRTC = locationsData
        .filter((location) => location.id === locationId)
        .map((location) => location.resourceTypeCategories)
        .flat()
        .filter(
          (category) => category.available && category.isMembershipAvailable
        );

      // 5)Create data for selecte RTC card
      const selectedRTC = alreadySelectedLocation[0].selectedRTC.map((RTC) => ({
        id: RTC.id,
        name: RTC.name,
        resourceTypes: locationRTC
          .filter((LRTC) => LRTC.id === RTC.id)
          .map((RTC) => RTC.resourceTypes)
          .flat()
          .map((resourceType) => ({
            ...resourceType,
            resourceTypePlans: resourceType.resourceTypePlans.map(
              (resourceTypePlan) => ({
                Id: resourceTypePlan?.Id,
                Name: resourceTypePlan?.Name,
                resourceTypeName: resourceType.name,
                resourceTypeId: resourceType.id,
                selected: false,
              })
            ),
          })),
        RTPlans: [],
        userSelectedPlans: RTC.selectedResourceTypes.map((RT) => ({
          resourceTypeName: RT.name,
          resourceTypeId: RT.id,
          plans: RT.plans,
        })),
      }));

      // 6) Set date For selected RTC for Selected Location
      setSelectedRTC(selectedRTC);
      // 7) show edit btn
      setEditBtnShow(true);
      // 8) form disable beacuse this location already selected initiall selectd data not edit able
      setFormDisable(true);
      // 9) save selected location Id for active Cancel btn
      setIdAllReadyselectedLocation(locationId);
      // 10) Set selected RTC for Cancel model
      setSelectedLocationRTC(selectedLocationRTC);
      // 11) Set selected RTC Card data
      setSelectedLocationRTCCard(selectedRTC);

      // console.log(
      //   " This location is selected and RTC are seting...",
      //   selectedLocationRTC,
      //   selectedRTC,
      //   "selectedRTC"
      // );
      return;
    }

    //? Initial flow when no RTC selected
    setSelectedRTC([]);
    //6) Here we get new locationRTC
    const selectedLocationRTC = locationsData
      .filter((location) => location.id === locationId)
      .map((location) => location.resourceTypeCategories)
      .flat()
      .filter(
        (category) => category.available && category.isMembershipAvailable
      ) // return Only those RTC which have MemberShip
      .map((locationRTC) => {
        return {
          id: locationRTC.id,
          name: locationRTC.name,
          selected: false,
          resourceTypes: locationRTC?.resourceTypes.map((resourceType) => ({
            ...resourceType,
            resourceTypePlans: resourceType?.resourceTypePlans?.map(
              (resourceTypePlan) => ({
                Id: resourceTypePlan?.Id,
                Name: resourceTypePlan?.Name,
                resourceTypeName: resourceType.name,
                resourceTypeId: resourceType.id,
                selected: false,
              })
            ),
          })),
        };
      });
    //7) Here we assign new LocationRTC
    setLocationRTC(selectedLocationRTC);
    // 8) Hide Message Row
    setHideMessageRow(true);
  }

  //* 5)--> Handler RTC selection
  function selectRTCHandler(sltedRTC) {
    // 00) Hide cancel btn when user come first time to select location
    // setactiveCancelBtn(false);
    //0) Set True one contidion to active submit btn
    setActiveSubmit2(true);
    if (
      selectedLocationsForDiscount.some(
        (location) => location.businessId === IdAllReadyselectedLocation
      )
    ) {
      setActiveSubmit2(true);
      setactiveCancelBtn(true);
    }

    //1) Check if the selectedRTC array already contains an RTC with the same id
    const existingIndex = selectedRTC.findIndex(
      (rtc) => rtc.id === sltedRTC.id
    );
    // 2) get all RTC for All Selected btn
    const allRTCSelected = locationRTC.map((RTC) => {
      return {
        id: RTC.id,
        name: RTC.name,
        resourceTypes: RTC.resourceTypes,
        RTPlans: [],
        userSelectedPlans: [],
      };
    });

    // 3)All Case...
    if (sltedRTC === "all") {
      // console.log("Fn Called and A case ", sltedRTC);
      //4) Here we checking if already all RTC selected then its all remove case and remove it
      if (selectedRTC.length === locationRTC.length) {
        setLocationRTC((pre) => {
          return pre.map((preLRTC) => {
            return { ...preLRTC, selected: false };
          });
        });
        return setSelectedRTC([]);
      } else {
        // 5) Here we Marking all selected RTC True
        setLocationRTC((pre) => {
          return pre.map((preLRTC) => {
            return { ...preLRTC, selected: true };
          });
        });
        // 6) Here I assign all RTC for next Card
        return setSelectedRTC(allRTCSelected);
      }
    }

    if (existingIndex !== -1) {
      //7) If exists, remove the existing selected RTC
      setSelectedRTC((prevSelectedRTC) =>
        prevSelectedRTC.filter((rtc, index) => index !== existingIndex)
      );

      //8) Unchecked This RTC also
      setLocationRTC((prevLocationRTC) => {
        return prevLocationRTC.map((LRTC) => {
          if (LRTC.id === sltedRTC.id) {
            return { ...LRTC, selected: false };
          } else {
            return LRTC;
          }
        });
      });
    } else {
      //9) If not exists, add a new object to the array
      setSelectedRTC((prevSelectedRTC) => [
        ...prevSelectedRTC,
        {
          id: sltedRTC.id,
          name: sltedRTC.name,
          resourceTypes: sltedRTC.resourceTypes,

          RTPlans: [],
          userSelectedPlans: [],
        },
      ]);
      // 10)Set Checked also that RTC
      setLocationRTC((prevLocationRTC) => {
        return prevLocationRTC.map((LRTC) => {
          if (LRTC.id === sltedRTC.id) {
            return { ...LRTC, selected: true };
          } else {
            return LRTC;
          }
        });
      });
    }
  }
  // console.log(selectedRTC, "selectedRTC>>>>>>>");
  //* 6)--> Get plans on the basis of selected RT
  function handleResourceTypeChange(
    ResourceTypes,
    selectedResourceType,
    RTCId
  ) {
    //1)--> Set selected ResourceType for selected Plans List
    setSelectedResourceTypeForPlans(selectedResourceType);
    // console.log(ResourceTypes, selectedResourceType, RTCId, ">>>>>>>>>>");

    // 2) Check this RT have plans
    const selectedRT = ResourceTypes.find(
      (RT) => RT.id === selectedResourceType.value
    );
    // 3) if not show error
    if (!selectedRT || !selectedRT.resourceTypePlans) {
      // Plans not exist, show an error message and return early
      toast.error("No Plans for this ResourceType");
      return;
    }

    // 4) Find plans for selected RT
    const selectedRTPlans = ResourceTypes.filter(
      (RT) => RT.id === selectedResourceType.value
    )
      .map((RT) => {
        return RT.resourceTypePlans || toast.error("Plans not exist");
      })
      .flat()
      .map((plan) => ({
        id: plan?.Id,
        name: plan?.Name,
        resourceTypeName: selectedResourceType.label,
        resourceTypeId: selectedResourceType.value,
        selected: false,
      }));

    // 5)Set RTPlans Of Selected RTC
    setSelectedRTC((prevRTC) => {
      return prevRTC.map((RTC) => {
        // This check find RTC in which Category show list of plans then user assign
        if (RTC.id === RTCId) {
          // Update the RTPlans property of the matched object
          return {
            ...RTC,
            RTPlans: selectedRTPlans,
          };
        }
        return RTC; // Return the unchanged object if it doesn't match the condition
      });
    });
  }

  // * 7)--> Set select plans
  function selectPlansHandler(selectedPlan, RTCId, RTCPlans) {
    //0) Set True one contidion to active submit btn
    setActiveSubmit2(true);
    if (
      selectedLocationsForDiscount.some(
        (location) => location.businessId === IdAllReadyselectedLocation
      )
    ) {
      setActiveSubmit2(true);
      setactiveCancelBtn(true);
    }
    // 1)Update selected plan property selected: false to true from RTCPlans
    const updateRTCPlans = RTCPlans.map((RTCPlan) => {
      if (RTCPlan.id === selectedPlan.id) {
        return {
          ...RTCPlan,
          selected: !RTCPlan.selected,
        };
      }

      return RTCPlan;
    });

    // console.log(updateRTCPlans, "upDateRTCPlans>>>>>");
    // 2) updated this selected RTC property RTCPlans array
    setSelectedRTC((prevRTC) => {
      return prevRTC.map((RTC) => {
        if (RTC.id === RTCId) {
          return {
            ...RTC,
            RTPlans: updateRTCPlans,
          };
        }
        return RTC;
      });
    });
  }

  // console.log(selectedPlans, "selectedPlan>>>>");

  //* 8)--> Assign btn Handler
  function assignPlanHandler(RTCId, selectedReourceType, SelectedPlans) {
    // Set submitBtn Active
    setsubmitBtnDisable(false);
    //1) Set Empty RTPlans
    setSelectedRTC((prevRTC) => {
      return prevRTC.map((RTC) => {
        if (RTC.id === RTCId) {
          return {
            ...RTC,
            RTPlans: [], // When Click on Assign Btn plans list should be empty
          };
        }
        return RTC; // Return the unchanged object if it doesn't match the condition
      });
    });
    // console.log(
    //   selectedReourceType,
    //   "selectedReourceType>",
    //   SelectedPlans,
    //   "SelectedPlans",
    //   RTCId,
    //   "RTCId"
    // );

    // 2) Prepare selected Resource For List
    const userSelectedPlans = {
      resourceTypeName: selectedReourceType.resourceTypeName,
      resourceTypeId: selectedReourceType.resourceTypeId,
      plans: SelectedPlans.map(({ id, ...rest }) => {
        // Create a new object with the property `planId` and without `id`
        return { ...rest, planId: id };
      }),
    };

    // console.log(userSelectedPlans, "userSelectedPlans>>>>>>>>");
    // 3)Then set selected Plans to specific RTC
    setSelectedRTC((prevRTC) => {
      return prevRTC.map((RTC) => {
        if (RTC.id === RTCId) {
          return {
            ...RTC,
            userSelectedPlans: [...RTC.userSelectedPlans, userSelectedPlans],
          };
        }
        return RTC;
      });
    });
  }
  //* 9)--> RTC All RT Plans selected Fn Handler
  function allRTPlansSelectedHandler(RTCResourceTypes, RTCId) {
    //0) Set True one contidion to active submit btn
    setActiveSubmit2(true);

    if (
      selectedLocationsForDiscount.some(
        (location) => location.businessId === IdAllReadyselectedLocation
      )
    ) {
      setActiveSubmit2(true);

      setactiveCancelBtn(true);
    }
    // Set submitBtn Active
    setsubmitBtnDisable((pre) => !pre);

    // 1) Transformed object into according to selected Resource
    const transformedArray = RTCResourceTypes?.map((RTCResourceType) => ({
      resourceTypeName: RTCResourceType.name,
      resourceTypeId: RTCResourceType.id,
      plans: RTCResourceType.resourceTypePlans?.map((resourceTypePlan) => {
        return {
          name: resourceTypePlan.Name,
          planId: resourceTypePlan.Id,
          resourceTypeName: RTCResourceType.name,
          resourceTypeId: RTCResourceType.id,
          selected: true,
        };
      }),
    }));
    //  2) assign this selected plans to specific RTC selectedPlans property
    setSelectedRTC((prevRTC) => {
      return prevRTC.map((RTC) => {
        if (RTC.id === RTCId) {
          return {
            ...RTC,
            RTPlans: [], //Remove beacuse all btn clicked
            userSelectedPlans:
              RTC.userSelectedPlans?.every((userPlan) =>
                userPlan?.plans?.every((plan) => plan?.selected)
              ) && RTC.userSelectedPlans.length === RTC.resourceTypes.length
                ? []
                : transformedArray,
          };
        }
        return RTC;
      });
    });

    // console.log(transformedArray, RTCId, "All RT Selected>>");
  }
  //* 10)--> Selected plan edit fn
  function planEditHandler(plan, RTCId) {
    
    //1) Set RTC for edit model save change btn
    setRTCId(RTCId);
    //2) Set editable plan for edit model
    setSelectedPlanForEdit((preSelectPlan) => [ plan]);
    //3) Set edit model open
    setEditModel(true);
   
  }
  // console.log(selectedPlanForEdit, "selectedPlanForEdit>>>>>>>");

  // * 11)--> Submit Fn for API Call
  async function submitHandler() {
    setSelectedRTC([]);
    // 1)set SubmitBtn Disable perLocation API
    setsubmitBtnDisable(true);

    // 2)get Selected location object
    const selectedLocation = locations.find((location) => location.selected);

    // 3) Check if the selected location is already in the list
    // if (
    //   selectedLocationDataForAPI.some(
    //     (location) => location.businessId === selectedLocation.id
    //   )
    // ) {
    //   toast.error("This location is already selected");
    //   return;
    // }

    // 4) Create location data for the current selected location
    const locationData = [
      {
        businessId: selectedLocation.id,
        businessName: selectedLocation.name,
        selectedRTC: selectedRTC.map((selectedRTC) => {
          return {
            id: selectedRTC.id,
            name: selectedRTC.name,
            selectedResourceTypes: selectedRTC.userSelectedPlans.map(
              (userSelectedPlan) => {
                return {
                  id: userSelectedPlan.resourceTypeId,
                  name: userSelectedPlan.resourceTypeName,
                  plans: userSelectedPlan.plans,
                };
              }
            ),
          };
        }),
      },
    ];

    // 5) Update state variable to hold this location data

    const selectedLocationData = selectedLocationsForDiscount.filter(
      (location) => location.businessId !== selectedLocation.id
    );
    // 6) Data for API Call
    const DataForAPI = [...locationData, ...selectedLocationData];

    // console.log(DataForAPI, ">>>>>>>>>DataForAPI>>>>>>>>>");
    //7) API Call
    const body = { planResources: DataForAPI };
    const response = await fetch(`${DIOM_BASED_URLS}/plan-discounts/${id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      toast.error(" Something Went Wrong");
    } else {
      toast.success("Code Updated successfully");
      // Navigate to
      history.push(`/plan-discounts`);
    }
  }

  // *12) All location checkbox handler
  function allLocationSelectedHandler() {
    //*1) Set Individual all location Inactive
    setInactiveLocation((pre) => !pre);
    setLocations((pre) => {
      return pre.map((item) => {
        return {
          ...item,
          selected: false,
        };
      });
    });
    setHideMessageRow(false);
    setLocationRTC([]);
    setSelectedRTC([]);

    // 1) manipulate data according to API final object
    const allLocationData = locationsData.map((location) => {
      return {
        businessId: location.id,
        businessName: location.Name,
        selectedRTC: location.resourceTypeCategories
          .filter((RTC) => RTC.isMembershipAvailable && RTC.available)
          .map((RTC) => ({
            id: RTC.id,
            name: RTC.name,
            selectedResourceTypes: RTC.resourceTypes.map((RT) => ({
              id: RT.id,
              name: RT.name,
              plans: RT.resourceTypePlans.map((RTplan) => ({
                planId: RTplan.Id,
                name: RTplan.Name,
                resourceTypeName: RT.name,
                resourceTypeId: RT.id,
                selected: true,
              })),
            })),
          })),
      };
    });
    // Set All Location Data
    setAllLocationData(allLocationData);
    // Show and active Submit Btn
    setAllSubmitBtnDisable((pre) => !pre);
    // Hide Cancel Btn

    console.log(allLocationData, "All Location Selected");
  }
  //*13) All location submit handler
  async function allLocationSubmitHandler() {
    //1) API Call
    const body = { planResources: allLocationData };
    const response = await fetch(`${DIOM_BASED_URLS}/plan-discounts/${id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      toast.error(" Something Went Wrong");
    } else {
      toast.success("Code Updated successfully");
      // Navigate to
      history.push(`/plan-discounts`);
    }
  }
  //* 14) Cancel hadler to show cancel confirmation model
  function cancelBtnHandler() {
    setActiveSubmit2(false);
    // 2)show cancel modelS
    setShowCancelConfirmModel(true);
  }
  // 15)* Cancel  Model Yes Btn handler
  function chnageCancelHandler() {
    // 1) Set initiall selected RTC data
    setLocationRTC(selectedLocationRTC);
    // 2) Set initiall selected RTC Card Data
    setSelectedRTC(selectedLocationRTCCard);
    // 3) Hide the cancel Model
    setShowCancelConfirmModel(false);
    // 4)set form Disable
    setFormDisable(true);
    // 5)Active  previously selected Btn
    setLocations((pre) => {
      return pre.map((item) => {
        return {
          ...item,
          selected: item.id === IdAllReadyselectedLocation ? true : false,
        };
      });
    });
    // 6) set inactiveLocation false
    setInactiveLocation(false);
    // 7) set edit Btn Active
    setEditBtnShow(true);
    //8) Hide Cancel Btn
    setactiveCancelBtn(false);
  }
  return {
    locationsData,
    isLocationGet,
    isLoading,
    locations,
    selectedLocationIdHandler,
    locationRTC,
    selectRTCHandler,
    selectedRTC,
    handleResourceTypeChange,
    setSelectedPlans,
    selectedPlans,
    selectPlansHandler,
    assignPlanHandler,
    allRTPlansSelectedHandler,
    locationModelHandle,
    locationModel,
    locationId,
    setlocationModel,
    planEditHandler,
    selectedPlanForEdit,
    editModel,
    selectedResourceTypeForPlans,
    submitHandler,
    setSelectedLocationDataForAPI,
    submitBtnDisable,
    allLocationSelectedHandler,
    allSubmitBtnDisable,
    hideMessageRow,
    allLocationSubmitHandler,
    selectedLocationsForDiscount,
    editBtnShow,
    formDisable,
    setFormDisable,
    activeCancelBtn,
    cancelBtnHandler,
    showCancelConfirmModel,
    setShowCancelConfirmModel,
    chnageCancelHandler,
    activeSubmit2,
    setactiveCancelBtn,
    inactiveLocation,
    isAllLocationsSelected,
    setEditBtnShow,
    setEditModel,
    id
  };
};
