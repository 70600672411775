import moment from "moment";
import { DIOM_BASED_URLS } from "../config/url";

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns a list of all userProfile in DIOM
 */
export const getplandiscount = async (token, id) => {
  if (id) {
    const res = await fetch(
      `${DIOM_BASED_URLS}/plan-discounts/${id}`,

      {
        method: "GET",
        redirect: "follow",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    const data = await res.json();

    return {
      status: data.active,
      name: data.code,
      percentage: data.discountPercentage,
      startDate: data.startDate,
      endDate: data.endDate,
      allowedUsers: data.allowedUsers,
      maxUsePerUser: data.maxUsesPerUser,
    };
  } else {
    return {
      data: "No ID provided",
    };
  }
};

export const getallplandiscounts = async (token, active) => {
  // console.log(active, "Code Active from API File>>>>");
  const res = await fetch(
    `${DIOM_BASED_URLS}/plan-discounts?filter={"where":{"active":${active}},"order":["createdAt DESC"]}`,

    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  const data = await res.json();
  // console.log(data, "From API PAge");
  const planDiscounts = data.data.map((discount, index) => {
    return {
      no: index + 1,
      _id: discount.id,
      code: discount.code,
      active: discount.active,
      discountPercentage: discount.discountPercentage,
      startDate: moment(discount.startDate).format("YYYY-MM-DD HH:mm"),
      timesUsed: discount.timesUsed,
      lastTimeUsed: discount?.lastTimeUsed
        ? moment(discount?.lastTimeUsed).format("YYYY-MM-DD HH:mm")
        : "Not Used Yet",
    };
  });

  return { data: planDiscounts };
};

export const getPlanDiscount = async (token, id) => {
  const res = await fetch(
    `${DIOM_BASED_URLS}/plan-discounts/${id}`,

    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  const data = await res.json();
  // console.log(data, "PlanSelected location data");
  return data.planResources;
};
