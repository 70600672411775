import moment from "moment";
import { DIOM_BASED_URLS } from "../config/url";

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns a list of all deleted users
 */

export const getDeletedUser = async (
  size = 30,
  page = 1,
  token = "",
  applyFilter = false,
  history
) => {
  const res = await fetch(`${DIOM_BASED_URLS}/user-delete-requests`, {
    method: "GET",
    redirect: "follow",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resJson = await res.json();

  const DeltedUserData = resJson.map((e, index) => ({
    ...e,
    userName: e.userName,
    timeRemaining: moment.utc(e?.ttl).local().startOf("seconds").fromNow(),
    recentCheckin: e.recentCheckin
      ? moment(e.recentCheckin).format("YYYY-MM-DD HH:MM")
      : "N/A",
    contact: e.contact,
    createdAt: moment(e.createdAt).format("YYYY-MM-DD HH:MM"),
  }));

  return {
    data: DeltedUserData,
    total: resJson.total,
    hasNextPage: resJson.hasNextPage,
    hasPreviousPage: resJson.hasPreviousPage,
  };
};
